import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    promotionCardContainer: {
        padding: '10px',
        backgroundColor: 'black',
        borderRadius: '12px',

        display: 'flex',
        flexDirection: 'column',
        gap: '12px',

        margin: 'auto',
        minWidth: '197px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '365px'
        }
    },
    promotionCardImage: {
        objectFit: 'cover',
        borderRadius: '6px',
        minHeight: '220px'
    },
    dividerContainer: {
        margin: '6px 8px',
        borderBottom: '1px dashed #999999',
        position: 'relative'
    },
    leftCircle: {
        backgroundColor: 'white',
        width: '23px',
        height: '23px',
        borderRadius: '50%',
        position: 'absolute',
        left: '-32px',
        top: '-10px'
    },
    rightCircle: {
        backgroundColor: 'white',
        width: '23px',
        height: '23px',
        borderRadius: '50%',
        position: 'absolute',
        right: '-32px',
        top: '-10px'
    },
    promotionTitleMain: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: '12px !important',
        color: 'white',
        height: 'calc(12px * 1.5 * 2)',
        overflow: 'hidden',
        textOverflow: 'clip',
        textAlign: 'center'
    },
    promotionCardButton: {
        backgroundColor: 'white !important',
        height: '32px',
        borderRadius: '6px',
        '& p': {
            color: 'black !important',
            fontFamily: "'GintoNormal-Medium', sans-serif !important"
        },
        '&:hover': {
            backgroundColor: 'var(--red) !important',
            '& p': {
                color: 'white !important'
            }
        }
    }
}))
