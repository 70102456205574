import React from 'react'
import ReactQuill from 'react-quill'
import { FormattedMessage } from 'react-intl'
import { Box, Switch } from '@material-ui/core'

import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import { useStyles } from './OfferForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import { INTERNSHIP, QUILL_MODULES } from '../../../settings'

const OfferForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleAttributeChange } = props

    return (
        <Box className={sharedClasses.formContainer}>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.we_offer" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.weOffer || ''}
                        name="weOffer"
                        onChange={(value) => handleAttributeChange('weOffer', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.weOffer} />
                {jobForm.jobType === INTERNSHIP && <FieldDivider />}
            </Box>
            {jobForm.jobType === INTERNSHIP &&
                <Box className={sharedClasses.formField}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.paid_internship" />} />
                    <Switch
                        name='internshipPaid'
                        classes={{ root: sharedClasses.iosSwitch }}
                        checked={jobForm.internshipPaid}
                        value={jobForm.internshipPaid}
                        onChange={() => handleAttributeChange('internshipPaid', !jobForm.internshipPaid)}
                    />
                    <FieldError errorMessage={errors.internshipPaid} />
                </Box>
            }
        </Box>
    )
}

export default OfferForm
