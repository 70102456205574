import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    titlePage: {
        textAlign: 'left',
        fontSize: '14px !important'
    },
    titleContainer: {
        justifyContent: 'flex-start',
        marginBottom: '1rem',
        width: '80%'
    },
    renderArticles: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    }
}))
