/* eslint-disable react/prop-types */

import React from 'react'
import css from './companypagedescription.scss'
import css2 from './companypagedescriptionresponsive.scss'
import ResponsiveVideo from 'components/ResponsiveVideo/ResponsiveVideo'
import { Image, Transformation } from 'cloudinary-react'

import Hidden from '@material-ui/core/Hidden'
import { FormattedMessage } from 'react-intl'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import ArticleCardNew from 'cards/ArticleCardNew/ArticleCardNew'
import JobCard from 'cards/JobCard/JobCard'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './CompanyPageDescription_style'
import { LARGER_SIZE, MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

class CompanyPageDescription extends React.Component {
    constructor (props) {
        super(props)
        this.isMobile = window.innerWidth <= 1140
    }

    getYoutubeId (url) {
        if (url) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
            var match = url.match(regExp)
            return (match && match[7].length === 11) ? match[7] : false
        }
    }

    getNameFromCollection (labelValue, collection) {
        const labelObj = this.props.formParams[collection].find((obj) => {
            return obj.value === labelValue
        })
        if (labelObj) {
            return labelObj.name
        }
        return ''
    }

    render () {
        return (
            <Container maxWidth='lg' className={this.props.classes.container}>
                <Hidden mdUp>
                    <Box className={this.props.classes.logoContainer}>
                        <Image
                            className={this.props.classes.logoImage}
                            cloudName="studentbe"
                            publicId={this.props.companyData.logo_cloudinary_key}
                            alt={`${this.props.companyName} ${this.props.companyData.logo_cloudinary_key} logo`}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                        </Image>
                    </Box>
                </Hidden>
                <Box>
                    <Grid container spacing={10}>
                        <Grid item xs={12} md={8}>
                            <Box>
                                <Typography variant='h2' component='h1' className={this.props.classes.titlePage}>
                                    {this.props.companyName.toUpperCase()}
                                </Typography>
                            </Box>
                            {((this.props.mainShowVideo && this.props.mainVideoUrl && this.props.mainVideoUrl.length > 0) || (!this.props.mainShowVideo && this.props.mainImageCloudinaryKey) || this.props.edit) &&
                                <Grid item>
                                    {this.props.mainShowVideo && this.props.mainVideoUrl && this.props.mainVideoUrl.length > 0 &&
                                        <ResponsiveVideo videoUrl={this.props.mainVideoUrl} />
                                    }
                                    {!this.props.mainShowVideo && this.props.mainImageCloudinaryKey &&
                                        <Box>
                                            <Image
                                                className={this.props.classes.mainImage}
                                                cloudName="studentbe"
                                                publicId={this.props.mainImageCloudinaryKey}
                                                alt={`${this.props.companyName} ${this.props.mainImageCloudinaryKey} main image`}
                                            >
                                                <Transformation flags="progressive" fetchFormat="auto" width={LARGER_SIZE} quality="auto:best" crop="fill" />
                                            </Image>
                                        </Box>
                                    }
                                    {!this.props.mainVideoUrl && !this.props.mainImageCloudinaryKey && this.props.edit &&
                                        <Typography variant='body2' className="caption-text">
                                            <FormattedMessage id="company_profile.company_page_description.add_img_video" />
                                        </Typography>
                                    }
                                </Grid>
                            }
                            <Grid item>
                                {
                                    this.props.edit && this.props.companyDescription && this.props.companyDescription.length <= 13
                                        ? <Typography className="caption-text">
                                            <FormattedMessage id="company_profile.company_page_description.add_description" />
                                        </Typography>
                                        : <Typography
                                            className={this.props.classes.text}
                                            dangerouslySetInnerHTML={{ __html: this.props.companyDescription }}
                                            component='div'
                                        />
                                }
                            </Grid>
                        </Grid>
                        {(this.props.highlightedArticle || this.props.highlightedOffer) &&
                            <Hidden smDown>
                                <Grid item xs={12} md={4}>
                                    <Typography variant='h2' className={this.props.classes.subtitle}>
                                        <FormattedMessage id="company_profile.company_page_description.highlights" />
                                    </Typography>
                                    <Box className={this.props.classes.highlights}>
                                        {this.props.highlightedArticle &&
                                            <ArticleCardNew
                                                article={this.props.highlightedArticle}
                                                id={'articlecard_' + this.props.highlightedArticle.id}
                                                key={'articlecard_' + this.props.highlightedArticle.id}
                                                userType={this.props.userType}
                                            />}
                                        {this.props.highlightedOffer &&
                                            <Box>
                                                <JobCard
                                                    job={this.props.highlightedOffer}
                                                    id={'listcard_' + this.props.highlightedOffer.id}
                                                    key={'listcard_' + this.props.highlightedOffer.id}
                                                    userType={this.props.userType}
                                                />
                                            </Box>}
                                    </Box>
                                </Grid>
                            </Hidden>}
                    </Grid>
                </Box>
            </Container>
        )
    }
}

export default withStyles(useStyles)(CompanyPageDescription)
