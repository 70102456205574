/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Image, Transformation } from 'cloudinary-react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'

import { useStyles } from '../JobShowBanner_style'
import ApplyButton from '../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import FilledButton from 'buttons/FilledButton/FilledButton'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'

const JobShowBannerMobile = (props) => {
    const { user, job, unavailable, toggleBoost } = props
    const classes = useStyles()
    const userHasAccessToBoost = EmployerJobIndexHelper.userHasAccessToBoost(job, user, job.current_user_is_job_owner)
    const isJobBoostable = EmployerJobIndexHelper.isJobBoostable(job)

    return (
        <Box className={classes.bannerContainer}>
            <Typography variant='h6' component='h1' className={classes.jobTitleBox}>
                {job.title}
            </Typography>
            <Box className={classes.infoBannerContainer}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={job.company_url}
                    className={classes.logoBox}
                >
                    <Image
                        className={classes.logo}
                        cloudName="studentbe"
                        publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                        alt={`${job.company_logo_cloudinary_key} logo`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                    </Image>
                </RouteMapLink>
                <Box className={classes.jobInfoContainer}>
                    <Box className={classes.companyNameBox}>
                        {job.company_name?.toUpperCase()}
                    </Box>
                    <Box className={classes.tagBox}>
                        {job.company_size &&
                            <Chip
                                className={classes.tag}
                                label={CollectionHandler.Translate('companySizeCollection', job.company_size)}
                            />
                        }
                        {job.sector &&
                            <Chip
                                className={classes.tag}
                                label={CollectionHandler.Translate('sectorCollection', job.sector)}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            {!unavailable &&
                <>
                    <Box className={classes.ctaBox}>
                        <ApplyButton
                            job={job}
                            user={user}
                            unavailable={unavailable}
                            className={classes.applyButton}
                        />
                    </Box>
                    {userHasAccessToBoost && <FilledButton
                        newStyle
                        color='secondary'
                        onClick={toggleBoost}
                        name={isJobBoostable ? <FormattedMessage id="company_job_card.buttons.boost" /> : <FormattedMessage id="company_job_card.buttons.boosted" />}
                        disabled={!isJobBoostable}
                        className={isJobBoostable ? classes.boostable : classes.boosted}
                        forceWrap
                    />}
                </>
            }
        </Box>
    )
}

export default JobShowBannerMobile
