import React from 'react'

import { useStyles } from './NavigationUserActions_style'
import { Box } from '@material-ui/core'

import LangSelect from 'inputs/LangSelect/LangSelect'
import NavigationUserActionsLoggin from './NavigationUserActionsLoggin/NavigationUserActionsLoggin'
import NavigationUserActionsLogout from './NavigationUserActionsLogout/NavigationUserActionsLogout'

const NavigationUserActions = (props) => {
    const classes = useStyles()
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props

    return (
        <Box className={classes.userActionsContainer}>
            <Box className={classes.langSelect}>
                <LangSelect
                    altLangUrls={altLangUrls}
                    color={darkMode ? 'white' : 'var(--black)'}
                    paramsForUrlTranslation={paramsForUrlTranslation}
                />
            </Box>
            {user
                ? <NavigationUserActionsLoggin user={user} darkMode={darkMode} />
                : <NavigationUserActionsLogout darkMode={darkMode}/>
            }
        </Box>
    )
}

export default NavigationUserActions
