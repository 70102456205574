import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    regularLine: {
        display: 'flex'
    },
    iconBox: {
        width: 18,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 1
    },
    text: {
        fontSize: '14px !important',
        lineHeight: '20px',
        marginLeft: 8,
        marginBottom: 10
    }
}))
