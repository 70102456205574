import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    popperDropDown: {
        width: '100%',
        position: 'absolute !important' // Overriding <tooltip> in HTML
    },
    paperDropDown: {
        maxWidth: '1285px',
        minWidth: '1200px',

        position: 'absolute',
        top: '76px',
        left: 0,
        right: 0,
        margin: 'auto',

        borderRadius: '16px',
        boxShadow: 'var(--shadow-paper)'
    },
    dropDownContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '12px 12px'
    }

}))
