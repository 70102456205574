import React from 'react'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import { Box, Chip, Typography } from '@material-ui/core'
import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import { useStyles } from './ArticleListMinimalCard_style'

const ArticleListMinimalCard = (props) => {
    const classes = useStyles()
    const { article } = props

    return (
        <RouteMapLink showItemType='article' showItemUrl={article.url} style={{ width: '100%' }}>
            <Box className={classes.main}>
                <Typography variant='h3' className={classes.articleTitle}>
                    {article.title}
                    <span className={classes.readMore} id='hidden-text'>Read More →</span>
                </Typography>
                <Box className={classes.articleInfo}>
                    <Box className={classes.readTimeAndDate}>
                        <Box className={classes.readContainer}>
                            <IconFactory
                                icon='clock'
                                fontSize='12px'
                                color='var(--grey)'
                                style={{
                                    fontFamily: 'GintoNormal-Medium, sans-serif !important'
                                }}
                            />
                            <Typography variant='body2' component='span' className={classes.minorText}>
                                {`${article.read_time} min`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </RouteMapLink>
    )
}

export default ArticleListMinimalCard
