import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap.js'
import NavigationAPI from 'api/NavigationAPI'

const FooterList = (props) => {
    const { type, classes } = props

    const [filters, setFilters] = useState(false)

    useEffect(() => {
        NavigationAPI.GetNavigationCollection({
            locale: RouteMap.GetLocaleFromUrl(),
            type: type
        }).then((response) => {
            setFilters(response.data.filters)
        })
    }, [])

    const buildRouteMapLinkProps = (filter) => {
        switch (type) {
        case 'student-jobs':
            return { location: filter.value_name }
        case 'first-jobs':
        case 'internships':
            return { domain: filter.value_name }
        case 'news':
            return { articleCategory: filter.value_name }
        }
    }

    const renderLink = () => {
        return (
            filters.map((filter) =>
                <li key={`${filter.name} link`}>
                    <RouteMapLink
                        itemType={type}
                        {...buildRouteMapLinkProps(filter)}
                        className={`${classes.link}`}
                    >
                        {type !== 'news' && <FormattedMessage id={`page_footer_navbar_data.student_job_button_${type}`}/>}
                        {filter.name}
                    </RouteMapLink>
                </li>
            )
        )
    }

    return (
        <ul className={classes.link1}>
            {filters && renderLink()}
        </ul>
    )
}

export default FooterList
