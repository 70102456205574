import React, { useState }  from 'react'
import ReactQuill from 'react-quill'
import { Box, Typography, Grid, TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import IconFactory from 'icons/IconFactory/IconFactory'
import FieldError from '../../components/FieldError/FieldError'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import CollectionHandler from 'assets/CollectionHandler.js'
import Field from 'inputs/Field/Field'
import { useStyles } from './StudentJobExtraForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import OpenQuestions from '../../components/OpenQuestions/OpenQuestions'
import { QUILL_MODULES } from '../../../settings'

const StudentJobExtraForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleChange, handleUpdate, handleAttributeChange, updateMainImage } = props
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studentJobAdditionalRequestsCollection = CollectionHandler.Get('studentJobAdditionalRequestsCollection')
    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        handleUpdate({ [attribute]: formattedItems })
    }

    const handleQuestionsValidated = (updatedQuestions) => {
        handleAttributeChange('candidateQuestions', updatedQuestions)
    }

    const clearDate = () => {
        const dateInput = document.getElementById('studentJobStartDate')
        dateInput.value = ''
        handleAttributeChange('studentJobStartDate', null)
    }

    return (
        <Box className={sharedClasses.formContainer}>
            {/* COMPANY DESCRIPTION */}
            <Box className={sharedClasses.formField}>
                <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.company_description" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.companyDescription || ''}
                        name="companyDescription"
                        onChange={(value) => handleAttributeChange('companyDescription', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.companyDescription} />
                <FieldDivider />
            </Box>
            {/* SEARCHED PROFILE */}
            <Box className={sharedClasses.formField}>
                <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.searched_profile" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.candidateSearchedProfile || ''}
                        name="candidateSearchedProfile"
                        onChange={(value) => handleAttributeChange('candidateSearchedProfile', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.candidateSearchedProfile} />
                <FieldDivider />
            </Box>
            {/* WE OFFER */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.we_offer" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="jobcreationfunnel.sub_titles.we_offer" />} />
                </Box>
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.weOffer || ''}
                        name="weOffer"
                        onChange={(value) => handleAttributeChange('weOffer', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.weOffer} />
                <FieldDivider />
            </Box>
            {/* START DATE */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.start_date" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.specific_period" />} />
                </Box>
                <Grid container>
                    <Grid item xs={11} md={4} className={sharedClasses.dateSelection}>
                        <FormattedMessage id="job_form.step_2.specific_period_placeholder">
                            {placeholder =>
                                <TextField
                                    type="date"
                                    id="studentJobStartDate"
                                    name="studentJobStartDate"
                                    value={jobForm.studentJobStartDate}
                                    classes={{ root: sharedClasses.dateInput }}
                                    placeholder={placeholder}
                                    onChange={handleChange}
                                />
                            }
                        </FormattedMessage>
                    </Grid>
                    <Grid item xs={1} md={2}>
                        <IconFactory type='button' onClick={clearDate} icon='close' color='var(--black)'/>
                    </Grid>
                </Grid>
                <FieldError errorMessage={errors.studentJobStartDate} />
                <FieldDivider />
            </Box>
            {/* MOBILITY */}
            <Box className={sharedClasses.formField}>
                <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.mobility" />} />
                <TagMultiSelect
                    setSelectedItems={(items) => handleSelectedItems(items, 'studentRequest')}
                    selectedItems={jobForm.studentRequest.map(request =>  { return { value: request } })}
                    collection={studentJobAdditionalRequestsCollection}
                />
                <FieldError errorMessage={errors.studentRequest} />
                <FieldDivider />
            </Box>
            {/* STUDY DOMAIN */}
            <Box className={sharedClasses.formField}>
                <Box>
                    <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.student_job_study_domains" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="jobcreationfunnel.subtitles.student_job_study_domains" />} />
                </Box>
                <TagMultiSelect
                    setSelectedItems={(items) => handleSelectedItems(items, 'candidateStudyDomains')}
                    selectedItems={jobForm.candidateStudyDomains.map(studyDomain =>  { return { value: studyDomain } })}
                    collection={studyDomainCollection}
                />
                <FieldError errorMessage={errors.candidateStudyDomains} />
                <FieldDivider />
            </Box>
            {/* QUESTIONS */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.open_questions" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="open_questions.sub_title" />} />
                </Box>
                <OpenQuestions
                    type={jobForm.jobType}
                    jobIsActive={jobForm.isActive}
                    openQuestions={jobForm.candidateQuestions}
                    handleQuestionsValidated={handleQuestionsValidated}
                    error={errors.questionsValidated}
                />
                <FieldError errorMessage={errors.questionsValidated} />
            </Box>
        </Box>
    )
}

export default StudentJobExtraForm
