/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react'

import css from './articleshowcard.scss'
import css_responsive from './articleshowcard_responsive.scss'

import RouteMap from 'assets/RouteMap'
import DateHandler from 'assets/DateHandler'
import ShareAction from 'components/ShareAction/ShareAction'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import Tooltip from '@material-ui/core/Tooltip'

import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { FormattedMessage } from 'react-intl'

export default class ArticleShowCard extends React.Component {
    render () {
        return (
            <Box style={{
                borderBottom: '1px solid var(--red)',
                marginBottom: 15,
                marginTop: 20
            }}>
                <Typography
                    variant='h1'
                    color='primary'
                    style={{
                        fontSize: 38,
                        textAlign: 'center',
                        marginBottom: 15
                    }}
                >
                    {this.props.title}
                </Typography>
                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Typography variant='subtitle1'>
                            <FormattedMessage id='landing_article_show.by'/>
                            <RouteMapLink href={this.props.companyUrl}>
                                <span>{this.props.companyName}</span>
                            </RouteMapLink>
                        </Typography>
                        <Divider orientation='vertical' style={{ margin: '0 5px', color: 'var(--grey)', height: 15 }} />
                        <time dateTime={DateHandler.ToSchemaFormat(this.props.date)}>
                            <Typography
                                variant='subtitle1'
                            >
                                {DateHandler.ToBasicFormat(this.props.date)}
                            </Typography>
                        </time>
                        <Divider orientation='vertical' style={{ margin: '0 5px', color: 'var(--grey)', height: 15 }} />
                        <Box className='reading-time'>
                            <Typography variant='subtitle1'>
                                <IconFactory icon='clock' />
                                {this.props.readingTime} mins
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        {
                            (this.props.isOwner || this.props.isAdmin) &&
                                <Tooltip title={'Editer votre annonce'}>
                                    <IconButton onClick={() => {
                                        window.location.href = RouteMap.Edit('article', this.props.id)
                                    }}>
                                        <IconFactory
                                            icon='edit'
                                            style={{ color: 'var(--grey)' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                        }
                        <ShareAction
                            infos={{
                                title: this.props.title,
                                url: window.location.href,
                                type: 'article'
                            }}
                            tooltip='Partager'
                        />
                    </Box>
                </Box>
            </Box>
        )
    }
}
