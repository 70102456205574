import React from 'react'
import { FormControl, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormattedMessage } from 'react-intl'
import EndAdornment from '../EndAdornment/EndAdornment'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceStudyFieldInput = (props) => {
    const { inputRef, error, showEndAdornment, studentFormation, setStudentFormation, disableUnderline, studyFieldCollection, formationKey, textFontClassName, InputAdornmentClassName } = props
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <Autocomplete
                disableUnderline={disableUnderline}
                className={InputAdornmentClassName}
                disableClearable
                forcePopupIcon={false}
                options={studyFieldCollection}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                value={studentFormation[formationKey].field_of_study}
                onChange={(event, newValue) => {
                    studyFieldCollection.forEach(
                        (fields) => {
                            if (fields.name === newValue.name) {
                                studentFormation[formationKey].field_of_study = newValue
                                if (newValue.specializations) {
                                    studentFormation[formationKey].specializationList = newValue.specializations
                                } else {
                                    studentFormation[formationKey].specializationList = ''
                                }
                                setStudentFormation([...studentFormation])
                            }
                        }
                    )
                }}
                renderInput={(params) =>
                    <FormattedMessage id='landing_experience_profile.choose_field_of_study'>
                        {placeholder =>
                            <TextField {...params}
                                required
                                placeholder={placeholder}
                                inputRef={inputRef}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: showEndAdornment && <EndAdornment/>,
                                    classes: { input: `${textFontClassName} ${error && classes.textFieldError}` },
                                    disableUnderline: disableUnderline
                                }}
                            />
                        }
                    </FormattedMessage>
                }
            />
        </FormControl>
    )
}

export default ExperienceStudyFieldInput
