import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    card: {
        height: '100%',
        width: 280,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: '24px 28px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            width: 320 ,
        }
    },
    title: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: '24px !important',
        marginBottom: 40
    },
    buttonText: {
        display: 'flex',
        alignItems: 'center',
        gap: 7
    }
}))
