import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    moreFilterBox: {
        position: 'relative',
        textTransform: 'none',
        fontFamily: "'GintoNormal-Bold', sans-serif !important",
        fontSize: 14,
        backgroundColor: 'white',
        borderRadius: 12,
        color: 'black',
        padding: '9.5px 14px !important',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'var(--light-grey)'
        }
    },
    contentBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    numberFilters: {
        position: 'absolute',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'var(--beige)',
        fontSize: 14,
        borderRadius: 50,
        width: 20,
        height: 18,
        top: -5,
        right: -5,
        zIndex: 1
    }
}))
