import React, { useContext } from 'react'

import { useStyles } from './CTABanner_style'
import { Box, Typography } from '@material-ui/core'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import URLParamsHandler from 'assets/URLParamsHandler'

const CTABanner = ({ user, origin, link, title, buttonText, reloadProcess, buttonIcon, skipLogin }) => {
    const classes = useStyles()
    const loginRegisterContext = useContext(LoginRegisterContext)

    const clickNotConnected = () => {
        loginRegisterContext.openLoginRegisterModal({
            origin: origin,
            afterAuthAction: afterAuthAction
        })
    }

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'cta_access' })
    }

    return (
        <Box className={classes.CTAContainer}>
            <Typography component='span' className={classes.CTAContainerTitle}>
                {title}
            </Typography>
            {user || skipLogin
                ? <RouteMapLink target='_blank' redirectionUrl={link} className={classes.ctaLink}>
                    <FilledButton
                        startIcon={<IconFactory icon={buttonIcon} />}
                        name={buttonText}
                        className={classes.ctaButton}
                        color='secondary'
                    />
                </RouteMapLink>
                : <FilledButton
                    startIcon={<IconFactory icon={buttonIcon} />}
                    name={buttonText}
                    color='secondary'
                    onClick={clickNotConnected}
                    className={classes.ctaButton}
                />
            }
        </Box>
    )
}

export default CTABanner
