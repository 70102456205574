import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ArticleShow from 'pages/ArticleShow/ArticleShow'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import JobShow from 'pages/JobShow/JobShow'
import KotShow from 'pages/KotShow/KotShow'
import PromotionShow from 'pages/PromotionShow/PromotionShow'
import URLHandler from 'assets/URLHandler.js'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import NoJobShow from 'pages/JobShow/components/NoJobShow/NoJobShow'
import LostKotPage from 'pages/Lost/LostKotPage/LostKotPage'

const ItemShowFactory = (props) => {
    const [state, setState] = useState({
        item: {},
        itemid: props.match.params.itemid.split('?')[0],
        needRedirect: false,
        redirect_to: '',
        loading: true,
        unavailable: false,
        doesNotExist: false
    })
    const [feedback, setFeedback] = useState({
        feedback: {
            open: false,
            severity: 'success',
            message: null
        }
    })

    // === PrerenderReady UseEffect ===
    // Note: Temporary delayed implemented in the meantime of implementing
    // the prerender-ready context. The purpose is to delayed as much as possible
    // the useEffect, in order to wait for the entire page to load before
    // triggering prerenderReady == True
    useEffect(() => {
        const delay = 500
        const timerId = setTimeout(() => {
            if (!state.loading) window.prerenderReady = true
        }, delay)

        return () => clearTimeout(timerId)
    }, [state])

    // TODO: === Context PreRenderReady True ===
    // Add 'PrerenderReady = true' switch with new specific context.
    // A context should be used here so that all elements loading items in the
    // the page can communicate to the context that they have finished loading.
    // After all elemnents have loaded, prerenderReady can be set to true, indicating
    // the page can be rendered in Prerender.io

    useEffect(() => {
        axios.get(
            props.getAllInfoUrl + props.match.params.itemid, 
            { params: { locale: URLHandler.GetLocaleFromUrl() }}
        )
            .then((response) => {
                setState({
                    item: response.data,
                    loading: false,
                    unavailable: response.data.unavailable,
                    doesNotExist: response.data.does_not_exist
                })
            })
            .catch((error) => {
                if (error.response) {
                    setState({
                        item: error.response.data,
                        loading: false,
                        unavailable: error.response.data.unavailable,
                        doesNotExist: error.response.data.does_not_exist
                    })
                } else {
                    setFeedback({
                        open: true,
                        severity: 'error',
                        message: <FormattedMessage id='item_show.error.server_crash' />
                    })
                }
            })
    }, [])

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    const closeShow = () => {
        window.history.back()
    }

    const renderItemShow = () => {
        if (props.renderType === 'job') {
            return (
                <JobShow
                    closeJob={closeShow}
                    job={state.item}
                    user={props.user}
                    recentArticles={props.recentArticles}
                    ads={props.ads}
                    unavailable={state.unavailable}
                    doesNotExist={state.doesNotExist}
                />
            )
        }
        if (props.renderType === 'kot') {
            return (
                <KotShow
                    closeKot={closeShow}
                    similarUrl={props.similarUrl}
                    kot={state.item}
                    recentArticles={state.item.recent_articles}
                    user={props.user}
                    ads={props.ads}
                    unavailable={state.unavailable}
                    doesNotExist={state.doesNotExist}
                />
            )
        }
        if (props.renderType === 'article') {
            return (
                <ArticleShow
                    ads={props.ads}
                    article={state.item}
                    closeShow={closeShow}
                    user={props.user}
                    lang={props.lang}
                />
            )
        }
        if (props.renderType === 'promotion') {
            return (
                <PromotionShow
                    promotion={state.item}
                    lang={props.lang}
                    user={props.user}
                />
            )
        }
    }

    const shouldRenderDoesNotExist = () => {
        return (
            state.doesNotExist || (props.renderType === 'article' && state.unavailable)
        )
    }

    const renderShowContent = () => {
        if (shouldRenderDoesNotExist()) {
            return renderDoesNotExist()
        } else {
            return renderItemShow()
        }
    }

    const renderDoesNotExist = () => {
        if (props.renderType === 'job') {
            return <NoJobShow user={props.user} />
        } else if (props.renderType === 'kot') {
            return <LostKotPage />
        } else {
            return (<>
                <Helmet>
                    <meta name='robots' content='noindex' />
                    <meta name='googlebot' content='noindex' />
                </Helmet>
                <Container maxWidth="lg">
                    <Box my={8}>
                        <Typography variant='body2'>
                            <FormattedMessage id={`item_show.does_not_exist.${props.renderType}`} />
                        </Typography>
                    </Box>
                </Container></>
            )
        }
    }

    if (state.needRedirect) {
        return <Redirect to={state.redirect_to}/>
    }

    return (
        <div>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                severity={feedback.severity}
                closeFeedback={closeFeedback}
            />
            {state.loading
                ? <div className="loading-cirular">
                    <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                </div>
                : renderShowContent()
            }
        </div>
    )
}

export default ItemShowFactory
