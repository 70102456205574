/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../KotShowBanner_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import IconFactory from 'icons/IconFactory/IconFactory'
import DateHandler from 'assets/DateHandler'

const KotShowBannerDesktop = (props) => {
    const { kot, unavailable, date, renderApplyBox, user } = props
    const classes = useStyles()

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.infoBannerContainer}>
                <Box className={classes.kotInfoContainer}>
                    <Typography variant='h6' component='h1' className={classes.kotTitleBox}>
                        {kot.title}
                    </Typography>
                    <Box className={classes.kotLocalisationContainer}>
                        <Box>
                            <Box className={classes.mapIcon}>
                                <IconFactory icon='marker' />
                            </Box>
                        </Box>
                        <Typography className={classes.address}>
                            <span className={classes.bold}>{kot.city.toLowerCase()} {kot.zip}</span>, {kot.street.toLowerCase()}
                        </Typography>
                    </Box>
                    <Typography component='p' className={classes.kotDate}>
                        <time dateTime={DateHandler.ToSchemaFormat(kot.updated_at)}>
                            <FormattedMessage id='transl_internship.job_show.published' values={{ date: date }} />
                        </time>
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.kotPriceContainer}>
                <Typography variant='h6' component='p' className={classes.kotPrice}>
                    {kot.charges === 0
                        ? <>€{kot.rent}</>
                        : <>€{kot.rent} (+{kot.charges})</>
                    }
                </Typography>
            </Box>
            {!unavailable &&
                <Box className={classes.applyBox}>
                    {renderApplyBox()}
                    {user && user.type === 'employer' && (
                        <Typography className={classes.textLight}>
                            <FormattedMessage id='landing_card_kot_page.employer_cannot_contact' />
                        </Typography>
                    )}
                </Box>
            }
        </Box>
    )
}

export default KotShowBannerDesktop
