import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'

import SettingsAPI from 'api/SettingsAPI'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMap from 'assets/RouteMap'

import { useStyles } from './EmailSettings_style'
import { FormattedMessage } from 'react-intl'

const EmailSettings = ({ props }) => {
    const email = props.user.email
    const classes = useStyles()
    const [userState, setUserState] = useState({
        newEmail: '',
        confirmationEmail: '',
        currentPassword: '',
        errorEmail: '',
        errors: {}
    })

    const handleChangeData = prop => event => {
        setUserState({ ...userState, [prop]: event.target.value })
    }

    const canSubmit = () => {
        var canSubmit = true

        if (userState.newEmail !== userState.confirmationEmail) {
            setUserState({
                ...userState,
                errorEmail: <FormattedMessage id="landing_app_setting_page.errors.new_email" />
            })
            canSubmit = false
        }
        if (userState.currentPassword === '') {
            setUserState({
                ...userState,
                errors: {
                    password: <FormattedMessage id="landing_app_setting_page.errors.missing_password" />
                }
            })
            canSubmit = false
        }
        return canSubmit
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (canSubmit()) {
            SettingsAPI.ChangeEmail({
                email: email,
                new_email: userState.newEmail,
                confirm_email: userState.confirmationEmail,
                password: userState.currentPassword
            }).then((response) => {
                window.location.href = RouteMap.Page('users/settings/edit')
            }).catch((error) => {
                setUserState({ ...userState, errors: error.response.data.errors })
            })
        }
    }

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit} autoComplete="nope">
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography
                                variant='h3' component='h2'
                                color='primary'
                            >
                                <FormattedMessage id="landing_app_setting_page.change_email_address" />
                                {` (${email})`}
                            </Typography>
                        </Box>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                {/* This input serves as a hidden hack to prevent the browser from autofilling the new password fields */}
                                <input type="email" style={{ position: 'absolute', width: '0px', height: '0px', padding: '0', margin: '0', border: 'none', opacity: 0 }}/>
                                {/* This input serves as a hidden hack to prevent the browser from autofilling the new password fields */}
                                <FormattedMessage id="landing_app_setting_page.new_email_address">
                                    {label =>
                                        <TextField
                                            id="new-email"
                                            fullWidth
                                            type='email'
                                            value={userState.newEmail}
                                            onChange={handleChangeData('newEmail')}
                                            label={label}
                                            error={!!userState.errors.email}
                                            helperText={userState.errors.email}
                                            autoComplete='nope'
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormattedMessage id="landing_app_setting_page.confirm_email_address">
                                    {label =>
                                        <TextField
                                            id="new-email-confirmation"
                                            fullWidth
                                            type='email'
                                            value={userState.confirmationEmail}
                                            onChange={handleChangeData('confirmationEmail')}
                                            label={label}
                                            autoComplete='nope'
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <FormattedMessage id="landing_app_setting_page.current_password">
                                    {label =>
                                        <TextField
                                            fullWidth
                                            type={'password'}
                                            value={userState.currentPassword}
                                            onChange={handleChangeData('currentPassword')}
                                            label={label}
                                            error={userState.errors.password}
                                            helperText={userState.errors.password}
                                            autoComplete="current-password"
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display={'flex'} style={{ height: '100%' }} justifyContent='flex-end' alignItems='flex-end'>
                                    <FilledButton
                                        color='secondary'
                                        type='submit'
                                        name={<FormattedMessage id="landing_app_setting_page.save" />}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}
export default EmailSettings
