import React, { useState, useContext, useEffect } from 'react'
import { UserContext } from 'contexts/UserContext'
import { Box, Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../ProfileEditForm_style'
import StepButtons from '../StepButtons/StepButtons'
import StudentJobSearchStatus from 'components/StudentJobSearchStatus/StudentJobSearchStatus'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const ProfileEditFormStepSearchStatus = (props) => {
    const { step, nextStep, previousStep } = props
    const [jobSearchStatusError, setJobSearchStatusError] = useState(false)
    const userContext = useContext(UserContext)
    const classes = useStyles()

    useEffect(() => {
        if (jobSearchStatusError) validateStatus()
    }, [userContext.statusConfig])

    const validateStatus = () => {
        const userHasNotSelectedAJobType = userContext.userHasNotSelectedAJobType()
        setJobSearchStatusError(userHasNotSelectedAJobType)

        return userHasNotSelectedAJobType
    }

    const handleSubmit = (event) => {
        if (validateStatus()) return false

        AnalyticsHelper.sendGTMEvent(
            'Account Updated',
            AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
                userContext.user,
                userContext.user,
                userContext.user.current_experience,
                userContext.user.profile.birth_date || new Date(),
                'Personal Dashboard ProfileEditFormStepSearchStatus step 4'
            )
        )
        nextStep('Personal Dashboard step 4 status')
    }
    return (
        <Box className={classes.formBox}>
            <Typography variant='body1' className={`${classes.text} ${jobSearchStatusError && classes.errorText}`}>
                <FormattedMessage id='personal_dashboard.job_search_status_title' />
            </Typography>
            <StudentJobSearchStatus simple black dashboard context='Dashboard ProfilePassport ProfileEditForm' />
            <StepButtons
                step={step}
                previousStep={previousStep}
                nextStep={handleSubmit}
            />
        </Box>
    )
}
export default ProfileEditFormStepSearchStatus
