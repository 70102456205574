import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    mostReadContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& #upBorder': {
            width: '100%',
            height: 2,
            marginTop: -2,
            borderRadius: 20,
            backgroundColor: 'var(--beige)',
            transition: '0.3s ease-in-out'
        },
        [theme.breakpoints.up('md')]: {
            width: 305,
            '&:hover': {
                '& #upBorder': {
                    height: 4,
                    marginTop: -4,
                    backgroundColor: 'var(--red)'
                }
            }
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        },
        gap: 20
    },
    articleLink: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    }
}))
