import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cityFilterContainer: {
        width: '100%',
    },
    placeholderText: {
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16
        }
        
    },
    iconContainer: {
        marginRight: '8px',
        height: 19
    },
    dropdownContainer: {
        marginLeft: 20.7
    },
    baseInput: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 50,
        width: '100%',
        backgroundColor: 'var(--beige)',
        textTransform: 'none',
        '& span': {
            justifyContent: 'space-between'
        }

    },
    selectPlaceholder: {
        width: '100%'
    },
    selectSearchbar: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'var(--beige)',
        padding: '10px 14px',
        margin: '12px 0 12px 20px',
        borderRadius: 50
    },
    inputBase: {
        width: '100%',
        padding: 0
    },
    menuItem: {
        padding: '6px 20px'
    },
    formControl: {
        width: '100%'
    },
    boxInputSearchCity: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'sticky',
        backgroundColor: 'white',
        zIndex: 9999,
        top: 0,
        borderBottom: '1px solid #EFEAE5'
    },
    closeIconButton: {
        marginLeft: 8,
        marginRight: 8,
        height: 'fit-content',
        '& svg': {
            width: 18
        }
    },
    inputField: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
        height: 60,
        borderRadius: 12,
        [theme.breakpoints.down('sm')]: {
            height: 40
        }
    },
    underline: {
        '&:before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            pointerEvents: 'none'
        },
        '& svg': {
            color: 'var(--red)',
            margin: '4px 20px 0px',
            width: 16,
            height: 16,
            [theme.breakpoints.down('sm')]: {
                width: 12,
                height: 12,
                margin: '6px 10px 0px'
            }
        },
        '& div': {
            paddingLeft: 12,
            fontSize: 20,
            borderRadius: '12px !important',
            display: 'flex ',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 6,
                paddingRight: 6,
                fontSize: 16
            }
        }
    }
}))
