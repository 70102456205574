import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from '../FiltersModals_style'
import MoreFilterSectionWrapper from '../MoreFilterSectionWrapper/MoreFilterSectionWrapper'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersModalWrapper from 'modals/FilterModals/FiltersModalWrapper'

const KotFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, filters, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props

    const kotDisponibilityCollection = CollectionHandler.Get('kotDisponibilityCollection')
    const kotPriceCollection = CollectionHandler.Get('kotPriceCollection')
    const kotRoomCountCollection = CollectionHandler.Get('kotRoomCountCollection')
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')

    return (
        <FiltersModalWrapper
            type='kot'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            <MoreFilterSectionWrapper
                titleId='kot_filters.text_filter.city.title'
                underTitleId='kot_filters.text_filter.city.description'
            >
                <Box className={classes.filterBox}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='search_bar.kot_type.title'
                underTitleId='search_bar.kot_type.description'
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'kotType') }}
                    selectedItems={filters.kotType}
                    collection={kotTypeCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='search_bar.disponibility.title'
                underTitleId='search_bar.disponibility.description'
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'disponibility') }}
                    selectedItems={filters.disponibility}
                    collection={kotDisponibilityCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='search_bar.price.title'
                underTitleId='search_bar.price.description'
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'price') }}
                    selectedItems={filters.price}
                    collection={kotPriceCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='search_bar.rooms.title'
                underTitleId='search_bar.rooms.description'
                disableDivider
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'roomCount') }}
                    selectedItems={filters.roomCount}
                    collection={kotRoomCountCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
        </FiltersModalWrapper>
    )
}

export default KotFiltersModalView
