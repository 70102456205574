import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import IconFactory from 'icons/IconFactory/IconFactory'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './MoreFilters_style'

const MoreFilters = (props) => {
    const { setShowModal, filters } = props
    const classes = useStyles()
    const [nbFilters, setNbFilters] = useState(0)

    useEffect(() => {
        const nbFilters = Object.values(filters).reduce((count, value) => {
            if (Array.isArray(value) && value.length > 0) {
                return count + 1
            }
            if (typeof value === 'boolean' && value) {
                return count + 1
            }
            return count
        }, 0)
        setNbFilters(nbFilters)
    }, [filters])

    return (
        <Box>
            <Button className={classes.moreFilterBox} onClick={() => setShowModal(true)}>
                <Box className={classes.contentBox}>
                    <IconFactory
                        icon='sliders'
                        color='primary'
                        fontSize={20}
                        className={classes.moreFilterIcon}
                    />
                </Box>
                {nbFilters > 0 &&
                    <Box className={classes.numberFilters}>
                        <Box>{nbFilters}</Box>
                    </Box>
                }
            </Button>
        </Box>
    )
}

export default MoreFilters
