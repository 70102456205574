import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'

import { useStyles } from './HomeAlert_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { HOME_PAGE_BUTTON } from 'assets/AlertsHelper'
import FilledButton from 'buttons/FilledButton/FilledButton'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import RouteMap from 'assets/RouteMap'
import { Hidden } from '@material-ui/core'

const HomeAlert = (props) => {
    const { renderAlertPopup, user } = props
    const classes = useStyles()
    const context = useContext(LoginRegisterContext)

    const redirectUser = () => {
        if (user) {
            switch(user.type) {
                case 'student':
                    window.location.href = RouteMap.Page('users/dashboard')
                    break
                case 'employer':
                    window.location.href = RouteMap.Page('users/company_jobs')
                    break
                default:
                    window.location.href = RouteMap.Page('users/kots')
            }
        } else {
            window.location.href = RouteMap.Page('login')
        }
    }

    return (
        <Box className={classes.alertContainer}>
            <Box className={classes.alertLeft}>
                <Box className={classes.alertTextContainer}>
                    <Box className={classes.alertTitleContainer}>
                        <Typography variant='h6' component='h2' className={classes.alertTitle}>
                            <FormattedMessage id='landing_student.alert_title1' />
                        </Typography>
                    </Box>
                    <Hidden mdDown>
                        <Box className={classes.alertDescriptionContainer}>
                            <Typography variant='body2' component='p' className={classes.alertDescription}>
                                <FormattedMessage id='landing_student.alert_description' />
                            </Typography>
                        </Box>
                    </Hidden>
                </Box>
            </Box>
            <Box className={classes.alertRight}>
                <Image
                    cloudName='studentbe'
                    publicId='assets/home-alert-background.png'
                    alt='home-alert-background'
                    className={classes.imageBackground}
                />
                <FilledButton
                    newStyle
                    color='initial'
                    className={classes.alertButton}
                    onClick={() => redirectUser()}
                    name={<FormattedMessage id='landing_student.alert_button' />}
                />
            </Box>
        </Box>
    )
}
export default HomeAlert
