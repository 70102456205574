import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    fullWidth: {
        width: '100%',
        fontSize: '0.875rem'
    },
    CardBoxShadow: {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 7px 0px rgba(0,0,0,0.12)'
    },
    languagesContainer: {
        padding: 24,
        [theme.breakpoints.down('sm')]: {
            padding: '24px 8px'
        }
    },
    gridFlex: {
        display: 'flex',
        justifyContent: 'flex-start',
        textAlign: 'end'
    },
    formControl: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    isEmpty: {
        color: 'var(--red)'
    },
    datePicker: {
        color: 'white',
        textColor: 'white',
        calendarTextColor: 'white',
        selectColor: 'white',
        selectTextColor: 'white',
        calendarYearBackgroundColor: 'white',
        headerColor: 'white'
    },
    iconColor: {
    },
    textFont: {
        fontSize: '0.875rem'
    },
    languageContainer: {
        display: 'flex',
        alignItems: 'center'
    }

}))
