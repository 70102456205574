import React, { useEffect, useState, useContext } from 'react'

import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from '../CreditPackages_style'
import { UserContext } from 'contexts/UserContext'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import RouteMap from 'assets/RouteMap'
import FilledButton from 'buttons/FilledButton/FilledButton'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import IconFactory from 'shared/icons/IconFactory/IconFactory'


const FreeCreditPackage = (props) => {
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const registerForFreeCreditsUrl = userContext?.user
		? RouteMap.Page('employer/new-ad')
		: `${RouteMap.Page('login')}?userType=employer`

    const sendAnalyticsClickFreeJobs = () => {
        if (!userContext) AnalyticsHelper.sendGTMEvent('Clicked Publish Jobs Free')
    }

    const redirectToRegisterLogin = () => {
        window.location.href = registerForFreeCreditsUrl
    }

    const handleClickFreejobs = () => {
        sendAnalyticsClickFreeJobs()
        redirectToRegisterLogin()
    }

    return (
		<Box className={classes.packageCard}>
            <Box className={classes.infoSection}>
                <Box className={classes.cardIcon}>
                    <SVGFactory name='package-balloon' />
                </Box>
                <Typography variant='h6' component='h3' color='primary' className={`${classes.cardTitle} ${classes.uppercase} ${classes.withMinHeight}`}>
                    <FormattedMessage id='credit_packages.try_free' />
                </Typography>
                <Box className={classes.creditFeatures}>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.new' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.90_days' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.1_credit' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.credits_valid_4_months' /></Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.CTASection}>
                <Typography variant='h6' component='p' color='primary' className={classes.cardTitle}>
                    <FormattedMessage id='credit_packages.new_user' />
                </Typography>
                <Box className={classes.cardButton}>
                    <FilledButton
                        newStyle
                        name={<FormattedMessage id='credit_packages.publish_free' />}
                        color='secondary'
                        size='small'
                        className={`${classes.button} ${classes.squeezeText}`}
                        typoVariant='p'
                        forceWrap
                        disabled={!!userContext?.user}
                        onClick={handleClickFreejobs}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default FreeCreditPackage
