import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 24px',
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none'
        }
    },
    disableShadow: {
        boxShadow: 'none'
    },
    cardContent: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    typographyStyle: {
        textAlign: 'center'
    },
    conditionStyle: {
        fontSize: '12px',
        textAlign: 'justify',
        color: 'var(--grey)'
    },
    textUnderline: {
        textDecoration: 'underline'
    },
    loginButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 10
    },
    forgotPasswordContent: {
        marginTop: 10,
        fontStyle: 'italic'
    },
    buttonStyle: {
        width: '100%'
    },
    cardActionsContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    dividerStyle: {
        margin: '10px 0'
    },
    linkStyle: {
        cursor: 'pointer'
    },
    rememberMe: {
        marginLeft: '-9px'
    }
}))
