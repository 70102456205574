/* eslint-disable react/prop-types */
import React from 'react'

// import css from './cardcontainer.scss'
// import css_responsive from './cardcontainer_responsive.scss'

import CardFactory from 'cards/CardFactory/CardFactory'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { FormattedMessage } from 'react-intl'

export default class CardContainer extends React.Component {
    /**
         * @param props - Comes from your rails view.
         */
    constructor (props) {
        super(props)
        this.scroll = this.scroll.bind(this)
        this.onclick = this.onclick.bind(this)
        this.state = {}
        this.cardsRef = []
        this.bubbleRef = []
        this.flexRef = React.createRef()
        this.currentCard = 0
        for (let i = 0; i < this.props.cards.length; i++) {
            this.cardsRef.push(React.createRef())
            this.bubbleRef.push(React.createRef())
        }
    }

    scroll (event) {
        const scrollWidth = this.flexRef.current.scrollWidth
        const scrollLeft = this.flexRef.current.scrollLeft
        const next = Math.round(scrollLeft / (scrollWidth / this.props.cards.length))
        if (this.currentCard !== next) {
            this.bubbleRef[this.currentCard].current.classList.remove('active')
            this.bubbleRef[next].current.classList.add('active')
            this.currentCard = next
        }
    }

    onclick (index) {
        this.cardsRef[index].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    render () {
        if (this.cardsRef.length === 0) {
            for (let i = 0; i < this.props.cards.length; i++) {
                this.cardsRef.push(React.createRef())
                this.bubbleRef.push(React.createRef())
            }
        }

        return (
            <Box mb={2} ref={this.props.forwardRef}>
                <Grid container spacing={3} onScroll={this.scroll} ref={this.flexRef}>
                    {this.props.cards.length !== 0 ? this.props.cards.map((card, index) => {
                        return (
                            <Grid
                                key={index}
                                ref={this.cardsRef[index]}
                                item
                                xs={12}
                                sm={6}
                                md={4}
                            >
                                {
                                    <CardFactory
                                        type={this.props.type}
                                        cardsRef={this.cardsRef[index]}
                                        card={card}
                                        displayShow={this.props.displayShow}
                                        userType={this.props.userType}
                                        user={this.props.user}
                                        userAddress={this.props.userAddress}
                                        remove={this.props.remove}
                                        articleOwner={this.props.articleOwner}
                                        mapProviderProps={this.props.mapProviderProps}
                                    />
                                }
                            </Grid>
                        )
                    })
                        : <div className="card-container">
                            {this.props.noCardsText ? this.props.noCardsText : <FormattedMessage id="landing_about_pages.card_container" /> }
                        </div>}
                </Grid>
                <div className="bubble-selection-container">
                    {this.props.cards.map((card, index) => {
                        return (
                            <div
                                className={'bubble-selection' + (index === 0 ? ' active' : '')}
                                onClick={() => this.onclick(index)}
                                key={index}
                                ref={this.bubbleRef[index]}
                            />
                        )
                    })}
                </div>
            </Box>
        )
    }
}

CardContainer.defaultProps = {
    translation: {
        aboutPages: {
            card_container: <FormattedMessage id="landing_about_pages.card_container" />
        }
    },
    cards: [{ title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } },
        { title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } },
        { title: 'Courier Operations Representative', date: '5/11/2019', category: 'Jeune diplômé', type: 'First job', address: 'Brussels (200m)', company: { name: 'Puratos', logo_uid: '' } }],
    forwardRef: React.createRef()
}
