import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewsIndex_style'
import { Box, Container, Typography, Grid, Divider } from '@material-ui/core'

import ArticleAPI from 'api/ArticleAPI'
import ArticleCardV2 from 'shared/cards/ArticleCard/ArticleCardV2'
import ArticleListCard from 'shared/cards/NewsCard/ArticleListCard/ArticleListCard'
import ArticleListMinimalCard from 'shared/cards/NewsCard/ArticleListMinimalCard/ArticleListMinimalCard'
import CTANewsletter from 'shared/components/CTANewsletter/CTANewsletter'
import CollectionHandler from 'assets/CollectionHandler.js'
import HomeSearch from 'components/HomeSearch/HomeSearch'
import HorizontalCardsSlider from 'shared/components/HorizontalCardsSlider/HorizontalCardsSlider'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import NewsSearchBar from '../shared/NewsSearchBar/NewsSearchBar'
import PromotionAPI from 'api/PromotionAPI'
import PromotionCard from 'shared/cards/PromotionCard/PromotionCard'
import RouteMap from 'assets/RouteMap'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import TrendMainArticleCard from 'shared/cards/NewsCard/TrendMainArticleCard/TrendMainArticleCard'

import { NUMBER_TREND_ARTICLES, NUMBER_RECENT_ARTICLES, NUMBER_CLASSIC_ARTICLES, NUMBER_TOTAL_TREND_ARTICLES } from '../settings'

const NewsIndex = (props) => {
    const classes = useStyles()

    const [recentArticles, setRecentArticles] = useState([])
    const [mostReadArticles, setMostReadArticles] = useState([])
    const [classicArticles, setClassicArticles] = useState([])
    const [promotions, setPromotions] = useState([])
    const [trendMainArticles, setTrendMainArticles] = useState(null)
    const [trendArticles, setTrendArticles] = useState(null)

    const newsCategories = CollectionHandler.Get('newsIndexCategories')

    useEffect(() => {
        loadRecentArticles()
        loadMostReadArticles()
        loadClassicArticles()
        loadPromotions()
    }, [])

    useEffect(() => {
        setTrendMainArticles(mostReadArticles[0])
        setTrendArticles(mostReadArticles.slice(-NUMBER_TREND_ARTICLES))
    }, [mostReadArticles])

    // *** ASYNC ARTICLES & PROMOTIONS LOAD ***
    const loadRecentArticles = () => {
        ArticleAPI.GetRecentArticles({
            count: NUMBER_RECENT_ARTICLES
        }).then((response) => {
            setRecentArticles(response.data)
        })
    }

    const loadMostReadArticles = () => {
        ArticleAPI.getMostReadArticles({
            number_of_articles: NUMBER_TOTAL_TREND_ARTICLES
        }).then((response) => {
            setMostReadArticles(response.data)
        })
    }

    const loadClassicArticles = () => {
        ArticleAPI.getClassicArticles({
            number_of_articles: NUMBER_CLASSIC_ARTICLES
        }).then((response) => {
            setClassicArticles(response.data)
        })
    }

    const loadPromotions = () => {
        PromotionAPI.filterPromotion({

        }).then((response) => {
            setPromotions(response.data.promotions)
        })
    }
    // *** END BLOCK ASYNCS ***

    const createCategoryCase = (category) => {
        const categoryTranslated = CollectionHandler.Translate('articleCategoryCollection', category.name)
        return (
            <RouteMapLink redirectionUrl={`${RouteMap.Page('student-life')}categories/${CollectionHandler.categoryToValueTransformation(categoryTranslated)}/`}>
                <Box className={classes.categoryCase}>
                    <Box>
                        <IconFactory icon={category.icon} fontSize={30}/>
                    </Box>
                    <Box className={classes.categoryText}>
                        {categoryTranslated}
                    </Box>
                </Box>
            </RouteMapLink>
        )
    }

    const renderTrendArticles = () => {
        const articlesList = []
        trendArticles.forEach((article, index) => {
            articlesList.push(
                <ArticleListMinimalCard
                    article={article}
                    key={index}
                />
            )
            if (index < NUMBER_TREND_ARTICLES - 1) {
                articlesList.push(
                    <Divider orientation='horizontal' />
                )
            }
        })
        return articlesList
    }

    return (
        <Box className={classes.pageContainer}>
            {props.darkMode && <Box className={classes.bannerImage} />}
            <Box className={classes.titleContainer}>
                <Typography variant='h1' className={classes.titleText}>
                    <FormattedMessage id='news_index.topics_title' />
                </Typography>
                <Box className={classes.categoryListContainer}>
                    <Box className={classes.categoryList}>
                        {newsCategories.map((category) => createCategoryCase(category))}
                    </Box>
                </Box>
            </Box>
            <Container maxWidth='lg' disableGutters>
                <Box className={classes.searchContainer}>
                    <NewsSearchBar/>
                </Box>

                <Box className={classes.articlesListsContainer}>
                    {/* *** TRENDY ARTICLES *** */}
                    {trendMainArticles && trendArticles &&
                        <Grid container spacing={3} className={classes.trendiesTitlesContainer}>
                            <Grid item xs={12} md={6} className={classes.trendiesContainer}>
                                {renderTrendArticles()}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TrendMainArticleCard article={trendMainArticles} />
                            </Grid>
                        </Grid>
                    }
                    {/* *** CLASSIC ARTICLES *** */}
                    {!!classicArticles.length &&
                        <Box className={classes.classicContainer}>
                            <Box className={classes.classicArticlesContainer}>
                                {classicArticles.map((article, index) => {
                                    return (
                                        <ArticleCardV2 article={article} key={index} />
                                    )
                                })}
                            </Box>
                        </Box>
                    }

                    {/* *** MOST RECENT ARTICLES *** */}
                    {!!recentArticles.length &&
                        <Box className={classes.classicContainer}>
                            <Typography variant='h2' className={classes.sectionTitle}>
                                <FormattedMessage id='news_index.most_read' />
                            </Typography>
                            <Box className={classes.classicArticlesContainer}>
                                {recentArticles.map((article, index) => {
                                    return (
                                        <ArticleListCard article={article} key={index} />
                                    )
                                })}
                            </Box>
                        </Box>
                    }
                </Box>
            </Container>

            <Box className={classes.footerPageContainer}>
                <Box className={classes.CTAContainer}>
                    <HomeSearch />
                </Box>
                {/* <CTANewsletter /> */}
                {!!promotions.length &&
                    <Container maxWidth='lg' disableGutters>
                        <HorizontalCardsSlider
                            shift={500}
                            title={<FormattedMessage id='news_index.deals' />}
                        >
                            {promotions.map((promo, index) => {
                                return (
                                    <Box className={classes.promotionCardContainer} key={index}>
                                        <PromotionCard promotion={promo}/>
                                    </Box>
                                )
                            })}
                        </HorizontalCardsSlider>
                    </Container>
                }
            </Box>
        </Box>
    )
}

export default NewsIndex
