
export const useStyles = (theme) => ({
    root: {
        display: 'flex'
    },
    footerContainer1: {
        zIndex: 9000,
        backgroundColor: 'var(--beige)',
        // height: 400,
        display: 'flex',
        alignItems: 'center',
        padding: '60px 0'
    },
    titleColumn1: {
        lineHeight: '25px'
    },
    link1: {
        listStyle: 'none',
        paddingLeft: 0
    },
    footerContainer: {
        zIndex: 9000,
        backgroundColor: 'var(--black)',
        // height: 400,
        display: 'flex',
        alignItems: 'center',
        padding: '60px 0'
    },
    footerTitle: {
        color: 'white !important',
        margin: 'unset !important',
        fontSize: '20px !important',
        lineHeight: 1.3
    },
    linksContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    logoContainer: {
        marginRight: 50
    },
    titleColumn: {
        color: 'white',
        fontFamily: 'GintoNormal-Medium',
        lineHeight: '25px'
    },
    link: {
        color: 'white',
        marginTop: 2,
        fontFamily: 'GintoNormal-Regular'
    },
    allCity: {
        color: 'var(--red) !important',
        fontFamily: 'GintoNormal-Medium',
        textDecoration: 'none !important'
    },
    divider: {
        backgroundColor: 'white',
        marginTop: 30,
        marginBottom: 10
    },
    footerBottomLinks: {
        alignItems: 'center'
    },
    footerLeftBox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    footerRightBox: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    dividerLangIcons: {
        backgroundColor: 'white',
        height: 26,
        margin: '0 20px'
    },
    copyrightTypo: {
        fontFamily: 'GintoNormal-Regular',
        fontSize: 12,
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    iconCopyright: {
        fontSize: 14,
        marginRight: 5
    },
    iconsContainer: {
        width: 95,
        display: 'flex',
        justifyContent: 'space-between'
    },
    iconButton: {
        padding: 0
    },
    icon: {
        color: 'white',
        fontSize: 20
    },
    logo: {
        width: 100,
        height: 100,
        marginBottom: 20
    }
})
