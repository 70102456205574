import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from '../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import { Hidden } from '@material-ui/core'

import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from '../../../icons/IconFactory/IconFactory'
import QuickFilterArrows from '../shared/QuickFilterArrows/QuickFilterArrows'

const InternshipQuickFilters = (props) => {
    const classes = useStyles()
    const { filters, setFilters } = props
    const quickFilterInternshipCollection = CollectionHandler.Get('quickFilterInternshipCollection')
    const quickFiltersRef = useRef(null)

    const setQuickFilter = (selectedQuickFilter) => {
        if (selectedQuickFilter === filters.quickFilter) {
            setFilters({
                ...filters,
                quickFilter: ''
            })
        } else {
            setFilters({
                ...filters,
                quickFilter: selectedQuickFilter
            })
        }
    }

    return (
        <Box className={classes.quickFilterContainer}>
            <Box className={classes.quickFilters} ref={quickFiltersRef}>
                {quickFilterInternshipCollection.map(quickFilterOption => {
                    const isSelected = filters.quickFilter === quickFilterOption.value
                    return (
                        <Box
                            key={quickFilterOption.value}
                            className={`${classes.quickFilterOption} ${isSelected ? classes.selected : classes.notSelected}`}
                            onClick={() => setQuickFilter(quickFilterOption.value)}
                        >
                            <IconFactory icon={quickFilterOption.icon} />
                            <span className={classes.quickFilterText}>
                                <FormattedMessage id={`quick_filters.${quickFilterOption.value}`} />
                            </span>
                            <Box className={`${classes.optionUnderline} ${isSelected ? classes.underlineVisible : classes.underlineInvisible}`} />
                        </Box>
                    )
                })}
            </Box>
            <Hidden smDown>
                <QuickFilterArrows quickFiltersRef={quickFiltersRef} />
            </Hidden>
        </Box>
    )
}

export default InternshipQuickFilters
