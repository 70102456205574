import React from 'react'
import { TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceTextFieldInput = (props) => {
    const { value, inputRef, error, handleChange, placeholderTextId, textFontClassName, disableUnderline } = props
    const classes = useStyles()

    return (
        <FormattedMessage id={placeholderTextId}>
            {placeholder =>
                <TextField
                    required
                    fullWidth
                    value={value}
                    onChange={handleChange}
                    placeholder={placeholder}
                    className={`${textFontClassName} ${error && classes.textFieldError}`}
                    InputProps={{ disableUnderline: disableUnderline }}
                    inputRef={inputRef}
                />
            }
        </FormattedMessage>
    )
}

export default ExperienceTextFieldInput
