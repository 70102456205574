import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    kotShowMapContainer: {
        height: '458px',
        width: '100%',
        display: 'flex'
    },
    mapContainer: {
        width: '100%',
        height: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    loadMap: {
        height: '78%',
        border: '1px solid var(--grey)',
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mapShow: {
        width: '100%',
        textAlign: 'center',
        borderRadius: 12
    },
    kotLocalisationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16
    },
    titleSection: {
        marginTop: 65
    },
    mapIcon: {
        marginRight: '18px',
        fontSize: '22px',
        color: 'var(--main-color)'
    },
    bold: {
        fontFamily: theme.typography.h1.fontFamily
    },
    address: {
        textTransform: 'capitalize'
    }
}))
