/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './kotdescription_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import KotShowSectionTitle from '../KotShowSectionTitle/KotShowSectionTitle'

const KotDescription = ({ kot, user }) => {
    const classes = useStyles()

    return (
        <Box className={classes.kotDescription}>
            <Box className={classes.kotDescriptionText}>
                <Box className={`${classes.titleSection}`}>
                    <KotShowSectionTitle textId='landing_card_kot_page.kot_description'/>
                </Box>
                <Box className={classes.descriptionTextRow}>
                    <Typography className={classes.kotDescriptionContent}>
                        {kot.description || <i> <FormattedMessage id='landing_card_kot_page.no_description_available' /></i>}
                    </Typography>
                </Box>
                {kot.proximity &&
                        <Box className={classes.descriptionTextRow}>
                            <Typography
                                variant='h3'
                                color='primary'
                                className={classes.kotDescriptionTitle}
                            >
                                <FormattedMessage id='landing_card_kot_page.proximity' />
                            </Typography>
                            <Typography className={classes.kotDescriptionContent}>
                                {kot.proximity ? kot.proximity : <i> <FormattedMessage id='landing_card_kot_page.data_not_disclosed' /></i>}
                            </Typography>
                        </Box>
                }
                {kot.other_infos &&
                        <Box className={classes.descriptionTextRow}>
                            <Typography
                                variant='h3'
                                className={classes.kotDescriptionTitle}
                                color='primary'
                            >
                                <FormattedMessage id='landing_card_kot_page.others_info' />
                            </Typography>
                            <Typography className={classes.kotDescriptionContent}>
                                {kot.other_infos ? kot.other_infos : <i> <FormattedMessage id='landing_card_kot_page.others_info_not_available' /></i>}
                            </Typography>
                        </Box>
                }
                {kot.phone_number && kot.show_phonenumber && user &&
                        <Box className={classes.descriptionTextRow}>
                            <Typography variant='h3'
                                color='primary' className={classes.kotDescriptionTitle}><FormattedMessage id='landing_card_kot_page.phone' /></Typography>
                            <Typography className={classes.kotDescriptionContent}>
                                {kot.phone_number ? kot.phone_number : <i> <FormattedMessage id='landing_card_kot_page.not_number_phone' /></i>}
                            </Typography>
                        </Box>
                }
            </Box>
        </Box>
    )
}

export default KotDescription
