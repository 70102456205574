import React from 'react'
import { useStyles } from './ExperienceProfile_style'
import { Box, Grid } from '@material-ui/core'
import CollectionHandler from 'assets/CollectionHandler.js'
import ExperienceYearInput from './components/ExperienceYearInput/ExperienceYearInput'
import ExperienceSelectInput from './components/ExperienceSelectInput/ExperienceSelectInput'
import ExperienceCampusSelectInput from './components/ExperienceCampusSelectInput/ExperienceCampusSelectInput'
import ExperienceSchoolInput from './components/ExperienceSchoolInput/ExperienceSchoolInput'
import ExperienceStudyFieldInput from './components/ExperienceStudyFieldInput/ExperienceStudyFieldInput'
import ExperienceTextFieldInput from './components/ExperienceTextFieldInput/ExperienceTextFieldInput'
import ExperienceHighSchoolYearInput from './components/ExperienceHighSchoolYearInput/ExperienceHighSchoolYearInput'
import { FormattedMessage } from 'react-intl'

const ExperienceProfileViewBlack = (props) => {
    const {
        handleChangeStudyCycle,
        handleChangeFormation,
        handleChangeFormationDates,
        handleChangeHighSchoolStudyType,
        handleSchoolChange,
        highSchoolStudyTypeCollection,
        schoolCollection,
        setStudentFormation,
        studentFormation,
        studyFieldCollection,
        inputRefs,
        errors,
        highlightInputField
    } = props

    const classes = useStyles()

    const studyCycleCollection = CollectionHandler.Get('studyCycleCollection')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')

    return (
        <Grid container>
            {studentFormation.map((formation, formationKey) => {
                return (
                    <Grid key={formationKey.toString()} item xs={12} className={formation.deleted ? classes.displayNone : undefined}>
                        <Grid container>
                            <Grid mb={2} item xs={12} className={classes.gridElem}>
                                <ExperienceSelectInput
                                    type='study_cycle'
                                    collection={studyCycleCollection}
                                    formation={formation}
                                    formationKey={formationKey}
                                    handleChange={handleChangeStudyCycle(formationKey, 'study_cycle')}
                                    textFontClassName={classes.textFontBlack}
                                    placeholderTextClassName={classes.placeholderText}
                                    placeholderTextId='landing_experience_profile.choose'
                                    disableUnderline
                                    inputRef={inputRefs.studyCycle}
                                    error={highlightInputField || errors.studyCycle}
                                />
                            </Grid>
                            {formation.study_cycle === 'higher_education' && <>
                                <Grid item xs={12} className={classes.gridElem}>
                                    <ExperienceSelectInput
                                        type='study_type'
                                        collection={studyTypeCollection}
                                        formation={formation}
                                        formationKey={formationKey}
                                        handleChange={handleChangeFormation(formationKey, 'study_type')}
                                        textFontClassName={classes.textFontBlack}
                                        placeholderTextClassName={classes.placeholderText}
                                        placeholderTextId='landing_experience_profile.choose_type_of_training'
                                        disableUnderline
                                        inputRef={inputRefs.studyType}
                                        error={errors.studyType}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.gridElem}>
                                    <ExperienceSchoolInput
                                        InputAdornmentClassName={classes.InputAdornment}
                                        textFontClassName={classes.textFontBlack}
                                        type='study_type'
                                        studentFormation={studentFormation}
                                        handleSchoolChange={handleSchoolChange}
                                        schoolCollection={schoolCollection}
                                        formationKey={formationKey}
                                        disableUnderline
                                        inputRef={inputRefs.school}
                                        error={errors.school}
                                    />
                                </Grid>
                                {formation.campuses &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <ExperienceCampusSelectInput
                                            type='campus'
                                            formation={formation}
                                            formationKey={formationKey}
                                            handleChange={handleChangeFormation}
                                            textFontClassName={classes.textFontBlack}
                                            placeholderTextClassName={classes.placeholderText}
                                            placeholderTextId='landing_experience_profile.choose_campus'
                                            disableUnderline
                                            inputRef={inputRefs.campus}
                                            error={errors.campus}
                                        />
                                    </Grid>
                                }
                                {formation.school && formation.school.value === 'Other' &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <ExperienceTextFieldInput
                                            value={formation.other_school}
                                            handleChange={handleChangeFormation(formationKey, 'other_school')}
                                            placeholderTextId='landing_experience_profile.indicate_establishment'
                                            textFontClassName={classes.textFontBlack}
                                            disableUnderline
                                            inputRef={inputRefs.otherSchool}
                                            error={errors.otherSchool}
                                        />
                                    </Grid>
                                }
                                <Box component={Grid} item xs={12} className={classes.gridElem}>
                                    <ExperienceStudyFieldInput
                                        InputAdornmentClassName={classes.InputAdornment}
                                        textFontClassName={classes.textFontBlack}
                                        placeholderTextClassName={classes.placeholderText}
                                        type='study_type'
                                        studentFormation={studentFormation}
                                        setStudentFormation={setStudentFormation}
                                        studyFieldCollection={studyFieldCollection}
                                        formationKey={formationKey}
                                        disableUnderline
                                        inputRef={inputRefs.studyField}
                                        error={errors.studyField}
                                    />
                                </Box>
                                {formation.specializationList &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <ExperienceSelectInput
                                            type='specialization'
                                            collection={formation.specializationList}
                                            formation={formation}
                                            formationKey={formationKey}
                                            handleChange={handleChangeFormation(formationKey, 'specialization')}
                                            textFontClassName={classes.textFontBlack}
                                            placeholderTextClassName={classes.placeholderText}
                                            placeholderTextId='landing_experience_profile.choose_specialization'
                                            disableUnderline
                                            inputRef={inputRefs.specialization}
                                            error={errors.specialization}
                                        />
                                    </Grid>
                                }
                                {formation.field_of_study && formation.field_of_study.value === 'Other' &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <ExperienceTextFieldInput
                                            value={formation.other_field_of_study}
                                            handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                            placeholderTextId='landing_experience_profile.indicate_establishment'
                                            textFontClassName={classes.textFontBlack}
                                            disableUnderline
                                            inputRef={inputRefs.otherStudyField}
                                            error={errors.otherStudyField}
                                        />
                                    </Grid>
                                }
                                {formation.study_type &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={8}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage id='landing_experience_profile.start_date' />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Grid container justify='space-around'>
                                                    <ExperienceYearInput
                                                        className={classes.dateBlack}
                                                        value={formation.start_year}
                                                        onChange={handleChangeFormationDates(formationKey, 'start_year')}
                                                        disableUnderline
                                                        inputRef={inputRefs.startYear}
                                                        error={errors.startYear}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {formation.showEndYear &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={8}
                                                container
                                                direction='column'
                                                justify='center'
                                                alignItems='flex-start'
                                                className={classes.gridFlex}
                                            >
                                                <FormattedMessage
                                                    values={{ study_type: CollectionHandler.Translate('studyTypeCollection', formation.study_type) }}
                                                    id='landing_experience_profile.end_date'
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Grid container justify='space-around'>
                                                    <ExperienceYearInput
                                                        className={classes.dateBlack}
                                                        value={formation.end_year}
                                                        onChange={handleChangeFormationDates(formationKey, 'end_year')}
                                                        disableUnderline
                                                        inputRef={inputRefs.endYear}
                                                        error={errors.endYear}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}/>
                            </>}
                            {formation.study_cycle === 'high_school' && <>
                                <Grid item xs={12} className={classes.gridElem}>
                                    <ExperienceSelectInput
                                        type='study_type'
                                        collection={highSchoolStudyTypeCollection}
                                        formation={formation}
                                        formationKey={formationKey}
                                        handleChange={handleChangeHighSchoolStudyType(formationKey, 'study_type')}
                                        textFontClassName={classes.textFontBlack}
                                        placeholderTextClassName={classes.placeholderText}
                                        placeholderTextId='landing_experience_profile.choose_type_of_training'
                                        disableUnderline
                                        inputRef={inputRefs.highSchoolStudyType}
                                        error={errors.highSchoolStudyType}
                                    />
                                </Grid>
                                {formation.highSchoolStudyFields && formation.highSchoolStudyFields.length > 0 &&
                                    <Grid item xs={12} className={classes.gridElem}>
                                        <ExperienceSelectInput
                                            type='other_field_of_study'
                                            collection={formation.highSchoolStudyFields}
                                            formation={formation}
                                            formationKey={formationKey}
                                            handleChange={handleChangeFormation(formationKey, 'other_field_of_study')}
                                            textFontClassName={classes.textFontBlack}
                                            placeholderTextClassName={classes.placeholderText}
                                            placeholderTextId='landing_experience_profile.choose_specialization'
                                            disableUnderline
                                            inputRef={inputRefs.highSchoolStudyField}
                                            error={errors.highSchoolStudyField}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} className={classes.gridElem}>
                                    <ExperienceTextFieldInput
                                        value={formation.other_school}
                                        handleChange={handleChangeFormation(formationKey, 'other_school')}
                                        placeholderTextId='landing_experience_profile.indicate_establishment'
                                        textFontClassName={classes.textFontBlack}
                                        disableUnderline
                                        inputRef={inputRefs.highSchool}
                                        error={errors.highSchool}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box className={classes.highSchoolYearInputBoxContainerBlack}>
                                        <FormattedMessage id='landing_experience_profile.current_school_year' />
                                        <Box className={classes.highSchoolYearInputBoxBlack}>
                                            <ExperienceHighSchoolYearInput
                                                value={formation.high_school_year}
                                                formationKey={formationKey}
                                                handleChangeFormation={handleChangeFormation}
                                                textFontClassName={classes.textFontBlack}
                                                disableUnderline
                                                inputRef={inputRefs.highSchoolYear}
                                                error={errors.highSchoolYear}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </>}
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default ExperienceProfileViewBlack
