import React from 'react'

import { FormattedMessage } from 'react-intl'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './ProfileSimpleInfo_style'

const ProfileSimpleInfoView = (props) => {
    const { handleChangeTextField } = props

    const classes = useStyles()

    return (
        <Paper elevation={0} className={classes.PaperShadow}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h2' component={'div'}>
                        <FormattedMessage id="landing_info_profile.information" />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.fieldsContainer}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={3} className={classes.fieldText}>
                                    <Typography variant='body2'>
                                        <FormattedMessage id="landing_info_profile.first_name" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        type='text'
                                        fullWidth
                                        onBlur={handleChangeTextField('first_name')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={3} className={classes.fieldText}>
                                    <Typography variant='body2'>
                                        <FormattedMessage id="landing_info_profile.last_name" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField
                                        required
                                        type='text'
                                        fullWidth
                                        onBlur={handleChangeTextField('last_name')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ProfileSimpleInfoView
