const ACTIVELY_LOOKING_STUDENT_JOB = 'actively_looking_student_job'
const ACTIVELY_LOOKING_INTERNSHIP = 'actively_looking_internship'
const ACTIVELY_LOOKING_FIRST_JOB = 'actively_looking_first_job'
const ACTIVELY_LOOKING_KOT = 'actively_looking_kot'
const NOT_LOOKING_ANY_JOB = 'not_looking_any_job'

const GetIcon = (statusCode) => {
    if (statusCode === ACTIVELY_LOOKING_STUDENT_JOB) return 'double-check'
    if (statusCode === ACTIVELY_LOOKING_INTERNSHIP) return 'double-check'
    if (statusCode === ACTIVELY_LOOKING_FIRST_JOB) return 'double-check'
    if (statusCode === ACTIVELY_LOOKING_KOT) return 'double-check'
    if (statusCode === NOT_LOOKING_ANY_JOB) return 'cross-circle'

    return 'question-circle'
}

const GetColor = (statusCode) => {
    if (statusCode === ACTIVELY_LOOKING_STUDENT_JOB) return 'var(--green)'
    if (statusCode === ACTIVELY_LOOKING_INTERNSHIP) return 'var(--green)'
    if (statusCode === ACTIVELY_LOOKING_FIRST_JOB) return 'var(--green)'
    if (statusCode === ACTIVELY_LOOKING_KOT) return 'var(--green)'
    if (statusCode === NOT_LOOKING_ANY_JOB) return 'var(--red)'

    return 'var(--black)'
}

export default {
    ACTIVELY_LOOKING_STUDENT_JOB,
    ACTIVELY_LOOKING_INTERNSHIP,
    ACTIVELY_LOOKING_FIRST_JOB,
    NOT_LOOKING_ANY_JOB,
    ACTIVELY_LOOKING_KOT,
    GetIcon,
    GetColor
}
