import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    applyButtonBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 12,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            width: '100%'
        }
    },
    flexColumn: {
        flexDirection: 'column'
    },
    alreadyApplied: {
        backgroundColor: 'var(--green)',
        color: 'white'
    },
}))
