import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobTypesCardContainer: {
        width: '100%',
        padding: '20px',
        borderRadius: '12px',
        boxShadow: 'var(--shadow-paper)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    jobTypesTitle: {
        fontSize: '12px !important'
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    jobTypeButton: {
        borderRadius: '16px',
        border: '1px solid #E9EAF0',
        width: '100%',
        height: '90px',
        padding: '12px',
        textTransform: 'capitalize'
    },
    jobTypeContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '12px'
    },
    jobType: {
        fontSize: '10px !important',
        fontFamily: "'GintoNormal-Medium', sans-serif !important"
    },
    jobIcon: {
        fontSize: '24px !important'
    }

}))
