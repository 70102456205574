/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from './InfoLineView_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import IconFactory from 'icons/IconFactory/IconFactory'

const InfoLineView = (props) => {
    const { icon, text } = props
    const classes = useStyles()

    return (
        <Box className={classes.regularLine}>
            <Box className={classes.iconBox}>
                <IconFactory fontSize='14px' icon={icon} color='var(--red)' />
            </Box>
            <Typography className={classes.text}>
                {text}
            </Typography>
        </Box>
    )
}

export default InfoLineView
