import React from 'react'
import css from './articlepicture.scss'

export default class ArticlePicture extends React.Component {
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <div className={'article-show-picture size-' + this.props.pictures.length}>
                {
                    this.props.pictures.map((picture, index) => {
                        return (
                            <div
                                className="picture-container"
                                key={picture.src + index}
                                alt={picture.src}
                                style={{ backgroundImage: 'url(' + picture.src + ')' }}
                            >
                                <img src={picture.src} alt={picture.alt} />
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}
