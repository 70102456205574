/* eslint-disable react/prop-types */
import React from 'react'
import ReactOnRails from 'react-on-rails'
import { FormattedMessage } from 'react-intl'
import axios from 'axios'
import isEqual from 'lodash.isequal'

import AdBanner from 'components/AdBanner/AdBanner'
import AlertsAPI from 'api/AlertsAPI.js'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CTABlock from './CTABlock/CTABlock'
import FeedbackFactory from 'components/FeedbackFactory/FeedbackFactory'
import FilledButton from 'buttons/FilledButton/FilledButton'
import FiltersHelper from 'assets/FiltersHelper'
import GenericHelper from 'assets/GenericHelper'
import IconFactory from 'icons/IconFactory/IconFactory'
import ItemIndex from './ItemIndex/ItemIndex'
import ItemOffers from './ItemOffers/ItemOffers'
import JobIndexHelmet from 'helmets/JobIndexHelmet'
import MapProvider from 'wrappers/MapProvider/MapProvider'
import PopupFactory from 'modals/Popup/PopupFactory'
import RouteMap from 'assets/RouteMap.js'
import Searchbar from 'components/Searchbar/Searchbar'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import { ITEM_WITH_ANALYTICS_FILTER_LIST } from 'assets/ItemHelper'
import { JOB_INDEX_FILTER_BUTTON, JOB_INDEX_MOBILE_STICKY_BUTTON, JOB_INDEX_ALERT_AD_BUTTON, JOB_INDEX_SEE_MORE_BUTTON } from 'assets/AlertsHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { SAVE_FILTERS } from 'assets/AuthentificationHelper'

import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'

class ItemIndexMain extends React.Component {
    static contextType = LoginRegisterContext

    constructor (props) {
        super(props)
        this.mapRef = React.createRef()
        this.searchBarRef = React.createRef()
        this.jumpingRef = React.createRef()

        this.state = {
            applyJob: false,
            cardRef: React.createRef(),
            currentPage: URLParamsHandler.loadCurrentPage(),
            filters: null,
            indexRef: React.createRef(),
            isCheckMap: true,
            itemDivSelected: '',
            itemSelected: '',
            items: [],
            itemsGeocoding: false,
            jumpingRef: React.createRef(),
            listRef: React.createRef(),
            loadMoreStatus: true,
            loadPreviousPage: URLParamsHandler.loadPreviousPageBool(),
            loading: true,
            loadingMore: false,
            needRedirect: false,
            offerCount: '0',
            redirect_to: '',
            showAlertPopup: this.props.user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === SAVE_FILTERS,
            alertOrigin: URLParamsHandler.parseURLParams().alertOrigin,
            showRef: React.createRef()
        }

        this.urlFilters = URLParamsHandler.loadUrlFilters(this.props.type)

        this.closeShowTag = this.closeShowTag.bind(this)
        this.createAlert = this.createAlert.bind(this)
        this.filterItems = this.filterItems.bind(this)
        this.getCoords = this.getCoords.bind(this)
        this.renderAlertPopup = this.renderAlertPopup.bind(this)
        this.renderSaveSearchButton = this.renderSaveSearchButton.bind(this)
        this.setCheckMap = this.setCheckMap.bind(this)
        this.setFilters = this.setFilters.bind(this)

        window.prerenderReady = false

        this.feedbackRef = React.createRef()

        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }

    componentDidMount () {
        this.sendIndexViewEvent()
    }

    getIndexListViewEventName () {
        switch (this.props.type) {
        case 'kot':
            return 'Kot List Viewed'
        case 'company':
            return 'Company List Viewed'
        default:
            return 'Job List Viewed'
        }
    }

    sendIndexViewEvent () {
        AnalyticsHelper.sendGTMEvent(
            this.getIndexListViewEventName(), 
            AnalyticsHelper.constructIndexListViewedAnalyticsAttributes(this.props.user, this.props.type)
        )
    }

    sendFilterEvent () {
        AnalyticsHelper.sendGTMEvent(
            'Search Filter Applied',
            AnalyticsHelper.constructSearchFilterAppliedAnalyticsAttributes(this.props.user, this.state.filters)
        )
    }

    getJobInfo () {
        return this.state.itemSelected
    }

    closeShowTag (tag) {
        this.searchBarRef.current.selectOnly(tag)
    }

    setFilters (filters) {
        this.setState({ filters: filters })
    }

    setCheckMap (checked) {
        this.setState({ isCheckMap: checked })
    }

    renderAlertPopup (origin = null) {
        this.setState({
            showAlertPopup: !this.state.showAlertPopup,
            alertOrigin: origin
        })
    }

    getCoords (filterCities) {
        if (filterCities && filterCities.length) {
            return filterCities.map((option) => {
                return { long: option.long, lat: option.lat, value: option.value }
            })
        } else {
            return 'null'
        }
    }

    isSearchEmpty () {
        if (!ITEM_WITH_ANALYTICS_FILTER_LIST.includes(this.props.type)) return true
        
        if (this.state.filters) {
            return !this.state.filters.studyDomain?.length &&
                !this.state.filters.location?.length &&
                !this.state.filters.companySize?.length &&
                !this.state.filters.jobSchedule?.lenght && 
                !this.state.filters.sector?.length &&
                !this.state.filters.jobType?.length && 
                !this.state.filters.title?.length &&
                !this.state.filters.quickFilter?.length &&
                !this.state.filters.traineeship?.length &&
                !this.state.filters.jobSchedule?.length
        }
        return false
    }

    afterAuthAction (alertOrigin) {
        URLParamsHandler.reloadPageAndAddParams({ process: 'save_filters', alertOrigin });
    }
    /**
        Async function sending the new alert to API
    */
    createAlert () {
        const filters = this.state.filters
        const alertOrigin = this.state.alertOrigin

        AlertsAPI.CreateAlert({
            study_domains: FiltersHelper.getValues(filters.studyDomain),
            locations: this.getCoords(filters.location),
            company_sizes: FiltersHelper.getValues(filters.companySize),
            sectors: FiltersHelper.getValues(filters.sector),
            job_type: this.props.type,
            schedules: FiltersHelper.getValues(filters.jobSchedule),
            creation_origin: alertOrigin,
            quick_filter: filters.quickFilter ? filters.quickFilter : undefined
        }).then((response) => {
            this.renderAlertPopup()
            this.feedbackRef.current.triggerFeedback(true, <FormattedMessage id={'create_alert_popup.alert_creation_success'} />)
            AnalyticsHelper.sendGTMEvent('alert-creation-success')
            AnalyticsHelper.sendGTMEvent(
                'Search Saved',
                AnalyticsHelper.constructSearchFilterAppliedAnalyticsAttributes(this.props.user, this.state.filters, alertOrigin)
            )
        },
        error => {
            this.renderAlertPopup()
            this.feedbackRef.current.triggerFeedback(false, <FormattedMessage id={'create_alert_popup.alert_creation_failure'} /> + error)
        })
    }

    /*
    Axios request to filter items based on this.filters
    */
    async filterItems (tmpFilters) {
        const loadingMore = !tmpFilters
        const filters = tmpFilters || this.state.filters
        const haveFiltersChanged = !isEqual(tmpFilters, this.state.filters)

        // *** Page url param loading ***
        // page var defined by state and the 'loadMore' button
        const currentPage = loadingMore && !this.state.loadPreviousPage
            ? this.state.currentPage + 1
            : this.state.loadPreviousPage
                ? this.state.currentPage
                : 1

        this.setState({
            filters: filters,
            currentPage: currentPage,
            loadingMore: loadingMore,
            loading: !loadingMore
        }, () => {
            !this.isSearchEmpty() && this.sendFilterEvent()
            const params = {
                locale: RouteMap.GetLocaleFromUrl(),
                title: filters.title,
                study_domain: FiltersHelper.getValues(filters.studyDomain),
                study_type: FiltersHelper.getValues(filters.studyType),
                location: this.getCoords(filters.location),
                company_size: FiltersHelper.getValues(filters.companySize),
                sector: FiltersHelper.getValues(filters.sector),
                quick_filter: filters.quickFilter,
                kot_type: FiltersHelper.getValues(filters.kotType),
                job_type: FiltersHelper.getValues(filters.jobType),
                job_schedule: FiltersHelper.getValues(filters.jobSchedule),
                availability: FiltersHelper.getValues(filters.disponibility),
                rent: FiltersHelper.getValues(filters.price),
                available_rooms: FiltersHelper.getValues(filters.roomCount),
                selectedTags: filters.selectedTags,
                filterText: filters.filterText,
                traineeship: filters.traineeship,
                paid: filters.paid,
                page: this.state.currentPage
            }
            axios.get(this.props.filterUrl, {
                params: params
            }).then((response) => {
                let loadMoreStatus = true
                if (this.props.type === 'company' && response.data.res) {
                    if (response.data.res.length < 18) {
                        loadMoreStatus = false
                    }
                } else {
                    if (response.data.res.length < 27) {
                        loadMoreStatus = false
                    }
                }
                if (response.data.search_count === response.data.res.length) {
                    loadMoreStatus = false
                }
                let items = loadingMore ? this.state.items : []
                items = items.concat(response.data.res)
                if (response.data.search_count === items.length) {
                    loadMoreStatus = false
                }
                if (this.props.user) {
                    const hasFilters = Object.values(this.state.filters).some(filter => GenericHelper.isPresent(filter))
                    if (hasFilters && haveFiltersChanged) {
                        AnalyticsHelper.sendAnalyticsEvent('track', {
                            user_id: this.props.user.id,
                            event_name: 'Item Searched',
                            custom_props: { item_type: this.props.type, filters: params, results_count: response.data.search_count }
                        })
                    }
                }
                this.setState({
                    loadMoreStatus: loadMoreStatus,
                    items: items,
                    itemsGeocoding: response.data.items_geocoded,
                    offerCount: response.data.search_count,
                    loading: false,
                    loadingMore: false
                }, () => {
                    window.prerenderReady = true

                    // #region *** URL PARAMS SYSTEM ***
                    if (this.state.loadPreviousPage) {
                        // const indexCardContainer = (currentPage - 1) * 3
                        // const elemToScroll = document.getElementById(`cardContainer_${indexCardContainer}`)
                        // elemToScroll.scrollIntoView()

                        this.setState({
                            currentPage: currentPage
                            // loadPreviousPage: false
                        })
                    }

                    URLParamsHandler.createURLFilters(this.props.type, filters, currentPage)
                    this.props.refreshParamsForUrlTranslation()
                    // #endregion *** END ***
                })
            })
        })
    }

    openAlertJobSeeMoreBtn () {
        if (this.props.user?.type === 'student') {
            if(['first_job', 'student_job', 'internship'].includes(this.props.type)) {
                this.renderAlertPopup(JOB_INDEX_SEE_MORE_BUTTON)
            }
        }
    }

    /*
    This method send an axios request to the server with the filters so we can
    get back the filtered items.
    */
    leaderBoardAdPlace () {
        switch (this.props.type) {
        case 'first_job':
            return 'Firstjobs_Index_'
        case 'internship':
            return 'Internships_Index_'
        case 'student_job':
            return 'Studentjobs_Index_'
        case 'kot':
            return 'Kots_Index_'
        default:
        }
    }

    openJobAlertModal (origin) {
        if (this.props.user?.type === 'student') {
            this.renderAlertPopup(origin)
        } else if (!this.props.user) {
            this.context.openLoginRegisterModal({
                origin: SAVE_FILTERS,
                afterAuthAction: () => this.afterAuthAction(origin)
            })
        }
    }
    
    renderSaveSearchButton (buttonClassName) {
        let saveButtonOnClick = null

        if (['first_job', 'student_job', 'internship'].includes(this.props.type)) {
            if (this.props.user?.type === 'student') {
                saveButtonOnClick = () => {
                    this.renderAlertPopup(JOB_INDEX_FILTER_BUTTON)
                    AnalyticsHelper.sendGTMEvent('alert-creation-click')
                }
            } else if (!this.props.user) {
                saveButtonOnClick = () => {
                    this.context.openLoginRegisterModal({
                        origin: SAVE_FILTERS,
                        afterAuthAction: () => this.afterAuthAction(JOB_INDEX_FILTER_BUTTON)
                    })
                }
            }
        }

        return saveButtonOnClick &&
            <Box className='alertContainer'>
                <Box className='alertTextContainer'>
                    <Typography variant='h6' component='p' className='alertText'>
                        <FormattedMessage id={`item_index.alert.${this.props.type}.title`} />
                    </Typography>
                </Box>
                <Box className='alertButtonContainer'>
                    <FilledButton 
                        newStyle
                        color="secondary"
                        className={buttonClassName}
                        onClick={saveButtonOnClick}
                        name={<FormattedMessage id={`item_offers.${this.props.type}.save_my_search`} />}
                        startIcon={<IconFactory icon='mail' />}
                    />
                </Box>
            </Box>
    }

    render () {
        return (
            <div>
                <JobIndexHelmet
                    jobType={this.props.type}
                    selectedLocationTag={this.props.selectedLocationTag}
                    resultsCount={this.state.offerCount}
                    lang={this.props.lang}
                    altLangUrls={this.props.altLangUrls}
                    pathname={this.props.pathname}
                />
                <PopupFactory
                    type="create-alert-popup"
                    popupExit={() => this.renderAlertPopup()}
                    actionPopup={this.createAlert}
                    openShare={this.state.showAlertPopup}
                    filters={this.state.filters}
                    jobType={this.props.type}
                />
                <FeedbackFactory
                    ref={this.feedbackRef}
                />
                <div className="item-index-page-container">
                    <div className="item-index-main-container">
                        <div className="item-index-main" ref={this.state.indexRef}>
                            <Searchbar
                                key="searchbar"
                                type={this.props.type}
                                selectedLocationTag={this.props.selectedLocationTag}
                                user={this.props.user}
                                handleFilterChange={this.filterItems}
                                setFilters={this.setFilters}
                                location={this.props.selectedLocationTag}
                                urlFilters={this.urlFilters}
                                offerCount={this.state.offerCount}
                            />
                            <Container maxWidth="lg">
                                {!this.state.loading &&
                                    <ItemOffers
                                        offerCount={this.state.offerCount}
                                        cardRef={this.state.cardRef}
                                        listRef={this.state.listRef}
                                        user={this.props.user}
                                        type={this.props.type}
                                        setCheckMap={this.setCheckMap}
                                        isCheckMap={this.state.isCheckMap}
                                        selectedLocationTag={this.props.selectedLocationTag}
                                    />
                                }
                                {this.props.type === 'kot' &&
                                    <MapProvider itemsGeocoding={this.state.itemsGeocoding} isCheckMap={this.state.isCheckMap}>
                                        <ItemIndex
                                            type={this.props.type}
                                            currentTab={this.props.currentTab}
                                            items={this.state.items}
                                            itemsGeocoding={this.state.itemsGeocoding}
                                            cardSlider={this.props.cardSlider}
                                            cardRef={this.state.cardRef}
                                            listRef={this.state.listRef}
                                            mapRef={this.mapRef}
                                            userAddress={this.props.userAddress}
                                            userType={this.props.user && this.props.user.type}
                                            currentPage={this.state.currentPage}
                                            offerCount={this.state.offerCount}
                                            loadMoreStatus={this.state.loadMoreStatus}
                                            pageName={this.props.pageName}
                                            ads={this.props.ads}
                                            selectedArticletag={this.props.selectedArticleTag}
                                            selectedLocationTag={this.props.selectedLocationTag}
                                            isCheckMap={this.state.isCheckMap}
                                            jumpingRef={this.state.jumpingRef}
                                            lang={this.props.lang}
                                            loading={this.state.loading}
                                            loadingMore={this.state.loadingMore}
                                            filterItems={this.filterItems}
                                            openAlertJobSeeMoreBtn={() => this.openAlertJobSeeMoreBtn()}
                                        />
                                    </MapProvider>
                                }
                                {this.props.type !== 'kot' &&
                                    <ItemIndex
                                        type={this.props.type}
                                        currentTab={this.props.currentTab}
                                        items={this.state.items}
                                        itemsGeocoding={this.state.itemsGeocoding}
                                        cardSlider={this.props.cardSlider}
                                        cardRef={this.state.cardRef}
                                        listRef={this.state.listRef}
                                        mapRef={this.mapRef}
                                        userAddress={this.props.userAddress}
                                        user={this.props.user}
                                        userType={this.props.user && this.props.user.type}
                                        user={this.props.user}
                                        currentPage={this.state.currentPage}
                                        offerCount={this.state.offerCount}
                                        loadMoreStatus={this.state.loadMoreStatus}
                                        pageName={this.props.pageName}
                                        ads={this.props.ads}
                                        selectedArticletag={this.props.selectedArticleTag}
                                        jumpingRef={this.state.jumpingRef}
                                        lang={this.props.lang}
                                        loading={this.state.loading}
                                        loadingMore={this.state.loadingMore}
                                        filterItems={this.filterItems}
                                        openAlertJobSeeMoreBtn={() => this.openAlertJobSeeMoreBtn()}
                                        selectedLocationTag={this.props.selectedLocationTag}
                                        alertButton={this.renderSaveSearchButton('alertButton')}
                                        stickyAlertBtn={() => this.openJobAlertModal(JOB_INDEX_MOBILE_STICKY_BUTTON)}
                                        openAlertJobAd={() => this.openJobAlertModal(JOB_INDEX_ALERT_AD_BUTTON)}
                                    />
                                }
                            </Container>
                            {this.props.selectedLocationTag && this.props.type !== 'company' && !this.state.loadMoreStatus &&
                                <CTABlock
                                    page={this.props.page}
                                    type={this.props.type}
                                    selectedLocationTag={this.props.selectedLocationTag}
                                    loadMoreStatus={this.state.loadMoreStatus}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ItemIndexMain
