import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    button: {
        margin: 'auto'
    },
    ctaContainer: {
        background: 'var(--red)',
        color: 'white',
        padding: '80px 0'
    },
    maxWidth: {
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            width: '70%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))
