export const useStyles = (theme) => ({
    container: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            marginTop: 130,
            marginBottom: 50
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 40,
            marginBottom: 50
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
            marginBottom: 50
        }
    },
    logoContainer: {
        top: -80,
        display: 'flex',
        zIndex: 1,
        position: 'absolute',
        textAlign: 'center',
        display: 'flex'
    },
    logoImage: {
        width: 100,
        height: 100,
        objectFit: 'contain',
        borderRadius: 16,
        backgroundColor: 'white',
        boxShadow: '0 0 4px 0 rgba(0,0,0,.2)'
    },
    mainImage: {
        width: '100%'
    },
    titlePage: {
        color: 'var(--main-color)',
        marginBottom: 25
    },
    subtitle: {
        color: 'var(--main-color)',
        marginBottom: 25,
    },
    highlights: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: 280,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        '& > div': {
            padding: '8px 0',
            '& > a': {
                '& > div': {
                    padding: '0',
                }
            }
        },
    },
    text: {
        marginTop: '8px',

        [theme.breakpoints.down('md')]: {
            marginBottom: 25
        },
        '& p': {
            ...theme.typography.body1,
            ...{
                margin: 0,
                fontFamily: theme.typography.subtitle1.fontFamily
            }
        },
        '& h1': {
            ...theme.typography.h1,
            ...{
                color: 'var(--main-color)',
                fontFamily: theme.typography.h2.fontFamily,
                [theme.breakpoints.down('md')]: {
                    fontSize: '24px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '22px !important'
                }
            }
        },
        '& h2': {
            ...theme.typography.h2,
            ...{
                color: 'var(--main-color)',
                [theme.breakpoints.down('md')]: {
                    fontSize: '20px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '18px !important'
                }
            }
        },
        '& h3': {
            ...theme.typography.h3,
            ...{
                color: 'var(--main-color)',
                [theme.breakpoints.down('md')]: {
                    fontSize: '16px !important'
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '16px !important'
                }
            }
        }
    }
})
