import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    statBoxContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: '40px 0px',
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            padding: '20px 0px',
            justifyContent: 'center'
        }
    },
    statBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '25%',
        [theme.breakpoints.down('sm')]: {
            minWidth: '48%',
            width: '48%',
            justifyContent: 'flex-start'
        }
    },
    statIcon: {
        height: 100,
        width: 100,
        [theme.breakpoints.down('sm')]: {
            height: 60,
            width: 60
        }
    },
    statText: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: 100,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'none'
        }
    },
    stat: {
        color: '#76243A',
        fontFamily: 'var(--title-font) !important',
        fontSize: '35px !important',
        lineHeight: 0.8,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    statDescription: {
        color: '#76243A',
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px !important'
        }
    }
}))
