/* eslint-disable react/prop-types */
import React from 'react'

import axios from 'axios'

import css from './multistepregister.scss'
import css_responsive from './multistepregister_responsive.scss'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Field from 'inputs/Field/Field'
import FilledButton from 'buttons/FilledButton/FilledButton'
import MultipleSelect from 'inputs/MultipleSelect/MultipleSelect'
import StudentRegister from '../StudentRegister/StudentRegister'

import { FormattedMessage } from 'react-intl'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap.js'
import URLParamsHandler from 'assets/URLParamsHandler'
import JoinCompanyModal from 'shared/modals/JoinCompanyModal/JoinCompanyModal'

export default class MultiStepRegister extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            step: 1,
            user: this.props.user,
            userType: this.props.userType === null ? null : this.props.userType,
            studentRegister: this.props.userType === 'student',
            employerRegister: false,
            isSubmitting: false,
            firstName: '', // TODO: To change
            lastName: '',
            companyId: 0,
            companyName: '',
            companySize: '',
            companySectors: [],
            phoneNumber: '',
            errorsList: {},
            lockUserTypesVisibility: false,
            employerModalOpen: false,
            matchingCompanies: [],
        }

        this.companySizeCollection = CollectionHandler.Get('companySizeCollection')

        this.funnelPath = this.calculateFunnelPathType()
        this.calculateMaxStep()
        this.nextStep = this.nextStep.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.canSubmit = this.canSubmit.bind(this)
        this.frontValidation = this.frontValidation.bind(this)
        this.redirectUser = this.redirectUser.bind(this)
        this.openJoinCompanyModal = this.openJoinCompanyModal.bind(this)
    }

    componentDidMount () {
        window.scrollTo(0, 0)
        axios.defaults.headers.common[
            'X-CSRF-TOKEN'
        ] = ReactOnRails.authenticityToken()
    }

    openJoinCompanyModal () {
        this.setState({ employerModalOpen: true })
    }

    calculateFunnelPathType () {
        var funnelPath
        funnelPath = 'job_path'
        return funnelPath
    }

    calculateMaxStep () {
        switch (this.funnelPath) {
        case 'job_with_motivation_path' || 'student_path':
            this.maxStep = 4
            break
        case 'student_with_motivation_path':
            this.maxStep = 5
            break
        case 'job_path':
            this.maxStep = 3
            break
        }
    }

    nextStep (value) {
        window.scrollTo(0, 0)
        if (value > this.maxStep) {
            window.location.href = '/'
        } else {
            if (value === 4 && this.funnelPath === 'job_with_motivation_path') {
                value = value + 1
            }
            this.setState({
                step: value
            })
        }
    }

    canSubmit () {
        var canSubmit
        if (this.state.userType === 'student') {
            canSubmit = true
        } else if (this.state.userType === 'kot_owner') {
            canSubmit = this.state.firstName &&
            this.state.lastName
        } else if (this.state.userType === 'employer') {
            canSubmit = this.state.firstName &&
            this.state.lastName &&
            this.state.companyName &&
            this.state.companySize &&
            (this.state.companySectors.length !== 0) &&
            this.state.phoneNumber
        }

        return canSubmit
    }

    frontValidation () {
        const {
            userType,
            firstName,
            lastName,
            phoneNumber,
            companyName,
            companySize,
            companySectors
        } = this.state

        var errorsList = {}
        if (userType === 'student') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id="landing_login_page.errors.first_name" />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id="landing_login_page.errors.last_name" />
            }
        } else if (userType === 'kot_owner') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id="landing_login_page.errors.first_name" />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id="landing_login_page.errors.last_name" />
            }
        } else if (userType === 'employer') {
            if (firstName === '') {
                errorsList.first_name = <FormattedMessage id="landing_login_page.errors.first_name" />
            }
            if (lastName === '') {
                errorsList.last_name = <FormattedMessage id="landing_login_page.errors.last_name" />
            }
            if (companyName === '') {
                errorsList.name = <FormattedMessage id="landing_login_page.errors.name" />
            }
            if (companySize === '') {
                errorsList.size = <FormattedMessage id="landing_login_page.errors.size" />
            }
            if (companySectors.length === 0) {
                errorsList.sectors = <FormattedMessage id="landing_login_page.errors.sector" />
            }
            if (phoneNumber === '') {
                errorsList.phone_number = <FormattedMessage id="landing_login_page.errors.phone_number" />
            }
        }
        this.setState({ errorsList: errorsList })
    }

    updateProfile () {
        if (this.state.isSubmitting) return

        if (this.canSubmit()) {
            this.setState({ isSubmitting: true })
            const {
                userType,
                firstName,
                lastName,
                phoneNumber,
                companyName,
                companySize,
                companySectors
            } = this.state
            axios({
                url: this.props.urls.updateProfileUrl,
                method: 'post',
                data: {
                    user_type: userType,
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                    name: companyName,
                    size: companySize,
                    sectors: companySectors
                }
            }).then(
                response => {
                    if (response?.data?.user?.id) {
                        AnalyticsHelper.sendAnalyticsEvent('identify', { user_id: response.data.user.id })
                        if (this.state.userType !== 'student') {
                            AnalyticsHelper.sendAnalyticsEvent('track', {
                                user_id: response.data.user.id,
                                event_name: 'Signup Completed',
                                custom_props: { origin: this.props.origin }
                            })
                        }
                    }
                    switch (this.state.userType) {
                    case 'student':
                        this.setState({ studentRegister: true, userID: response?.data?.user?.id })
                        break
                    case 'kot_owner':
                        AnalyticsHelper.sendGTMEvent('registration-success', {
                            user_type: 'kot_owner',
                            language: RouteMap.GetLocaleFromUrl()
                        })
                        AnalyticsHelper.sendGTMEvent(
                            'Create Account Completed - Kot Owner',
                            AnalyticsHelper.constructRegisterOwnerAnalyticsAttributes(
                                { // Construct User object
                                    id: response?.data?.user?.id,
                                    type: userType
                                },
                                this.props.loginType,
                                this.props.origin
                            ))

                        this.redirectUser(RouteMap.Page('users/kots'))
                        break
                    case 'employer':
                        AnalyticsHelper.sendGTMEvent('registration-success', {
                            user_type: 'employer',
                            language: RouteMap.GetLocaleFromUrl()
                        })
                        AnalyticsHelper.sendGTMEvent(
                            'Create Account Completed - Employer',
                            AnalyticsHelper.constructRegisterEmployerAnalyticsAttributes(
                                { // Construct User object
                                    id: response?.data?.user?.id,
                                    type: userType
                                },
                                this.props.loginType,
                                this.props.origin,
                                { // Construct Company object
                                    id: response?.data?.company_id,
                                    name: companyName,
                                    sector: companySectors,
                                    size: companySize
                                }
                            ))

                        const matchingCompanies = response.data.matching_companies
                        if (matchingCompanies) {
                            this.setState({ matchingCompanies: matchingCompanies, companyId: response.data.company_id })
                            this.openJoinCompanyModal()
                        } else if (URLParamsHandler.parseURLParams().redirect) { // Direct redirection to required page
                            URLParamsHandler.redirectWithURL()
                        } else {
                            // Redirection happening in the welcome page
                            window.location.href = `${RouteMap.Page('users')}welcome?redirect=${this.props.userStoredLocation}`
                        }
                        break
                    }
                },
                error => {
                    if (error.response.data.error) {
                        this.setState({ errorsList: error.response.data.error, isSubmitting: false })
                    }
                }
            )
        } else {
            this.setState({ isSubmitting: false })
            this.frontValidation()
        }
    }

    handleSubmit (event) {
        switch (this.state.userType) {
        case 'student':
            this.updateProfile()
            break
        case 'kot_owner':
            this.updateProfile()
            break
        case 'employer':
            this.updateProfile()
            break
        default:
            this.updateProfile()
            break
        }
    };

    handleChange (event) {
        const { name, value } = event.target
        if (name === 'userType') {
            this.state.lockUserTypesVisibility = true
        }
        this.setState({
            [name]: value
        })
    }

    renderKotOwnerInfos () {
        return (
            <form
                className="user-infos-container"
                action=""
                onSubmit={event => event.preventDefault()}
            >
                <div className="two-columns">
                    <Field
                        type="input"
                        title={<FormattedMessage id="landing_login_page.sign_up.first_name" />}
                        name="firstName"
                        required
                        error={this.state.errorsList.first_name}
                        handleChange={this.handleChange}
                    />
                    <Field
                        type="input"
                        title={<FormattedMessage id="landing_login_page.sign_up.last_name" />}
                        name="lastName"
                        required
                        error={this.state.errorsList.last_name}
                        handleChange={this.handleChange}
                    />
                </div>
                <div className="two-columns">
                    <Field
                        type="input"
                        title={<FormattedMessage id="landing_login_page.sign_up.phone_number" />}
                        name="phoneNumber"
                        handleChange={this.handleChange}
                        error={this.state.errorsList.phone_number}
                    />
                </div>
            </form>
        )
    }

    renderStudentInfos () {
        return (
            null
        )
    }

    renderEmployerInfos () {
        return (
            <Container maxWidth="md">
                <JoinCompanyModal
                    newCompanyName={this.state.companyName}
                    userStoredLocation={this.props.userStoredLocation}
                    companyId={this.state.companyId}
                    matchingCompanies={this.state.matchingCompanies}
                    employerModalOpen={this.state.employerModalOpen}
                    fullName={`${this.state.firstName} ${this.state.lastName}`}
                />
                <Box my={6}>
                    <form
                        className="employer-infos-container"
                        action=""
                        onSubmit={event => event.preventDefault()}
                    >
                        <div className="two-columns">
                            <Field
                                type="input"
                                title={<FormattedMessage id="landing_login_page.sign_up.first_name" />}
                                name="firstName"
                                required
                                error={this.state.errorsList.first_name}
                                handleChange={this.handleChange}
                            />
                            <Field
                                type="input"
                                title={<FormattedMessage id="landing_login_page.sign_up.last_name" />}
                                name="lastName"
                                required
                                error={this.state.errorsList.last_name}
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className="two-columns">
                            <Field
                                type="input"
                                title={<FormattedMessage id="landing_login_page.sign_up.compagny_name" />}
                                name="companyName"
                                required
                                error={this.state.errorsList.name}
                                handleChange={this.handleChange}
                            />
                            <MultipleSelect
                                values={this.state.companySectors}
                                handleChange={this.handleChange}
                                valueCollectionString={'sectorCollection'}
                                title={<FormattedMessage id="company_profile.company_page_description.description_popup.sector" />}
                                name="companySectors"
                                placeholder={<FormattedMessage id="company_profile.company_page_description.description_popup.sector" />}
                                error={this.state.errorsList.sectors}
                            />
                        </div>
                        <div className="two-columns">
                            <Field
                                type="input"
                                title={<FormattedMessage id="landing_login_page.sign_up.phone_number" />}
                                name="phoneNumber"
                                handleChange={this.handleChange}
                                error={this.state.errorsList.phone_number}
                            />
                            <Field
                                type="hashes-select"
                                title={<FormattedMessage id="landing_login_page.sign_up.compagny_size" />}
                                name="companySize"
                                different
                                blank
                                optionSelect={this.companySizeCollection}
                                handleChange={this.handleChange}
                                error={this.state.errorsList.size}
                            />
                        </div>
                    </form>
                </Box>
            </Container>
        )
    }

    redirectUser (redirectionUrl = null) {
        if (this.props.userStoredLocation) {
            window.location.href = this.props.userStoredLocation
        } else {
            window.location.href = redirectionUrl
        }
    }

    renderGeneralUserInfos () {
        switch (this.state.userType) {
        case 'student':
            return this.renderStudentInfos()
        case 'employer':
            return this.renderEmployerInfos()
        case 'kot_owner':
            return this.renderKotOwnerInfos()
        }
    }

    renderUserFunnelRegister () {
        if (this.state.studentRegister || this.props.origin === 'job_application') {
            return this.renderStudentFunnel()
        } else if (this.state.userType === 'employer' && this.state.lockUserTypesVisibility === false) {
            return (
                <>
                    {this.renderEmployerInfos()}
                    <FilledButton newStyle name={<FormattedMessage id="landing_login_page.next" />} onClick={this.handleSubmit} />
                </>
            )
        } else {
            return (
                <Container maxWidth="md">
                    <Box my={6}>
                        {!(this.props.userStoredLocation && this.props.userStoredLocation.replace(/^\/(fr|en|nl)/g, '') !== '/users/kots/new') &&
                            <Field
                                title={<FormattedMessage id="landing_login_page.sign_up.sign_up_title" />}
                                name="userType"
                                type="radio"
                                required
                                options={[
                                    { name: <FormattedMessage id="landing_login_page.sign_up.option_student" />, value: 'student' },
                                    { name: <FormattedMessage id="landing_login_page.sign_up.option_employer" />, value: 'employer' },
                                    { name: <FormattedMessage id="landing_login_page.sign_up.option_kot_owner" />, value: 'kot_owner' }
                                ]}
                                direction="column"
                                size="1"
                                value={this.state.userType}
                                handleChange={this.handleChange}
                            />
                        }
                        {this.renderGeneralUserInfos()}
                        <Box mt={4}>
                            <FilledButton newStyle color="secondary" name={<FormattedMessage id="landing_login_page.next" />} onClick={this.handleSubmit} />
                        </Box>
                    </Box>
                </Container>
            )
        }
    }

    renderStudentFunnel () {
        return (
            <StudentRegister
                origin={this.props.origin}
                loginType={this.props.loginType}
                userID={this.state.userID}
                jobInfo={this.props.jobInfo}
                afterSignInAction={this.props.afterSignInAction || this.props.afterAuthAction}
            />
        )
    }

    render () {
        return (
            <div className="multi-step-register-container">
                <div className="multi-step-width">
                    {false &&
                        <Typography
                            variant='h1'
                            color='primary'
                        >
                            <FormattedMessage id="landing_login_page.sign_up.title" />
                        </Typography>
                    }
                    {this.renderUserFunnelRegister()}
                </div>
            </div>
        )
    }
}
