import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobShowContainer: {
        height: '100%'
    },
    desktopContainer: {
        marginTop: 32
    },
    unavailablePaper: {
        backgroundColor: 'var(--red)',
        padding: '6px 16px',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 32
    },
    bottomAdBox: {
        backgroundColor: 'var(--super-dark-grey)'
    },
    similarJobsBox: {
        paddingTop: 24,
        paddingBottom: 24,
        backgroundColor: 'var(--super-dark-grey)'
    },
    topSimilarJobsBox: {
        paddingTop: 80,
        paddingBottom: 24,
        backgroundColor: 'var(--super-dark-grey)'
    },
    similarArticlesBox: {
        paddingTop: 24,
        paddingBottom: 80,
        backgroundColor: 'var(--super-dark-grey)'
    },
    applyCardContainer: {
        marginBottom: 24
    },
    applyCardContainerEnd: {
        marginTop: 20
    }
}))
