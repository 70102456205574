/* eslint-disable react/prop-types */

import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AboutSummerJobsHelmet from 'helmets/AboutSummerJobsHelmet'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import FilledButton from 'buttons/FilledButton/FilledButton'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import CardSlider from 'components/CardSlider/CardSlider'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CarouselCards from 'components/CarouselCards/CarouselCards'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'

class AboutSummerJobs extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="landing_about_pages.how_to_found" />
                            <FormattedMessage id="landing_about_pages.summer_job" /> ?
                        </Typography>
                    </Container>
                </Box>

                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_summer_jobs.student_and_broke" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_summer_jobs.student_opportunity" />
                        </Typography>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_pages.found_job_on" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.apply_for_free" />
                        </Typography>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_pages.complete_profile" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.autorisation" />
                        </Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.students_found_job" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_summer_jobs.ideal_job" />
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_pages.want_to_get_started" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_summer_jobs.found_summer_job_near_you" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_summer_jobs.select_city" />
                        </Typography>
                    </Box>

                    <Box my={5}>
                        <LocationsListContainer type="student-jobs" />
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_pages.search_job_student" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.studentbe" />
                            <FormattedMessage id="landing_about_pages.help_you_to_found" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_pages.kot" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.a" />
                            <RouteMapLink page='internships' className={classes.studentFont}><FormattedMessage id="landing_about_pages.internship" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.and_even_a" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.first_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.trying_your_luck" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.offers_internship" />
                        </Typography>
                    </Box>

                    <Box className={classes.buttonOffers}>
                        <RouteMapLink page='student-jobs'>
                            <FilledButton
                                name=<FormattedMessage id="landing_about_pages.consult_offers" />
                                color='secondary'
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
            </>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <PageWrapper user={this.props.user}>
                <AboutSummerJobsHelmet />
                {this.createPageContent()}
                <Container maxWidth="lg">
                    <Typography variant="h2" className={classes.responsiveText}><FormattedMessage id="landing_about_pages.discover_last_job_students" /></Typography>
                    <Box my={5}>
                        <CarouselCards
                            cards={this.props.lastOffers}
                            type='student_job'
                            userType={this.props.userType}
                            id='lastOffers'
                        />
                    </Box>
                    <Box className={classes.cardTitle}>
                        <Typography variant='h2' className="staticArticleTitleLayout">
                            <FormattedMessage id="landing_about_pages.advice_to_find" />
                            <FormattedMessage id="landing_about_pages.student_job" />
                        </Typography>
                        <RouteMapLink page='student-jobs/articles'>
                            <ArrowButton
                                name=<FormattedMessage id="landing_about_pages.see_all_articles" />
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
                <CardSlider
                    cards={this.props.recentArticles}
                    type="article"
                    side="left"
                    maxItem="3"
                    showLine
                />
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(AboutSummerJobs)
