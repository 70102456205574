import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeCityList_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import RouteMap from 'assets/RouteMap.js'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const HomeCityList = (props) => {
    const classes = useStyles()
    const locale = RouteMap.GetLocaleFromUrl()

    const displayLocationList = (target) => {
        const footerLinks = []
        const footerCityCollection = CollectionHandler.Get('footerCityCollection')
        footerCityCollection[locale].forEach((value) => {
            footerLinks.push(
                <li key={`${value.name} ${target} link`}>
                    <RouteMapLink
                        itemType={target}
                        location={value.value}
                        variant='body2'
                        component='a'
                        className={`${classes.link}`}
                    >
                        {value.name}
                    </RouteMapLink>
                </li>
            )
        })
        return footerLinks
    }

    return (
        <Box className={classes.citylistContainer}>
            <Container maxWidth='lg'>
                <Box className={classes.citylistTitleContainer}>
                    <Typography
                        variant='h6'
                        component='h2'
                        color='primary'
                        className={classes.title}
                    >
                        <FormattedMessage id="landing_student.citylist.title1" />
                    </Typography>
                    <Typography
                        variant='body2'
                        component='p'
                        color='primary'
                        className={classes.description}
                    >
                        <FormattedMessage id="landing_student.citylist.title2" />
                    </Typography>
                </Box>
                <Box className={classes.linksContainerGroup}>
                    <Box className={classes.linksContainer}>
                        <Box className={classes.linksBox}>
                            <ul className={classes.link}>
                                <li>
                                    <Typography
                                        variant='h6'
                                        component='h3'
                                        color='primary'
                                        className={classes.titleColumn}
                                    >
                                        <FormattedMessage id="page_footer_navbar_data.student_job_button" />
                                    </Typography>
                                </li>
                                {displayLocationList('student-jobs')}
                            </ul>
                            <Link
                                component='a'
                                className={`${classes.allCity}`}
                                href={RouteMap.Page('student-jobs')}
                                hreflang={`${locale}-BE`}
                            >
                                <FormattedMessage id="landing_student.citylist.all_city" />
                            </Link>
                        </Box>
                    </Box>
                    <Box className={classes.linksContainer}>
                        <Box className={classes.linksBox}>
                            <ul className={classes.link}>
                                <li>
                                    <Typography
                                        variant='h6'
                                        component='h3'
                                        color='primary'
                                        className={classes.titleColumn}
                                    >
                                        <FormattedMessage id="page_footer_navbar_data.internship_button" />
                                    </Typography>
                                </li>
                                {displayLocationList('internships')}
                            </ul>
                            <Link
                                component='a'
                                className={`${classes.allCity}`}
                                href={RouteMap.Page('internships')}
                                hreflang={`${locale}-BE`}
                            >
                                <FormattedMessage id="landing_student.citylist.all_city" />
                            </Link>
                        </Box>
                    </Box>
                    <Box className={classes.linksContainer}>
                        <Box className={classes.linksBox}>
                            <ul className={classes.link}>
                                <li>
                                    <Typography
                                        variant='h6'
                                        component='h3'
                                        color='primary'
                                        className={classes.titleColumn}
                                    >
                                        <FormattedMessage id="landing_student.citylist.first_job_title" />
                                    </Typography>
                                </li>
                                {displayLocationList('first-jobs')}
                            </ul>
                            <Link
                                component='a'
                                className={`${classes.allCity}`}
                                href={RouteMap.Page('first-jobs')}
                                hreflang={`${locale}-BE`}
                            >
                                <FormattedMessage id="landing_student.citylist.all_city" />
                            </Link>
                        </Box>
                    </Box>
                    <Box className={classes.linksContainer}>
                        <Box className={classes.linksBox}>
                            <ul className={classes.link}>
                                <li>
                                    <Typography
                                        variant='h6'
                                        component='h3'
                                        color='primary'
                                        className={classes.titleColumn}
                                    >
                                        <FormattedMessage id="page_footer_navbar_data.kot_button" />
                                    </Typography>
                                </li>
                                {displayLocationList('student-rooms')}
                            </ul>
                            <Link
                                component='a'
                                className={`${classes.allCity}`}
                                href={RouteMap.Page('student-rooms')}
                                hreflang={`${locale}-BE`}
                            >
                                <FormattedMessage id="landing_student.citylist.all_city" />
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
export default HomeCityList
