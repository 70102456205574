import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './SEOBlock_style'

import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import URLHandler from 'assets/URLHandler.js'

const SEOBlock = (props) => {
    const { selectedLocationTag, type } = props
    if (!selectedLocationTag) return null
    const seoTextQuickFilterCollection = CollectionHandler.Get('seoTextQuickFilterCollection')
    const seoTextLocationCollection = CollectionHandler.Get('seoTextLocationCollection')
    const seoTextStudyDomainCollection = CollectionHandler.Get('seoTextStudyDomainCollection')
    const [showContent, setShowContent] = useState(false)

    if (selectedLocationTag.type === 'quickFilter' &&
        seoTextQuickFilterCollection[URLHandler.GetLocaleFromUrl()][type].includes(selectedLocationTag.value)) {
        // render component
    } else if (selectedLocationTag.type === 'location' &&
        seoTextLocationCollection[URLHandler.GetLocaleFromUrl()][type].some(location =>
            location[URLHandler.GetLocaleFromUrl()] === selectedLocationTag.name && location.zip === selectedLocationTag.zip)) {
        // render component
    } else if (selectedLocationTag.type === 'studyDomain' &&
        seoTextStudyDomainCollection[URLHandler.GetLocaleFromUrl()][type].includes(selectedLocationTag.value)) {
    } else {
        return null
    }

    const toggleAccordion = () => { setShowContent(!showContent) }

    const getTitleId = () => {
        if (selectedLocationTag.type === 'quickFilter') {
            return `seo_block.title.${type}.quick_filter.${selectedLocationTag.value}`
        } else if (selectedLocationTag.type === 'studyDomain') {
            return `seo_block.title.${type}.study_domain.${selectedLocationTag.value}`
        } else if (selectedLocationTag.type === 'location') {
            return `seo_block.title.${type}.location`
        }
    }

    const getTextId = () => {
        if (selectedLocationTag.type === 'quickFilter') {
            return `index_seo_text.${type}.quick_filter.${selectedLocationTag.value}`
        } else if (selectedLocationTag.type === 'studyDomain') {
            return `index_seo_text.${type}.study_domain.${selectedLocationTag.value}`
        } else if (selectedLocationTag.type === 'location') {
            return `index_seo_text.${type}.${selectedLocationTag.value}_${selectedLocationTag.value_name}`
        }
    }

    const getSplitTextOnWords = (text) => {
        if (showContent) return text

        const words = text.split(/[\s]/) // Split the text into words
        const totalWords = words.length
        let part1 = ''

        // Calculate where to split
        const splitIndex = Math.floor(totalWords * 0.45)

        // Reconstruct the parts
        for (let i = 0; i < totalWords; i++) {
            const word = words[i] + (i < totalWords - 1 ? ' ' : '') // Add punctuation
            if (i < splitIndex) {
                part1 += word
            }
        }
        part1 = part1.slice(0, -1) + '...'

        return part1
    }

    const classes = useStyles()
    return (
        <Box className={classes.seoBlockContainer}>
            <Container maxWidth="lg">
                <Box className={classes.seoTextContainer}>
                    <Accordion className={classes.accordion} expanded onChange={toggleAccordion}>
                        <AccordionSummary expandIcon={<IconFactory icon={showContent ? 'down' : 'up'} />}>
                            <Box className={classes.accordionTitle}>
                                <Typography variant="h3">
                                    <FormattedMessage id={getTitleId()} values={{ city: selectedLocationTag.name }}/>
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails onClick={toggleAccordion} className={classes.accordionDetails}>
                            <FormattedMessage id={getTextId()}>
                                {translatedText => <>
                                    <Typography className={classes.accordionText} dangerouslySetInnerHTML={{ __html: getSplitTextOnWords(translatedText) }} />
                                </>}
                            </FormattedMessage>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Container>
        </Box>
    )
}

export default SEOBlock
