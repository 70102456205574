import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        '& #readMore': {
            opacity: 0,
            fontSize: 10,
            color: 'var(--red)',
            marginLeft: -3,
            transition: '0.3s ease-in-out',
        },
        '&:hover': {
            '& #readMore': {
                opacity: 1,
                marginLeft: 8,
            },
            '& #uniqArticleTitle': {
                color: 'var(--red)',
            },
        },
    },
    main: {
        display: 'flex',
        gap: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid var(--light-grey)',
        borderBottom: '1px solid var(--light-grey)',
    },
    cardMediaContainer: {
        minWidth: 170,
        minHeight: 100,
        objectFit: 'contain',
        borderRadius: 10,
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            minWidth: 100,
            minHeight: 100,
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        justifyContent: 'space-around'
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12
    },
    readTimeAndDate: {
        display: 'flex',
        alignItems: 'center',
    },
    minorText: {
        fontSize: '12px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    bullet: {
        margin: '0 4px',
        fontSize: '8px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    articleTitle: {
        fontSize: '18px !important',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        transition: '0.3s ease-in-out',
        '&:hover': {
            color: 'var(--red)',
        }
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 12,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))
