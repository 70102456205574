/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import PasswordAPI from 'api/PasswordAPI'

import { FormattedMessage } from 'react-intl'

const ForgotPasswordPage = (props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const sendRequest = (event) => {
        event.preventDefault()
        setLoading(true)
        PasswordAPI.RequestPasswordResetEmail({
            email: email
        })
            .then((response) => {
                setLoading(false)
                setSent(true)
            }, (error) => {
                setLoading(false)
                setError(true)
                setErrorMessage(error.response.data.error)
            })
    }

    const renderEmailForm = () => {
        return (
            <Box>
                <form onSubmit={sendRequest}>
                    <Box my={4}>
                        <FormControl>
                            <InputLabel htmlFor="my-input"><FormattedMessage id="forgot_password.input_label" /></InputLabel>
                            <Input
                                id="my-input"
                                fullWidth
                                aria-describedby="my-helper-text"
                                type='email'
                                value={email}
                                onChange={(event) => { setEmail(event.target.value) }}
                            />
                            <FormHelperText id="my-helper-text"><FormattedMessage id="forgot_password.helper_text" /></FormHelperText>
                        </FormControl>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            <FormattedMessage id="forgot_password.submit" />
                        </Button>
                    </Box>
                </form>
            </Box>
        )
    }

    const renderRetryButton = () => {
        return (
            <RouteMapLink page="password/new">
                <Button
                    variant="contained"
                    color="primary"
                >
                    <FormattedMessage id="forgot_password.retry" />
                </Button>
            </RouteMapLink>
        )
    }

    const renderSuccess = () => {
        return (
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box my={4}>
                    <Typography
                        color='primary'
                    >
                        <FormattedMessage id="forgot_password.success" />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    {renderRetryButton()}
                </Box>
            </Box>
        )
    }

    const renderError = () => {
        return (
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box my={4}>
                    <Typography
                        color='primary'
                    >
                        {errorMessage}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    {renderRetryButton()}
                </Box>
            </Box>
        )
    }

    return (
        <Container maxWidth='sm'>
            <Box my={16} display="flex" alignItems="center" flexDirection="column">
                <Typography
                    variant='h3' component='h2'
                    color='primary'
                >
                    <FormattedMessage id="forgot_password.title" />
                </Typography>
                {!loading && !sent && !error &&
                    renderEmailForm()
                }
                {loading && <ProgressCircle/>}
                {sent &&
                    renderSuccess()
                }
                {error &&
                    renderError()
                }
            </Box>
        </Container>

    )
}
export default ForgotPasswordPage
