/* eslint-disable react/prop-types */

import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './aboutpress_style'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import AboutPressHelmet from 'helmets/AboutPressHelmet'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { FormattedMessage } from 'react-intl'

class AboutPress extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
        this.articleList = [
            ['10 Novembre 2023', 'BX1', 'Podcast+', 'bit.ly/47CvPFf'],
            ['24 Octobre 2023', 'Le Podcast des Entrepreneurs Inspirants', "Florence LOUMAYE - De l'idée à l'impact", 'bit.ly/49EuLCI'],
            ['11 Octobre 2023', 'La Libre', 'L’argent est tabou dans notre pays mais cela change avec la génération Z, plus décomplexée', 'bit.ly/40PvInO'],
            ['25 Avril 2023', 'Startup Vie', 'Cartonner sans lever de fonds !', 'bit.ly/3uqDvfo'],
            ['18 Octobre 2022', 'BX1', 'Jobs étudiants : le plafond augmenté de 475 à 600 heures, quel impact pour les jobistes et les entreprises ?', 'bit.ly/49DlJG7'],
            ['9 Juni 2022', 'Nieuwsblad', 'Vraag naar jobstudenten is torenhoog. Zo hoog dat klassieke studentensector uit de boot dreigt te vallen', 'bit.ly/3sEnYIw'],
            ['20 Mai 2022', 'Trends-Tendances', '2022 pourrait être une année record pour les jobs étudiants', 'bit.ly/49RsNiy'],
            ['20 Mai 2022', '7 sur 7', 'La demande de jobs étudiants explose: vers une année record?', 'bit.ly/3R1e0ub'],
            ['20 Mai 2022', 'RTBF', 'Vers une année record dans le secteur des jobs étudiants', 'bit.ly/47gqHqN'],
            ['20 Mai 2022', 'La DH', 'Vers une année record dans le secteur des jobs étudiants', 'bit.ly/3SNk5vG'],
            ['19 Mai 2022', 'Le soir', 'Vers une année record dans le secteur des jobs étudiants', 'bit.ly/3vZ2Z6e'],
            ['12 Mai 2022', 'RTL INFO', "Tout savoir sur la recherche d'un kot étudiant: à quoi faut-il être attentif?", 'bit.ly/47eKc31'],
            ['1 Mars 2022', 'La Libre', 'Comment trouver un stage? Voici quelques plateformes où tenter sa chance', 'bit.ly/3MMy8Mi'],
            ['28 Août 2020', 'La Libre', "Tout ce qu'un étudiant doit savoir pour trouver un stage", 'bit.ly/3bf8zl7'],
            ['08 Juillet 2020', "L'Echo", 'Quelque 93.000 étudiants risquent de ne pas avoir de job cet été ', 'bit.ly/3avw22n'],
            ['11 Avril 2020', 'La Capitale', 'Les entrepreneurs bruxellois tendent la main aux étudiants ', 'bit.ly/2NawMkO'],
            ['10 Avril 2020', 'BECI', 'Les entrepreneurs tendent la main aux étudiants', 'bit.ly/3ptRNDR'],
            ['20 Maart 2020 ', 'De wereld morgen.be', 'Student.be: Studentenvrijwilligers ter ondersteuning van ziekenhuizen en rust - en verzorgingstehuizen', 'bit.ly/3axUIYd'],
            ['20 Mars 2020', 'Le Soir', 'Coronavirus: le personnel de réserve se prépare à aider dans les hopitaux', 'bit.ly/3biYsf8'],
            ['6 Februari 2020', 'Fokus - De Standaard', 'Een groeimindset voor elke student', 'bit.ly/2Zp5gT4'],
            ['3 Juillet 2019', 'RTL INFO', "Vous êtes à la recherche d'un kot? Ce qu'il faut savoir", 'bit.ly/3pxP2ld'],
            ['7 Juillet 2018', 'De Tijd', 'De personnelsdienst worldt een algorithme', 'bit.ly/3avu9Tr'],
            ['28 Juin 2018', 'MT', 'Stem je jobaanbod beter af op verwachtingen van werkzoekenden', 'bit.ly/3avG5Ez'],
            ['12 Juin 2018', 'Made In Antwerpen', 'Kazi lanceert check voor witte raaf', 'bit.ly/3k37cKy'],
            ['5 Novembre 2018', "Le Vif l'Express", 'Comment réduire le stress du blocus et des examens ?', 'bit.ly/3k1YETS'],
            ['11 Mai 2018', 'LE VIF', 'Comment réduire le stress du blocus et des examens ?', 'bit.ly/2NhGIZF'],
            ['12 Juillet 2017', 'Today in Liège', 'Où trouver un kot à Liège? Voici la liste des différentes solutions', 'bit.ly/3ucS6H4'],
            ['3 Mai 2017', 'Le soir', 'Exclusif: le palmarès des meilleures start-up', 'bit.ly/3bhWpI9'],
            ['26 Janvier 2017', 'RH Magazine', "Pour les jeunes diplômés, la lettre de motivation n'a aucun sens", 'bit.ly/3saA0Uh'],
            ['10 Août 2016', 'DVO', 'Start-up GraduAid gaat voor optimale matching jong afgestudeerde en vacature', 'bit.ly/3u6cEAO'],
            ['7 Juillet 2016', 'HelloBank', 'Stujobs, le Tinder des jobs étudiants', 'hellobk.be/3rWu9lg'],
            ['3 Juillet 2016', 'Made in Oost-Vlaanderen', 'Starter van de week : Graduaid helpt bedrijven beste afgestudeerden vinden', 'bit.ly/37rOW8x'],
            ['30 Juin 2016', 'inforjeunes', 'Stujobs, une application pour trouver un job étudiant durant l’été', 'bit.ly/3au5vCp'],
            ['29 Juin 2016', 'bloovi.be', 'Deze Belgische start-up helpt afgestudeerden aan een job via slim matchingalgoritme', 'bit.ly/2NgsyYO'],
            ['13 Juin 2016', 'Metro', 'Jobs d’été: une app’ pour mettre étudiants et employeurs en contact', 'bit.ly/3avq7KH'],
            ['8 Juin 2016', 'La Libre', "Stujobs, une application pour relier étudiants et patrons durant l'été", 'bit.ly/3pu3ZEU'],
            ['8 Juin 2016', 'La DH', "Stujobs, une application pour relier étudiants et patrons durant l'été", 'bit.ly/2LZnR4S'],
            ['11 Mai 2016', 'Young Prozzz', 'GraduAid.be is aiming to become the most efficient for starter jobs and internships', 'bit.ly/3s29kou'],
            ['21 Mars 2016', 'RTBF', 'Trouver un job étudiant : trucs et astuces pour faciliter la recherche', 'bit.ly/3awIbUK'],
            ['3 Juin 2015', 'zowerkthet', 'Je eerste job of stage vinden met GraduAid', 'bit.ly/3qyvqyr'],
            ['1 Juin 2015', 'Technologium', 'GraduAid, een Belgisch platform om je eerste job of stage te vinden', 'bit.ly/3qz8dfD'],
            ['23 Juin 2012', 'La Libre', "Job étudiants : il n'est pas encore trop tard", 'bit.ly/3au51MB'],
            ['23 Juin 2012', 'La DH', "Job étudiants : il n'est pas trop tard", 'bit.ly/3bjjB8T']
        ]
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={ classes.titlePage }><FormattedMessage id="landing_about_press.title" /></Typography>
                    </Container>
                </Box>

                <Container maxWidth="md" className={classes.pageBox}>
                    <Box className={ classes.pressContainer }>
                        {
                            this.articleList.map((elem, index) => {
                                return (
                                    <Box className={ classes.pressBox } key={index}>
                                        <RouteMapLink className={ classes.titleOfPress } redirectionUrl={`https://${elem[3]}`} target="_blank" rel="noopener noreferrer">
                                            <Box className={ classes.mediaAndDate }>
                                                {elem[1]}
                                                <Typography className={ classes.bullet }></Typography>
                                                <Typography className={ classes.date }>{elem[0]}</Typography>
                                            </Box>
                                        </RouteMapLink>
                                        <Typography color="primary">{elem[2]}</Typography>
                                    </Box>
                                )
                            })
                        }
                    </Box>

                    <Typography variant="h2" className={ classes.contactTitle }>
                        <FormattedMessage id="landing_about_press.contact_us" />
                    </Typography>
                    <Box variant="body1" className={ classes.contactInfo }>
                        <Typography color="primary"><FormattedMessage id="landing_about_press.co_founder_name" /></Typography>
                        <Typography color="primary"><FormattedMessage id="landing_about_press.co_founder" /></Typography>
                        <Typography color="primary"><FormattedMessage id="landing_about_press.contact_email" /></Typography>
                    </Box>
                </Container>
            </>
        )
    }

    render () {
        return (
            <PageWrapper user={this.props.user}>
                <AboutPressHelmet />
                {this.createPageContent()}
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles, { withTheme: false })(AboutPress)
