/* eslint-disable react/prop-types */

import React from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import FilledButton from 'buttons/FilledButton/FilledButton'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap'
import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './Welcome_style'

class Welcome extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
        this.userStoredLocation = window.location.search.replace('?redirect=', '').replace('null', '')
        console.log(this.userStoredLocation)
    }

    redirectUser (redirectionUrl = null) {
        if (this.userStoredLocation) {
            window.location.href = this.userStoredLocation
        } else {
            window.location.href = RouteMap.Page('employer/new-ad')
        }
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <Container maxWidth="lg" className={classes.welcomeContainer}>
                <div className={classes.pageTitle}>
                    <FormattedMessage id="landing_login_page.sign_up.employer_title" />
                </div>
                <div>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.description" />
                    </Typography>
                </div>
                <div>
                    <Typography variant='h3' className={classes.subtitle}>
                        <FormattedMessage id="landing_login_page.sign_up.how" />
                    </Typography>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.text_info" />
                    </Typography>
                </div>
                <div>
                    <Typography variant='h3' className={classes.subtitle}>
                        <FormattedMessage id="landing_login_page.sign_up.more" />
                    </Typography>
                    <Typography className={classes.textContent}>
                        <FormattedMessage id="landing_login_page.sign_up.more_text" />
                    </Typography>
                </div>

                <FilledButton
                    color="secondary"
                    name={<FormattedMessage id="landing_login_page.sign_up.post_annonce_button_text" />}
                    onClick={() => this.redirectUser()}
                />
            </Container>
        )
    }

    render () {
        return (
            <PageWrapper user={this.props.user}>
                {this.createPageContent()}
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(Welcome)
