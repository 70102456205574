import React, { useState, useEffect } from 'react'

import { useStyles } from './ArticleReaction_style'
import ArticleAPI from 'api/ArticleAPI'
import Funny from 'images/emojis/funny'
import Great from 'images/emojis/great'
import Interesting from 'images/emojis/interesting'
import Like from 'images/emojis/like'
import Ugh from 'images/emojis/ugh'

const emojiToImageMapping = (emoji) => {
    if (emoji === 'funny') return Funny
    if (emoji === 'great') return Great
    if (emoji === 'interesting') return Interesting
    if (emoji === 'like') return Like
    if (emoji === 'ugh') return Ugh
}

const ArticleReaction = (props) => {
    const { articleId } = props
    const classes = useStyles()
    const reactionIdentifier = `articleReactionFor_${articleId}`
    const reactionIdIdentifier = `articleReactionIdFor_${articleId}`
    const [reaction, setReaction] = useState(localStorage.getItem(reactionIdentifier))
    const [animate, setAnimate] = useState(false)
    const [reactionOptions, setReactionOptions] = useState()
    const [articleReactionId, setArticleReactionId] = useState(localStorage.getItem(reactionIdIdentifier))

    useEffect(() => {
        ArticleAPI.getReactionOptions().then((response) => {
            setReactionOptions(response.data.options)
        })
    }, [])

    const handleArticleReaction = (newReaction) => {
        const data = {
            article_id: articleId,
            article_reaction_id: articleReactionId,
            emotion: newReaction
        }
        localStorage.setItem(reactionIdentifier, newReaction)
        setReaction(newReaction)
        setAnimate(true)
        ArticleAPI.addReactionToArticle(data).then(response => {
            if (!articleReactionId) {
                const reactionId = response.data.article_reaction_id
                localStorage.setItem(reactionIdIdentifier, reactionId)
                setArticleReactionId(reactionId)
            }
        })
    }

    return (
        <>
            {reactionOptions &&
                <div className={classes.container}>
                    {reactionOptions.map(option => {
                        const isSelected = reaction === option.value
                        return (
                            <div
                                onClick={() => handleArticleReaction(option.value)}
                                className={`
                                    ${classes.emoji}
                                    ${!reaction ? classes.noneSelected : ''}
                                    ${isSelected ? classes.selected : ''}
                                    ${isSelected && animate ? classes.animated : ''}
                                `}
                            >
                                <img src={emojiToImageMapping(option.value)} className={classes.emojiImage} alt={`emoji ${option.value}`}/>
                            </div>
                        )
                    })}
                </div>
            }
        </>
    )
}

export default ArticleReaction
