import React, { useContext } from 'react'

import { useStyles } from '../CreditPackages_style'
import { FormattedMessage } from 'react-intl'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@material-ui/core'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import { UserContext } from 'contexts/UserContext'

const StudentTriesToBuyCreditsModal = (props) => {
    const classes = useStyles()
    const { openModal, closeModal } = props
    const userContext = useContext(UserContext)

    return (
		<Dialog open={openModal} onClose={closeModal} className={classes.dialogContainer}>
            <DialogTitle disableTypography className={classes.dialogTitle}>
                <Typography variant='h6'>
                    <FormattedMessage id="credit_packages.mistaken_account.title_html" />
                </Typography>
                <IconButton
                    color='inherit'
                    onClick={closeModal}
                    aria-label='close'
                    className={classes.closeButton}
                >
                    <IconFactory icon='close'/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage values={{ email: userContext?.user?.email }} id="credit_packages.mistaken_account.description_html" >
                        {translatedText => <Typography variant='body1' component='p' dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                    </FormattedMessage>
                </DialogContentText>
                <DialogActions>
                    <RouteMapLink page='contact-employer' >
                        <FilledButton
                            newStyle
                            name={<FormattedMessage id='page_footer_navbar_data.footer.contact_us' />}
                            color='secondary'
                            size='small'
                            className={classes.buttonSize}
                        />
                    </RouteMapLink>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

export default StudentTriesToBuyCreditsModal
