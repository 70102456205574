import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    banner: {
        height: '420px',
        width: '100%',
        background: 'var(--red-burgundy)',
        padding: 60,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
            padding: '60px 30px'
        }
    },
    bannerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 28,
            alignItems: 'center'
        }
    },
    horizontalArrow: {
        position: 'absolute',
        zIndex: 2,
        left: '48%',
        top: '30%',
        top: '30%',
        transform: 'rotate(44deg)',
        [theme.breakpoints.down('md')]: {
            top: '42%',
            left: '58%',
            width: 150,
            transform: 'rotate(47deg)',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    pageTitle: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontSize: 40,
        marginBottom: 12,
        width: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            textAlign: 'center',
            lineHeight: 1.2,
            maxWidth: 300
        }
    },
    pageTitlFullWidth: {
        display: 'flex',
        alignItems: 'center',
        color: 'black',
        fontSize: 40,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 26,
            textAlign: 'center',
            lineHeight: 1.2,
        }
    },
    pageSubTitle: {
        fontSize: 18,
        width: '75%',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center',
            width: '100%'
        }
    },
    bannerCTA: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    button: {
        width: 220,
        fontSize: 14,
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontWeight: 500,
        minHeight: 60,
        textTransform: 'uppercase !important',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            minWidth: 200,
            minHeight: 40,
        }
    },
    containerOverride: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    whyUs: {
        background: 'var(--beige)',
        width: '100%',
        maxHeight: '420px',
        paddingTop: 60,
        padding: '80px 40px',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'unset'
        }
    },
    whyUsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 42
    },
    flexCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
    whyUsStats: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    },
    whyUsStat: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    statIconSmall: {
        width: 40,
        height: 40,
        marginTop: 8,
        marginBottom: 8,
        color: 'var(--red)',
        transform: 'rotate(-24deg)'
    },
    stat: {
        color: 'var(--red-burgundy)',
        fontFamily: 'var(--title-font) !important',

        [theme.breakpoints.only('xl')]: {
            fontSize: '32px !important'
        },
        [theme.breakpoints.between('sm', 'lg')]: {
            fontSize: '28px !important'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            fontSize: '24px !important'
        }
    },
    statDescription: {
        color: 'var(--red-burgundy)',
        [theme.breakpoints.only('sm')]: {
            width: '80%',
            margin: '0 auto'
        },
        [theme.breakpoints.only('xs')]: {
            width: '80%',
            margin: '0 auto'
        }
    },
    whatWeOffer: {
        paddingTop: 60,
        padding: '80px 40px',
    },
    offers: {
        display: 'flex',
        flexDirection: 'column',
        gap: 60
    },
    offer: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        margin: '0 auto',
        gap: 60,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gap: 20,
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    offerTitle: {
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    offerTagline: {
        fontSize: 22
    },
    offerMobileReverse: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        }
    },
    offerImage: {
        width: '50%',
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    offerText: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    boldRed: {
        fontWeight: 'bold',
        color: 'var(--red)',
        fontSize: 16,
    },
    expandingText: {},
    offerExplanationTitle: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer'
    },
    divider: {
        margin: '10px 0'
    },
    squeezeText: {
        lineHeight: '1rem',
    },
    // THEY TRUST US, CLIENT
    theyTrustUs: {
        paddingTop: 60,
        padding: '80px 40px',
    },
    partnerBox: {
        alignItems: 'center',
    },
    partnerImageBox: {
        width: '100%',
        '& a': {
            display: 'flex',
        }
    },
    partnerImage: {
        width: '40%',
        objectFit: 'contain',
        margin: '1rem auto',
        cursor: 'pointer',
        '&:hover' :{
            transform: 'scale(1.05)',
            transition: '.2s',
        },
        [theme.breakpoints.up('md')]: {
            width: '65%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    employerArticles: {
        paddingTop: 60,
        padding: '80px 40px',
        background: 'var(--beige)',
    },
    employerArticlesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 50,
    },
    employerArticlesTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        color: 'var(--black)',
        fontSize: 40,
        marginBottom: 12,
        width: 600,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            lineHeight: 1.2,
            textAlign: 'center'
        }
    },
    articles: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 20,
        marginTop: 30,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& a:nth-child(n+4)': {
                display: 'none',
  }
        }
    },
    articleCard: {
        flex: '0 0 32%',
        background: 'white',
        borderRadius: 20,
        padding: 20,
        minHeight: 330,
        display: 'flex',
        gap: 28,
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flex: '0 0 48%',
        }
    },
    articleCardTitle: {
        fontSize: 22
    },
    biggerFont: {
        fontSize: '16px !important'
    },
    articleCardDescription: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    readMore: {
        fontWeight: 'bold',
        fontSize: 16,
        color: 'var(--red)'
    },
    employerArticleCTA: {
        display: 'flex',
        justifyContent: 'center'
    }
}))
