import React from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

import SectionTitle from 'components/SectionTitle/SectionTitle'
import Field from 'inputs/Field/Field'
import ObjectTagList from 'components/ObjectTagList/ObjectTagList'
import FilledButton from 'buttons/FilledButton/FilledButton'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CollectionHandler from 'assets/CollectionHandler.js'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import FeedbackFactory from 'shared/components/FeedbackFactory/FeedbackFactory'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import ArticleImageUpload from './components/ArticleImageUpload/ArticleImageUpload'
import ArticleFormTextEditor from './components/ArticleFormTextEditor/ArticleFormTextEditor'
import css from './newarticle.scss'
import css_responsive from './newarticle_responsive.scss'
import { useStyles } from './newarticle_style'

import { FormattedMessage } from 'react-intl'
import { Checkbox, IconButton, Switch, TextField } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import { Autocomplete } from '@material-ui/lab'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import DocumentAPI from 'api/DocumentAPI'

class NewArticle extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            companyId: props.company_id,
            title: props.article ? props.article.title : '',
            content: props.article ? props.article.content : '',
            metaDescription: props.article ? props.article.meta_description : '',
            language: props.article ? props.article.language : '',
            categoryList: props.article ? props.article.tags : [],
            studyDomainList: props.article ? props.article.study_domains : [],
            bannerImage: (props.article && props.article.bannerImage) ? props.article.bannerImage.cloudinary_key : '',
            bannerImageId: (props.article && props.article.bannerImage) ? props.article.bannerImage.id : '',
            coverImage: (props.article && props.article.coverImage) ? props.article.coverImage.cloudinary_key : '',
            coverImageId: (props.article && props.article.coverImage) ? props.article.coverImage.id : '',
            ebook: props?.article?.ebook?.cloudinary_key,
            ebookId: props?.article?.ebook?.id,
            active: props.article ? props.article.active : false,
            alsoRead: props.article ? props.article.also_read : true,
            draft: props.article ? props.article.draft : false,
            aiAssistance: props.article ? props.article.ai_assistance : false,
            errorsList: {},
            loaded: false,
            admin: props.admin || false,
            publicationDate: props.article ? props.article.publication_date : '',
            jobTitleSubString: props.article ? props.article.job_title_sub_string : '',
            companyWriter: {},
            linkedCompanies: props.linkedCompanies || []
        }
        this.MINIMUM_TITLE_LENGTH = 20
        this.SUGGESTED_MAX_TITLE_LENGTH = 70
        this.MAX_TITLE_LENGTH = 90
        this.MINIMUM_META_DESCR_LENGTH = 70
        this.MAXIMUM_META_DESCR_LENGTH = 160

        this.articleCategoryCollection = props.user.admin ? CollectionHandler.Get('articleCategoryCollection') : CollectionHandler.Get('limitedArticleCategoryCollection')
        this.articleStudyDomainsCollection = []
        this.handleChange = this.handleChange.bind(this)
        this.canSubmit = this.canSubmit.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCoverImageChange = this.handleCoverImageChange.bind(this)
        this.handleBannerImageChange = this.handleBannerImageChange.bind(this)
        this.handleChangeDate = this.handleChangeDate.bind(this)
        this.handleQuillChange = this.handleQuillChange.bind(this)
        this.uploadEbook = this.uploadEbook.bind(this)
        this.postUploadEbook = this.postUploadEbook.bind(this)
        this.deleteEbook = this.deleteEbook.bind(this)
        this.frontValidation = this.frontValidation.bind(this)
        this.getCountColorClasName = this.getCountColorClasName.bind(this)
        this.renderTitleCount = this.renderTitleCount.bind(this)
        this.renderDescriptionCount = this.renderDescriptionCount.bind(this)

        this.feedbackRef = React.createRef()
        this.originUrl = window.location.search.replace('?redirect=', '')
    }

    componentDidMount () {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        this.articleStudyDomainsSort()
        this.canSubmit()
        if (this.props.companies) {
            this.initCompanyWriter()
        }
        console.log(this.originUrl)
        this.setState({
            loaded: true
        })
    }

    initCompanyWriter () {
        this.setState({
            companyWriter: this.props.companies.find(company => company.id === this.state.companyId)
        })
    }

    articleStudyDomainsSort () {
        const domains = CollectionHandler.Get('studyDomainCollection')
        const newDomains = []

        for (var domain in domains) {
            if (domains[domain].value !== 'event') {
                newDomains.push(
                    domains[domain]
                )
            }
        }

        this.articleStudyDomainsCollection = newDomains
    }

    handleChange (event) {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleChangeDate (date) {
        this.setState({
            publicationDate: date
        })
    }

    handleBannerImageChange (cloudinaryKey) {
        this.setState({
            bannerImageId: cloudinaryKey
        })
    }

    handleCoverImageChange (cloudinaryKey) {
        this.setState({
            coverImageId: cloudinaryKey
        })
    }

    handleQuillChange (value) {
        this.setState({
            content: value
        })
    }

    getActiveTags (type) {
        if (this[type]) {
            return this[type].getSelectedTags()
        } else {
            return []
        }
    }

    canSubmit () {
        var canSubmit = this.state.coverImageId &&
        this.state.title &&
        this.state.title.length >= this.MINIMUM_TITLE_LENGTH &&
        this.state.title.length <= this.MAX_TITLE_LENGTH &&
        this.state.content &&
        this.state.metaDescription &&
        this.state.metaDescription.length >= this.MINIMUM_META_DESCR_LENGTH &&
        this.state.metaDescription.length <= this.MAXIMUM_META_DESCR_LENGTH &&
        this.state.language &&
        this.getActiveTags('categoryList').length > 0
        // this.setState({canSubmit:canSubmit});
        return canSubmit
    }

    frontValidation () {
        const {
            title,
            content,
            metaDescription,
            language,
            coverImageId
        } = this.state
        const categoryList = this.getActiveTags('categoryList')

        var errorsList = {}
        if (title === '') {
            errorsList.title = <FormattedMessage id="landing_new_article.errors.enter_title" />
        } else if (title.length > this.MAX_TITLE_LENGTH) {
            errorsList.title = <FormattedMessage id="landing_new_article.max_140_char" />
        }
        if (content === '') {
            errorsList.content = <FormattedMessage id="landing_new_article.errors.enter_content" />
        }
        if (metaDescription === '') {
            errorsList.meta_description = <FormattedMessage id="landing_new_article.errors.enter_meta_data" />
        }
        if (language === '') {
            errorsList.language = <FormattedMessage id="landing_new_article.errors.enter_language" />
        }
        // if (bannerImageId === '') {
        //     errorsList.bannerImageId = <FormattedMessage id="landing_new_article.errors.enter_background_image" />
        // }
        if (coverImageId === '') {
            errorsList.coverImageId = <FormattedMessage id="landing_new_article.errors.enter_illustration" />
        }
        if (categoryList.length === 0) {
            errorsList.categoryList = <FormattedMessage id="landing_new_article.errors.choose_category" />
        }
        this.setState({ errorsList: errorsList })
    }

    handleSubmit (event) {
        if (this.canSubmit()) {
            const {
                companyId,
                title,
                content,
                metaDescription,
                language,
                bannerImageId,
                coverImageId,
                ebookId,
                active,
                alsoRead,
                aiAssistance,
                draft,
                publicationDate,
                admin,
                jobTitleSubString,
                linkedCompanies
            } = this.state
            const categoryList = this.getActiveTags('categoryList')
            const studyDomainList = this.getActiveTags('studyDomainList')
            axios({
                url: this.props.mod === 'edit' ? '/api/v1/articles/' + this.props.article.id : '/api/v1/articles',
                method: this.props.mod === 'edit' ? 'patch' : 'post',
                data: {
                    company_id: companyId,
                    title: title,
                    content: content,
                    meta_description: metaDescription,
                    language: language,
                    categories: categoryList,
                    cover_image_id: coverImageId,
                    banner_image_id: bannerImageId,
                    ebook_id: ebookId,
                    active: active,
                    also_read: alsoRead,
                    ai_assistance: aiAssistance,
                    draft: draft,
                    publication_date: publicationDate,
                    admin: admin,
                    job_title_sub_string: jobTitleSubString,
                    linked_companies: linkedCompanies.map((company) => company.id),
                    study_domains: studyDomainList
                }
            }).then(response => {
                this.setState({ errorsList: {} })

                if (this.state.admin) {
                    window.location.reload()
                } else if (this.props.user && this.props.user.admin) {
                    window.location.href = response.data.articleUrl
                } else if (this.originUrl === 'company-profile') {
                    window.location.href = response.data.companyProfileUrl
                } else {
                    window.location.href = response.data.redirectionUrl
                }
                this.feedbackRef.current.triggerFeedback(true, <FormattedMessage id="landing_new_article.article_posted" />)
                window.scrollTo(0, 0)
            },
            error => {
                if (error.response?.data.error) {
                    this.setState({ errorsList: error.response.data.error })
                } else {
                    this.feedbackRef.current.triggerFeedback(false, <FormattedMessage id="landing_new_article.errors.error_posting" />)
                }
            })
            event.preventDefault()
        } else {
            this.frontValidation()
            this.feedbackRef.current.triggerFeedback(false, <FormattedMessage id="landing_new_article.errors.error_posting" />)
            window.scrollTo(0, 0)
        }
    }

    postUploadEbook (upload_result) {
        const data = {
            cloudinary_key: upload_result.public_id,
            format: upload_result.format,
            name: upload_result.original_filename,
            is_pdf: true
        }
        DocumentAPI.postDocument(data).then((response) => {
            this.setState({
                ebook: response.data.document_object.cloudinary_key,
                ebookId: response.data.document_object.id
            })
        }).catch((error) => {
            alert(`An error has occurred: ${error}`)
            Bugsnag.notify(error)
        })
    }

    uploadEbook (value) {
        const cloudinaryWidget = CloudinaryHelper.createDocumentUploadWidget(
            { clientAllowedFormats: ['pdf'] },
            (error, result) => {
                if (!error && result?.event === 'success') {
                    this.postUploadEbook(result.info)
                }
            }
        )
        cloudinaryWidget.open()
    }

    deleteEbook () {
        this.setState({
            ebook: null,
            ebookId: null
        })
    }

    getCountColorClasName (currentLength, minLength, maxLength) {
        const { classes } = this.props
        let colorClassName = {}
        if (currentLength < minLength || currentLength > maxLength) {
            colorClassName = classes.countColor
        }
        return colorClassName
    }

    renderTitleCount () {
        return (
            <span className={this.getCountColorClasName(this.state.title.length, this.MINIMUM_TITLE_LENGTH, this.SUGGESTED_MAX_TITLE_LENGTH)}>
                <FormattedMessage id="landing_new_article.title_instructions" />{this.state.title.length}
            </span>
        )
    }

    renderDescriptionCount () {
        return (
            <span className={this.getCountColorClasName(this.state.metaDescription.length, this.MINIMUM_META_DESCR_LENGTH, this.MAXIMUM_META_DESCR_LENGTH)}>
                <FormattedMessage id="landing_new_article.about_article_summary" />{this.state.metaDescription.length}
            </span>
        )
    }

    renderContentCount () {
        const contentLength = this.state.content.split(' ').length

        return (
            <span>
                <FormattedMessage id="landing_new_article.content_instructions" />{contentLength}
            </span>
        )
    }

    render () {
        const { classes } = this.props
        if (this.state.loaded) {
            return (
                <div className="item-form-main">
                    <PageWrapper user={this.props.user} disableFooter={this.props.disableFooter} disableNavbar={this.props.disableNavbar}>
                        <FeedbackFactory ref={this.feedbackRef}/>
                        <Box className={classes.aboutPageBanner}>
                            <Container maxWidth="lg">
                                <Typography variant="h1" className={classes.titlePage}>
                                    {this.props.mod === 'edit' ? <FormattedMessage id="landing_new_article.article.modify" /> : <FormattedMessage id="landing_new_article.article.new" />}
                                </Typography>
                            </Container>
                        </Box>
                        <Container maxWidth="lg">
                            <Box my={2}>
                                <ArrowButton
                                    size='small'
                                    onClick={() => { window.history.back() }}
                                    direction='left'
                                    name={<FormattedMessage id="company_profile.company_page_quote.popup.back" />}
                                />
                            </Box>
                            <div className={classes.newArticleContainer}>
                                <SectionTitle step="1" title={<FormattedMessage id="landing_new_article.article_title" />} />
                                <Typography variant='body2'>
                                    {this.renderTitleCount()}
                                </Typography>
                                <Box mb={2} mt={1}>
                                    <FormattedMessage id="landing_new_article.max_title_char">
                                        {placeholder =>
                                            <TextField
                                                fullWidth
                                                required
                                                value={this.state.title}
                                                name="title"
                                                placeholder={placeholder}
                                                onChange={this.handleChange}
                                                autoComplete='on'
                                                error={this.state.errorsList.title}
                                            />
                                        }
                                    </FormattedMessage>
                                </Box>
                                <SectionTitle step="2" title={<FormattedMessage id="landing_new_article.illustrative_image" />} />

                                <Typography variant='body2'>
                                    <FormattedMessage id="landing_new_article.about_article" />
                                </Typography>

                                <ArticleImageUpload
                                    handleChange={this.handleCoverImageChange}
                                    cloudinaryKey={this.state.coverImage}
                                    error={this.state.errorsList.coverImageId}
                                    coverImage
                                />

                                <div>
                                    <SectionTitle title={<FormattedMessage id="landing_new_article.cover_image" />} />
                                    <Typography variant='body2'>
                                        <FormattedMessage id="landing_new_article.about_cover_image" />
                                    </Typography>
                                    <ArticleImageUpload
                                        handleChange={this.handleBannerImageChange}
                                        cloudinaryKey={this.state.bannerImage}
                                        error={this.state.errorsList.bannerImageId}
                                    />
                                </div>

                                <SectionTitle step="3" title={<FormattedMessage id="landing_new_article.body_content" />} />
                                <Typography variant='body2'>
                                    {this.renderContentCount()}
                                </Typography>
                                <div className={classes.articleEditorText}>
                                    <ArticleFormTextEditor
                                        isAdmin={this.props.user.admin}
                                        value={this.state.content}
                                        onChange={this.handleQuillChange}
                                    />
                                    { this.state.errorsList.content &&
                                        <p className="error">{this.state.errorsList.content}</p>
                                    }
                                </div>
                                <SectionTitle
                                    step="4"
                                    title={<FormattedMessage id="landing_new_article.article_summary" />}
                                />
                                <Typography variant='body2'>
                                    {this.renderDescriptionCount()}
                                </Typography>
                                <FormattedMessage id="landing_new_article.max_180_char">
                                    {placeholder =>
                                        <Field
                                            type="text"
                                            name="metaDescription"
                                            placeholder={placeholder}
                                            value={this.state.metaDescription}
                                            handleChange={this.handleChange}
                                            error={this.state.errorsList.meta_description}
                                        />
                                    }
                                </FormattedMessage>
                                <SectionTitle step="5" title={<FormattedMessage id="landing_new_article.language" />} />
                                <Typography variant='body2'>
                                    <FormattedMessage id="landing_new_article.about_language" />
                                </Typography>
                                <Field
                                    name="language"
                                    type="radio"
                                    required
                                    options={
                                        [
                                            { name: 'Nederlands', value: 'nl' },
                                            { name: 'Français', value: 'fr' },
                                            { name: 'English', value: 'en' }
                                        ]
                                    }
                                    direction="column"
                                    size="1"
                                    value={this.state.language}
                                    handleChange={this.handleChange}
                                    error={this.state.errorsList.language}
                                />
                                <SectionTitle step="6" title={<FormattedMessage id="landing_new_article.tags" />} />
                                <Typography variant='body2'>
                                    <FormattedMessage id="landing_new_article.about_tags" />
                                </Typography>

                                <Box mt={2} mb={5}>
                                    <ObjectTagList
                                        enableSelectAll
                                        value={this.state.categoryList}
                                        collection={this.articleCategoryCollection}
                                        name="categories"
                                        onRef={ref => (this.categoryList = ref)}
                                        canSubmit={this.canSubmit}
                                        error={this.state.errorsList.categoryList}
                                        disabledSelectAll
                                    />
                                </Box>

                                {this.state.admin &&
                                    <>
                                        <Box my={3}>
                                            <SectionTitle step="7" title="Active" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_active" />
                                            </Typography>
                                            <Switch
                                                color='secondary'
                                                checked={this.state.active}
                                                value={this.state.active}
                                                name='active'
                                                onChange={() => { this.setState({ active: !this.state.active }) }}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="8" title="Also Read" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_also_read" />
                                            </Typography>
                                            <Switch
                                                color='secondary'
                                                checked={this.state.alsoRead}
                                                value={this.state.alsoRead}
                                                name='alsoRead'
                                                onChange={() => { this.setState({ alsoRead: !this.state.alsoRead }) }}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="9" title="Draft" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_draft" />
                                            </Typography>
                                            <Switch
                                                color='secondary'
                                                checked={this.state.draft}
                                                value={this.state.draft}
                                                name='draft'
                                                onChange={() => { this.setState({ draft: !this.state.draft }) }}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="10" title="AI assistance" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_ai_assistance" />
                                            </Typography>
                                            <Switch
                                                color='secondary'
                                                checked={this.state.aiAssistance}
                                                value={this.state.aiAssistance}
                                                name='aiAssistance'
                                                onChange={() => { this.setState({ aiAssistance: !this.state.aiAssistance }) }}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="11" title="Company Writer" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_company_writer" />
                                            </Typography>
                                            <Autocomplete
                                                disablePortal
                                                value={this.state.companyWriter}
                                                onChange={(_, value) => this.setState({ companyWriter: value, companyId: value.id })}
                                                id="company-writer-field"
                                                options={this.props.companies}
                                                getOptionLabel={(option) => option.name}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Company" />}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="12" title="Publication date" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_publication_date" />
                                            </Typography>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                                <KeyboardDatePicker
                                                    value={this.state.publicationDate}
                                                    keyboardIcon={<IconFactory icon='date'/>}
                                                    required
                                                    error={false}
                                                    helperText={null}
                                                    className={classes.date}
                                                    format="dd/MM/yyyy"
                                                    onChange={this.handleChangeDate}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date'
                                                    }}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.inputBase
                                                        }
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="13" title="Linked companies" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_linked_companies" />
                                            </Typography>
                                            <Autocomplete
                                                disablePortal
                                                id="linked-companies-field"
                                                value={this.state.linkedCompanies}
                                                multiple={true}
                                                options={this.props.companies}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, value) => this.setState({ linkedCompanies: value })}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} label="Linked companies" />}
                                            />
                                        </Box>

                                        <Box my={3}>
                                            <SectionTitle step="14" title="Linked jobs" />
                                            <Typography variant='body2'>
                                                <FormattedMessage id="landing_new_article.about_linked_jobs" />
                                            </Typography>
                                            <Field
                                                type="input"
                                                name="jobTitleSubString"
                                                value={this.state.jobTitleSubString}
                                                handleChange={this.handleChange}
                                            />
                                        </Box>

                                        <Box mt={2} mb={5}>
                                            <SectionTitle step="15" title="Study domains" />
                                            <ObjectTagList
                                                enableSelectAll
                                                value={this.state.studyDomainList}
                                                collection={this.articleStudyDomainsCollection}
                                                name="study_domains"
                                                onRef={ref => (this.studyDomainList = ref)}
                                                canSubmit={this.canSubmit}
                                                error={this.state.errorsList.studyDomainList}
                                                disabledSelectAll
                                            />
                                        </Box>

                                        <Box mt={2} mb={5}>
                                            <SectionTitle step="16" title="Link an ebook" />
                                            {this.state.ebook
                                                ? <>
                                                    <IconButton onClick={this.deleteEbook}>
                                                        <IconFactory icon='delete' />
                                                    </IconButton>
                                                    <RouteMapLink redirectionUrl={CloudinaryURLHelper.getDocumentUrl(this.state.ebook)} target='_blank' rel="noreferrer">
                                                        {CloudinaryURLHelper.getDocumentUrl(this.state.ebook)}
                                                    </RouteMapLink>
                                                </>
                                                : <FilledButton
                                                    name='Upload Ebook'
                                                    onClick={this.uploadEbook}
                                                    color='secondary'
                                                />
                                            }
                                        </Box>
                                    </>
                                }

                                <div className={classes.articleSubmitButton}>
                                    <FilledButton
                                        name={this.props.mod === 'edit' ? <FormattedMessage id="landing_new_article.save" /> : <FormattedMessage id="landing_new_article.publish" /> }
                                        onClick={this.handleSubmit}
                                        color='secondary'
                                    />
                                </div>
                            </div>
                        </Container>
                    </PageWrapper>
                </div>
            )
        } else {
            return null
        }
    }
}

export default withStyles(useStyles)(NewArticle)
