/* eslint-disable react/prop-types */
import React from 'react'
import CheckboxListView from './CheckboxListView'

const CheckboxList = (props) => {
    const { type, setSelectedItems, selectedItems, collection, filterTextId, width, showTags } = props

    const handleCheckboxChange = (targetItem) => {
        let newSelectedItems = []

        if (selectedItems.some(item => item.value === targetItem.value)) {
            newSelectedItems = selectedItems.filter(item => targetItem.value !== item.value)
        } else {
            newSelectedItems = [...selectedItems, targetItem]
        }
        setSelectedItems(newSelectedItems)
    }

    return (
        <CheckboxListView
            handleCheckboxChange={handleCheckboxChange}
            selectedItems={selectedItems}
            collection={collection}
            width={width}
            showTags={showTags}
        />
    )
}

export default CheckboxList
