import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertCardContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            minHeight: '100px'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            minHeight: '150px'
        }

    },
    checkContainer: {
        width: '35px'
    },
    alertCard: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            height: '100%'
        }
    },
    alertCardColumn: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            height: '100%',
            padding: '0 10px',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    alertJobType: {
        borderRadius: '5px 0 0 5px',
        textTransform: 'uppercase',
        backgroundColor: 'var(--main-color)',
        [theme.breakpoints.up('md')]: {
            width: '35px',
            color: 'white',
            '& span': {
                transform: 'rotate(-90deg)',
                textAlign: 'center'
            }
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            padding: '0 10px'
        }
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '0 2rem'
        },
        [theme.breakpoints.down('md')]: {
            margin: '5px 0'
        }
    },
    alertCreationDate: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    hoverActionContainer: {
        display: 'flex',
        [theme.breakpoints.up('lg')]: {
            padding: '20px'
        },
        [theme.breakpoints.down('md')]: {
            color: 'var(--main-color)',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            padding: '0 1rem'
        }
    },
    alertColor: {
        color: 'var(--main-color) !important'
    }
}))
