import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    fieldButton: {
        padding: '14px',
        cursor: 'pointer',
        textTransform: 'none',
        height: '75px',
        borderRadius: '12px',
        width: '100%',
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    },
    fieldContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        width: '100%'
    },
    cityContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    },
    cityChip: {
        height: '24px',
        borderRadius: '36px',
        backgroundColor: '#E4F8E4',
        fontSize: '10px',
        '& .MuiChip-label': {
            color: 'var(--green)'
        }
    },
    openJobs: {
        color: 'var(--grey-middle)',
        lineHeight: '18.33px',
        textAlign: 'left'
    }
}))
