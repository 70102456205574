import React from 'react'

import css from './mainTitle.scss'
import css_responsive from './mainTitle_responsive.scss'

export default class MainTitle extends React.Component {
    constructor (props) {
        super(props)

        this.state = { firstname: this.props.Firstname }
    }

    render () {
        return (
            <div className="mainTitle">
                <div className="maintitlerotation">
                    <div className="backMainTitle " >
                        <h4>{this.props.text}</h4>
                    </div>
                    <div className="frontMainTitle dynamic-blue-font">
                        {this.props.text}
                    </div>
                </div>
            </div>
        )
    }
}
