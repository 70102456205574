import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: 16,
        gap: 8
    },
    numberBox: {
        fontFamily: "'GintoNormal-Medium', sans-serif  !important",
        display: 'flex',
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        border: '1px solid var(--dark-grey)',
        fontSize: 14,
        lineHeight: 'normal',
        height: 36,
        width: 36
    },
    title: {
        fontFamily: "'GintoNormal-Medium', sans-serif  !important",
        fontSize: '12px !important',
        whiteSpace: 'break-spaces'
    },
    text: {
        fontSize: '12px !important',
        whiteSpace: 'break-spaces'
    }
}))
