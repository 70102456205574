/* eslint-disable react/prop-types */
import React from 'react'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/core/styles'
import { styles } from './articleListCard_style'

class ArticleListCard extends React.Component {
    constructor (props) {
        super(props)
        this.getCategory = this.getCategory.bind(this)
        this.getCloudinaryKey = this.getCloudinaryKey.bind(this)
        this.category = this.getCategory()
        this.coverImageCloudinaryKey = this.getCloudinaryKey()
    }

    getCategory () {
        let categoryString = CollectionHandler.Translate('articleCategoryCollection', this.props.article.category)
        if (categoryString === '/') {
            categoryString = CollectionHandler.Translate('articleCategoryCollection', 'student_life')
        }
        return categoryString.toUpperCase()
    }

    getCloudinaryKey () {
        if (this.props.article?.cover_image?.cloudinary_key) {
            return this.props.article.cover_image.cloudinary_key
        } else {
            return 'default-company-logo-black'
        }
    }

    render () {
        const { classes } = this.props
        return (
            <RouteMapLink
                showItemType='article'
                showItemUrl={this.props.article.url}
                style={{ width: '100%' }}
            >
                <Card
                    ref={this.props.forwardRef}
                    id={this.props.id}
                    className={classes.cardContainer}
                    elevation={4}
                >
                    <CardMedia
                        image={CloudinaryURLHelper.getImageUrl('ARTICLE_CARD', this.coverImageCloudinaryKey)}
                        alt={this.props.article.title}
                        title={this.props.article.title}
                        className={classes.cardMediaContainer}
                    />
                    <CardContent className={classes.cardContentContainer}>
                        <Box className={classes.cardContentBox}>
                            <Box className={classes.articleTitleBox}>
                                <Typography
                                    variant='h3'
                                    component='span'
                                    className={classes.typographyTitle}
                                >
                                    {this.props.article.title}
                                </Typography>
                            </Box>
                            <Box className={classes.categoryBoxContainer}>
                                <Typography variant='subtitle1' component='span' style={{color: 'var(--grey)'}}>
                                    {this.category}
                                </Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </RouteMapLink>
        )
    }
}

export default withStyles(styles)(ArticleListCard)
