/* eslint-disable no-fallthrough */
import React from 'react'

import { useStyles } from './MobileWalletCard_style'
import { Box, Button, Chip, IconButton, Typography } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MobileWalletCard = (props) => {
    const classes = useStyles()
    const {
        filter, index, jobFilterCard,
        name, page, secondary
    } = props

    const buildRouteMapLinkProps = () => {
        switch (page) {
        case 'student-jobs':
            if (!index) {
                return { location: filter.value_name, itemType: page }
            }
        case 'first-jobs':
        case 'internships':
            if (!index) {
                return { domain: filter.value_name, itemType: page }
            }
        default:
            return { page: page }
        }
    }

    return (
        <RouteMapLink {...buildRouteMapLinkProps()}>
            <Button className={`${classes.walletCardButton} ${secondary && classes.secondary} ${jobFilterCard && classes.walletFilterCardButton}`}>
                <Box className={classes.cardNameContainer}>
                    <Typography variant='h4' component='p'>
                        {name}
                    </Typography>
                    {filter?.new > 0 &&
                        <Chip label={`${filter.new} NEW`} className={classes.walletCardChip}/>
                    }
                </Box>
                <IconButton edge='end'>
                    <IconFactory icon='arrow-right' className={classes.walletCardArrow}/>
                </IconButton>
            </Button>
        </RouteMapLink>
    )
}

export default MobileWalletCard
