/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from '../JobShowBanner_style'
import { Image, Transformation } from 'cloudinary-react'
import ApplyButton from '../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Typography from '@material-ui/core/Typography'

const JobShowBannerMobileSticky = (props) => {
    const { user, job, unavailable } = props
    const classes = useStyles()

    return (
        <Box className={`${classes.bannerContainer} ${classes.bannerContainerSticky}`}>
            <Box className={classes.titleBoxSticky}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={job.company_url}
                    className={classes.logoBox}
                >
                    <Image
                        className={`${classes.logo} ${classes.logoSticky}`}
                        cloudName="studentbe"
                        publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                        alt={`${job.company_logo_cloudinary_key} logo`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                    </Image>
                </RouteMapLink>
                <Typography variant='h6' component='h1' className={`${classes.jobTitleBox} ${classes.jobTitleBoxSticky}`}>
                    {job.title}
                </Typography>
            </Box>
            {!unavailable &&
                <Box className={`${classes.ctaBox} ${classes.ctaBoxSticky}`}>
                    <ApplyButton
                        job={job}
                        user={user}
                        unavailable={unavailable}
                        className={classes.applyButton}
                    />
                </Box>
            }
        </Box>
    )
}

export default JobShowBannerMobileSticky
