import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    pageTitle: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: '20px !important',
        color: 'white !important',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 310
        }
    }
}))
