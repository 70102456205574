import React, { useEffect, useState, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewsCategoryIndex_style'
import { Box, Chip, Typography, Container } from '@material-ui/core'

import ArticleAPI from 'api/ArticleAPI'
import ArticleCardV2 from 'shared/cards/ArticleCard/ArticleCardV2'
import CTANewsletter from 'shared/components/CTANewsletter/CTANewsletter'
import CollectionHandler from 'assets/CollectionHandler.js'
import HomeSearch from 'components/HomeSearch/HomeSearch'
import NewsSearchBar from '../shared/NewsSearchBar/NewsSearchBar'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NewsCategoryIndex = (props) => {
    const classes = useStyles()

    const [categoryArticles, setCategoryArticles] = useState([])
    const [categoryArticlesCount, setCategoryArticlesCount] = useState(null)
    const [indexPage, setIndexPage] = useState(1)
    const [isInView, setIsInView] = useState(false)
    const loadMoreRef = useRef(null)

    const newsSubCategories = CollectionHandler.Get('newsIndexSubCategories')[props.selectedArticleTagObject.value]?.sub_categories || []

    const getCurrentCategoryTranslated = () => {
        const category = props.selectedArticleTagObject.value
        const translatedCategory = CollectionHandler.Translate('articleCategoryCollection', category)

        // If translation is set as '/', that means the category as to be a study domain
        // as those are the only two options.
        if (translatedCategory === '/') {
            return CollectionHandler.Translate('studyDomainCollection', category)
        } else {
            return translatedCategory
        }
    }
    const currentCategoryTranslated = getCurrentCategoryTranslated()

    useEffect(() => {
        if (isInView) {
            if (categoryArticlesCount > categoryArticles.length) setIndexPage(indexPage + 1)
        }
    }, [isInView])

    useEffect(() => {
        const handleScroll = () => {
            const element = loadMoreRef.current

            if (element) {
                const rect = element.getBoundingClientRect()
                const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0

                setIsInView(isVisible)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        loadCategoryArticles()
    }, [indexPage])

    const loadCategoryArticles = () => {
        ArticleAPI.filterArticle({
            categories: [props.selectedArticleTagObject.value],
            page: indexPage,
            load_previous_pages: true
        }).then((response) => {
            setCategoryArticles(response.data.res)
            setCategoryArticlesCount(response.data.search_count)
        })
    }

    const renderCategoryChip = (category) => {
        const categoryTranslated = CollectionHandler.Translate('articleCategoryCollection', category.name)
        return (
            <RouteMapLink articleCategory={category.name}>
                <Chip
                    label={categoryTranslated}
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                    className={classes.chip}
                />
            </RouteMapLink>
        )
    }

    return (
        <Box>
            {props.darkMode && <Box className={classes.bannerImage} />}
            <Box className={classes.headContainer}>
                <Box className={classes.breadcrumbsContainer}>
                    <Container maxWidth='lg' disableGutters>
                        <Typography component='div' className={classes.breadcrumbs}>
                            <RouteMapLink page='student-life'><span className={classes.breadcrumbRoot}>News /</span></RouteMapLink> {currentCategoryTranslated}
                        </Typography>
                    </Container>
                </Box>
                <Box className={classes.titleContainer}>
                    <Typography variant='div' className={classes.titleText}>
                        {currentCategoryTranslated}
                    </Typography>
                    <Box className={classes.categoryList}>
                        {newsSubCategories.map((category) => renderCategoryChip(category))}
                    </Box>
                </Box>
            </Box>
            <Box className={classes.searchContainer}>
                <NewsSearchBar />
            </Box>
            <Box className={classes.contentContainer}>
                <Container maxWidth='lg' disableGutters className={classes.pageContainer}>
                    <FormattedMessage id={`blog_section.category_index.articles_count${categoryArticlesCount < 2 ? '_singular' : ''}`} values={{ articlesCount: categoryArticlesCount, category: currentCategoryTranslated.toLowerCase() }}>
                        {text =>
                            <Typography variant='h1' className={classes.articlesContainerTitle}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        }
                    </FormattedMessage>

                    {/* Category Articles List */}
                    <Box className={classes.categoryArticlesContainer}>
                        {categoryArticles.map((article, index) => {
                            return (
                                <ArticleCardV2
                                    key={`articlecard_${article.id}`}
                                    article={article}
                                />
                            )
                        })}
                    </Box>
                </Container>
                <Box className={classes.CTAContainer}>
                    <Container maxWidth='lg' disableGutters>
                        <HomeSearch />
                    </Container>
                </Box>
                {/* <CTANewsletter /> */}
            </Box>
            {/* Invisible div to trigger load more */}
            <Box ref={loadMoreRef}/>
        </Box>
    )
}

export default NewsCategoryIndex
