import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './VerticalVideos_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import SectionMainTitle from 'components/SectionMainTitle/SectionMainTitle'
import VideoAPI from 'api/VideoAPI'
import NewCarouselCards from 'components/NewCarouselCards/NewCarouselCards'

const VerticalVideos = (props) => {
    const { companyId, type, cardsNumber, onLoad } = props
    const [videos, setVideos] = useState([])
    const classes = useStyles()

    useEffect(() => {
        getVideos()
    }, [])

    const getVideos = () => {
        const data = {
            type: type,
            company_id: companyId
        }

        VideoAPI.GetVideos(data).then((response) => {
            setVideos(response.data)
            onLoad && onLoad()
        })
    }

    return (
        videos.length > 0 &&
            <Box className={classes.videosContainer}>
                <SectionMainTitle textId='job_show.video'/>
                <NewCarouselCards
                    type='video'
                    items={videos}
                    autoPlay={false}
                    cycleNavigation={false}
                    cardsNumber={cardsNumber || 4}
                    allowButtons={true}
                    hideButtonOnMobile={false}
                    btnStyles={classes.arrowBtn}
                    arrowStyles={classes.arrows}
                />
            </Box>
    )
}
export default VerticalVideos
