import React from 'react'

import { useStyles } from './ContactEmployerPage_style'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import EmployerContactForm from 'components/EmployerContactForm/EmployerContactForm.jsx'
import ContactEmployerHelmet from 'helmets/ContactEmployerHelmet'

const ContactEmployerPage = (props) => {
	const { user } = props
    const classes = useStyles()

    return (
		<PageWrapper user={user} disableFooterLists>
			<ContactEmployerHelmet />
			<EmployerContactForm background='var(--beige)' color='var(--black)' />
		</PageWrapper>
    )
}

export default ContactEmployerPage
