import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(
    (theme) => (
        {
            imgNouvelleAnnonce: {
                width: '300px'
            },
            subtitle: {
                fontStyle: 'italic',
                color: 'gray'
            },
            avatar: {
                color: theme.palette.primary.main,
                border: 'solid 1px',
                backgroundColor: 'white',
                width: 30,
                height: 30
            },
            titleField: {
                marginBottom: 16
            },
            sectionTitleField: {
                marginBottom: 19
            },
            inputfield: {
                maxLength: 60,
                fontSize: theme.typography.body2.fontSize
            },
            field: {
                width: '100%'
            },
            spacing: {
                margin: theme.spacing(9, 'auto')
            },
            labelStyle: {
                fontSize: theme.typography.body2.fontSize
            }
        })
)
