import React from 'react'

import { useStyles } from './MobileJobTypesCard_style'
import { Box, Typography, Grid, Button } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import IconFactory from 'icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler'

const MobileJobTypesCards = (props) => {
    const classes = useStyles()
    const { setJobTypeSelection } = props

    return (
        <Box className={classes.jobTypesCardContainer}>
            <Box>
                <Typography variant='h6' component='div' className={classes.jobTypesTitle}>
                    <FormattedMessage id='page_footer_navbar_data.what_job_looking_for' />
                </Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Button className={classes.jobTypeButton} onClick={() => setJobTypeSelection('student-jobs')}>
                        <Box className={classes.jobTypeContainer}>
                            <IconFactory icon='student-job' className={classes.jobIcon}/>
                            <Typography className={classes.jobType}>
                                {CollectionHandler.Translate('itemTypeCollection', 'student_job')}
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.jobTypeButton} onClick={() => setJobTypeSelection('internships')}>
                        <Box className={classes.jobTypeContainer}>
                            <IconFactory icon='internship' className={classes.jobIcon}/>
                            <Typography className={classes.jobType}>
                                {CollectionHandler.Translate('itemTypeCollection', 'internship')}
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
                <Grid item xs={4}>
                    <Button className={classes.jobTypeButton} onClick={() => setJobTypeSelection('first-jobs')}>
                        <Box className={classes.jobTypeContainer}>
                            <IconFactory icon='first-job' className={classes.jobIcon}/>
                            <Typography className={classes.jobType}>
                                {CollectionHandler.Translate('itemTypeCollection', 'first_job')}
                            </Typography>
                        </Box>
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default MobileJobTypesCards
