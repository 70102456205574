/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import PasswordInput from 'inputs/PasswordInput/PasswordInput'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap.js'
import FilledButton from 'buttons/FilledButton/FilledButton'

import PasswordAPI from 'api/PasswordAPI'

import { FormattedMessage } from 'react-intl'

const NewPasswordPage = (props) => {
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [errors, setErrors] = useState([])
    const search = props.railsContext.search.split('?')
    const resetToken = search[0].split('reset_password_token=')[1]
    const email = search[1].split('email=')[1]
    const resetUrl = props.railsContext.href

    const sendRequest = (event) => {
        event.preventDefault()
        setLoading(true)
        console.log(email)
        PasswordAPI.ChangePassword({
            email: email,
            password: password,
            resetToken: resetToken
        })
            .then((response) => {
                console.log(response)
                setLoading(false)
                setSent(true)
            }).catch((error) => {
                setLoading(false)
                setErrors(error.response.data.errors)
            })
    }

    const renderEmailForm = () => {
        return (
            <Box>
                <form onSubmit={sendRequest}>
                    <Box my={8} display="flex" justifyContent="center">
                        <FormControl>
                            <Box minWidth="300px" my={2}>
                                <PasswordInput
                                    key='password-input'
                                    value={password}
                                    name="newPassword"
                                    onChange={(event) => { setPassword(event.target.value) }}
                                    error={errors.length > 0}
                                    helperText={errors[0]}
                                />
                                {errors.length === 0 &&
                                    <FormHelperText id="my-helper-text">
                                        <FormattedMessage id="new_password.new_password" />
                                    </FormHelperText>
                                }
                            </Box>
                        </FormControl>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            <FormattedMessage id="new_password.submit" />
                        </Button>
                    </Box>
                </form>
            </Box>
        )
    }

    const renderSuccess = () => {
        return (
            <Box>
                <Box my={4} display="flex" justifyContent="center">
                    <Typography color='primary'>
                        <FormattedMessage id="new_password.success" />
                    </Typography>
                </Box>
                <Box my={4} display="flex" justifyContent="center">
                    <FilledButton
                        name={<FormattedMessage id="new_password_page.go_to_login" />}
                        href={RouteMap.Page('login')}
                        color='secondary'
                    />
                </Box>
            </Box>
        )
    }

    const renderError = () => {
        return (
            <Box>
                <Box my={4} display="flex" justifyContent="center">
                    <Typography
                        color='primary'
                    >
                        <FormattedMessage id="new_password.error" />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <RouteMapLink redirectionUrl={resetUrl}>
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            <FormattedMessage id="new_password.retry" />
                        </Button>
                    </RouteMapLink>
                </Box>
            </Box>
        )
    }

    return (
        <Container maxWidth='sm'>
            <Box my={16}>
                <Typography
                    variant='h3' component='h2'
                    color='primary'
                    align='center'
                >
                    <FormattedMessage id="new_password.title" />
                </Typography>
                {!loading && !sent &&
                    renderEmailForm()
                }
                {loading && <ProgressCircle/>}
                {sent &&
                    renderSuccess()
                }
            </Box>
        </Container>

    )
}
export default NewPasswordPage
