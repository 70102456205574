/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from '../../../../shared/wrappers/RouteMapLink/RouteMapLink'
import IconFactory from '../../../../shared/icons/IconFactory/IconFactory'

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400
    },
    iconButton: {
        padding: 10
    },
    divider: {
        height: 28,
        margin: 4
    },
    buttonReset: {
        textTransform: 'capitalize'
    },
    boxInput: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    boxInputSearch: {
        paddingRight: 25,
        paddingLeft: 10
    },
    fullWidth: {
        width: '100%'
    },
    inputBase: {
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: 14
        }
    }
}))
const MyKotIndexSearchBar = ({ allKot, setAllKot }) => {
    const classes = useStyles()
    const [saveAllkot] = useState(allKot)
    const [words, setWords] = useState('')

    const handleChange = (event) => {
        setWords(event.target.value)
    }
    const handleClick = () => {
        setAllKot(allKot.filter(allKot => allKot.title.toLowerCase().includes(words.toLowerCase())))
        console.log(allKot)
        if (words === '') {
            handleReset()
        }
    }
    const handleReset = () => {
        setWords('')
        setAllKot(saveAllkot)
    }

    return (
        <Box mt={5} mb={5}>
            <Grid container spacing={5} >
                <Grid item xs={12} sm={6} md={7} lg={8}>
                    <Box className={`${classes.boxInput} ${classes.boxInputSearch}`}>
                        <Box display="flex" mr={1}>
                            <IconFactory icon='search' color='primary'/>
                        </Box>
                        <FormattedMessage id="search_bar.search.kot_title">
                            {placeholder =>
                                <TextField
                                    InputProps={{
                                        classes: {
                                            input: classes.inputBase
                                        }
                                    }}
                                    placeholder={placeholder}
                                    value={words}
                                    inputProps={{ 'aria-label': { placeholder } }}
                                    onChange={handleChange}
                                    className={classes.inputBase}
                                    fullWidth
                                />
                            }
                        </FormattedMessage>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4} >
                    <Box display="flex" justifyContent="center">
                        <Button
                            fullWidth
                            className={classes.buttonReset}
                            size={'small'}
                            color={'primary'}
                            variant={'outlined'}
                            onClick={handleReset}
                            style={{ marginRight: '1rem' }}
                        >
                            <FormattedMessage id="search_bar.reinit_button_1" />
                        </Button>
                        <RouteMapLink page='users/kots/new' className={classes.fullWidth}>
                            <FilledButton
                                fullWidth
                                name=<FormattedMessage id='item_offers.kot.new_ad' />
                                color='secondary'
                                style={{ color: 'white' }}
                            />
                        </RouteMapLink>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}
export default MyKotIndexSearchBar
