import React, { useEffect } from 'react'
import NewArticle from '../../pages/user/NewArticle/NewArticle'

const AdminEditArticle = (props) => {
    return (
        <NewArticle
            user={props.user}
            company_id={props.company_id}
            mod={props.mod}
            article={props.article}
            admin={true}
            companies={props.companies}
            disableFooter={true}
            disableNavbar={true}
            linkedCompanies={props.linkedCompanies}
        />
    )
}
export default AdminEditArticle
