/* eslint-disable react/prop-types */

import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './aboutterms_style'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import { FormattedMessage } from 'react-intl'

class AboutTerms extends React.Component {
    constructor (props) {
        super(props)

        this.state = {

        }

        this.createPageContent = this.createPageContent.bind(this)
    }

    toParagraphs (...nodes) {
        let key = 0;
        let title = ["Article", "Artikel"]; // title start with Article or Artikel
        const children = nodes.reduce((result, node) => (
            result.concat(
                typeof node === 'string'
                    ? node
                        .split('\n')
                        .map(paragraph => <p key={++key}>
                            {title.some(elem => paragraph.split(" ")[0].includes(elem)) ? <strong>{paragraph}</strong> : paragraph}
                        </p>)
                    : node
            )
        ), []);
        return <span>{children}</span>
    };

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}><FormattedMessage id="landing_about_terms.title" /></Typography>
                    </Container>
                </Box>
                <Container maxWidth='md' className={classes.pageContainer}>
                    <Typography className={classes.breakline}>
                        <FormattedMessage id="landing_about_terms.content">
                            {this.toParagraphs}
                        </FormattedMessage>
                    </Typography>
                </Container>
            </>
        )
    }

    render () {
        return (
            <div className="name-your-parent-container">
                <PageWrapper user={this.props.user}>
                    {this.createPageContent()}
                </PageWrapper>
            </div>
        )
    }
}
export default withStyles(useStyles, { withTheme: false })(AboutTerms)
