/* eslint-disable react/prop-types */

import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import AboutKotsHelmet from 'helmets/AboutKotsHelmet'
import FilledButton from 'buttons/FilledButton/FilledButton'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import CardSlider from 'components/CardSlider/CardSlider'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from '../../shared/wrappers/PageWrapper/PageWrapper'
import CarouselCards from '../../shared/components/CarouselCards/CarouselCards'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import Container from '@material-ui/core/Container'

class AboutKots extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="landing_about_kots.search_kots" />
                            <FormattedMessage id="landing_about_pages.kot" /> ?
                        </Typography>
                    </Container>
                </Box>

                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_kots.want_kot" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_kots.student_opportunity" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>.
                        </Typography>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_kots.go_to" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_kots.kots" /></RouteMapLink>
                            <FormattedMessage id="landing_about_kots.define_search_criteria" />
                        </Typography>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_kots.on_website" />
                            <RouteMapLink page='student-life/kot' className={classes.studentFont}><FormattedMessage id="landing_about_kots.blog" /></RouteMapLink>
                            <FormattedMessage id="landing_about_kots.discover_articles" />
                            <RouteMapLink page='student-life' className={classes.studentFont}><FormattedMessage id="landing_about_kots.blog" /></RouteMapLink>
                            <FormattedMessage id="landing_about_kots.check_it_out" />
                        </Typography>
                        <Typography className={classes.breakline}><FormattedMessage id="landing_about_kots.ideal_kots" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_kots.found_kots_near_you" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_kots.select_city" />
                        </Typography>
                    </Box>

                    <Box my={5}>
                        <LocationsListContainer type="student-rooms" />
                    </Box>

                    <Box>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_kots.found_city" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_kots.kots" /></RouteMapLink> !
                        </Typography>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_kots.kots_research" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.studentbe" />
                            <FormattedMessage id="landing_about_pages.help_you_to_found" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.student_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.a" />
                            <RouteMapLink page='internships' className={classes.studentFont}><FormattedMessage id="landing_about_pages.internship" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.and_even_a" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.first_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.trying_your_luck" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.offers_internship" />
                        </Typography>
                    </Box>

                    <Box className={classes.buttonOffers}>
                        <RouteMapLink page='student-rooms'>
                            <FilledButton
                                name=<FormattedMessage id="landing_about_pages.consult_offers" />
                                color='secondary'
                            />
                        </RouteMapLink>
                    </Box>
                </Container>

            </>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <PageWrapper user={this.props.user}>
                <AboutKotsHelmet />
                {this.createPageContent()}
                <Container maxWidth="lg">
                    <Typography variant="h2" className={classes.responsiveText}><FormattedMessage id="landing_about_pages.discover_last_kots" /></Typography>
                    <Box my={5}>
                        <CarouselCards
                            cards={this.props.lastOffers}
                            type='kot'
                            userType={this.props.userType}
                            id='lastOffers'
                        />
                    </Box>
                    <Box className={classes.cardTitle}>
                        <Typography variant='h2'>
                            <FormattedMessage id="landing_about_pages.advice_to_find" />
                            <FormattedMessage id="landing_about_pages.kot" />
                        </Typography>

                        <RouteMapLink page='student-rooms/articles'>
                            <ArrowButton
                                name=<FormattedMessage id="landing_about_pages.see_all_articles" />
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
                <CardSlider
                    side="left"
                    cards={this.props.recentArticles}
                    maxItem="3"
                    showLine
                />
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(AboutKots)
