/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react'
import {
    Box, ClickAwayListener, Popper,
    InputBase, Avatar, Typography, MenuItem,
    ListItemText, IconButton
} from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import { List } from 'react-virtualized'
import { useStyles } from 'components/Searchbar/Searchbar_v2_style'
import { FormattedMessage } from 'react-intl'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'

const CityFilterSearchView = (props) => {
    const { filters, handleSearchCities, handleInputSearchCity, searchCities, citiesInput, showTags, modal } = props
    const classes = useStyles()
    const inputRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (e) => {
        setOpen(true)
        setAnchorEl(e.currentTarget)
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleSearchCities(option)}
            >
                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
        )
    }

    return (
        <Box className={classes.cityFilterDropdownBox}>
            <Box
                onClick={handleClick}
                className={`${classes.selectSearchbarV2} ${modal ? classes.inputBackground : ''}`}
            >
                <IconFactory icon='search-regular' color='primary' fontSize='15px' className={classes.searchIcon}/>
                <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                    <FormattedMessage id="search_bar.search.city">
                        {placeholder =>
                            <InputBase
                                classes={{ input: classes.inputBase }}
                                placeholder={placeholder}
                                name="citySearch"
                                onChange={handleInputSearchCity}
                                value={citiesInput}
                                fullWidth
                                onKeyDown={e => e.stopPropagation()}
                                inputRef={inputRef}
                            />
                        }
                    </FormattedMessage>
                </Typography>
                {filters.location.length > 0 &&
                    <Avatar className={classes.tagAvatar}>{filters.location.length}</Avatar>
                }
            </Box>
            <Popper
                className={classes.cityFilterDropdown}
                placement='bottom'
                open={open}
                anchorEl={anchorEl}
                transition
                disablePortal
            >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <Box>
                        <Box className={classes.boxInputSearchCityV2}>
                            <FilterTagList small handleChange={handleSearchCities} items={filters.location}/>
                            <IconButton
                                aria-label='close'
                                onClick={() => setOpen(false)}
                                className={classes.closeIconButton}
                            >
                                <IconFactory icon='close' />
                            </IconButton>
                        </Box>
                        <List
                            width={400}
                            height={300}
                            rowCount={searchCities.length}
                            rowHeight={45}
                            rowRenderer={rowRenderer}
                        />
                    </Box>
                </ClickAwayListener>
            </Popper>
            {showTags &&
                <Box className={classes.filterTagList}>
                    <FilterTagList handleChange={handleSearchCities} items={filters.location} />
                </Box>
            }
        </Box>
    )
}

export default CityFilterSearchView
