import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    baseTag: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        padding: '6px 10px',
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        fontSize: 10,
        fontWeight: 300,
        lineHeight: '0.8rem',
    },
    baseTagTopRight: {
        top: 12,
        right: 12,
    },
    baseTagTopLeft: {
        top: 12,
        left: 12,
    },
    newTag: {
        color: 'white',
        textTransform: 'uppercase',
        backgroundColor: 'var(--green)',
    },
    jobTypeTag: {
        backgroundColor: 'white',
    },
    baseTageText: {
        '&:first-letter': {
            textTransform: 'capitalize',
        }
    },
    notActiveTag: {
        color: 'white',
        backgroundColor: 'var(--red)'
    }
}))
