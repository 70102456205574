import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleCardContainer: {
        width: 305,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    articleLink: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    articleCardImage: {
        height: 172,
        [theme.breakpoints.down('xs')]: {
            height: 200
        }
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '12px',
        objectFit: 'cover'
    }
}))
