import React, { useEffect, useState } from 'react'
import RegexHelper from 'assets/RegexHelper'

const ResponsiveVideo = (props) => {
    const { videoUrl, fixedWidth } = props
    const [video, setVideo] = useState({
        source: '',
        sourceId: ''
    })

    useEffect(() => {
        const sourceAndId = getVideoSourceAndId()
        setVideo({
            ...video,
            ...sourceAndId
        })
    }, [videoUrl])

    const getVideoSourceAndId = () => {
        if (!videoUrl) return { source: '', sourceId: '' }
        const youtubeParsed = youtubeParser()
        if (youtubeParsed) return { source: 'youtube', sourceId: youtubeParsed }
        const dailymotionParsed = dailymotionParser()
        if (dailymotionParsed) return { source: 'dailymotion', sourceId: dailymotionParsed }
        const vimeoParsed = vimeoParser()
        if (vimeoParsed) return { source: 'vimeo', sourceId: vimeoParsed }
        return { source: '', sourceId: '' }
    }

    const youtubeParser = () => {
        /* got this from stack overflow:
        https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
        It takes into account a bunch of different youtube url formats
         */
        const match = RegexHelper.youTubeRegex.exec(videoUrl)
        return (match && match[7].length === 11) ? match[7] : false
    }

    const dailymotionParser = () => {
        const match = RegexHelper.dailyMotionRegex.exec(videoUrl)
        if (!match) { return false }
        return match[2] || match[1]
    }

    const vimeoParser = () => {
        const match = RegexHelper.vimeoRegex.exec(videoUrl)
        if (!match) { return false }
        if (match[6]) return `${match[5]}?h=${match[6]}`
        return match[5]
    }

    const renderYouTubeVideo = () => {
        if (fixedWidth) {
            return (
                <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${video.sourceId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowfullscreen
                />
            )
        }
        return (
            <div
                className="video"
                style={{
                    position: 'relative',
                    paddingBottom: '56.25%' /* 16:9 */,
                    paddingTop: 25,
                    height: 0
                }}
            >
                <iframe
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                    src={`https://www.youtube.com/embed/${video.sourceId}`}
                    frameBorder="0"
                />
            </div>
        )
    }

    const renderDailymotionVideo = () => {
        /* got this from google, medium. It creates a responsive youtube iframe:
        https://medium.com/@kevinsimper/full-width-youtube-embed-with-react-js-responsive-embed-509de7e7c3bf
        */
        return (
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
                <iframe
                    style={{ width: '100%', height: '100%', position: 'absolute', left: '0px', top: '0px', overflow: 'hidden' }}
                    frameBorder="0"
                    type="text/html"
                    src={`https://www.dailymotion.com/embed/video/${video.sourceId}`}
                    width="100%"
                    height="100%"
                    allowFullScreen
                ></iframe>
            </div>
        )
    }

    const renderVimeoVideo = () => {
        /* got this from google, medium. It creates a responsive youtube iframe:
        https://medium.com/@kevinsimper/full-width-youtube-embed-with-react-js-responsive-embed-509de7e7c3bf
        */
        return (
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                    src={`https://player.vimeo.com/video/${video.sourceId}`}
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    frameBorder="0"
                    allow="autoplay;fullscreen; picture-in-picture"
                    allowFullScreen
                ></iframe>
                <script src="https://player.vimeo.com/api/player.js" />
            </div>

        )
    }

    const renderVideo = () => {
        if (video.source === 'youtube') return renderYouTubeVideo()
        if (video.source === 'dailymotion') return renderDailymotionVideo()
        if (video.source === 'vimeo') return renderVimeoVideo()
    }

    return (
        <div className="videobox">
            {renderVideo()}
        </div>
    )
}

export default ResponsiveVideo
