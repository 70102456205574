import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    jobSelectionForm: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            height: '100%',
            padding: 12,
        }
    },
    jobType: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        height: 280,
        width: 200,
        border: '1px solid var(--light-grey)',
        borderRadius: 20,
        cursor: 'pointer',
        '&:hover': {
            borderColor: 'var(--red)'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'fit-content',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            padding: 14
        }
    },
    selectedJobType: {
        borderColor: 'var(--red)'
    },
    jobIcon: {
        fontSize: 12,
        height: 24,
        width: 24,
        padding: 14,
        background: 'var(--beige)',
        borderRadius: 12,
    },
    jobTitle: {
        textTransform: 'capitalize',
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: 16,
    },
}))
