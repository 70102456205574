/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from 'components/Searchbar/Searchbar_v2_style'
import IconFactory from 'icons/IconFactory/IconFactory'

const FilterTagList = (props) => {
    const { items, handleChange, small } = props
    const classes = useStyles()

    return (
        <Box className={classes.selectedTagsContainer}>
            {items.map((item) => {
                return (
                    <Box
                        className={`${classes.selectedLocationTag} ${small && classes.selectedLocationTagSmall}`}
                        key={`selected-location-${item.name}`}
                        onClick={() => handleChange(item)}
                    >
                        <IconFactory icon='cross' className={classes.tagCross} fontSize='12px'/>
                        {item.name}
                    </Box>
                )
            })}
        </Box>
    )
}

export default FilterTagList
