import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    radioGroupOption: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%'
    },
    radioGroupLabel: {
        width: '100%'
    },
    radioGroupField: {
        width: '100%'
    }
}))

