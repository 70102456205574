import React, { useState } from 'react'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import OpenIcon from '../OpenIcon/OpenIcon'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceHighSchoolYearInput = (props) => {
    const { value, inputRef, error, handleChangeFormation, textFontClassName, formationKey, disableUnderline } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl} required >
            <Select
                className={`${textFontClassName} ${error && classes.textFieldError}`}
                disableUnderline={disableUnderline}
                value={value}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                onChange={handleChangeFormation(formationKey, 'high_school_year')}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                IconComponent={() => <OpenIcon open={open}/>}
                inputRef={inputRef}
            >
                {[...Array(7)].map((elem, index) => (
                    <MenuItem value={index + 1} key={`experience-menu-item-${index}`}>
                        {index + 1}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ExperienceHighSchoolYearInput
