/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import { useStyles } from './ActionButtons_style'

const ActionButtons = (props) => {
    const { job, user } = props
    const classes = useStyles()

    return (
        <Box className={classes.actionButtonsContainer}>
            <FavoriteAction
                fullButton
                userType={user?.type}
                type={job.type}
                id={job.id}
                item={job}
                size='20px'
                padding='8px'
                buttonClassName={`${classes.actionButton} ${classes.favoriteActionButton}`}
            />
            <ShareAction
                fullButton
                infos={{
                    title: job.title,
                    url: window.location.href,
                    type: job.type,
                    shareText: 'transl_internship.share_button'
                }}
                userType={user?.type}
                size='20px'
                padding='8px'
                buttonClassName={`${classes.actionButton} ${classes.shareActionButton}`}
            />
        </Box>
    )
}

export default ActionButtons
