/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './MoreItemsCard_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const MoreItemsCard = (props) => {
    const { titleId, title, moreItemsURL, textValues } = props
    const classes = useStyles()

    const buttonText = () => {
        return <Box className={classes.buttonText}>
            <FormattedMessage id='job_show_similar_jobs.button' />
            <IconFactory icon='arrow-right' fontSize='14px'/>
        </Box>
    }

    return (
        <RouteMapLink redirectionUrl={moreItemsURL}>
            <Box className={classes.card}>
                <Typography className={classes.title}>
                    {title}
                    {titleId && <FormattedMessage id={titleId} values={textValues} />}
                </Typography>
                <FilledButton
                    newStyle
                    fullWidth
                    color='primary'
                    name={buttonText()}
                />
            </Box>
        </RouteMapLink>
    )
}

export default MoreItemsCard
