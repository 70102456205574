/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import { styles } from './KotCard_style'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import CardImageCarousel from './components/CardImageCarousel'

const KotCard = (props, thisRef) => {
    const { kot, id } = props
    const { classes } = props

    const handleMouseOver = () => {
        if (props.mapProviderProps) {
            props.mapProviderProps.flyToCard(kot.latitude, kot.longitude)
        }
    }
    return (
        <Box id={id} onMouseOver={handleMouseOver} className={classes.wrapper}>
            <Card className={classes.cardRoot} elevation={4}>
                <Box>
                    {kot.images && kot.images.length > 0
                        ? <CardImageCarousel kot={kot} />
                        : <CardMedia
                            className={classes.media}
                            image={CloudinaryURLHelper.getImageUrl(
                                'DEFAULT_LOGO_STUDENT',
                                'default-company-logo-black'
                            )}
                            alt='default company logo black'
                        />
                    }
                </Box>

                <RouteMapLink
                    showItemType='kot'
                    showItemUrl={kot.url}
                    className={classes.kotCardText}
                >
                    <Box className={classes.kotTitleAndFavourite}>
                        <Typography
                            variant='h3'
                            component='p'
                            className={classes.kotTitle}
                        >
                            {kot.kot_type
                                ? kot.title.toLowerCase().substring(0, 60) +
                                    (kot.title.length > 60
                                        ? '...'
                                        : ''
                                    )
                                : ''
                            }
                        </Typography>
                        <Box className={classes.kotFavourite}>
                            <FavoriteAction
                                item={props.kot}
                                userType={props.userType}
                                color='var(--black)'
                                onItemCard
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box my={1} className={classes.kotDetails}>
                            <Typography className={classes.type}>
                                {kot.kot_type
                                    ? CollectionHandler.Translate('kotTypeCollection', kot.kot_type.toLowerCase())
                                    : ''
                                }
                            </Typography>
                        </Box>
                        <Box className={classes.kotCityAndRoom}>
                            <Box className={classes.kotRoom}>
                                <IconFactory icon='bed-front' color='primary' />
                                <Typography
                                    variant='subtitle1'
                                    component='span'
                                    color='primary'
                                    className={classes.titleRoom}
                                >
                                    {kot.available_rooms}
                                </Typography>
                            </Box>
                            <Box className={classes.kotCity}>
                                <IconFactory icon='marker' color='primary' />
                                <Typography
                                    variant='subtitle1'
                                    component='span'
                                    color='primary'
                                    className={classes.titleCity}
                                >
                                    {kot.city.toLowerCase()}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </RouteMapLink>
            </Card>
        </Box>
    )
}

export default withStyles(styles)(KotCard)
