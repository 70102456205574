import React, { useEffect, useState } from 'react'

import DocumentsAPI from 'api/DocumentAPI'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

import { FormattedMessage } from 'react-intl'

import UploadAvatarProfileView from './UploadAvatarProfileView'

const UploadAvatarProfile = (props) => {
    const {
        setImageAvatar,
        imageAvatar,
        profileId,
        pictureId,
        user,
        fabClassName,
        avatarClassName
    } = props

    const [state, setState] = useState({ imageArray: imageAvatar || [] })
    const [avatarUrl, setAvaterUrl] = useState()
    const [cloudy, setcloudy] = useState()
    const [feedback, setFeedback] = useState({
        open: false,
        severity: 'success',
        message: null
    })

    useEffect(() => {
        const cloudinaryKey =
            state.imageArray.length > 0
                ? state.imageArray[state.imageArray.length - 1].cloudinary_key
                : (pictureId || 'profile-default-image')
        const cloudinaryImageUrl = CloudinaryURLHelper.getImageUrl('USER_AVATAR', cloudinaryKey)
        setAvaterUrl(cloudinaryImageUrl)
    }, [imageAvatar, pictureId, state])

    useEffect(
        () => {
            setcloudy(
                CloudinaryHelper.createImageUploadWidget(
                    {
                        cropping: true,
                        multiple: false
                    },
                    (error, result) => {
                        if (!error && result && result.event === 'success') {
                            try {
                                DocumentsAPI.createAvatar({
                                    cloudinary_key: result.info.public_id,
                                    profile_id: profileId,
                                    name: result.info.original_filename,
                                    format: result.info.format
                                }).then((response) => {
                                    const newImageArray = state.imageArray
                                    newImageArray.push({
                                        id: result.info.public_id,
                                        cloudinary_key: result.info.public_id,
                                        format: result.info.format,
                                        name: result.info.original_filename
                                    })
                                    setImageAvatar(newImageArray)
                                    setState({ ...state, imageArray: newImageArray })
                                    setFeedback({
                                        open: true,
                                        severity: 'success',
                                        message: <FormattedMessage id='landing_student_profile_page.success' />
                                    })
                                })
                            } catch (e) {
                                setFeedback({
                                    open: true,
                                    severity: 'error',
                                    message: `Error Avatar Upload: ${e}`
                                })
                            }
                        }
                    }
                )
            )
        }, ['']
    )

    const openModal = () => {
        cloudy.open()
    }

    const closeFeedback = () => {
        setFeedback({ ...feedback, open: false })
    }

    return (
        <>
            {cloudy && <UploadAvatarProfileView
                avatarUrl={avatarUrl}
                openModal={openModal}
                feedback={feedback}
                closeFeedback={closeFeedback}
                user={user}
                avatarClassName={avatarClassName}
                fabClassName={fabClassName}
            />}
        </>
    )
}

export default UploadAvatarProfile
