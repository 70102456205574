import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    noPaddingLeft: {
        paddingLeft: 0
    },
    noListStyle: {
        listStyle: 'none'
    },
    noBorderBottom: {
        borderBottom: 0
    },
    p: {
        fontFamily: 'GintoNormal-Regular',
        fontSize: '15px',
        margin: 0
    },
    b: {
        fontFamily: 'var(--title-font)'
    },
    ul: {
        fontFamily: 'GintoNormal-Regular',
        listStyle: 'none',
        paddingLeft: 0,
        margin: 0
    },
    tableMinWidth: {
        minWidth: 250
    },
    minWidth: {
        minWidth: 'initial'
    },
    contentSection: {
        marginTop: 65
    }
}))
