/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import IconFactory from 'icons/IconFactory/IconFactory'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './CheckboxList_style'

const CheckboxListView = (props) => {
    const { selectedItems, collection, handleCheckboxChange } = props
    const classes = useStyles()

    return (
        <FormControl fullWidth className={classes.formControl}>
            <Grid container spacing={1}>
                {collection.map((domain, key) => (
                    <Grid item xs={12} md={6} key={key}>
                        <Box className={classes.checkboxLine} onClick={() => handleCheckboxChange(domain)}>
                            <Checkbox
                                className={classes.checkbox}
                                icon={<IconFactory icon='checkbox-false' fontSize='18px' />}
                                checkedIcon={<IconFactory icon='checkbox-true-filled' color='var(--red)' fontSize='18px' />}
                                color='primary'
                                value={domain.value}
                                checked={selectedItems.some(elem => elem.value === domain.value)}
                            />
                            <Typography variant='body2' className={classes.checkboxText}>{domain.name}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </FormControl>
    )
}

export default CheckboxListView
