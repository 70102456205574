/* eslint-disable react/prop-types */

import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import { useStyles } from './paymentcard_style'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import SVGFactory from 'icons/SVGFactory/SVGFactory'

import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler'
import RouteMap from 'assets/RouteMap'

const handleValutaOrder = (price) => {
    let result = ''
    if (RouteMap.GetLocaleFromUrl === 'fr') {
        result = price + ' €'
    } else {
        result = '€' + price
    }
    return result
}

const PaymentCard = (props) => {
    const { startPayment } = props
    const classes = useStyles()
    const isButtonDisabled = props.user?.type === 'student'
    const creditValue = CollectionHandler.Get('creditsValue')
    const registerForFreeCreditsUrl = props.user ? RouteMap.Page('employer/new-ad') : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('employer/new-ad')}&userType=employer`
    const generateFeatures = (features) => {
        return (
            <Grid container spacing={1}>
                {
                    features.map((elem, index) => {
                        return (
                            Array.isArray(elem)
                                ? <Grid item xs={12} key={index} className={classes.flexBox}>
                                    <Typography
                                        variant='body2'
                                        className={classes.packagesItem}
                                    >
                                        <IconFactory icon='check' fontSize='0.875rem' style={{ marginTop: '4px' }} className={classes.checkRoundedIcon} />
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        className={classes.packagesItem}
                                    >
                                        {elem[0]}
                                    </Typography>
                                    <Tooltip title={elem[1]}>
                                        <IconButton className={classes.tooltipButton}>
                                            <IconFactory
                                                icon='question-circle'
                                                fontSize='0.875rem' className={classes.helpOutlineTwoTonIcon}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                : <Grid item xs={12} key={index} className={classes.flexBox}>
                                    <Typography
                                        variant='body2'
                                        className={classes.packagesItem}
                                    >
                                        <IconFactory icon='check' fontSize='0.875rem' style={{ marginTop: '4px' }} className={classes.checkRoundedIcon} />
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        className={classes.packagesItem}
                                    >
                                        {elem}
                                    </Typography>
                                </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    const renderStyleIfSelected = (style, style2 = null, index = 0) => {
        return props.selectedPack === index ? style : style2
    }

    const renderOfferPayement = () => {
        const packageProps = props.selectedPackage
        return (
            <Card elevation={4} style={{ backgroundColor: renderStyleIfSelected('var(--main-color)', 'white', props.index) }}>
                <CardContent className={classes.studentCardBox}>
                    <Box className={classes.flexCenter}>
                        <Box className={classes.cardIconSize}>
                            <SVGFactory name={'package-' + packageProps.icon} style={{ fill: renderStyleIfSelected('white', 'var(--red)', props.index) }}/>
                        </Box>
                    </Box>
                    <Box className={classes.studentCardTitleHeight}>
                        <Typography
                            variant='h1'
                            component='h3'
                            color='primary'
                            className={classes.cardTitle}
                        >
                            {packageProps.title}
                        </Typography>
                    </Box>
                    <Box className={classes.studentCardItemsHeight}>
                        {generateFeatures(packageProps.features)}
                    </Box>
                    <Box className={classes.priceContainer}>
                        {
                            packageProps.price
                                ? <>
                                    <Typography
                                        variant='h1'
                                        component='h3'
                                        color='primary'
                                        className={classes.priceBox}
                                    >
                                        {handleValutaOrder(packageProps.price)}
                                        <Typography variant='body2' className={classes.priceDescription}>
                                            <FormattedMessage id="landing_payment_page.by_ads" />
                                        </Typography>
                                    </Typography>
                                    {packageProps.total &&
                                        <Typography variant='body2' className={classes.priceTotal}>
                                            <FormattedMessage id="landing_payment_page.total" />&nbsp;
                                            {handleValutaOrder(packageProps.price * packageProps.credits)}
                                        </Typography>
                                    }
                                    <Typography
                                        style={{ color: renderStyleIfSelected('white', 'var(--grey)', props.index) }}
                                        variant='h3'
                                        component='body1'
                                    >
                                        {packageProps.promo && '- ' + packageProps.promo + ' %'}
                                    </Typography>
                                </>
                                : <>
                                    <Typography
                                        variant='h4'
                                        component='body1'
                                        color='primary'
                                    >
                                        {packageProps.name === 'student_job-free'
                                            ? <FormattedMessage id="landing_payment_page.student_job-free" />
                                            : <FormattedMessage id="landing_payment_page.request" />
                                        }
                                    </Typography>
                                </>
                        }
                    </Box>
                </CardContent>
                <CardActions className={classes.flexCenter}>
                    {
                        !props.buyingCredits
                            ? <FilledButton
                                newStyle
                                variant='contained'
                                style={{ minWidth: 170 }}
                                size='small'
                                disabled={isButtonDisabled}
                                onClick={() => { startPayment(packageProps) }}
                                name={packageProps.buttonText}
                            />
                            : packageProps.name === 'student_job-3'
                                ? <RouteMapLink page='contact'>
                                    <FilledButton
                                        newStyle
                                        variant='contained'
                                        className={classes.buttonSize}
                                        disabled={isButtonDisabled}
                                        color='secondary'
                                        size='small'
                                        name={<FormattedMessage id="landing_payment_page.contact_us" />}
                                    />
                                </RouteMapLink>
                                : packageProps.name === 'student_job-free'
                                    ? <RouteMapLink redirectionUrl={registerForFreeCreditsUrl} >
                                        <FilledButton
                                            newStyle
                                            name={<FormattedMessage id='landing_payment_page.free_credits' />}
                                            color='secondary'
                                            size='small'
                                            className={classes.buttonSize}
                                        />
                                    </RouteMapLink>
                                    : <FilledButton
                                        newStyle
                                        variant='contained'
                                        className={classes.buttonSize}
                                        size='small'
                                        disabled={isButtonDisabled}
                                        onClick={() => { props.startPayment(packageProps) }}
                                        name={<FormattedMessage id="landing_payment_page.buy" />}
                                    />
                    }
                </CardActions>
            </Card>
        )
    }

    const renderTabPayement = () => {
        const packageProps = props.selectedPackage
        const notEnoughCredit = props.credits < packageProps.credits
        let handlePayment = () => {}
        if (notEnoughCredit) {
            handlePayment = () => { props.startPayment(packageProps) }
        } else {
            handlePayment = () => { props.payWithCredits(packageProps) }
        }

        return (
            <Card>
                <CardContent className={classes.firstJobCardBox}>
                    <Box className={classes.flexCenter}>
                        <Box className={classes.cardIconSize}>
                            <SVGFactory name={'package-' + packageProps.icon} style={{ fill: 'var(--red)' }}/>
                        </Box>
                    </Box>
                    <Box className={classes.firstJobCardTitleHeight}>
                        <Typography
                            variant='h1'
                            component='h3'
                            color='primary'
                            className={classes.textCenter}
                        >
                            {packageProps.title}
                        </Typography>
                    </Box>
                    <Box className={classes.firstJobSubtitle}>
                        {
                            Array.isArray(packageProps.subtitle)
                                ? <Box>
                                    <Typography
                                        variant='body2'
                                        className={classes.firstJobSubtitleColor}
                                    >
                                        {packageProps.subtitle[0]}
                                        <Tooltip title={packageProps.subtitle[1]}>
                                            <IconFactory icon='question-circle' fontSize='0.875rem' className={classes.helpOutlineTwoTonIcon} />
                                        </Tooltip>
                                    </Typography>
                                </Box>
                                : <Typography
                                    variant='body2'
                                    className={classes.firstJobSubtitleColor}
                                >
                                    {packageProps.subtitle}
                                </Typography>
                        }
                    </Box>
                    <Box className={classes.firstJobCardItemsHeight}>
                        {generateFeatures(packageProps.features)}
                    </Box>
                    <Box className={`${classes.firstJobPriceContainer} ${classes.alignCenter}`}>
                        <Typography
                            color='primary'
                            variant='h1'
                            component='h3'
                            className={classes.textCenter}
                        >
                            {handleValutaOrder(packageProps.price * packageProps.credits)}
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions className={classes.flexCenter}>
                    <FilledButton
                        newStyle
                        variant='contained'
                        className={classes.buttonSize}
                        size='small'
                        disabled={isButtonDisabled}
                        onClick={handlePayment}
                        name={<FormattedMessage id="landing_payment_page.buy" />}
                    />
                </CardActions>
            </Card>
        )
    }

    const renderPayementCardType = () => {
        switch (props.type) {
        case 'offer':
            return renderOfferPayement()
        case 'payement':
            return renderTabPayement()
        }
    }

    return (
        <>
            {renderPayementCardType()}
        </>
    )
}

export default PaymentCard
