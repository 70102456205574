import React, { useState } from 'react'

import { useStyles } from './NavigationButton_style'
import { useStyles as useDarkModeStyles } from './NavigationButtonDarkMode_style'
import { Box, Typography, Button } from '@material-ui/core'

import NavigationDropDownMenu from '../NavigationDropDownMenu/NavigationDropDownMenu'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationButton = (props) => {
    const { name, page, type, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    const [isMenuModalOpen, setMenuModal] = useState(false)

    const openModal = () => {
        setMenuModal(true)
    }

    const closeModal = () => {
        setMenuModal(false)
    }

    const isCurrentNavigationButtonActive = () => {
        return page === type
    }

    return (
        <Box onMouseLeave={closeModal}>
            <RouteMapLink page={type}>
                <Button
                    onMouseOver={openModal}
                    className={`${classes.navigationButton} ${isCurrentNavigationButtonActive() ? classes.navigationButtonActive : classes.navigationButtonNonActive}`}
                >
                    <Typography variant='h4' component='div' className={classes.buttonText}>
                        {name}
                    </Typography>
                </Button>
            </RouteMapLink>
            <NavigationDropDownMenu
                openModal={openModal}
                closeModal={closeModal}
                isMenuModalOpen={isMenuModalOpen}
                type={type}
            />
        </Box>
    )
}

export default NavigationButton
