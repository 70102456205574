import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    image: {
        maxWidth: '100%',
        maxHeight: '600px',
        borderRadius: '16px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))
