import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler.js'
import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import Field from 'inputs/Field/Field'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

const DeleteCompanyJobsModalView = (props) => {
    const { removeCheckedJobs, checkedJobsLength, closeModal, handleDeleteInfoChange, loading } = props
    const theme = useTheme()
    const deleteReasonCollection = CollectionHandler.Get('deleteReasonCollection')

    return (
        <Dialog
            maxWidth='sm'
            open
            onClose={closeModal}
            disableScrollLock
            aria-labelledby="responsive-dialog-delete"
            fullWidth
        >
            <DialogTitle onClose={closeModal}>
                <Typography variant='h2' style={{ marginTop: '1rem' }}>
                    <FormattedMessage values={{ count: checkedJobsLength }} id={`delete_offer_popup.company-job.title.${checkedJobsLength === 1 ? 'one' : 'more'}`}/>
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        color: theme.palette.grey[500]
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            {loading && <ProgressCircle />}
            {!loading && <>
                <DialogContent>
                    <Box m={2}>
                        <Typography variant='body2'>
                            <FormattedMessage values={{ count: checkedJobsLength }} id={`delete_offer_popup.company-job.text.${checkedJobsLength === 1 ? 'one' : 'more'}`}/>
                        </Typography>
                        <Field
                            type="hashes-select"
                            title={<FormattedMessage id="delete_offer_popup.reason_for_delete" />}
                            different
                            blank
                            optionSelect={deleteReasonCollection}
                            name="deleteInfos"
                            handleDeleteInfoChange={handleDeleteInfoChange}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <LineButton
                        name={<FormattedMessage id="delete_offer_popup.option_no"/>}
                        onClick={closeModal}
                        size='small'
                    />
                    <FilledButton
                        size='small'
                        color='secondary'
                        onClick={removeCheckedJobs}
                        name={<FormattedMessage id="delete_offer_popup.option_yes"/>}
                        style={{ marginLeft: 10 }}
                    />
                </DialogActions>
            </>}
        </Dialog>
    )
}

export default DeleteCompanyJobsModalView
