/* eslint-disable react/prop-types */

import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import FilledButton from 'buttons/FilledButton/FilledButton'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import CardSlider from 'components/CardSlider/CardSlider'
import AboutInternshipsHelmet from 'helmets/AboutInternshipsHelmet'

import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CarouselCards from 'components/CarouselCards/CarouselCards'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import Container from '@material-ui/core/Container'

class AboutInternships extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="landing_about_internship.search_stage" />
                        </Typography>
                    </Container>
                </Box>

                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box>
                        <Typography>
                            <FormattedMessage id="landing_about_internship.want_experience" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_internship.want_experience_2" />
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_internship.found_internship" /></Typography>
                        <Typography className={classes.breakline}>
                            <FormattedMessage id="landing_about_internship.go_to" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_internship.create_profile_for_free" />
                            <RouteMapLink page='internships' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_internship.paid_internship" />
                        </Typography>
                        <Typography><FormattedMessage id="landing_about_internship.choose_internship_proposition" /></Typography>
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_internship.ideal_internship" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_internship.found_internship_near_you" />
                            <RouteMapLink page='internships' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.select_city" />
                        </Typography>
                    </Box>

                    <Box my={5}>
                        <LocationsListContainer type="internships" />
                    </Box>

                    <Box>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.found_city" />
                            <RouteMapLink page='internships' className={classes.studentFont}><FormattedMessage id="landing_about_pages.here" /></RouteMapLink>
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_internship.internship_research" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.studentbe" />
                            <FormattedMessage id="landing_about_pages.help_you_to_found" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_pages.kot" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.a" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.student_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.and_even_a" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.first_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_internship.on_website" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>.
                        </Typography>
                    </Box>

                    <Box className={classes.buttonOffers}>
                        <RouteMapLink page='internships'>
                            <FilledButton
                                name=<FormattedMessage id="landing_about_pages.consult_offers" />
                                color='secondary'
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
            </>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <PageWrapper user={this.props.user}>
                <AboutInternshipsHelmet />
                {this.createPageContent()}
                <Container maxWidth="lg">
                    <Typography variant="h2" className={classes.responsiveText}><FormattedMessage id="landing_about_pages.discover_last_internships_offers" /></Typography>
                    <Box my={5}>
                        <CarouselCards
                            cards={this.props.lastOffers}
                            type='internship'
                            userType={this.props.userType}
                            id='lastOffers'
                        />
                    </Box>
                    <Box className={classes.cardTitle}>
                        <Typography variant='h2' className="staticArticleTitleLayout">
                            <FormattedMessage id="landing_about_pages.advice_to_find" />
                            <FormattedMessage id="landing_about_pages.internship" />
                        </Typography>

                        <RouteMapLink page='internships/articles'>
                            <ArrowButton
                                name=<FormattedMessage id="landing_about_pages.see_all_articles" />
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
                <CardSlider
                    side='left'
                    cards={this.props.recentArticles}
                    maxItem="3"
                    showLine
                />
            </PageWrapper>
        )
    }
}

export default withStyles(useStyles)(AboutInternships)
