import React from 'react'
import { Select, MenuItem, ListItemText, FormControl, Checkbox } from '@material-ui/core'

import Tag from 'shared/components/Tag/Tag'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './DropdownSingleSelect_style'

const DropdownSingleSelect = (props) => {
    const { value, name, collection, handleChange, handleAttributeChange, placeholder } = props
    const classes = useStyles()

    return (
		<FormControl className={classes.dropdownContainer}>
            <Select
                value={value}
                name={name}
                displayEmpty
                disableUnderline
                label={name}
                onChange={handleChange}
                classes={{ select: classes.select }}
                renderValue={() => placeholder}
            >
                {collection.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                        <Checkbox
                            disableRipple
                            icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
                            checkedIcon={<IconFactory icon='checkbox-true-filled' color='var(--red)' fontSize='20px' />}
                            color='primary'
                            checked={value === item.value}
                        />
                        <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }}/>
                    </MenuItem>
                ))}
            </Select>
            {value &&
                <Tag
                    isSelected={true}
                    item={collection.find((collectionItem => collectionItem.value === value))}
                    handleClick={() => handleAttributeChange(name, null)}
                />
            }
        </FormControl>
    )
}

export default DropdownSingleSelect
