import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: '1px solid #E7E9F4'
    },
    bigIcon: {
        fontSize: '25px !important',
        color: 'black'
    },
    mediumIcon: {
        fontSize: '20px !important',
        color: 'black'
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center'
    },
    closeJobSelection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    logoContainer: {
        justifyContent: 'center'
    },
    logoImage: {
        width: '112px'
    },
    jobSelectionMenuContainer: {
        display: 'flex',
        gap: '15px'
    },
    jobSelectionMenuTitle: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center'
    },
    contentMenuContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        // height: '100%'
        minHeight: '100vh'
    },
    footerContainer: {
        backgroundColor: 'black',
        height: 138,
        borderRadius: '12px 12px 0 0',

        // position: 'absolute',
        // bottom: 0,
        // right: 0,
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        padding: '12px 20px',
        gap: '16px'
    },
    footerAuthentificationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authentification: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '12px'
    },
    footerLoginTypo: {
        color: 'white'
    },
    footerAccountIcon: {
        fontSize: 20,
        color: 'white'
    },
    footerArrowIcon: {
        fontSize: 16,
        color: 'white'
    },
    footerBottomPart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    footerUserActions: {
        display: 'flex',
        flexDirection: 'row',
        gap: '9px'
    }
}))
