import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleCardWrapper: {
        display: 'flex',
        cursor: 'pointer'
    },
    routeMapLinkStyle: {
        width: '100%'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 320,
        minWidth: 280,
        maxWidth: 320,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12,
        margin: '0 auto'
    },
    articleCardImage: {
        height: 178,
        position: 'relative'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '12px 12px 0 0',
        objectFit: 'cover'
    },
    articleCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 16
    },
    articleTitle: {
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        lineHeight: '21px'
    },
    articleDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'var(--grey-middle)',
        fontSize: '12px !important',
        whiteSpace: 'nowrap',
        overflowX: 'clip'
    },
    city: {
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: 12,
        fontWeight: 300
    },
    bullet: {
        fontSize: 6,
        margin: '0 4px'
    },
    timeToReadBox: {
        display: 'flex',
        alignItems: 'center',
        gap: 6
    },
    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'black',
        color: 'white',
        borderRadius: 6,
        fontSize: 10,
        padding: '6px 10px'
    }
}))
