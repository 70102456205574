import React, { useState } from 'react'
import { Image } from 'cloudinary-react'
import DocumentAPI from 'api/DocumentAPI'
import Bugsnag from '@bugsnag/js'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import LineButton from 'buttons/LineButton/LineButton'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './MessageFileUploadModal_style'

const MessageFileUpload = (props) => {
    const { pictureId, functionOnSuccess, addFileFunction } = props
    const [newPictureId, setNewPictureId] = useState(pictureId || '')
    const [image, setImage] = useState(null)
    const classes = useStyles()

    const myWidget = CloudinaryHelper.createDocumentUploadWidget(
        { clientAllowedFormats: ['pdf', 'image'] },
        (error, result) => {
            if (!error && result && result.event === 'success') {
                setNewPictureId(result.info.public_id)
                updatePicture(result)
            }
        }
    )

    const updatePicture = (result) => {
        try {
            const data = {
                cloudinary_key: result.info.public_id,
                name: result.info.original_filename,
                format: result.info.format,
                is_used: false,
                authenticated: true,
                is_cv: true
            }
            DocumentAPI.postImage(data).then((response) => {
                setNewPictureId(result.info.public_id)
                setImage({
                    id: response.data.document_object.id,
                    cloudinary_key: response.data.document_object.cloudinary_key,
                    format: response.data.document_object.format,
                    name: response.data.document_object.name,
                    is_used: response.data.document_object.is_used
                })
            }).catch((error) => {
                alert('error database')
                Bugsnag.notify(error)
            })
        } catch (e) {
            alert('error database')
            Bugsnag.notify(e)
        }
    }

    const sendMessage = () => {
        if (functionOnSuccess) {
            functionOnSuccess(image.id)
        }
    }

    const cancelUpload = () => {
        addFileFunction(false)
    }

    const openWidget = () => {
        myWidget.open()
    }

    return (
        <Dialog
            PaperProps={{ className: classes.dialogContainer }}
            aria-labelledby='responsive-dialog-delete'
            disableScrollLock={true}
            fullWidth
            maxWidth='sm'
            onClose={cancelUpload}
            open
        >
            <DialogTitle onClose={cancelUpload} className={classes.dialogTitle} disableTypography>
                <Typography variant='h6' className={classes.dialogTitleText}>
                    <FormattedMessage id='file_upload_modal.title' />
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={cancelUpload}
                    className={classes.closeIcon}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box m={2}>
                    {newPictureId &&
                        <Box mb={4} id='upload_widget' onClick={() => myWidget.open()}>
                            <Image
                                cloudName='studentbe'
                                publicId={newPictureId}
                                width='300'
                                crop='scale'
                            />
                        </Box>
                    }
                    <FilledButton
                        newStyle
                        variant='contained'
                        color='primary'
                        type='submit'
                        onClick={openWidget}
                        name={<FormattedMessage id='file_upload_modal.upload_file' />}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <LineButton
                    newStyle
                    name={<FormattedMessage id='job_chat.cancel' />}
                    onClick={cancelUpload}
                    size='small'
                />
                {newPictureId &&
                    <FilledButton
                        newStyle
                        color='secondary'
                        name={<FormattedMessage id='file_upload_modal.send_file' />}
                        onClick={sendMessage}
                        size='small'
                        style={{ marginLeft: 10 }}
                    />
                }
            </DialogActions>
        </Dialog>
    )
}

export default MessageFileUpload
