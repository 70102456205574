import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    bannerImage: {
        zIndex: -1,
        position: 'absolute',
        width: '100%',
        top: 0,
        backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.6), transparent), url('https://res.cloudinary.com/studentbe/image/upload/v1711443637/student-blog/News.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        [theme.breakpoints.up('md')]: {
            height: 464
        },
        [theme.breakpoints.down('sm')]: {
            height: 300
        }
    },
    pageContainer: {
        [theme.breakpoints.up('md')]: {
            marginBottom: 88
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 64
        }
    },
    footerPageContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            gap: 72,
            marginTop: 72
        },
        [theme.breakpoints.down('sm')]: {
            gap: 44,
            marginTop: 44
        }
    },
    CTAContainer: {
        [theme.breakpoints.up('md')]: {
            padding: '0 20px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px'
        }
    },
    promotionCardContainer: {
        width: 197,
        scrollSnapAlign: 'center'
    },
    titleContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            height: 400,
            gap: 36
        },
        [theme.breakpoints.down('sm')]: {
            height: 236,
            gap: 24,
            paddingTop: 40
        }
    },
    titleText: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        color: 'white',
        [theme.breakpoints.up('md')]: {
            fontSize: '36px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important',
            textAlign: 'center',
            width: '80%'
        }
    },
    categoryListContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(74,74,74,.4)',
        padding: '8px 0'
    },
    categoryList: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 12,
            width: '100%',
            overflowX: 'scroll',
            scrollBehavior: 'smooth',
            scrollbarWidth: 'none', /* Firefox */
            '-ms-overflow-style': 'none', /* IE 10+ */
            '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
                height: 0,
                background: 'transparent'
            }
        }
    },
    categoryCase: {
        padding: '22px 6px 12px 6px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        color: 'white',
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        textTransform: 'Uppercase',
        '&:hover': {
            // borderBottom: '1px solid red'
            borderRadius: 16,
            backgroundColor: '#34343470'
        },
        [theme.breakpoints.up('md')]: {
            height: '110px',
            width: '160px',
            fontSize: 12
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px',
            width: '130px',
            fontSize: 10
        }
    },
    categoryText: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 35,
        lineHeight: '15px',
        fontFamily: "'GintoNormal-Bold', sans-serif",
        fontSize: 14
    },
    searchContainer: {
        [theme.breakpoints.up('md')]: {
            top: -45,
            position: 'relative'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40
        }

    },
    articlesListsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            gap: 72
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 12px',
            gap: 44
        }
    },
    trendiesTitlesContainer: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    trendiesContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            gap: 24
        }
    },
    classicContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    classicArticlesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: 20,
        rowGap: 32,
        justifyContent: 'center'
    },
    sectionTitle: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        [theme.breakpoints.up('md')]: {
            fontSize: '24px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    }
}))
