export const useStyles = (theme) => ({
    cardContainer: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('md')]: {

            display: 'flex'
        }
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
            minHeight: 100,
            display: 'flex',
            width: '100%'
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 100,
            display: 'flex',
            width: '100%'
        },
        '&:last-child': {
            paddingBottom: 16
        }
    },
    cardTypography: {
        fontSize: 14,
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
        }
    },
    statusContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 25
    },
    statusInterested: {
        backgroundColor: 'var(--main-color)',
        color: 'white !important'
    },
    statusWaiting: {
        border: '1px solid grey',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        color: 'grey !important'
    },
    statusNotInt: {
        backgroundColor: 'grey',
        color: 'white !important'
    },
    statusNotInv: {
        color: 'grey !important'
    },
    statusTypography: {
        transform: 'rotate(-90deg)',
        fontSize: 14,
        whiteSpace: 'nowrap'
    },
    initialsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    dateTypography: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cardFieldStudy: {
        fontSize: 14
    },
    cardStudyLevel: {
        fontSize: 14,
    },
    langTypographyContainer: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    langMiddle: {
        margin: '0 5px'
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    langTypography: {
        marginRight: 4
    },
    cardPhoneNumber: {
        fontSize: 12
    },
    iconsActionContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end'
        },
    },
    checkBox: {
        [theme.breakpoints.up('md')]: {
            width: 45,
            height: 45
        },
    }
})
