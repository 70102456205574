
import React from 'react'

// import css from './jobresultscard.scss'
import DateHandler from 'assets/DateHandler'
import RouteMap from 'assets/RouteMap'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './JobResultsCard_css'
import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler.js'
import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import IconFactory from 'icons/IconFactory/IconFactory'
import CvButton from 'buttons/CvButton/CvButton'
import StudentNoteModal from 'modals/StudentNoteModal/StudentNoteModal'
import FormatHelper from 'assets/FormatHelper'
import RecruitmentStatus from 'components/RecruitmentStatus/RecruitmentStatus'

class JobResultsCard extends React.Component {
    constructor (props) {
        super(props)

        this.checkRef = React.createRef()
        this.state = {
            commentEdit: false,
            studentNote: this.props.studentProfile.candidacy.candidacy_note ? this.props.studentProfile.candidacy.candidacy_note : '',
            showPopupNote: false,
            checked: false,
            feedback: { open: false, severity: 'success', message: null }
        }

        this.studyFieldCollection = CollectionHandler.Get('academicAndHighSchoolStudyFieldCollection')

        this.renderPopup = this.renderPopup.bind(this)
        this.renderNameBox = this.renderNameBox.bind(this)
        this.renderLanguageLvl = this.renderLanguageLvl.bind(this)
        this.renderLanguage = this.renderLanguage.bind(this)
        this.renderSchoolName = this.renderSchoolName.bind(this)
        this.renderTransport = this.renderTransport.bind(this)
        this.setCheck = this.setCheck.bind(this)
        this.handleCopy = this.handleCopy.bind(this)
        this.closeFeedback = this.closeFeedback.bind(this)
        this.pathname = window.location.pathname
        this.lang = this.pathname.match(/^\/([^\/]*)\//)[1]
        this.phoneNumber = FormatHelper.FormatPhoneNumber(props.studentProfile.phone_number)
    }

    setCheck (check) {
        this.setState({
            checked: check || !this.state.checked
        })
        this.props.onCheck(this.props.forwardRef, this.props.studentProfile, check || !this.state.checked)
    }

    handleCopy (copyText) {
        try {
            navigator.clipboard.writeText(copyText)
            this.setState({ feedback: { ...this.state.feedback, open: true, message: <FormattedMessage id="copied_to_clipboard"/> } })
          } catch (error) {
            this.setState({ feedback: { ...this.state.feedback, open: true, severity: 'error', message: `Error: ${error}` } })
          }
    }

    closeFeedback () {
        this.setState({ feedback: { ...this.state.feedback, open: false } })
    }

    renderPopup (popupType) {
        this.setState({
            [popupType]: !this.state[popupType]
        })
    }

    renderStudyLevel () {
        let res = ''
        switch (this.props.studentProfile.study_level) {
        case 'master_60_degree':
        case 'master_120_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'master')
            break
        case 'advanced_master_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'advanced_master')
            break
        case 'phd_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'phd')
            break
        default:
            res = CollectionHandler.Translate('studyLevelForCardsCollection', this.props.studentProfile.study_level)
        }
        return res
    }

    renderDistance () {
        var res
        if (this.props.studentProfile.distance < 1.0) {
            res = '< 1'
        } else {
            res = this.props.studentProfile.distance.toString().split('.')[0]
        }
        return res + ' km'
    }

    renderFieldOfStudy () {
        var res

        for (var i = 1; i < this.studyFieldCollection.length; i++) {
            if (this.studyFieldCollection[i].value === this.props.studentProfile.field_of_study) {
                res = this.studyFieldCollection[i].name
            }
        }
        if (!res) {
            res = '/'
        }

        return res
    }

    renderFieldOfStudyMobile () {
        var res

        for (var i = 1; i < this.studyFieldCollection.length; i++) {
            if (this.studyFieldCollection[i].value === this.props.studentProfile.field_of_study) {
                res = `${this.studyFieldCollection[i].name} (${this.renderStudyLevel()})`
            }
        }
        if (!res) {
            res = this.renderStudyLevel()
        }

        return res
    }

    renderLastConnection () {
        return (
            <div>
                <FormattedMessage id="job_results.items_card.apply_date" />
                {' ' + DateHandler.ToBasicFormat(this.props.studentProfile.apply_date)}
            </div>
        )
    }

    renderUndefined () {
        return (
            <span className="undefined-container">{'/'}</span>
        )
    }

    renderTransport () {
        const { classes } = this.props
        let transport = []
        let bike = this.props.studentProfile.bike
        let car = this.props.studentProfile.car
        let drivingLicence = this.props.studentProfile.driving_licence
        if (bike) {
            transport.push(<IconFactory icon='bicycle' className={classes.iconSize} />)
        }
        if (car) {
            transport.push(<IconFactory icon='car' className={classes.iconSize} />)
        }
        if (drivingLicence) {
            transport.push(<IconFactory icon='driving-licence' className={classes.iconSize} />)
        }
        return transport
    }

    renderNameBox () {
        const { classes } = this.props

        return (
            <Box py={1} px={2} style={{ backgroundColor: 'var(--main-color)', color: (this.props.jobType === 'internship' ? 'var(--black)' : '#FFFFFF') }}>
                <Grid container className={classes.cardHeader}>
                    <Grid item xs={6}  md={this.props.jobType === 'student_job' && 4}>
                        <Typography
                            variant='body2'
                            className={classes.cardTypography}
                        >
                            {`${this.props.studentProfile.last_name && this.props.studentProfile.last_name.toUpperCase()} ${this.props.studentProfile.first_name}`}
                            {this.props.jobType === 'student_job' &&
                                this.props.studentProfile.age
                                ? <FormattedMessage id="job_results.items_card.years" values={{ years: this.props.studentProfile.age}} />
                                : ''
                            }
                        </Typography>
                    </Grid>
                    {this.props.jobType === 'student_job' &&
                        <Hidden smDown>
                            <Grid item xs={3}>
                                <Typography variant="body2">
                                    {this.renderTransport()}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2">
                                    <FormattedMessage id="job_results.items_card.distance"/> { this.renderDistance() }
                                </Typography>
                            </Grid>
                        </Hidden>
                    }
                    <Grid item xs={6} md={this.props.jobType === 'student_job' && 3}>
                        <Typography
                            variant='body2'
                            className={classes.cardTypography}
                            style={{ textAlign: 'end' }}
                        >
                            {this.props.studentProfile.apply_date
                                ? this.renderLastConnection()
                                : ''
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    renderIconsAction () {
        const { classes } = this.props
        return (
            <Box className={classes.iconsActionContainer}>
                <Tooltip title={<FormattedMessage id="job_results.items_card.tooltip.send_message" />} >
                    <IconButton
                        aria-label="MessageToStudent"
                        color="primary"
                        style={{ width: 45, height: 45 }}
                        onClick={() => { window.location.href = RouteMap.Page(this.props.studentProfile.chat_url) }}
                    >
                        <IconFactory icon='comment'/>
                    </IconButton>
                </Tooltip>
                <CvButton
                    cvUrl={this.props.studentProfile.cv_file?.asset_url}
                    oldCvUrl={this.props.studentProfile.old_cv_url}
                />
                <Tooltip title={<FormattedMessage id="job_results.items_card.tooltip.delete" />} >
                    <IconButton
                        aria-label="deleteCandidacy"
                        color="primary"
                        style={{ width: 45, height: 45 }}
                        onClick={() => this.props.deleteCard(this.props.studentProfile)}
                    >
                        <IconFactory icon='delete'/>
                    </IconButton>
                </Tooltip>
            </Box>
        )
    }

    renderLanguageLvl (value) {
        let languageLvl = []
        let starColor = "#FBDE79"
        for (let i = 1; i < 5; i++) {
            if (value < i) { starColor = "#ECECEC" } else { starColor = "#FBDE79"}
            languageLvl.push(<IconFactory icon='star-full' style={{ color: starColor, height: 15, width: 'auto', marginRight: 5 }} />)
        }
        return languageLvl
    }

    renderLanguage () {
        const { classes } = this.props
        return (
            <>
                <Typography variant='body2' className={classes.cardTypography}>
                    FR { this.renderLanguageLvl(this.props.studentProfile.languages.fr) }
                </Typography>
                <Typography variant='body2' className={classes.cardTypography}>
                    NL { this.renderLanguageLvl(this.props.studentProfile.languages.nl) }
                </Typography>
                <Typography variant='body2' className={classes.cardTypography}>
                    EN { this.renderLanguageLvl(this.props.studentProfile.languages.fr) }
                </Typography>
            </>
        )
    }

    renderSchoolName () {
        return this.props.studentProfile.school_name ? this.props.studentProfile.school_name : '/'
    }

    render () {
        const { classes } = this.props
        return (
            <Box className={classes.maxWidth}>
                <FeedbackSnackbar
                    open={this.state.feedback.open}
                    message={this.state.feedback.message}
                    severity={this.state.feedback.severity}
                    closeFeedback={this.closeFeedback}
                    autoHideDuration={1000}
                />
                <StudentNoteModal
                    candidacyId={this.props.studentProfile.candidacy_id}
                    studentNote={this.state.studentNote}
                    setStudentNote={(note) => this.setState({ studentNote: note })}
                    popupExit={() => this.renderPopup('showPopupNote')}
                    openShare={this.state.showPopupNote}
                />
                <Hidden smDown>
                    <Box className={classes.cardContainer} ref={this.divref}>
                        <Hidden mdDown>
                            <Checkbox
                                icon={<IconFactory icon='checkbox-false' fontSize='1rem'/>}
                                checkedIcon={<IconFactory icon='checkbox-true' fontSize='1rem' />}
                                color="primary"
                                style={{ width: 45, height: 45 }}
                                ref={this.checkRef}
                                onClick={() => { this.setCheck() }}
                                checked={this.state.checked}
                            />
                        </Hidden>
                        <Card elevation={3} className={classes.card}>
                            {this.renderNameBox()}
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={2}>
                                        <Typography variant='body2' className={classes.cardTypography}>
                                            {this.renderStudyLevel()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant='body2' className={classes.cardTypography}>
                                            {this.renderFieldOfStudy()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant='body2' className={classes.cardTypography}>
                                            {this.renderSchoolName()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        {this.renderLanguage()}
                                    </Grid>
                                    <Grid item xs={2} className={classes.userContactDetails}>
                                        <Tooltip title={<FormattedMessage id="copy_to_clipboard"/>} arrow classes={{ tooltip: classes.overrideTooltip }}>
                                            <Box className={classes.userContactDetailsItem} onClick={() => this.handleCopy(this.phoneNumber)}>
                                                <IconFactory icon='phone' className={classes.iconSize} color='primary'/>
                                                <Typography variant='body2' className={classes.cardTypography}>
                                                    {this.props.studentProfile.phone_number ? this.phoneNumber : this.renderUndefined()}
                                                </Typography>
                                                <IconFactory icon='duplicate' className={classes.copyIcon} color='primary'/>
                                            </Box>
                                        </Tooltip>
                                        <Tooltip title={<FormattedMessage id="copy_to_clipboard"/>} arrow classes={{ tooltip: classes.overrideTooltip }}>
                                            <Box className={classes.userContactDetailsItem} onClick={() => this.handleCopy(this.props.studentProfile.email)}>
                                                <IconFactory icon='mail' className={classes.iconSize} color='primary'/>
                                                <Typography variant='body2' className={classes.cardTypography}>
                                                    {this.props.studentProfile.email || this.renderUndefined()}
                                                </Typography>
                                                <IconFactory icon='duplicate' className={classes.copyIcon} color='primary'/>
                                            </Box>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item style={{ width: '100%', height: this.props.jobType === 'student_job' ? '' : '100%', display: 'flex', alignItems: 'center' }}>
                                        <RecruitmentStatus
                                            status={this.props.studentProfile.recruitment_status}
                                            candidacy={this.props.studentProfile.candidacy}
                                        />
                                        <Divider orientation='vertical' flexItem style={{ marginRight: 15 }} />
                                        <Typography
                                            variant='subtitle1'
                                            className={`${classes.cardTypography}`}
                                            onClick={() => this.renderPopup('showPopupNote')}
                                        >
                                            {this.state.studentNote !== ''
                                                ? this.state.studentNote
                                                : <FormattedMessage id="job_results.items_card.add_comment" />
                                            }
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {this.renderIconsAction()}
                    </Box>
                </Hidden>
                <Hidden mdUp>
                    <Box className={classes.cardContainer}>
                        <Card className={classes.maxWidth}>
                            {this.renderNameBox()}
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' className={classes.cardFieldStudy}>
                                            {this.renderFieldOfStudyMobile()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant='body2' className={`${classes.cardTypography} ${classes.cardInfo}`}>
                                            {this.renderSchoolName()}
                                        </Typography>
                                    </Grid>
                                    {
                                        this.props.jobType === 'student_job' &&
                                            <Grid item xs={12}>
                                                <Typography variant='body2' className={classes.cardTypography}>
                                                    <FormattedMessage id="job_results.items_card.distance"/> { this.renderDistance() }
                                                </Typography>
                                            </Grid>
                                    }
                                    <Grid item xs={12}>
                                        { this.renderLanguage() }
                                    </Grid>
                                    {
                                        this.props.jobType === 'student_job' &&
                                            <Grid item xs={12}>
                                                {this.renderTransport()}
                                            </Grid>
                                    }
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconFactory icon='phone' className={classes.iconSize} color='primary'/>
                                        <Typography variant='body2' className={classes.cardTypography}>
                                            { this.props.studentProfile.phone_number ? this.phoneNumber : this.renderUndefined() }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginBottom: this.state.studentNote && 5 }}>
                                        <IconFactory icon='mail' className={classes.iconSize} color='primary'/>
                                        <Typography variant='body2' className={classes.cardTypography}>
                                            {this.props.studentProfile.email || this.renderUndefined()}
                                        </Typography>
                                    </Grid>
                                    {
                                        this.state.studentNote &&
                                        <>
                                            <Grid item xs={12}>
                                                <Divider orientation="horizontal" />
                                            </Grid>
                                            <Grid item xs={12} style={{ paddingBottom: 0 }} >
                                                <Typography variant='subtitle1' className={`${classes.cardTypography}`}>
                                                    { this.state.studentNote }
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        <RecruitmentStatus
                                            status={this.props.studentProfile.recruitment_status}
                                            candidacy={this.props.studentProfile.candidacy}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {this.renderIconsAction()}
                    </Box>
                </Hidden>
            </Box>
        )
    }
}

export default withStyles(useStyles)(JobResultsCard)
