/* eslint-disable react/prop-types */

import React from 'react'
import PageWrapper from '../../shared/wrappers/PageWrapper/PageWrapper'
import AboutFAQPage from './components/AboutFAQPage/AboutFAQPage'

export default class AboutFaq extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <div className="faq-container">
                <PageWrapper user={this.props.user}>
                    <AboutFAQPage
                        props={this.props}
                    />
                </PageWrapper>
            </div>
        )
    }
}
