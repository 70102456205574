import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dropDownFieldsContainer: {
        padding: '20px',
        width: '631px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column'
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: '14px !important'
    },
    seeAll: {
        borderRadius: '8px',
        padding: '10px 14px',
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    }
}))
