import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    uploadBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        cursor: 'pointer',
        background: 'var(--beige)',
        maxWidth: 400,
        padding: 20,
        border: '1.5px var(--grey) dashed',
        borderRadius: 12,
    },
    uploadIcon: {
        padding: 12,
        background: 'var(--red-light)',
        borderRadius: 12,
    },
    uploadText: {
        fontWeight: 'bold',
        color: 'var(--black)'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: (props) => props.isLarge ? 'column' : 'row',
        alignItems: 'center',
        border: '1px solid var(--grey)',
        padding: 16,
        maxWidth: (props) => props.isLarge ? 'unset' : 400,
        borderRadius: 12,
        gap: 12,
    },
    image: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    cardMedia: {
        width: (props) => props.isLarge ? 'unset' : 80,
        height: (props) => props.isLarge ? 200 : 80,
        maxWidth: '100%',
        objectFit: 'contain',
        borderRadius: 16,
        backgroundColor: 'white',
        boxShadow: (props) => props.isLarge ? 'unset' : '0 0 4px 0 rgba(0,0,0,.2)',
    },
    deleteImage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        backgroundColor: 'var(--black)',
        borderRadius: 4,
        cursor: 'pointer'
    }
}))
