import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    container: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '4px',
        background: 'var(--red)',
        zIndex: 1000,
        transition: 'width 100ms ease-in-out',
    }
}))
