import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        [theme.breakpoints.up('md')]: {
            borderRadius: 20
        }
    },
    dialogTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: 20,
        borderBottom: '1px solid #EFEAE5'
    },
    dialogTitleText: {
        display: 'flex',
        alignItems: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: 20,
        right: 20,
        color: 'black',
        height: 26,
        width: 26
    }
}))
