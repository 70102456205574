import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    homeStudentMain: {
        background: 'white'
    },
    backgroundBeige: {
        backgroundColor: 'var(--beige)'
    },
    backgroundWhite: {
        backgroundColor: 'white'
    },
    backgroundRed: {
        backgroundColor: 'var(--red)'
    },
    container: {
        position: 'relative'
    }
}))
