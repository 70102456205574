/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import CityFilterView from './CityFilterView'
import CityFilterSearchView from './CityFilterSearchView'
import CollectionHandler from 'assets/CollectionHandler.js'

const CityFilter = (props) => {
    const { filters, setFilters, width, showTags, dropdownPosition, modal, searchView } = props
    const cityCollection = CollectionHandler.Get('cityCollection')
    const [cityQuery, setCityQuery] = useState('')
    const [citiesMatchingSearch, setCitiesMatchingSearch] = useState(cityCollection)

    const handleSearchCities = (value) => {
        /* This condition is to prevent the Input inside the SELECT to be added
        on the location array. Because the Input is inside the SElECT, clicking
        on the Input also trigger the onChange of the SELECT */
        if (value.dispatchConfig) return null

        const locations = filters.location
        const newLocations = []
        let push = true

        locations.forEach((location) => {
            if (location.value === value.value) {
                push = false
            } else {
                newLocations.push(location)
            }
        })
        if (push) {
            newLocations.push(value)
        }
        setFilters({ ...filters, location: newLocations })
        setCityQuery('')
        setCitiesMatchingSearch(cityCollection)
    }

    const handleInputSearchCity = (event) => {
        const { value } = event.target
        var newCities = []
        if (value.length !== 0) {
            const regex = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            newCities = cityCollection.filter(elem =>
                elem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(regex) === true ||
                elem.value.includes(regex) === true
            )
        }

        if (newCities.length !== 0) {
            setCitiesMatchingSearch(newCities)
        } else {
            setCitiesMatchingSearch(cityCollection)
        }
        setCityQuery(value)
    }

    return (<>
        {searchView
            ? <CityFilterSearchView
                filters={filters}
                handleSearchCities={handleSearchCities}
                handleInputSearchCity={handleInputSearchCity}
                searchCities={citiesMatchingSearch}
                citiesInput={cityQuery}
                width={width}
                modal={modal}
                showTags={showTags}
                dropdownPosition={dropdownPosition}
            />
            : <CityFilterView
                filters={filters}
                handleSearchCities={handleSearchCities}
                handleInputSearchCity={handleInputSearchCity}
                searchCities={citiesMatchingSearch}
                citiesInput={cityQuery}
                width={width}
                modal={modal}
                showTags={showTags}
                dropdownPosition={dropdownPosition}
            />
        }
    </>)
}

export default CityFilter
