import React from 'react'

import { useStyles } from './MobileBurgerMenuContainer_style'
import {
    AppBar, Dialog, IconButton,
    Toolbar, Grid, Box, Typography
} from '@material-ui/core'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'icons/IconFactory/IconFactory'
import LangSelect from 'inputs/LangSelect/LangSelect'
import MyMessagesIcon from '../../MyMessagesIcon/MyMessagesIcon'
import Notifications from '../../Notifications/Notifications'
import ProfileHelper from 'assets/ProfileHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { FormattedMessage } from 'react-intl'

const MobileBurgerMenuContainer = (props) => {
    const classes = useStyles()
    const {
        children, open, closeModal,
        jobTypeSelection, setJobTypeSelection,
        user, altLangUrls, paramsForUrlTranslation
    } = props

    const closeModalAndResetState = () => {
        closeModal()
        setJobTypeSelection(false)
    }

    const jobTypeSelectionToJobIcon = () => {
        return jobTypeSelection.slice(0, -1)
    }

    const jobTypeSelectionToTypography = () => {
        return CollectionHandler.Translate('itemTypeCollection', jobTypeSelection.replace('-', '_').slice(0, -1)) + 's'
    }

    const renderUserActionsTypo = () => {
        return user
            ? ProfileHelper.GetProfileName(user)
            : <FormattedMessage id='page_footer_navbar_data.navbar' />
    }

    const renderUserRedirection = () => {
        return user
            ? ProfileHelper.GetProfileUrl(user.type)
            : 'login'
    }

    const renderHomeMenu = () => {
        return (
            <Grid container>
                <Grid item xs={3} className={classes.gridItem}>
                    <IconButton edge='start' onClick={closeModal}>
                        <IconFactory icon='close' className={classes.bigIcon} />
                    </IconButton>
                </Grid>
                <Grid item xs={6} className={`${classes.gridItem} ${classes.logoContainer}`}>
                    <RouteMapLink page='/'>
                        <img
                            src={CloudinaryURLHelper.getImageUrl('DEFAULT_LOGO_STUDENT', 'student-be-logo')}
                            alt='Student.be Logo'
                            className={classes.logoImage}
                        />
                    </RouteMapLink>
                </Grid>
                <Grid item xs={3} />
            </Grid>
        )
    }

    const renderJobSelectionMenu = () => {
        return (
            <Grid container>
                <Grid item xs={9} className={classes.jobSelectionMenuContainer}>
                    <IconButton
                        edge='start'
                        onClick={() => setJobTypeSelection(false)}
                    >
                        <IconFactory icon='left-regular' className={classes.mediumIcon} />
                    </IconButton>
                    <Box className={classes.jobSelectionMenuTitle}>
                        <IconFactory icon={jobTypeSelectionToJobIcon()} className={classes.mediumIcon} />
                        <Typography variant='h3' component='div'>
                            {jobTypeSelectionToTypography()}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={3} className={classes.closeJobSelection}>
                    <IconButton edge='end' onClick={closeModalAndResetState}>
                        <IconFactory icon='close' className={classes.bigIcon} />
                    </IconButton>
                </Grid>
            </Grid>
        )
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={closeModalAndResetState}
        >
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <Toolbar>
                    {jobTypeSelection
                        ? renderJobSelectionMenu()
                        : renderHomeMenu()
                    }
                </Toolbar>
            </AppBar>
            <Box className={classes.contentMenuContainer}>
                {children}
                <Box className={classes.footerContainer}>
                    <RouteMapLink page={renderUserRedirection()}>
                        <Box className={classes.footerAuthentificationContainer}>
                            <Box className={classes.authentification}>
                                <IconFactory icon='account' className={classes.footerAccountIcon}/>
                                <Typography variant='h4' component='p' className={classes.footerLoginTypo}>
                                    {renderUserActionsTypo()}
                                </Typography>
                            </Box>
                            <IconButton edge='end'>
                                <IconFactory icon='arrow-right' className={classes.footerArrowIcon} />
                            </IconButton>
                        </Box>
                    </RouteMapLink>
                    <Box className={classes.footerBottomPart}>
                        <LangSelect altLangUrls={altLangUrls} paramsForUrlTranslation={paramsForUrlTranslation} color='white'/>
                        {user &&
                            <Box className={classes.footerUserActions}>
                                <Notifications user={user} />
                                <MyMessagesIcon user={user} />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </Dialog>
    )
}

export default MobileBurgerMenuContainer
