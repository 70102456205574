import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { Box } from '@material-ui/core'

import MobileWalletCard from '../shared/MobileWalletCard/MobileWalletCard'
import NavigationAPI from 'api/NavigationAPI'
import RouteMap from 'assets/RouteMap'

const MobileJobWallet = (props) => {
    const { jobTypeSelection } = props

    const [filters, setFilters] = useState(false)

    useEffect(() => {
        NavigationAPI.GetNavigationCollection({
            locale: RouteMap.GetLocaleFromUrl(),
            type: jobTypeSelection
        }).then((response) => {
            setFilters(response.data.filters)
        })
    }, [])

    const renderWalletCards = () => {
        return filters.map((filter, index) =>
            <Box key={`navigation-wallet-card-${index}`}>
                <MobileWalletCard
                    page={jobTypeSelection}
                    filter={filter}
                    name={filter.name}
                    jobFilterCard
                />
            </Box>
        )
    }

    return (<>
        <MobileWalletCard
            secondary
            index
            jobFilterCard
            name={<FormattedMessage id={`page_footer_navbar_data.see_all.${jobTypeSelection.replace('-', '_')}`} />}
            page={jobTypeSelection}
        />
        {filters && renderWalletCards()}
    </>)
}

export default MobileJobWallet
