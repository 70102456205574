import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    burgerMenuContainer: {
        padding: '20px 12px',
        gap: '12px',
        display: 'flex',
        flexDirection: 'column'
    }
}))
