/* eslint-disable react/prop-types */
/* eslint-disable no-useless-constructor */

import React from 'react'
import AppMyItemSelectionIndexMain from './Components/AppMyKotItemSelectionIndexMain'
import PageWrapper from '../../../shared/wrappers/PageWrapper/PageWrapper'
import SharedTabs from '../../../shared/components/SharedTabs/SharedTabs'
import AttentionBanner from '../../../shared/components/AttentionBanner/AttentionBanner'

export default class MyKotIndex extends React.Component {
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <div className="desktop-profile profile-container">
                <PageWrapper user={this.props.user}>
                    <SharedTabs user={this.props.user} currentTab='users/kots' />
                    <AttentionBanner user={this.props.user} />
                    <AppMyItemSelectionIndexMain
                        props={this.props}
                    />
                </PageWrapper>
            </div>
        )
    }
}
