import React from 'react'

import { useStyles } from './CardsSliderTitle_style'
import { Typography } from '@material-ui/core'

const CardsSliderTitle = (props) => {
    const classes = useStyles()
    const { title } = props

    return (
		<Typography
            className={classes.title}
            variant='h2'
        >
            {title}
        </Typography>
    )
}

export default CardsSliderTitle
