/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import { useStyles } from './SettingsPage_style'
import BasicSettings from '../BasicSettings/BasicSettings'
import EmailSettings from '../EmailSettings/EmailSettings'
import PasswordSettings from '../PasswordSettings/PasswordSettings'
import DangerZone from '../DangerZone/DangerZone'

const SettingsPage = ({ props }) => {
    const classes = useStyles()
    return (
        <Container maxWidth='lg' className={classes.pageContainer}>
            <Box mb={4}>
                <BasicSettings props={props} />
            </Box>
            <Box mb={4}>
                <EmailSettings props={props} />
            </Box>
            <Box mb={4}>
                <PasswordSettings props={props} />
            </Box>
            <Box mb={4}>
                <DangerZone props={props} />
            </Box>
        </Container>
    )
}
export default SettingsPage
