import React from 'react'
import { Chip } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from '../StudentJobSearchStatus_style'
import StudentJobSearchStatusHelper from '../StudentJobSearchStatusHelper'

const StudentJobSearchStatusChip = (props) => {
    const { keyName, value, handleClick, simple, black } = props
    const classes = useStyles()

    if (simple) {
        if (keyName === StudentJobSearchStatusHelper.NOT_LOOKING_ANY_JOB) return null
        return (
            <Chip
                key={keyName}
                variant='outlined'
                label={<FormattedMessage id={`active_record.profiles.simple.${keyName}`} />}
                onClick={() => handleClick(keyName, !value)}
                clickable={false}
                className={`${black || classes.simpleChip} ${value ? classes.simpleFilled : ''} ${black && classes.blackChip}`}
                style={{ background: value ? StudentJobSearchStatusHelper.GetColor(keyName) : '' }}
            />
        )
    }

    return (
        <Chip
            key={keyName}
            variant='outlined'
            label={<FormattedMessage id={`active_record.profiles.${keyName}`} />}
            onClick={() => handleClick(keyName, !value)}
            clickable={false}
            icon={<IconFactory icon={StudentJobSearchStatusHelper.GetIcon(keyName)}/>}
            className={`${classes.chip} ${value ? classes.filled : ''}`}
            style={{ background: value ? StudentJobSearchStatusHelper.GetColor(keyName) : '' }}
        />
    )
}

export default StudentJobSearchStatusChip
