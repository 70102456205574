import React from 'react'
import ReactQuill from 'react-quill'
import { Box, Grid, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './StudentJobEssentialForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import TagSingleSelect from 'shared/components/TagSingleSelect/TagSingleSelect'
import DropdownSingleSelect from 'shared/components/DropdownSingleSelect/DropdownSingleSelect'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import UploadImageV2 from 'shared/modals/UploadImageV2/UploadImageV2'
import { QUILL_MODULES } from '../../../settings'

const StudentJobEssentialForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleChange, handleUpdate, handleAttributeChange, updateMainImage, updateCompanyLogo } = props
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')
    const studentJobSchedulesCollection = CollectionHandler.Get('studentJobSchedulesCollection')

    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        handleUpdate({ [attribute]: formattedItems })
    }

    const handleRadioChange = (event) => {
        const { name, value } = event.target
        const booleanValue = value === 'true'
        handleAttributeChange(name, booleanValue)
    }

    return (
        <Box className={sharedClasses.formContainer}>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_title" />} />
                <FormattedMessage id="jobcreationfunnel.placeholders.job_title">
                    {placeholder =>
                        <TextField
                            name='jobTitle'
                            value={jobForm.jobTitle}
                            onChange={handleChange}
                            InputProps={{ classes: { input: `${sharedClasses.textField} ${sharedClasses.shortField}` }, disableUnderline: true }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                <FieldError errorMessage={errors.jobTitle} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.student_job_schedule" />} />
                <TagMultiSelect
                    setSelectedItems={(items) => handleSelectedItems(items, 'studentJobSchedule')}
                    selectedItems={jobForm.studentJobSchedule.map(schedule =>  { return { value: schedule } })}
                    collection={studentJobSchedulesCollection}
                />
                <FieldError errorMessage={errors.studentJobSchedule} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_description" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.jobDescription || ''}
                        name="jobDescription"
                        onChange={(value) => handleAttributeChange('jobDescription', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.jobDescription} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.job_video_or_image" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.media_sub_title" />} />
                </Box>
                <Box className={classes.videoOrImageContainer}>
                    <RadioGroup
                        name="jobShowAddVideo"
                        defaultValue={'true'}
                        value={jobForm.jobShowAddVideo?.toString()}
                        onChange={handleRadioChange}
                    >
                        <Box className={classes.selectionContainer}>
                            <Box className={classes.addImage}>
                                <FormControlLabel
                                    value={'false'}
                                    control={<Radio />}
                                    label={<FormattedMessage id="job_form.step_2.media_photo_title" />}
                                />
                                <FieldSubtitle
                                    subtitle={<FormattedMessage id="job_form.step_2.media_photo_sub_title" />}
                                    className={classes.lightSubtitle}
                                />
                                <UploadImageV2
                                    image={jobForm.jobMainImage[0]}
                                    setImage={(newImage) => updateMainImage([newImage])}
                                    isLarge
                                />
                            </Box>
                            <Box className={classes.addVideo}>
                                <FormControlLabel
                                    value={'true'}
                                    control={<Radio />}
                                    label={<FormattedMessage id="job_form.step_2.media_video_title" />}
                                />
                                <FieldSubtitle
                                    subtitle={<FormattedMessage id="job_form.step_2.media_video_sub_title" />}
                                    className={classes.lightSubtitle}
                                />
                                <FormattedMessage id="job_form.step_2.media_video_placeholder">
                                    {placeholder =>
                                        <TextField
                                            name='jobVideoUrl'
                                            value={jobForm.jobVideoUrl}
                                            onChange={handleChange}
                                            placeholder={placeholder}
                                            classes={{ root: sharedClasses.maxWidth }}
                                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                                        />
                                    }
                                </FormattedMessage>
                            </Box>
                        </Box>
                    </RadioGroup>
                </Box>
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_name" />} />
                </Box>
                <FormattedMessage id="jobcreationfunnel.placeholders.company_name">
                    {placeholder =>
                        <TextField
                            name='companyName'
                            value={jobForm.companyName}
                            onChange={handleChange}
                            InputProps={{ classes: { input: `${sharedClasses.textField} ${sharedClasses.shortField}` }, disableUnderline: true }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                <FieldError errorMessage={errors.companyName} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle
                    title={<FormattedMessage id="jobcreationfunnel.titles.company_logo" />}
                    isOptional
                />
                <UploadImageV2
                    image={jobForm.companyLogo}
                    setImage={updateCompanyLogo}
                />
                <FieldError errorMessage={errors.companyLogo} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_size" />} />
                <TagSingleSelect
                    value={jobForm.companySize}
                    collection={companySizeCollection}
                    setValue={(newValue) => handleAttributeChange("companySize", newValue)}
                />
                <FieldError errorMessage={errors.companySize} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.company_sector" />}/>
                <DropdownSingleSelect
                    value={jobForm.companySector}
                    name='companySector'
                    placeholder={<FormattedMessage id="jobcreationfunnel.placeholders.sector" />}
                    collection={sectorCollection}
                    handleChange={handleChange}
                    handleAttributeChange={handleAttributeChange}
                />
                <FieldError errorMessage={errors.companySector} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_location" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="jobcreationfunnel.sub_titles.job_location" />} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_street" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobStreetName'
                            value={jobForm.jobStreetName}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobStreetName} />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_zip" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobZip'
                            value={jobForm.jobZip}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobZip} />
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_city" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobCity'
                            value={jobForm.jobCity}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobCity} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_country" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobCountry'
                            value={jobForm.jobCountry}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobCountry} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default StudentJobEssentialForm
