/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import axios from 'axios'

import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './NewKotMainPage_style'
import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler.js'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import KotOwnerAPI from 'api/KotOwnerAPI'
import RouteMap from 'assets/RouteMap'
import SectionTitle from 'components/SectionTitle/SectionTitle'
import UploadImage from 'modals/UploadImage/UploadImage'

const selectPaperStyle = () => {
    return ({
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        }
    })
}

const NewKotMainPage = ({ props }) => {
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')
    const classes = useStyles()
    const [dataProps] = useState(props)
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        titleKotOffer: props.kot.title ? props.kot.title : '',
        kotRent: props.kot.rent ? props.kot.rent : '',
        kotCharges: props.kot.charges ? props.kot.charges : '',
        kotInternet: props.kot.internet ? props.kot.internet : false,
        kotCleaning: props.kot.cleaning ? props.kot.cleaning : false,
        kotGazElec: props.kot.gas_electricity ? props.kot.gas_electricity : false,
        kotWater: props.kot.water ? props.kot.water : false,
        kotPEBBool: props.kot.peb ? props.kot.peb : false,
        typeKotOffer: props.kot.kot_type ? props.kot.kot_type : '',
        kotDisponibility: props.kot.availability ? props.kot.availability : '',
        kotAddress: props.kotAddress.street ? props.kotAddress.street : '',
        kotZipCode: props.kotAddress.zip ? props.kotAddress.zip : '',
        kotCity: props.kotAddress.city ? props.kotAddress.city : '',
        kotDescription: props.kot.description ? props.kot.description : '',
        kotProximity: props.kot.proximity ? props.kot.proximity : '',
        kotOtherInfos: props.kot.other_infos ? props.kot.other_infos : '',
        kotFurnished: props.kot.furnished ? props.kot.furnished : false,
        kotBathroom: props.kot.private_bathroom ? props.kot.private_bathroom : false,
        kotKitchen: props.kot.private_kitchen ? props.kot.private_kitchen : false,
        kotTerrace: props.kot.terrace ? props.kot.terrace : false,
        kotGarden: props.kot.garden ? props.kot.garden : false,
        kotState: props.kot.condition ? props.kot.condition : '',
        kotPEB: props.kot.peb_certificate ? props.kot.peb_certificate : 'D',
        kotRoomsNumber: props.kot.available_rooms ? props.kot.available_rooms : 1,
        showPopupPicturesEdit: false,
        kotDisponibilityOther: '',
        errors: {}
    })

    const [kdisponibilite, setKdisponibilite] = useState(props.kot.availability ? props.kot.availability : '')
    const [kState, setKState] = useState(props.kot.condition ? props.kot.condition : '')
    const [kRoomsNumber, setKRoomsNumber] = useState(props.kot.available_rooms ? props.kot.available_rooms : 1)
    const [kType, setKType] = useState(props.kot.kot_type ? props.kot.kot_type : '')
    const [peb, setPeb] = useState('no')
    const [pebCertificate, setPebCertificate] = useState('D')
    const [imageArray, setImageArray] = useState(props.images ? props.images : [])

    useEffect(
        () => {
            setLoading(false)
        },
        ['']
    )

    const handleChangeData = prop => event => {
        setState({ ...state, [prop]: event.target.value })
    }
    const handleChangeKotDisponibilite = event => {
        setKdisponibilite(event.target.value)
    }
    const handleChangeKotRoomNumber = event => {
        setKRoomsNumber(event.target.value)
    }
    const handleChangeKotState = event => {
        setKState(event.target.value)
    }
    const handleChangeKotType = event => {
        setKType(event.target.value)
    }
    const handleChangePeb = event => {
        setPeb(event.target.value)
    }
    const handleChangePebCertificate = event => {
        setPebCertificate(event.target.value)
    }
    const handleChangeDataCheckBoxes = event => {
        setState({ ...state, [event.target.name]: event.target.checked })
    }

    const onSubmitForm = event => {
        event.preventDefault()
        console.log(imageArray)

        const data = {
            title: state.titleKotOffer,
            kot_type: kType,
            rent: state.kotRent,
            charges: state.kotCharges,
            internet: state.kotInternet,
            cleaning: state.kotCleaning,
            gas_electricity: state.kotGazElec,
            water: state.kotWater,
            description: state.kotDescription,
            availability: kdisponibilite,
            available_rooms: kRoomsNumber,
            condition: kState,
            furnished: state.kotFurnished,
            private_bathroom: state.kotBathroom,
            private_kitchen: state.kotKitchen,
            terrace: state.kotTerrace,
            garden: state.kotGarden,
            peb: peb,
            peb_certificate: pebCertificate,
            proximity: state.kotProximity,
            other_infos: state.kotOtherInfos,
            availability_other: state.kotDisponibilityOther,
            kot_pictures: imageArray,
            id: dataProps.kot.id,
            origin: 'kot_creation',
            zip: state.kotZipCode,
            city: state.kotCity,
            street: state.kotAddress,
            action: dataProps.action === 'edit' ? 'put' : 'post'
        }
        try {
            // eslint-disable-next-line no-undef
            axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
            KotOwnerAPI.KotOwnerKot(dataProps.action, data).then(
                () => { window.location.href = RouteMap.Page('users/kots') }
            ).catch(
                (error) => {
                    console.log(error)
                }
            )
        } catch (error) {
            console.log(error)
        }
    }

    const buttonText = (dataProps.action === 'edit' && !dataProps.kot.duplicated) ? 'landing_new_kot_main_page.edit_button' : 'landing_new_kot_main_page.publish_button'

    return (
        <Container maxWidth='lg'>
            {!loading &&
                <>
                    <form onSubmit={onSubmitForm}>
                        {/* START Informations générales */}
                        <Box pt={5}>
                            <Grid container spacing={10}>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12} lg={11} xl={11}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <Box display={'flex'}>
                                                        <SectionTitle
                                                            step={1}
                                                            title={<FormattedMessage id="landing_new_kot_main_page.general_informations" />}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    ><FormattedMessage id="landing_new_kot_main_page.title_kot_offer" />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        type={'text'}
                                                        defaultValue={state.titleKotOffer}
                                                        onBlur={handleChangeData('titleKotOffer')}
                                                        autoComplete={'on'}
                                                        inputProps={{
                                                            maxLength: 60
                                                        }}
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.inputfield
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    ><FormattedMessage id="landing_new_kot_main_page.change_kot_type" />
                                                    </Typography>

                                                    <FormControl required={true} className={classes.field}>
                                                        <Select
                                                            value={kType}
                                                            onChange={handleChangeKotType}
                                                            inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                            MenuProps={selectPaperStyle()}
                                                        >
                                                            {kotTypeCollection.map(
                                                                (e) => {
                                                                    return <MenuItem key={e.value.toString()} value={e.value} >
                                                                        <Typography variant='body2'>
                                                                            {e.name}
                                                                        </Typography>
                                                                    </MenuItem>
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant='h4'
                                                                component='h3'
                                                                className={classes.titleField}
                                                                color='primary'
                                                            >
                                                                <FormattedMessage id="landing_new_kot_main_page.kot_rent" />
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                required={true}
                                                                type='number'
                                                                defaultValue={state.kotRent}
                                                                onBlur={handleChangeData('kotRent')}
                                                                autoComplete='on'
                                                                InputProps={{
                                                                    inputProps: { min: 0 },
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant='h4'
                                                                component='h3'
                                                                className={classes.titleField}
                                                                color='primary'
                                                            >
                                                                <FormattedMessage id="landing_new_kot_main_page.kot_charges" />
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                required={true}
                                                                type='number'
                                                                defaultValue={state.kotCharges}
                                                                onBlur={handleChangeData('kotCharges')}
                                                                autoComplete='on'
                                                                InputProps={{
                                                                    inputProps: { min: 0 },
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box mb={2}>
                                                        <Typography
                                                            variant='h4'
                                                            component='h3'
                                                            className={classes.titleField}
                                                            color='primary'
                                                        >
                                                            <FormattedMessage id="landing_new_kot_main_page.kot_rent_charges_include" />
                                                        </Typography>
                                                    </Box>
                                                    <Box m={1}>
                                                        <FormControl component='fieldset'>
                                                            <FormGroup>
                                                                <Grid container >
                                                                    <Grid item xs={12}>
                                                                        <FormattedMessage id="landing_new_kot_main_page.rent_include.internet">
                                                                            {label =>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color='primary' checked={state.kotInternet} onChange={handleChangeDataCheckBoxes} name='kotInternet' />}
                                                                                    label={label}
                                                                                    classes={{
                                                                                        label: classes.labelStyle
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormattedMessage id="landing_new_kot_main_page.rent_include.cleaning">
                                                                            {label =>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color='primary' checked={state.kotCleaning} onChange={handleChangeDataCheckBoxes} name='kotCleaning' />}
                                                                                    label={label}
                                                                                    classes={{
                                                                                        label: classes.labelStyle
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <FormattedMessage id="landing_new_kot_main_page.rent_include.water">
                                                                            {label =>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color='primary' checked={state.kotWater} onChange={handleChangeDataCheckBoxes} name='kotWater' />}
                                                                                    label={label}
                                                                                    classes={{
                                                                                        label: classes.labelStyle
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormattedMessage id="landing_new_kot_main_page.rent_include.gaz_elec">
                                                                            {label =>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox color='primary' checked={state.kotGazElec} onChange={handleChangeDataCheckBoxes} name='kotGazElec' />}
                                                                                    label={label}
                                                                                    classes={{
                                                                                        label: classes.labelStyle
                                                                                    }}
                                                                                />
                                                                            }
                                                                        </FormattedMessage>
                                                                    </Grid>

                                                                </Grid>
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12} md={11}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <Box display={'flex'}>
                                                        <SectionTitle
                                                            step={2}
                                                            title={<FormattedMessage id="landing_new_kot_main_page.location" />}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id="landing_new_kot_main_page.address" />
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        required={true}
                                                        type={'text'}
                                                        defaultValue={state.kotAddress}
                                                        onBlur={handleChangeData('kotAddress')}
                                                        autoComplete={'on'}
                                                        InputProps={{
                                                            classes: {
                                                                input: classes.inputfield
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container spacing={5}>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant='h4'
                                                                component='h3'
                                                                className={classes.titleField}
                                                                color='primary'
                                                            >
                                                                <FormattedMessage id="landing_new_kot_main_page.postal_code" />
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                type={'number'}
                                                                defaultValue={state.kotZipCode}
                                                                onBlur={handleChangeData('kotZipCode')}
                                                                autoComplete={'on'}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant='h4'
                                                                component='h3'
                                                                className={classes.titleField}
                                                                color='primary'
                                                            >
                                                                <FormattedMessage id="landing_new_kot_main_page.city" />
                                                            </Typography>
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                type={'text'}
                                                                defaultValue={state.kotCity}
                                                                onBlur={handleChangeData('kotCity')}
                                                                autoComplete={'on'}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* END générales */}

                        {/* START Détails */}
                        <Box pt={5}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item md={11} xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <Box display={'flex'}>
                                                        <SectionTitle
                                                            step={3}
                                                            title={<FormattedMessage id="landing_new_kot_main_page.details" />}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id="landing_new_kot_main_page.description" />
                                                    </Typography>
                                                    <FormattedMessage id="landing_new_kot_main_page.placeholder.kot_description">
                                                        {placeholder =>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                rows={10}
                                                                variant='outlined'
                                                                placeholder={placeholder}
                                                                defaultValue={state.kotDescription}
                                                                onBlur={handleChangeData('kotDescription')}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id="landing_new_kot_main_page.proximity" />
                                                    </Typography>
                                                    <FormattedMessage id="landing_new_kot_main_page.placeholder.kot_proximity">
                                                        {placeholder =>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                rows={10}
                                                                variant='outlined'
                                                                placeholder={placeholder}
                                                                defaultValue={state.kotProximity}
                                                                onBlur={handleChangeData('kotProximity')}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}>
                                                        <FormattedMessage id="landing_new_kot_main_page.others_info" />
                                                    </Typography>
                                                    <FormattedMessage id="landing_new_kot_main_page.placeholder.kot_other_infos">
                                                        {placeholder =>
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                rows={10}
                                                                variant='outlined'
                                                                placeholder={placeholder}
                                                                defaultValue={state.kotOtherInfos}
                                                                onBlur={handleChangeData('kotOtherInfos')}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: classes.inputfield
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} xl={6}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <Hidden only={['xs', 'sm']}>
                                                        <div className={classes.spacing} />
                                                    </Hidden>
                                                    <Typography
                                                        variant='h4'
                                                        component='h3'
                                                        className={classes.titleField}
                                                        color='primary'
                                                    >
                                                        <FormattedMessage id="landing_new_kot_main_page.availability" />
                                                    </Typography>
                                                    <FormControl required={true} className={classes.field}>
                                                        <Select
                                                            value={kdisponibilite}
                                                            onChange={handleChangeKotDisponibilite}
                                                            autoWidth
                                                            inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                            MenuProps={selectPaperStyle()}
                                                        >
                                                            {dataProps.kotDuration.map(
                                                                (e) => {
                                                                    return <MenuItem
                                                                        key={e.value.toString()}
                                                                        value={e.value}>
                                                                        <Typography variant='body2'>
                                                                            {e.name}
                                                                        </Typography>
                                                                    </MenuItem>
                                                                }
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {kdisponibilite === 'others' &&
                                            <Grid item xs={12}>
                                                <Typography variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}>
                                                    <FormattedMessage id="landing_new_kot_main_page.other" />
                                                </Typography>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    variant='outlined'
                                                    placeholder='Indiquez quand le kot est disponible pour la location.'
                                                    defaultValue={state.kotDisponibilityOther}
                                                    onBlur={handleChangeData('kotDisponibilityOther')}
                                                    InputProps={{
                                                        classes: {
                                                            input: classes.inputfield
                                                        }
                                                    }}
                                                />

                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <Typography variant='h4'
                                                component='h3'
                                                className={classes.titleField}>
                                                <FormattedMessage id="landing_new_kot_main_page.state" />
                                            </Typography>
                                            <FormControl required={true} className={classes.field}>
                                                <Select
                                                    value={kState}
                                                    onChange={handleChangeKotState}
                                                    MenuProps={selectPaperStyle()}
                                                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                >
                                                    {dataProps.kotState.map(
                                                        (e) => {
                                                            return <MenuItem key={e.value.toString()} value={e.value}>
                                                                <Typography variant='body2'>
                                                                    {e.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant='h4'
                                                component='h3'
                                                className={classes.titleField}
                                                color='primary'
                                            >
                                                <FormattedMessage id="landing_new_kot_main_page.number_rooms_available" />
                                            </Typography>
                                            <FormControl className={classes.field}>
                                                <Select
                                                    value={kRoomsNumber}
                                                    onChange={handleChangeKotRoomNumber}
                                                    MenuProps={selectPaperStyle()}
                                                    inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                >
                                                    {
                                                        [...Array(10)].map((elem, index) => (
                                                            <MenuItem value={index + 1} key={index}><Typography variant='body2'>{index + 1}</Typography></MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box mb={2}>
                                                <Typography
                                                    variant='h4'
                                                    component='h3'
                                                    className={classes.titleField}
                                                    color='primary'
                                                >
                                                    <FormattedMessage id="landing_new_kot_main_page.layout" />
                                                </Typography>
                                            </Box>
                                            <Box m={1}>
                                                <FormControl component='fieldset'>
                                                    <FormGroup>
                                                        <Grid container >
                                                            <Grid item xs={12}>
                                                                <FormattedMessage id="landing_new_kot_main_page.layout_include.furniture">
                                                                    {label =>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color='primary' checked={state.kotFurnished} onChange={handleChangeDataCheckBoxes} name='kotFurnished' />}
                                                                            label={label}
                                                                            classes={{
                                                                                label: classes.labelStyle
                                                                            }}
                                                                        />
                                                                    }
                                                                </FormattedMessage>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormattedMessage id="landing_new_kot_main_page.layout_include.terrace">
                                                                    {label =>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color='primary' checked={state.kotTerrace} onChange={handleChangeDataCheckBoxes} name='kotTerrace' />}
                                                                            label={label}
                                                                            classes={{
                                                                                label: classes.labelStyle
                                                                            }}
                                                                        />
                                                                    }
                                                                </FormattedMessage>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormattedMessage id="landing_new_kot_main_page.layout_include.private_bathroom">
                                                                    {label =>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color='primary' checked={state.kotBathroom} onChange={handleChangeDataCheckBoxes} name='kotBathroom' />}
                                                                            label={label}
                                                                            classes={{
                                                                                label: classes.labelStyle
                                                                            }}
                                                                        />
                                                                    }
                                                                </FormattedMessage>
                                                            </Grid>

                                                            <Grid item xs={12}>
                                                                <FormattedMessage id="landing_new_kot_main_page.layout_include.private_kitchen">
                                                                    {label =>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color='primary' checked={state.kotKitchen} onChange={handleChangeDataCheckBoxes} name='kotKitchen' />}
                                                                            label={label}
                                                                            classes={{
                                                                                label: classes.labelStyle
                                                                            }}
                                                                        />
                                                                    }
                                                                </FormattedMessage>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormattedMessage id="landing_new_kot_main_page.layout_include.garden">
                                                                    {label =>
                                                                        <FormControlLabel
                                                                            control={<Checkbox color='primary' checked={state.kotGarden} onChange={handleChangeDataCheckBoxes} name='kotGarden' />}
                                                                            label={label}
                                                                            classes={{
                                                                                label: classes.labelStyle
                                                                            }}
                                                                        />
                                                                    }
                                                                </FormattedMessage>
                                                            </Grid>
                                                        </Grid>
                                                    </FormGroup>
                                                </FormControl>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* END Détails */}

                        {/* START Photos de votre kot */}
                        <Box pt={5}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12} md={11}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12}>
                                                    <Box display={'flex'}>
                                                        <SectionTitle
                                                            step={4}
                                                            title={<FormattedMessage id="landing_new_kot_main_page.kot_photo" />}
                                                        />
                                                    </Box>

                                                    <Box fontStyle="italic">
                                                        <Typography variant='subtitle1'><FormattedMessage id="landing_new_kot_main_page.illustrate_kot_offer" /></Typography>
                                                    </Box>
                                                    <UploadImage imageArray={imageArray} setImageArray={setImageArray} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* END Photos de votre kot */}

                        {/* START Certificat énergétique (PEB) */}
                        <Box pt={5}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container alignItems='flex-start'>
                                        <Grid item xs={12} md={11}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12} md={6}>
                                                    <Box display={'flex'}>
                                                        <SectionTitle
                                                            step={5}
                                                            title={<FormattedMessage id="landing_new_kot_main_page.energy_certificate" />}
                                                        />
                                                    </Box>

                                                    <FormControl required={true} className={classes.field}>
                                                        <Select
                                                            value={peb}
                                                            onChange={handleChangePeb}
                                                            MenuProps={selectPaperStyle()}
                                                            inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                        >
                                                            <MenuItem value='yes'>
                                                                <Typography variant='body2'>
                                                                    <FormattedMessage id="landing_new_kot_main_page.answ_yes" />
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem value='no'>
                                                                <Typography variant='body2'>
                                                                    <FormattedMessage id="landing_new_kot_main_page.answ_no" />
                                                                </Typography>
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                {
                                                    peb === 'yes' &&
                                                     <Grid item xs={12} md={6}>
                                                         <Typography
                                                             variant='h2'
                                                             component='h3'
                                                             className={classes.sectionTitleField}
                                                             color='primary'
                                                         >
                                                             <FormattedMessage id="landing_new_kot_main_page.result_peb" />
                                                         </Typography>
                                                         <FormControl required={true} className={classes.field}>
                                                             <Select
                                                                 value={pebCertificate}
                                                                 onChange={handleChangePebCertificate}
                                                                 MenuProps={selectPaperStyle()}
                                                                 inputProps={{ MenuProps: { disableScrollLock: true } }}
                                                             >
                                                                 {
                                                                     dataProps.kotEnergyCertificate.map(
                                                                         (e, key) => {
                                                                             return <MenuItem key={key.toString()} value={e}>
                                                                                 <Typography variant='body2'>
                                                                                     {e}
                                                                                 </Typography>
                                                                             </MenuItem>
                                                                         }
                                                                     )
                                                                 }
                                                             </Select>
                                                         </FormControl>
                                                     </Grid>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* END Certificat énergétique (PEB) */}

                        <Box pt={5} pb={10} display={'flex'} justifyContent={'flex-end'} >
                            <FilledButton
                                endIcon={<IconFactory icon='forward'/>}
                                type='submit'
                                color='secondary'
                                name={<FormattedMessage id={buttonText} />}
                            />
                        </Box>
                    </form>
                </>
            }
        </Container>
    )
}

export default NewKotMainPage
