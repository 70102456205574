import { Box, InputLabel, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import { useStyles } from './ToolbarWidget_style'
import FilledButton from 'buttons/FilledButton/FilledButton'
import Picker from 'emoji-picker-react'

const DEFAULT_CTA = "<a href='https://www.student.be/nl' class='MuiButtonBase-root MuiButton-root MuiButton-contained' target='_blank' rel='noopener noreferrer' style='text-transform: unset; text-decoration: unset; color: #FFFFFF; background-color: #FF6762 !important'>Click here</a>"
const DEFAULT_SMILEY = '😁'
const DEFAULT_EMBED = `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/3GekPDsMO7g0ArxR3f0AxU?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`

const ToolbarWidget = (props) => {
    const { handleCustomHTML, isAdmin  } = props
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState(0)
    const [customElement, setCustomElement] = useState(DEFAULT_CTA)

    const handleClick = () => {
        if (activeTab === 0 || activeTab === 1) {
            handleCustomHTML('button', customElement)
        } else if (activeTab === 2) {
            handleCustomHTML('iframe', customElement)
        }
    }

    const handleTabChange = (_, selectedTab) => {
        if (selectedTab === 0) setCustomElement(DEFAULT_CTA)
        if (selectedTab === 1) setCustomElement(DEFAULT_SMILEY)
        if (selectedTab === 2) setCustomElement(DEFAULT_EMBED)

        setActiveTab(selectedTab)
    }

    const handleTextChange = (e) => {
        setCustomElement(e.target.value)
    }

    const onEmojiClick = (_, emojiObject) => {
        setCustomElement(emojiObject.emoji)
    }

    return (
        <Box className={classes.container}>
            <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
            >
                <Tab label="Add button" />
                <Tab label="Smiley" />
                {isAdmin && <Tab label="Embed widget" />}
            </Tabs>
            <Box my={4} className={classes.tabContent}>
                {activeTab === 0 && <>
                    <InputLabel htmlFor="HTMLButton">Button HTML</InputLabel>
                    <textarea id='HTMLButton' className={classes.textarea} value={customElement} onChange={handleTextChange} />
                </>}
                {activeTab === 1 &&
                    <Picker onEmojiClick={onEmojiClick} />
                }
                {activeTab === 2 && isAdmin && <>
                    <InputLabel htmlFor="HTMLIframe">Iframe HTML</InputLabel>
                    <textarea id='HTMLIframe' className={classes.textarea} value={customElement} onChange={handleTextChange} />
                    <Typography variant='body2'>
                        Following widget creators are accepted: spotify, soundcloud, youtube & twitter.
                    </Typography>
                    <Typography variant='body2'>
                        Let the developers know if you need any others.
                    </Typography>
                </>}
            </Box>
            <p>Preview 👇</p>
            <div className={classes.preview} dangerouslySetInnerHTML={{ __html: customElement}} />
            <Box className={classes.submit}>
                <FilledButton name='Paste' color='primary' onClick={handleClick} />
            </Box>
        </Box>
    )
}

export default ToolbarWidget
