import React from 'react'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './locationslistcontainer_style'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { FormattedMessage } from 'react-intl'

class LocationsListContainer extends React.Component {
    constructor (props) {
        super(props)

        this.displayLocationList = this.displayLocationList.bind(this)

        this.aboutCityCollection = CollectionHandler.Get('aboutCityCollection')
    }

    displayLocationList (location) {
        var buffer = []
        this.aboutCityCollection[location].forEach((value) => {
            buffer.push(
                <li>
                    <RouteMapLink
                        location={value.value}
                        itemType={this.props.type}
                        key={`${value.name} link to ${this.props.type}`}
                    >
                        {value.name}
                    </RouteMapLink>
                </li>
            )
        })
        return buffer
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.provCards}>
                <Paper elevation={4} className={classes.provCard}>
                    <Typography variant="h2" className={classes.cityRegion}>
                        <FormattedMessage id="landing_about_pages.locations_list.flanders" />
                    </Typography>
                    <ul>{this.displayLocationList('flanders')}</ul>
                </Paper>
                <Paper elevation={4} className={classes.provCard}>
                    <Typography variant="h2" className={classes.cityRegion}>
                        <FormattedMessage id="landing_about_pages.locations_list.wallonia" />
                    </Typography>
                    <ul>{this.displayLocationList('wallonia')}</ul>
                </Paper>
                <Paper elevation={4} className={classes.provCard}>
                    <Typography variant="h2" className={classes.cityRegion}>
                        <FormattedMessage id="landing_about_pages.locations_list.brussels" />
                    </Typography>
                    <ul>{this.displayLocationList('brussels')}</ul>
                </Paper>
            </div>

        )
    }
}

export default withStyles(useStyles)(LocationsListContainer)
