import React from 'react'
import { Box, Grid, Typography, Hidden } from '@material-ui/core'
import { useStyles } from './RecommendedItemsContainer_style'
import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'

const RecommendedItemsContainer = (props) => {
    const { children, moreItemsURL, titleId, subTitleId, recommendedItemType, type, textValues } = props
    const classes = useStyles()

    return (
        <>
            <Box className={classes.titleBox}>
                <Typography variant='h6' component='h2' className={classes.title}>
                    <FormattedMessage id={titleId} values={textValues} />
                </Typography>
                {moreItemsURL &&
                    <RouteMapLink redirectionUrl={moreItemsURL} className={classes.seeMoreBox}>
                        <Box className={classes.seeMore}>
                            <Typography variant='h4' component='span'>
                                <FormattedMessage id={`personal_dashboard.recommend_jobs.see_more.${type}`}/>
                            </Typography>
                            <IconFactory icon='arrow-right'/>
                        </Box>
                    </RouteMapLink>
                }
            </Box>
            <Typography variant='body2' className={classes.subTitle}>
                <FormattedMessage id={subTitleId} values={textValues} />
            </Typography>
            {type === 'article' &&
                    <Box className={classes.desktopBox}>
                        <Grid container spacing={2} className={classes.articleGridContainer}>
                            {children.map((recommendedItemCard) => {
                                return (
                                    <Grid item xs={12} md={6} lg={4} key={`item-card-${recommendedItemCard.props[recommendedItemType].id}`}>
                                        {recommendedItemCard}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
            }
            {type != 'article' && <>
                <Hidden mdDown>
                    <Box className={classes.desktopBox}>
                        <Grid container spacing={2}>
                            {children.map((recommendedItemCard) => {
                                return (
                                    <Grid item xs={12} sm={6} lg={4} key={`item-card-${recommendedItemCard.props[recommendedItemType].id}`}>
                                        {recommendedItemCard}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                </Hidden>
                <Hidden lgUp>
                    <Box className={classes.mobileScrollBox}>
                        {children}
                    </Box>
                </Hidden>
            </>}
        </>
    )
}
export default RecommendedItemsContainer
