import React from 'react'

import { useStyles } from './ArticleListCard_style'
import { Box } from '@material-ui/core'

import ArticleCardContent from '../../../components/ArticleCardContent/ArticleCardContent'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const ArticleListCard = (props) => {
    const classes = useStyles()
    const { article } = props

    return (
        <RouteMapLink showItemType='article' showItemUrl={article.url} className={classes.articleLink}>
            <Box className={classes.mostReadContainer}>
                <Box id='upBorder' className={classes.upBorder} />
                <ArticleCardContent article={article} />
            </Box>
        </RouteMapLink>
    )
}

export default ArticleListCard
