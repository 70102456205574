import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    articleContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 14
    },
    readTimeAndDate: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    minorText: {
        fontSize: '12px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        lineHeight: 1.43
    },
    bullet: {
        fontSize: '8px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    readTimeContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    articleTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.up('md')]: {
            height: 'calc(16px * 1.4 * 2)'
        }
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 12,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    }
}))
