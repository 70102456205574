import React, { useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Box from '@material-ui/core/Box'
import { useStyles } from './homesearch_style'
import IconFactory from 'icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap.js'
import FilledButton from 'buttons/FilledButton/FilledButton'
import { UserContext } from 'contexts/UserContext'
import UserAPI from 'api/UserAPI'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'

const HomeSearch = (props) => {
    const classes = useStyles()
    const userContext = useContext(UserContext)
    const itemTypeCollection = CollectionHandler.Get('itemTypeCollection')
    const cityCollection = CollectionHandler.Get('cityCollection')
    const [jobType, setJobType] = useState(itemTypeCollection[0].value)
    const [filters, setFilters] = useState({ location: [] })
    const [searchCities, setSearchCities] = useState(cityCollection)
    const [jobTypeOpen, setJobTypeOpen] = useState(false)

    const handleChangeJob = (event) => {
        setJobType(event.target.value)
    }

    const handleChangeLocation = (event) => {
        setFilters({ location: [event.target.value] })
    }

    const renderIcon = () => {
        return (
            <Box className={classes.iconBox}>
                <IconFactory icon='down-solid' fontSize='12px' color='black' />
            </Box>
        )
    }

    const updateStatusConfig = () => {
        const newStatusConfig = {
            ...userContext.statusConfig,
            not_looking_any_job: false
        }
        if (jobType === 'student_job') newStatusConfig.actively_looking_student_job = true
        if (jobType === 'internship') newStatusConfig.actively_looking_internship = true
        if (jobType === 'first_job') newStatusConfig.actively_looking_first_job = true
        if (jobType === 'kot') newStatusConfig.actively_looking_kot = true

        userContext.setStatusConfig(newStatusConfig)

        return newStatusConfig
    }

    const updateProfile = () => {
        const newStatusConfig = updateStatusConfig()
        const profileData = {
            only_profile: true,
            ...newStatusConfig
        }
        UserAPI.UserEditMyProfile(profileData).then(response => {
            AnalyticsHelper.sendGTMEvent(
                'Student Status Updated',
                AnalyticsHelper.constructStudentStatusAnalyticsAttributes(props.user, userContext.statusConfig)
            )
            window.location.href = getTargetUrl()
        })
    }

    const getTargetUrl = () => {
        if (filters.location.length === 0) {
            return RouteMap.Index(jobType)
        } else if (filters.location.length === 1) {
            return RouteMap.Tag(jobType, 'cityCollection', filters.location[0].value_name)
        } else {
            let params = ''
            filters.location.forEach((city) => {
                params += `location=${city.value},${city.lat},${city.long}&`
            })
            if (params.endsWith('&')) { params = params.slice(0, -1) }

            return RouteMap.Index(jobType, params)
        }
    }

    return (
        <Box className={classes.homeSearchContainer}>
            <Typography className={classes.mainTitle}>
                <FormattedMessage id='landing_student.home_search.main_title' />
            </Typography>
            <Box className={classes.inputContainer}>
                <Box className={classes.inputFieldsContainer}>
                    <FormControl className={classes.formField}>
                        <Typography className={classes.textBold}>
                            <FormattedMessage id='landing_student.home_search.title2' />
                        </Typography>
                        <Select
                            inputProps={{ MenuProps: { disableScrollLock: true } }}
                            disableUnderline={true}
                            className={classes.inputField}
                            value={jobType}
                            onChange={handleChangeJob}
                            IconComponent={renderIcon}
                            open={jobTypeOpen}
                            onClick={() => setJobTypeOpen(!jobTypeOpen)}
                        >
                            {itemTypeCollection.map((item) => (
                                <MenuItem value={item.value} key={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography><FormattedMessage id='landing_student.home_search.at' /></Typography>
                        <CityFilter
                            searchView
                            handleInputSearchCity={handleChangeLocation}
                            searchCities={searchCities}
                            setSearchCities={setSearchCities}
                            setFilters={setFilters}
                            filters={filters}
                            width={500}
                        />
                    </FormControl>
                </Box>
                <Box className={classes.buttonContainer}>
                    {userContext &&
                        <FilledButton
                            newStyle
                            name={<FormattedMessage id='landing_student.home_search.valid_btn' />}
                            color='primary'
                            className={classes.validateBtn}
                            onClick={updateProfile}
                        />
                    }
                    {!userContext &&
                        <FilledButton
                            newStyle
                            name={<FormattedMessage id='landing_student.home_search.valid_btn' />}
                            color='primary'
                            className={classes.validateBtn}
                            onClick={() => { window.location.href = getTargetUrl() }}
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default HomeSearch
