import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    navigationButton: {
        padding: '9px 12px',
        textAlign: 'center',
        textTransform: 'none',
        borderRadius: '8px',
        height: '36px'
    },
    navigationButtonNonActive: {
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    },
    navigationButtonActive: {
        backgroundColor: 'var(--red-light)',
        '& div': {
            color: 'var(--red)'
        },
        '&:hover': {
            backgroundColor: 'var(--red-light)'
        }
    }
}))
