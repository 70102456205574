import React from 'react'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

const StudentNoteModalView = (props) => {
    const theme = useTheme()

    const {
        openShare,
        studentNote,
        handleChange,
        updateStudentNote,
        closeModal
    } = props

    return (
        <Dialog
            maxWidth='sm'
            open={openShare}
            onClose={closeModal}
            disableScrollLock={true}
            aria-labelledby="responsive-dialog-delete"
            fullWidth
        >
            <DialogTitle onClose={closeModal}>
                <Typography
                    variant='h2'
                    style={{ marginTop: '1rem' }}
                >
                    <FormattedMessage id="job_results.popup.add_comment" />
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        color: theme.palette.grey[500],
                        height: 35,
                        width: 35
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box m={2}>
                    <TextField
                        id="outlined-multiline-static"
                        label={<FormattedMessage id="job_results.popup.your_comment" />}
                        multiline
                        rows={4}
                        value={studentNote}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box>
                    <LineButton
                        name={<FormattedMessage id="job_chat.cancel" />}
                        onClick={closeModal}
                        size='small'
                    />
                    <FilledButton
                        size='small'
                        color='secondary'
                        onClick={updateStudentNote}
                        name={<FormattedMessage id="job_results.popup.save_button" />}
                        style={{ marginLeft: 10 }}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default StudentNoteModalView
