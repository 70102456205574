import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16
        }
    },
    title: {
        fontSize: 20,
        lineHeight: 'normal'
    },
    subTitle: {
        marginBottom: 16
    },
    desktopBox: {
        paddingBottom: 40
    },
    mobileScrollBox: {
        display: 'flex',
        overflowX: 'scroll',
        marginLeft: '-16px',
        marginRight: '-24px',
        padding: '0px 16px 40px 16px',
        gap: 12
    },
    seeMore: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 8,
        borderBottom: '2px solid',
        paddingBottom: 8,
        cursor: 'pointer',
        color: 'black'
    },
    seeMoreBox: {
        borderRadius: 16,
        '&:hover': {
            '& div': {
                color: 'var(--red) !important'
            },
            '& span': {
                color: 'var(--red) !important'
            }
        }
    },
    articleGridContainer: {
        [theme.breakpoints.down('sm')]: {
            '& div:nth-child(n+5) ': {
                display:'none'
            }
        }
    }
}))
