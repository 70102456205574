import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from '../FiltersModals_style'
import MoreFilterSectionWrapper from '../MoreFilterSectionWrapper/MoreFilterSectionWrapper'
import TextFilter from 'components/Searchbar/shared/TextFilter/TextFilter'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import CheckboxList from 'components/Searchbar/shared/CheckboxList/CheckboxList'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersModalWrapper from 'modals/FilterModals/FiltersModalWrapper'

const StudentJobFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, debouncedFilterChange, handleFilterChange, filters, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studentJobSchedulesCollection = CollectionHandler.Get('studentJobSchedulesCollection')

    return (
        <FiltersModalWrapper
            type='student_job'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            <MoreFilterSectionWrapper
                titleId='job_filters.text_filter.title'
                underTitleId='job_filters.text_filter.description'
            >
                <Box className={classes.filterBox}>
                    <TextFilter
                        handleFilterChange={handleFilterChange}
                        debouncedFilterChange={debouncedFilterChange}
                        filters={filters}
                        setFilters={setFilters}
                        modal
                        filterTextId='search_bar.search.job'
                    />
                </Box>
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.schedule.title'
                underTitleId='job_filters.schedule.description'
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'jobSchedule') }}
                    selectedItems={filters.jobSchedule}
                    collection={studentJobSchedulesCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.study_fields.title'
                underTitleId='job_filters.study_fields.description'
            >
                <CheckboxList
                    type='studyDomain'
                    collection={studyDomainCollection}
                    selectedItems={filters.studyDomain}
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'studyDomain') }}
                    setFilters={setFilters}
                    width={400}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.location.title'
                underTitleId='job_filters.location.description'
                disableDivider
            >
                <Box className={classes.filterBox}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </MoreFilterSectionWrapper>
        </FiltersModalWrapper>
    )
}

export default StudentJobFiltersModalView
