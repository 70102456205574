/* eslint-disable quotes */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useRef, useImperativeHandle } from 'react'

import { useStyles } from './AlertListCard_style'
import { FormattedMessage } from 'react-intl'

import CollectionHandler from 'assets/CollectionHandler'
import GenericHelper from 'assets/GenericHelper'
import RouteMap from 'assets/RouteMap'
import URLParamsHandler from 'assets/URLParamsHandler'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import CheckButton from 'buttons/CheckButton/CheckButton'
import IconFactory from 'icons/IconFactory/IconFactory'

import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

const AlertListCard = React.forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        setCheck: () => {
            setCheck()
        }
    }))

    const classes = useStyles()

    const checkRef = useRef()

    const setCheck = (check) => {
        checkRef.current.setCheck(check)
    }

    const flattenLocations = (locations) => {
        return locations.map((option) => {
            return `${option.value},${option.lat},${option.long}`
        })
    }

    const getRedirectionUrl = () => {
        const urlFilters = {
            company_size: props.alert.company_sizes,
            location: flattenLocations(props.alert.locations),
            study_domain: props.alert.study_domains
        }
        const urlParams = URLParamsHandler.stringifyURLParams(urlFilters)

        return RouteMap.Show(
            props.alert.job_type,
            `?${urlParams}`
        ).slice(0, -1).replace('/?', '?')
    }

    const renderSearchParams = () => {
        let paramsFormat = ""

        switch (props.alert.job_type) {
        case 'first_job':
        case 'internship':
            props.alert.study_domains.map((domain) => {
                paramsFormat += CollectionHandler.Translate(
                    'studyDomainCollection',
                    domain
                ) + ' | '
            })
            props.alert.locations.map((location) => {
                paramsFormat += CollectionHandler.Translate(
                    'cityCollection',
                    location.value
                ) + ' | '
            })
            props.alert.company_sizes.map((size) => {
                paramsFormat += CollectionHandler.Translate(
                    'companySizeCollection',
                    size
                ) + ' | '
            })
            break
        case 'student_job':
            props.alert.locations.map((location) => {
                paramsFormat += CollectionHandler.Translate(
                    'cityCollection',
                    location.value
                ) + ' | '
            })
            break
        }
        // Removes '|' char after the last array elem
        paramsFormat = paramsFormat.slice(0, -3)

        return (
            paramsFormat
        )
    }

    return (
        <Box className={`${classes.alertCardContainer} ${props.alert.job_type.replace('_', '-')}`}>
            <Hidden mdDown>
                <Box className={classes.checkContainer}>
                    <CheckButton
                        onClick={() => props.onCheck(props.forwardRef, props.alert)}
                        ref={checkRef}
                    />
                </Box>
            </Hidden>
            <Card elevation={1} className={`${classes.alertCard} `}>
                <Box className={`${classes.alertJobType} ${classes.alertCardColumn}`} >
                    <Hidden smDown>
                        <Typography
                            variant='h5'
                            component='span'
                        >
                            <FormattedMessage id={`type.${props.alert.job_type}.singular`} />
                        </Typography>
                    </Hidden>
                </Box>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12} md={10}>
                        <Typography variant='body1' component='span'>
                            {
                                renderSearchParams()
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.alertCreationDate}>
                        <Typography variant='body1' component='span'>
                            {
                                GenericHelper.formatDateObject(props.alert.created_at)
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
            <Box className={classes.hoverActionContainer}>
                <RouteMapLink
                    redirectionUrl={getRedirectionUrl()}
                >
                    <Tooltip title={<FormattedMessage id="alert_card.tooltip.show_alert" />}>
                        <IconButton>
                            <IconFactory
                                icon='view'
                                className={classes.alertColor}
                            />
                        </IconButton>
                    </Tooltip>
                </RouteMapLink>
                <Tooltip title={<FormattedMessage id="alert_card.tooltip.delete_alert" />}>
                    <IconButton
                        onClick={() => props.remove(props.alert)}
                    >
                        <IconFactory
                            icon='delete'
                            className={classes.alertColor}
                        />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    )
})

export default AlertListCard
