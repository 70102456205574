import React, { useState, useEffect } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import JobCard from 'cards/JobCard/JobCard'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'

const RecentlyViewedJobs = (props) => {
    const { user } = props
    const [recentlyViewedJobs, setRecentlyViewedJobs] = useState([])

    useEffect(() => {
        getRecentlyViewedJobs()
    }, [])

    const getRecentlyViewedJobs = () => {
        const data = {
            user_id: user.id,
            amount: 3
        }

        DashboardAPI.GetRecentlyViewedJobs(data).then((response) => {
            setRecentlyViewedJobs(response.data.recently_viewed_jobs)
        })
    }

    if (recentlyViewedJobs?.length === 0) return null

    return (
        <RecommendedItemsContainer
            user={user}
            type='recently_viewed'
            titleId='personal_dashboard.recently_viewed_jobs.title'
            subTitleId='personal_dashboard.recently_viewed_jobs.sub_title'
            recommendedItemType='job'
        >
            {recentlyViewedJobs.map((recommendedJob) => {
                return (
                    <JobCard
                        key={`job-card-recently-viewed-${recommendedJob.id}`}
                        job={recommendedJob}
                        userType={user.type}
                    />
                )
            })}
        </RecommendedItemsContainer>
    )
}
export default RecentlyViewedJobs
