import React from 'react'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import IconFactory from 'icons/IconFactory/IconFactory'

import { useStyles } from './ResetFilters_style'

const ResetFilters = (props) => {
    const { reset } = props
    const classes = useStyles()

    return (
        <Hidden smDown>
            <Button className={classes.resetIconContainer} onClick={reset}>
                <Box className={classes.contentBox}>
                    <IconFactory
                        icon='reset'
                        fontSize='20px'
                        color='black'
                    />
                </Box>
            </Button>
        </Hidden>
    )
}

export default ResetFilters
