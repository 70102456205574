import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertAdContainer: {
        minHeight: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        paddingTop: 10,
        paddingBottom: 10,
        borderTop: '1px solid var(--light-grey)',
        borderBottom: '1px solid var(--light-grey)',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    alertAdText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 20,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    alertAdTitle: {
        fontSize: '18px !important',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        marginBottom: 10
    },
    alertAdDescription: {
        fontSize: '16px !important',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        textAlign: 'center'
    },
    alertAdButton: {
        backgroundColor: 'var(--red)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--red)'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
}))
