import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NavigationUserActionsLoggin_style'
import { useStyles as useDarkModeStyles } from './NavigationUserActionsLogginDarkMode_style'
import { Box } from '@material-ui/core'

import LineButton from 'buttons/LineButton/LineButton'
import MyMessagesIcon from '../../MyMessagesIcon/MyMessagesIcon'
import NavigationUserProfileIcon from '../../NavigationUserProfileIcon/NavigationUserProfileIcon'
import Notifications from '../../Notifications/Notifications'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationUserActionsLoggin = (props) => {
    const { user, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    return (
        <Box className={classes.userActionsLogginContainer}>
            {user.type === 'student' &&
                <RouteMapLink page='users/dashboard'>
                    <LineButton className={classes.loginButtonDashboardButton}>
                        <FormattedMessage id='landing_connected_user.dashboard' />
                    </LineButton>
                </RouteMapLink>
            }
            <Notifications user={user} darkMode={darkMode}/>
            <MyMessagesIcon user={user} darkMode={darkMode}/>
            <NavigationUserProfileIcon user={user} darkMode={darkMode} />
        </Box>
    )
}

export default NavigationUserActionsLoggin
