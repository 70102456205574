/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeCitySearch_style'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Box from '@material-ui/core/Box'

import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap.js'
import RouteMapLink from 'shared/wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import HomeCityFilter from './components/HomeCityFilter'

const HomeCitySearch = (props) => {
    const locale = RouteMap.GetLocaleFromUrl()
    const itemTypeCollection = CollectionHandler.Get('homeSearchItemCollection')
    const cityCollection = CollectionHandler.Get('cityCollection')

    const [jobType, setJobType] = useState(itemTypeCollection[0].value)
    const [location, setLocation] = useState({ value_name: 'city' })
    const [cityInput, setCityInput] = useState('')
    const [citiesMatchingSearch, setCitiesMatchingSearch] = useState(cityCollection)
    const [openDropdown, setOpenDropdown] = useState(false)

    const handleChangeJob = (event) => {
        setJobType(event.target.value)
    }

    const handleChangeLocation = (value) => {
        if (value.dispatchConfig) return null
        setLocation(value)
        setCityInput('')
        setCitiesMatchingSearch(cityCollection)
        setOpenDropdown(false)
    }

    const handleInputSearchCity = (event) => {
        const { value } = event.target
        var newCities = []
        if (value.length !== 0) {
            const regex = value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            newCities = cityCollection.filter(elem =>
                elem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(regex) === true
                || elem.value.includes(regex) === true
            )
        }

        if (newCities.length !== 0) {
            setCitiesMatchingSearch(newCities)
        } else {
            setCitiesMatchingSearch(cityCollection)
        }
        setCityInput(value)
    }

    const classes = useStyles()
    return (
        <Box className={classes.homeSearchContainer}>
            <Typography
                variant='h6'
                component='h2'
                className={classes.title}
            >
                <FormattedMessage id="landing_student.home_search.title" />
            </Typography>
            <Box className={classes.formContainer}>
                <FormControl className={classes.formField}>
                    <Select
                        IconComponent={(props) => (<IconFactory icon='down-solid' {...props} />)}
                        inputProps={{ MenuProps: { disableScrollLock: true } }}
                        disableUnderline={true}
                        className={`${classes.inputField} ${classes.underline}`}
                        value={jobType} onChange={handleChangeJob}
                    >
                        {itemTypeCollection.map((item) => (
                            <MenuItem value={item.value} key={item.value}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>

                    <HomeCityFilter
                        location={location}
                        handleChangeLocation={handleChangeLocation}
                        handleInputSearchCity={handleInputSearchCity}
                        cityCollection={citiesMatchingSearch}
                        cityInput={cityInput}
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                    />

                </FormControl>
            </Box>
            <RouteMapLink itemType={jobType} location={location.value_name}>
                <FilledButton
                    newStyle
                    color='initial'
                    className={classes.validateBtn}
                    name={<FormattedMessage id="landing_student.home_search.btn" />}
                />
            </RouteMapLink>
        </Box>
    )
}

export default HomeCitySearch
