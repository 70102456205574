/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import JobShowImage from './components/JobShowImage/JobShowImage'
import SectionMainTitle from 'components/SectionMainTitle/SectionMainTitle'
import CompanyPassport from './components/CompanyPassport/CompanyPassport'
import { useStyles } from './JobShowContent_style'
import Sanitizer from 'assets/Sanitizer'

const JobShowContent = (props) => {
    const { job } = props
    const classes = useStyles()
    const showVideo = job.show_add_video && job.video_url

    const checkIfStringIsPresent = (string) => {
        if (['<p></p>', '<p><br></p>', '', null, undefined].includes(string)) return false
        return true
    }

    return (
        <Box className={classes.jobContentContainer}>
            {(showVideo || job.job_show_cloudinary_key) &&
                <Box className={classes.companyContentSection}>
                    <JobShowImage job={job} showVideo={showVideo}/>
                </Box>
            }
            <Box>
                <CompanyPassport job={job}/>
            </Box>
            {checkIfStringIsPresent(job.description) &&
                <Box className={`${classes.contentSection} ${classes.jobDescriptionBox}`}>
                    <SectionMainTitle textId='job_resume.job_description'/>
                    <Typography
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.description) }}
                        component='div'
                    />
                </Box>
            }
            {checkIfStringIsPresent(job.searched_profile) &&
                <Box className={classes.contentSection}>
                    <SectionMainTitle textId='job_resume.profile_required'/>
                    <Typography
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.searched_profile) }}
                        component='div'
                    />
                </Box>
            }
            {checkIfStringIsPresent(job.we_offer) &&
                <Box className={classes.contentSection}>
                    <SectionMainTitle textId='job_resume.we_offer'/>
                    <Typography
                        className={classes.text}
                        dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.we_offer) }}
                        component='div'
                    />
                </Box>
            }
        </Box>
    )
}

export default JobShowContent
