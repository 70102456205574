import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '16px',
    },
    optional: {
        color: 'var(--grey-middle)',
        fontSize: 10,
        fontFamily: "'GintoNormal-Medium', sans-serif",
        marginLeft: 4
    }
}))
