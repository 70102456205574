import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    kotDescription: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            borderTopLeftRadius: '30px',
            borderTopRightRadius: '30px',
            fontSize: '2vh',
            alignItems: 'center'
        }
    },
    kotDescriptionText: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 0
        }
    },
    stickyContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20
    },
    kotShowHeader: {
        display: 'flex',
        width: '84%',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            zIndex: 10,
            top: '15vw'
        }
    },
    responsive: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'unset',
            width: '100%'
        }
    },
    customBox: {
        padding: '15px',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '15px'
    },
    marginSize: {
        margin: '15px auto',
        [theme.breakpoints.up('md')]: {
            width: '600px',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    columnContainer: {
        maxWidth: '1300px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    leftContainer: {
        opacity: 1,
        maxHeight: 'unset',
        transition: 'opacity ease 0.3s, max-height ease 0.3s',
        overflow: 'initial',
        padding: '0 16px',

        [theme.breakpoints.up('sm')]: {
            width: '75%',
            marginRight: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            padding: 0
        }
    },
    rightContainer: {
        top: '20px',
        width: '25%',
        height: '100%',
        opacity: 1,
        overflow: 'initial',
        alignItems: 'center'
    },
    applyBox: {
        marginTop: 16,
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'baseline'
        },
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'auto'
        }
    },
    sizeWidth: {
        width: 200,
        height: 40
    },
    ctaBox: {
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginTop: 24
        }
    },
    iconsButtonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    textLowerCase: {
        textTransform: 'none'
    }
}))
