import React, { useState, useEffect, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import { Box } from '@material-ui/core'

import JobCreationFunnelWrapper from 'wrappers/JobCreationFunnelWrapper/JobCreationFunnelWrapper'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import FunnelTableOfContents from '../shared/FunnelTableOfContents/FunnelTableOfContents'
import FunnelOverviewWrapper from '../shared/wrappers/FunnelOverviewWrapper/FunnelOverviewWrapper'
import FunnelContentWrapper from '../shared/wrappers/FunnelContentWrapper/FunnelContentWrapper'
import FunnelFormWrapper from '../shared/wrappers/FunnelFormWrapper/FunnelFormWrapper'
import FunnelSubmitBar from '../shared/FunnelSubmitBar/FunnelSubmitBar'
import FeedbackSnackbar from 'shared/components/FeedbackSnackbar/FeedbackSnackbar'
import JobFormFactory from '../shared/forms/JobFormFactory/JobFormFactory'

import { useStyles } from './StudentJobFunnel_style'
import RouteMap from 'assets/RouteMap'
import CollectionHandler from 'assets/CollectionHandler.js'
import JobAPI from 'api/JobAPI'
import validateStep from '../shared/helpers/validate_step'
import {
    FUNNEL_NEW, FUNNEL_EDIT,
    ESSENTIAL_STEP, EXTRA_STEP, SETTINGS_STEP, PUBLICATION_STEP,
    DIRECTION_FORWARD, DIRECTION_BACK, FIRST_JOB
} from '../settings'

const StudentJobFunnel = (props) => {
    const classes = useStyles()
    const { user, company, job, jobType, funnelType } = props
    const [errors, setErrors] = useState({})
    const [isLoading, setIsloading] = useState(false)
    const topOfFormRef = useRef(null)
    const scrollToTop = () => topOfFormRef.current.scrollIntoView()
    const scrollToError = () => {
        const firstError = document.getElementById('fieldError')
        firstError.scrollIntoView({block: "start", behavior: "smooth"});
    }
    const [feedback, setFeedback] = useState({
        open: false,
        message: null,
        severity: null,
        anchor: { vertical: 'bottom', horizontal: 'center' }
    })
    const closeFeedback = () => setFeedback({ ...feedback, open: false })
    const initAddressFields = () => {
        let address = {}
        if (job?.address) {
            address = job.address
        } else if (!company.is_child && company.address) {
            address = company.address
        }
        return ({
            jobStreetName: address.street || '',
            jobZip: address.zip || '',
            jobCity: address.city || '',
            jobCountry: address.country || CollectionHandler.Get('countryCollection')[RouteMap.GetLocaleFromUrl()],
        })
    }
    const initSteps = () => {
        let formSteps = [
            { value: ESSENTIAL_STEP, isValid: false },
            { value: EXTRA_STEP, isValid: false },
            { value: SETTINGS_STEP, isValid: false },
        ]
        const isJobActiveAndComplete = job.active && job.current_step === -1
        if (!isJobActiveAndComplete) formSteps.push({ value: PUBLICATION_STEP, isValid: false })

        return formSteps
    }
    const initVideoUrl = () => {
        if (job.video_url) return job.video_url

        const locale = RouteMap.GetLocaleFromUrl()
        return company[`main_video_url_${locale}`]
    }
    const initShowAddVideo = () => {
        if (typeof job.show_add_video === 'undefined') {
            const videoUrl = initVideoUrl()
            return videoUrl ? true : false
         } else {
            return job.show_add_video
         }
    }
    const initJobCompanyLogo = () => {
        return job.company_logo_cloudinary_key
            ? {
                cloudinary_key: job.company_logo_cloudinary_key,
                name: job.company_logo_name,
                id: job.company_logo_id,
                format: job.company_logo_format,
                is_used: true
            }
            : null
    }
    const [jobForm, setJobForm] = useState({
        id: job.id,
        user: user,
        jobType: jobType,
        funnelType: funnelType,
        isActive: job.active,
        companyName: job.company_name || company.name || '',
        companySector: job.sector || company.sector || '',
        companySize: job.company_size || company.size || '',
        companyDescription: job.company_description || company.description || '',
        companyLogo: initJobCompanyLogo(),
        ...initAddressFields(),
        jobTitle: job.title || '',
        studentJobSchedule: job.job_schedule || [],
        jobDescription: job.description || '',
        jobMainImage: job.images || [], // INFO: images defined as plural in backend but single image in reality
        jobVideoUrl: initVideoUrl(),
        jobShowAddVideo: initShowAddVideo(),
        candidateSearchedProfile: job.searched_profile || '',
        weOffer: job.we_offer || '',
        studentJobStartDate: job.start_date,
        studentRequest: job.student_request || [],
        candidateStudyDomains: job.study_domain || [],
        candidateQuestions: job.questions || [],
        fr: job.fr || false,
        nl: job.nl || false,
        en: true, // INFO: business decision to have all jobs visible on english version of website
        applicationType: job.application_type || 'email',
        applicationEmail: job.application_email || user.email,
        applicationUrl: job.application_url || '',
        candidacyEmailSetting: job.candidacy_email_setting || 'batch_per_day',
        askMotivationLetter: job.motivation_letter || false,
        chatOnly: job.chat_only || true,
    })
    const initIsBuyingCredits = () => {
        if (user.gold_licence) return false
        if (user.silver_licence && !(jobType === FIRST_JOB)) return false
        if (jobType === FIRST_JOB) return user.number_of_credits < 10

        return user.number_of_credits < 1
    }
    const [steps, setSteps] = useState(() => initSteps())
    const [currentStep, setCurrentStep] = useState(ESSENTIAL_STEP)
    const [currentStepIndex, setCurrentStepIndex] = useState()
    const [isBuyingCredits, setIsBuyingCredits] = useState(() => initIsBuyingCredits())

    useEffect(() => {
        if (funnelType != FUNNEL_EDIT) return

        const updatedSteps = steps.map(step => {
            const [isStepValid] = validateStep(step.value, jobForm)
            return { ...step, isValid: isStepValid}
        })
        const firstInvalidStep = updatedSteps.find(step => !step.isValid)?.value
        const firstStep = updatedSteps[0].value

        setCurrentStep(firstInvalidStep || firstStep)
        setSteps(updatedSteps)
    }, [])

    useEffect(() => {
        const updatedStepIndex = steps.map(step => step.value).indexOf(currentStep)
        setCurrentStepIndex(updatedStepIndex)
    }, [currentStep])

    useEffect(() => {
        const errorsPresent = Object.keys(errors).length > 0
        if (errorsPresent) scrollToError()
    }, [errors])

    const handleStepChange = (selectedStep) => {
        if (funnelType === FUNNEL_NEW) return
        if (selectedStep === currentStep) return

        const selectedStepIndex = steps.map(step => step.value).indexOf(selectedStep)
        const isSelectedStepValid = steps[selectedStepIndex].isValid
        if (!isSelectedStepValid) return

        const [isCurrentStepValid, formErrors] = validateStep(currentStep, jobForm)
        if (selectedStepIndex < currentStepIndex) {
            if (currentStep === PUBLICATION_STEP) {
                setCurrentStep(selectedStep)
            } else if (isCurrentStepValid) {
                setIsloading(true)
                createOrUpdateJob().then(() => {
                    setIsloading(false)
                    setErrors({})
                    scrollToTop()
                    const updatedSteps = steps.map(step => step.value === currentStep ? { ...step, isValid: true } : step)
                    setSteps(updatedSteps)
                    setCurrentStep(selectedStep)
                }).catch((error) => {
                    handleBackendError(error)
                })
            } else {
                setErrors(formErrors)
            }
        } else if (selectedStepIndex > currentStepIndex) {
            if (isCurrentStepValid) {
                setIsloading(true)
                createOrUpdateJob().then(() => {
                    setIsloading(false)
                    setErrors({})
                    scrollToTop()
                    const updatedSteps = steps.map(step => step.value === currentStep ? { ...step, isValid: true } : step)
                    setSteps(updatedSteps)
                    setCurrentStep(selectedStep)
                }).catch((error) => {
                    handleBackendError(error)
                })
            } else {
                setErrors(formErrors)
            }
        }
    }

    const handleBack = () => {
        const [isCurrentStepValid, formErrors] = validateStep(currentStep, jobForm)

        if (funnelType === FUNNEL_NEW) {
            moveOneStep(DIRECTION_BACK)
        } else if (currentStep === PUBLICATION_STEP) {
            moveOneStep(DIRECTION_BACK)
        } else if (isCurrentStepValid) {
            setIsloading(true)
            createOrUpdateJob().then(() => {
                setIsloading(false)
                setErrors({})
                scrollToTop()
                moveOneStep(DIRECTION_BACK)
            }).catch((error) => {
                handleBackendError(error)
            })
        } else {
            setErrors(formErrors)
        }
    }

    const moveOneStep = (direction) => {
        const isLastStep = currentStepIndex + 1 === steps.length
        if (isLastStep && direction === DIRECTION_FORWARD) return

        let nextStepIndex = currentStepIndex
        if (direction === DIRECTION_FORWARD) { nextStepIndex = currentStepIndex + 1 }
        if (direction === DIRECTION_BACK) { nextStepIndex = currentStepIndex - 1 }
        const newCurrentStep = steps[nextStepIndex].value
        setCurrentStep(newCurrentStep)
    }

    const handleSubmit = () => {
        const [isCurrentStepValid, formErrors] = validateStep(currentStep, jobForm)

        if (isCurrentStepValid) {
            setIsloading(true)
            if (currentStep === ESSENTIAL_STEP || currentStep === EXTRA_STEP) {
                updateCompanyInfo()
            }
            createOrUpdateJob().then(() => {
                setIsloading(false)
                setErrors({})
                scrollToTop()
                const isLastStep = currentStepIndex + 1 === steps.length

                if (isLastStep) {
                    window.location.href = RouteMap.Page('users/company_jobs')
                } else {
                    moveOneStep(DIRECTION_FORWARD)
                    const updatedSteps = steps.map(step => step.value === currentStep ? { ...step, isValid: true } : step)
                    setSteps(updatedSteps)
                }
            }).catch((error) => {
                handleBackendError(error)
            })
        } else {
            setErrors(formErrors)
        }
    }

    const handleBackendError = (error) => {
        setIsloading(false)
        const backendErrors = error.response.data.errors || {}
        setErrors(backendErrors)
        Bugsnag.notify(error)
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setJobForm({
            ...jobForm,
            [name]: value
        })
    }

    const handleUpdate = (updatedAttributes) => {
        setJobForm({
            ...jobForm,
            ...updatedAttributes
        })
    }

    const handleAttributeChange = (attribute, value) => {
        setJobForm({
            ...jobForm,
            [attribute]: value
        })
    }

    const handleCompanyLogoUpdate = (newCompanyLogo) => {
        setJobForm({
            ...jobForm,
            companyLogo: newCompanyLogo
        })
    }

    const handleJobMainImageUpdate = (newJobMainImage) => {
        setJobForm({
            ...jobForm,
            jobMainImage: newJobMainImage
        })
    }

    const isTextEmpty = (text) => {
        return (!text || text === '' || text === '<p><br></p>')
    }

    const createOrUpdateJob = () => {
        const data = formatJobDataForCurrentStep()
        const isNewJob = !jobForm.id

        if (isNewJob) {
            return JobAPI.createJob(data).then(response => {
                setJobForm({ ...jobForm, id: response.data.job.id })
                return response
            }).then(response => {
                return JobAPI.updateJob({ ...data, id: response.data.job.id })
            })
        } else {
            return JobAPI.updateJob(data)
        }
    }

    const updateCompanyInfo = () => {
        const companySectorEmpty = isTextEmpty(company.sector)
        const companySizeEmpty = isTextEmpty(company.size)
        const companyDescriptionEmpty = isTextEmpty(company.description)
        const companyLogoEmpty = !company.logo_id
        const companyAddressEmpty = isTextEmpty(company.address.city) || !company.address.zip || isTextEmpty(company.address.street) || isTextEmpty(company.address.country)
        if (!companySectorEmpty && !companySizeEmpty && !companyDescriptionEmpty && !companyLogoEmpty && !companyAddressEmpty) return

        let data = { id: company.id }
        if (companySectorEmpty) data.sector = jobForm.companySector
        if (companySizeEmpty) data.size = jobForm.companySize
        if (companyDescriptionEmpty) data['description_' + RouteMap.GetLocaleFromUrl()] = jobForm.companyDescription
        if (companyLogoEmpty) data.logo_id = jobForm.companyLogo?.id
        if (companyAddressEmpty) {
            data.street = jobForm.jobStreetName
            data.zip = jobForm.jobZip
            data.city = jobForm.jobCity
            data.country = jobForm.jobCountry
        }
        axios({
            method: 'patch',
            url: '/api/v1/users/companies',
            data: data
        }).then((_) => {
            console.log('Updated company')
        }, (error) => {
            Bugsnag.notify(error)
        })
    }

    const payWithCredits = () => {
        axios({
            url: '/api/v1/users/payment_with_credits',
            method: 'post',
            data: {
                job_id: jobForm.id,
                job_type: jobType,
                package_name: `${jobType.replace('-', '_')}-1`
            }
        }).then((_) => {
            window.location.href = RouteMap.Page('users/company_jobs?session_id=payWithCredits')
        }).catch((error) => {
            setFeedback({
                ...feedback,
                open: true,
                message: `Error occured while posting job: ${error}`,
                severity: 'error'
            })
        })
    }

    const formatJobDataForCurrentStep = () => {
        const baseData = {
            // :study_level, ❌
            id: jobForm.id,
            type: jobType,
            current_step: currentStepIndex,
            current_step_name: currentStep,
        }
        if (currentStep === ESSENTIAL_STEP) {
            return {
                ...baseData,
                company_name: jobForm.companyName,
                sector: jobForm.companySector,
                company_size: jobForm.companySize,
                company_logo_id: jobForm.companyLogo?.id,
                street: jobForm.jobStreetName,
                zip: jobForm.jobZip,
                city: jobForm.jobCity,
                country: jobForm.jobCountry,
                job_schedule: jobForm.studentJobSchedule,
                title: jobForm.jobTitle,
                description: jobForm.jobDescription,
                show_add_video: jobForm.jobShowAddVideo,
                video_url: jobForm.jobVideoUrl,
                images: jobForm.jobMainImage,
            }
        } else if (currentStep === EXTRA_STEP) {
            return ({
                ...baseData,
                company_description: jobForm.companyDescription,
                searched_profile: jobForm.candidateSearchedProfile,
                we_offer: jobForm.weOffer,
                start_date: jobForm.studentJobStartDate,
                student_request: jobForm.studentRequest,
                study_domain: jobForm.candidateStudyDomains,
                questions: jobForm.candidateQuestions
            })
        } else if (currentStep === SETTINGS_STEP) {
            let settingsData = {
                fr: jobForm.fr,
                nl: jobForm.nl,
                en: true,
                application_type: jobForm.applicationType,
                candidacy_email_setting: jobForm.candidacyEmailSetting,
                chat_only: jobForm.chatOnly,
            }
            if (jobForm.applicationType === 'email') {
                settingsData.application_email = jobForm.applicationEmail
                settingsData.motivation_letter = jobForm.askMotivationLetter
            } else if (jobForm.applicationType === 'url') {
                settingsData.application_url = jobForm.applicationUrl.trim()
            }
            return ({
                ...baseData,
                ...settingsData
            })
        }
    }

    return (
		<PageWrapper user={user} disableFooter>
            <JobCreationFunnelWrapper>
                <FeedbackSnackbar
                    open={feedback.open}
                    message={feedback.message}
                    severity={feedback.severity}
                    closeFeedback={closeFeedback}
                    anchor={feedback.anchor}
                />
                <FunnelOverviewWrapper>
                    <FunnelTableOfContents
                        handleStepChange={handleStepChange}
                        steps={steps}
                        currentStep={currentStep}
                        navigationBlocked={funnelType === FUNNEL_NEW}
                    />
                </FunnelOverviewWrapper>
                <FunnelContentWrapper>
                    <Box ref={topOfFormRef} />
                    <FunnelFormWrapper>
                        <JobFormFactory
                            currentStep={currentStep}
                            jobForm={jobForm}
                            company={company}
                            errors={errors}
                            handleChange={handleChange}
                            handleUpdate={handleUpdate}
                            handleAttributeChange={handleAttributeChange}
                            updateMainImage={handleJobMainImageUpdate}
                            updateCompanyLogo={handleCompanyLogoUpdate}
                            updateIsBuyingCredits={setIsBuyingCredits}
                        />
                    </FunnelFormWrapper>
                    <FunnelSubmitBar
                        handleBack={handleBack}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        stepsTotal={steps.length}
                        currentStep={currentStep}
                        currentStepIndex={currentStepIndex}
                        isBuyingCredits={isBuyingCredits}
                        payWithCredits={payWithCredits}
                    />
                </FunnelContentWrapper>
            </JobCreationFunnelWrapper>
        </PageWrapper>
    )
}

export default StudentJobFunnel
