import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        '& canvas': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none'
        }
    },
    recruitmentStatusDropdown: {
        display: 'flex',
        justifyContent: 'space-between', 
        padding: '8px 12px !important',
        borderRadius: 50,
        backgroundColor: 'var(--beige)',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 250,
        },
    },
    recruitmentStatusSelected: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        fontSize: 14
    },
    recruitmentStatusOptions: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    dropdownContainer: {
        marginLeft: 20
    },
    overrideSelect: {
        padding: 0
    }
}))
