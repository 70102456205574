import React from 'react'
import { Box, Grid, TextField, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import ReactQuill from 'react-quill'

import CollectionHandler from 'assets/CollectionHandler.js'
import { useStyles } from './FirstJobForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import TagSingleSelect from 'shared/components/TagSingleSelect/TagSingleSelect'
import DropdownSingleSelect from 'shared/components/DropdownSingleSelect/DropdownSingleSelect'
import { FIRST_JOB, INTERNSHIP, QUILL_MODULES } from '../../../settings'
import UploadImageV2 from 'shared/modals/UploadImageV2/UploadImageV2'

const FirstJobForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleChange, handleUpdate, handleAttributeChange, updateMainImage } = props

    const jobRegimeCollection = CollectionHandler.Get('regimeCollection')
    const contractTypeCollection = CollectionHandler.Get('contractTypeCollection')
    const departmentCollection = CollectionHandler.Get('departmentCollection')
    const internshipDurationCollection = CollectionHandler.Get('internshipDurationCollection')

    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        handleUpdate({ [attribute]: formattedItems })
    }

    const handleRadioChange = (event) => {
        const { name, value } = event.target
        const booleanValue = value === 'true'
        handleAttributeChange(name, booleanValue)
    }

    return (
        <Box className={sharedClasses.formContainer}>
            {/* Function title */}
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_title" />} />
                <FormattedMessage id="jobcreationfunnel.placeholders.job_title">
                    {placeholder =>
                        <TextField
                            name='jobTitle'
                            value={jobForm.jobTitle}
                            onChange={handleChange}
                            InputProps={{ classes: { input: `${sharedClasses.textField} ${sharedClasses.shortField}` }, disableUnderline: true }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                <FieldError errorMessage={errors.jobTitle} />
                <FieldDivider />
            </Box>
            {/* Function type */}
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_department" />} />
                <DropdownSingleSelect
                    value={jobForm.jobDepartment}
                    name='jobDepartment'
                    collection={departmentCollection}
                    handleChange={handleChange}
                    handleAttributeChange={handleAttributeChange}
                    placeholder={<FormattedMessage id="jobcreationfunnel.placeholders.job_department" />}
                />
                <FieldError errorMessage={errors.jobDepartment} />
                <FieldDivider />
            </Box>
            {/* Address */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_location" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="jobcreationfunnel.sub_titles.job_location" />} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_street" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobStreetName'
                            value={jobForm.jobStreetName}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobStreetName} />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_zip" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobZip'
                            value={jobForm.jobZip}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobZip} />
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_city" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobCity'
                            value={jobForm.jobCity}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobCity} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.address_country" />} className={classes.locationSubtitle} />
                        <TextField
                            name='jobCountry'
                            value={jobForm.jobCountry}
                            onChange={handleChange}
                            classes={{ root: sharedClasses.maxWidth }}
                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                        />
                        <FieldError errorMessage={errors.jobCountry} />
                    </Grid>
                </Grid>
                <FieldDivider />
            </Box>
            {jobForm.jobType === FIRST_JOB && <>
                <Box className={sharedClasses.formField}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_contract_type" />} />
                    <TagSingleSelect
                        value={jobForm.jobContractType}
                        collection={contractTypeCollection}
                        setValue={(newValue) => handleAttributeChange('jobContractType', newValue)}
                    />
                    <FieldError errorMessage={errors.jobContractType} />
                    <FieldDivider />
                </Box>
                <Box className={sharedClasses.formField}>
                    <Box className={classes.titles}>
                        <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.traineeship" />} />
                    </Box>
                    <Switch
                        name='graduate_program'
                        classes={{ root: sharedClasses.iosSwitch }}
                        checked={jobForm.graduate_program}
                        value={jobForm.graduate_program}
                        onChange={() => handleAttributeChange('graduate_program', !jobForm.graduate_program)}
                    />
                    <FieldDivider />
                </Box>
            </>}
            {/* Regime */}
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_regime" />} />
                <TagMultiSelect
                    collection={jobRegimeCollection}
                    selectedItems={jobForm.jobRegime.map(regime => { return { value: regime } })}
                    setSelectedItems={(items) => handleSelectedItems(items, 'jobRegime')}
                />
                <FieldError errorMessage={errors.jobRegime} />
                <FieldDivider />
            </Box>
            {/* Interhsip duration */}
            {jobForm.jobType === INTERNSHIP &&
                <Box className={sharedClasses.formField}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.internship_duration" />} />
                    <TagSingleSelect
                        value={jobForm.internshipLength}
                        collection={internshipDurationCollection}
                        setValue={(newValue) => handleAttributeChange('internshipLength', newValue)}
                    />
                    <FieldError errorMessage={errors.internshipLength} />
                    <FieldDivider />
                </Box>
            }
            {/* Function description */}
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_description" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.jobDescription || ''}
                        name="jobDescription"
                        onChange={(value) => handleAttributeChange('jobDescription', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.jobDescription} />
                <FieldDivider />
            </Box>
            {/* Video or image */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.job_video_or_image" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_2.media_sub_title" />} />
                </Box>
                <Box className={classes.videoOrImageContainer}>
                    <RadioGroup
                        name="jobShowAddVideo"
                        defaultValue={'true'}
                        value={jobForm.jobShowAddVideo?.toString()}
                        onChange={handleRadioChange}
                    >
                        <Box className={classes.selectionContainer}>
                            <Box className={classes.addImage}>
                                <FormControlLabel
                                    value={'false'}
                                    control={<Radio />}
                                    label={<FormattedMessage id="job_form.step_2.media_photo_title" />}
                                />
                                <FieldSubtitle
                                    subtitle={<FormattedMessage id="job_form.step_2.media_photo_sub_title" />}
                                    className={classes.lightSubtitle}
                                />
                                <UploadImageV2
                                    image={jobForm.jobMainImage[0]}
                                    setImage={(res) => updateMainImage([res])}
                                    isLarge
                                />
                            </Box>
                            <Box className={classes.addVideo}>
                                <FormControlLabel
                                    value={'true'}
                                    control={<Radio />}
                                    label={<FormattedMessage id="job_form.step_2.media_video_title" />}
                                />
                                <FieldSubtitle
                                    subtitle={<FormattedMessage id="job_form.step_2.media_video_sub_title" />}
                                    className={classes.lightSubtitle}
                                />
                                <FormattedMessage id="job_form.step_2.media_video_placeholder">
                                    {placeholder =>
                                        <TextField
                                            name='jobVideoUrl'
                                            value={jobForm.jobVideoUrl}
                                            onChange={handleChange}
                                            classes={{ root: sharedClasses.maxWidth }}
                                            placeholder={placeholder}
                                            InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                                        />
                                    }
                                </FormattedMessage>
                            </Box>
                        </Box>
                    </RadioGroup>
                </Box>
            </Box>

        </Box>
    )
}

export default FirstJobForm
