/* eslint-disable react/prop-types */
import React from 'react'

import EmployerJobIndexSearchBarView from './EmployerJobIndexSearchBarView'

const EmployerJobIndexSearchBar = (props) => {
    const { filters, setFilters, searchParams, getCompanyJobs } = props

    const reset = () => {
        setFilters({ title: '', type: '', page: 1, orderDirection: 0, orderKey: 'applied_date' })
    }

    const handleFilterChange = (event) => {
        const { name, value } = event.target
        setFilters({
            ...filters,
            [name]: value
        })
    }
    const onEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            getCompanyJobs()
        }
    }

    return (
        <EmployerJobIndexSearchBarView
            reset={reset}
            search={getCompanyJobs}
            filters={filters}
            handleFilterChange={handleFilterChange}
            searchParams={searchParams}
            onEnterKeyPress={onEnterKeyPress}
        />
    )
}

export default EmployerJobIndexSearchBar
