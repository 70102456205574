/* eslint-disable react/prop-types */
import React from 'react'
import ResponsiveVideo from 'components/ResponsiveVideo/ResponsiveVideo'
import Box from '@material-ui/core/Box'
import { useStyles } from './JobShowImage_style'
import { Image, Transformation } from 'cloudinary-react'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

const JobShowImage = (props) => {
    const { job, showVideo } = props
    const classes = useStyles()

    if (showVideo) {
        return <ResponsiveVideo videoUrl={job.video_url}/>
    } else {
        return (
            <Box>
                <Image
                    cloudName="studentbe"
                    publicId={job.job_show_cloudinary_key}
                    alt={job.job_show_cloudinary_key}
                    className={classes.image}
                >
                    <Transformation flags="progressive" fetchFormat="auto" quality="auto:best" width={MEDIUM_SIZE} crop="fill" />
                </Image>
            </Box>
        )
    }
}

export default JobShowImage
