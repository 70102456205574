export const useStyles = theme => ({
    provCards: {
        [theme.breakpoints.up("md")]: {
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    provCard: {
        minWidth: '30%',
        "& ul": {
            listStyleType: 'none',
            paddingRight: '40px',
            paddingBottom: '20px',
            fontSize: '16px',
            "& li": {
                marginBottom: '10px',
                letterSpacing: '.7px',
                "& a": {
                    color: 'rgba(68, 68, 68, .88)',
                    "&:hover": {
                        color: 'var(--black)'
                    }
                }
            }
        },

        [theme.breakpoints.up("sm")]: {
            marginBottom: '20px',
        },
        [theme.breakpoints.down("sm")]: {
            textAlign: 'center',
            maxWidth: '450px',
            margin: '0 auto',
            marginBottom: '30px'
        }
    },
    cityRegion: {
        padding: '40px 40px 20px',
        letterSpacing: '3px'
    }
})
