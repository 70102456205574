export const useGalleryImageFormatting = (images, onLoad) => {
    if (!images || images.length < 0) return []

    const imageSpecs = "c_fill,f_auto,fl_progressive,h_400,q_auto:eco"
    return images.map((image) => {
        return {
            src: `https://res.cloudinary.com/studentbe/image/upload/${imageSpecs}/${image.cloudinary_key}`,
            width: image.width,
            height: image.height,
            onLoad: onLoad
        }
    })
}
