import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        '& #bottomBorder': {
            width: '100%',
            height: 2,
            marginTop: -2,
            borderRadius: 20,
            backgroundColor: 'var(--beige)',
            transition: '0.3s ease-in-out'
        },
        '&:hover': {
            '& #bottomBorder': {
                height: 4,
                marginTop: -4,
                backgroundColor: 'var(--red)'
            }
        }
    },
    bottomBorder: {
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12
    },
    readTimeAndDate: {
        display: 'flex',
        alignItems: 'center'
    },
    minorText: {
        fontSize: '12px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    bullet: {
        margin: '0 4px',
        fontSize: '8px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    articleTitle: {
        fontSize: '16px !important',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 12,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    }
}))
