/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import { useStyles } from './HomeMyBS_style'
import {
    Container,
    Box,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core'

import FilledButton from 'shared/buttons/FilledButton/FilledButton'
import HomeMyBSHelmet from 'helmets/HomeMyBSHelmet'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import RecentArticlesSlideDefault from 'containers/RecentArticlesSlideshow/RecentArticlesSlideDefault/RecentArticlesSlideDefault'
import RouteMapLink from 'shared/wrappers/RouteMapLink/RouteMapLink'

import { Image, Transformation } from 'cloudinary-react'
import { optimiseSizeForMobile, MEDIUM_SIZE, LARGER_SIZE } from 'assets/CloudinaryURLHelper'

const HomeMyBS = (props) => {
    const classes = useStyles()
    const { user } = props

    const renderSchool = (schoolCloudinaryKey, url) => {
        return (
            <Grid item xs={6} sm={3} className={classes.partnerImageBox}>
                <RouteMapLink redirectionUrl={url} target='blank'>
                    <Image
                        className={classes.partnerImage}
                        cloudName="studentbe"
                        publicId={`my_bs/${schoolCloudinaryKey}`}
                        alt={`my BS busines school ${schoolCloudinaryKey}`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                    </Image>
                </RouteMapLink>
            </Grid>
        )
    }

    return (
        <PageWrapper user={user}>
            <HomeMyBSHelmet />
            <Container maxWidth='md' >
                <Box className={classes.scheduleContainer}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} className={classes.imageContainer}>
                            <Image
                                cloudName="studentbe"
                                publicId='my_bs/my-business-school-event'
                                alt='My Business School Event'
                                className={classes.imageStyle}
                            >
                                <Transformation
                                    flags="progressive"
                                    fetchFormat="auto"
                                    quality="auto:best"
                                    width={optimiseSizeForMobile(LARGER_SIZE)}
                                    crop="scale"
                                />
                            </Image>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component='h1' className={classes.titleEvent}>
                                myBS Event
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='h1' component='h2'>
                                        📅 Date
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h3'>
                                        8th of November in Leuven:
                                    </Typography>
                                    <Typography variant='body1' className={classes.subListLeft}>
                                        - Get-to-knows and Open Fair at Zaal Couvreur, Agora
                                    </Typography>
                                    <Typography variant='body1' className={classes.subListLeft}>
                                        - Presentations at Auditorium Emma Vorlat
                                    </Typography>
                                    <Typography variant='h3'>
                                        9th of November in Ghent:
                                    </Typography>
                                    <Typography variant='body1' className={classes.subListLeft}>
                                        - Get-to-knows and Open Fair at Foyer, Campus Tweekerken
                                    </Typography>
                                    <Typography variant='body1' className={classes.subListLeft}>
                                        - Presentations at Auditorium Maurice Van Vaerenbergh
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='h1' component='h2'>
                                        🕘 Schedule
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='h3'>
                                        12h-16h Get-to-knows
                                    </Typography>
                                    <Typography variant='h3'>
                                        16h-17h Presentation of the business schools
                                    </Typography>
                                    <Typography variant='h3'>
                                        17h-19h Open Fair (no registration needed)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h1' component='h2'>
                                        myBS November Edition
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>
                                        <span className={classes.spanBold}>Get-to-Know Interviews:</span> This will provide a unique opportunity for you to meet with the business school(s) you are interested in on a personal level and find out more about their courses, get advice on how to apply and learn about scholarship opportunities. From 12 to 4 PM.
                                    </Typography>
                                    <Typography variant='body1'>
                                        <span className={classes.spanBold}>Presentation of the business schools:</span> Each participating school will have a dedicated slot to present themselves. This is a fantastic chance to discover what sets the schools apart from eachother and what makes them unique in the educational landscape. From 4 to 5 PM.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FilledButton
                                        className='register-button-mybs'
                                        name='Register for the event'
                                        color='secondary'
                                        href='https://bit.ly/3REWoVz'
                                        target='_blank'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} className={classes.partnerBox}>
                                {renderSchool('Vlerick_Business_School', 'https://www.vlerick.com/en/ ')}
                                {renderSchool('Solvay_Brussels_School_of_Economics_and_Management', 'https://www.solvay.edu/en/ ')}
                                {renderSchool('Insead', 'https://www.insead.edu/ ')}
                                {renderSchool('Ie-Business-School-logo', 'https://www.ie.edu/business-school/ ')}
                                {renderSchool('Hult_International_Business_School_3e3154', 'https://www.hult.edu/ ')}
                                {renderSchool('Esade_Business_School', 'https://www.esade.edu/bachelor/en ')}
                                {renderSchool('AMS_Antwerp_Management_School', 'https://www.antwerpmanagementschool.be/en')}
                                {renderSchool('ESCP_Business_School', 'https://www.escp.eu/ ')}
                                {renderSchool('Bocconi_University', 'https://www.unibocconi.eu/wps/wcm/connect/bocconi/sitopubblico_en/navigation+tree/home')}

                                {renderSchool('skema', 'https://www.skema.edu/')}
                                {renderSchool('eada', 'https://www.eada.edu/en')}
                                {renderSchool('hec', 'https://www.hec.edu/en')}
                                {renderSchool('sommet-education', 'https://www.sommet-education.com/')}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' component='div'>
                                    * *Bocconi will only participate to the event in Leuven.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h1' component='h2'>
                                        Why participate?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body1'>
                                        myBS is the largest business school event of Belgium. This is your chance to meet the world's top business schools from the most popular study destinations. Talk to career advisors and business school representatives who provide all necessary information on courses, admission criteria, applying processes and career prospects after graduation.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <FilledButton
                                        className='register-button-mybs'
                                        name='Register for the event'
                                        color='secondary'
                                        href='https://bit.ly/3REWoVz'
                                        target='_blank'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <RecentArticlesSlideDefault
                                category='education'
                                nbrItems={6}
                                title='Readers also enjoyed'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant='h1' component='h2'>
                                        Questions about the event
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion bgColor='primary'>
                                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                            <Typography color='primary' variant='body1'>
                                                What should I wear during the event?
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                There is no official dress code, but it is recommanded to wear something business casual. That way, you will make a good first impression 💪.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                            <Typography variant='body1'>
                                                Should I prepare something for the Get-to-Knows?
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                No stress 😅 ! It's in the name ... You’ll just “get to know” the schools. To prepare yourself you can take a look on their website. Maybe you have a general question about their program? Or you have a more specific question concerning the scholarships? It’s recommended to bring your cv.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                            <Typography variant='body1'>
                                                What if I'm not sure yet about my business school?
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                If you are hesitating between schools it’s recommended to make an appointment with all schools you are interested in. That way, it will be easier to compare 🧐.
                                                In case you aren’t even sure about studying at a business school at all, it’s highly advised to follow the presentation, from 16h until 17h.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                                            <Typography variant='body1'>
                                                How will the presentation at 16h look like?
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                First of all, all schools will present themselves briefly. Afterwards there is a panel discussion between the schools where you’ll be able to ask some questions 💡.
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' component='div'>
                                        💌 If none of the above has answered your question, please contact margaux@student.be
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </PageWrapper>
    )
}

export default HomeMyBS
