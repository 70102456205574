import React, { useContext } from 'react'
import { Box, Typography, Slider } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './ProfileEditForm_style'
import ProfileEditFormHelper from './helpers/ProfileEditFormHelper'
import ProfileEditFormStepIntro from './components/ProfileEditFormStepIntro/ProfileEditFormStepIntro'
import ProfileEditFormStepName from './components/ProfileEditFormStepName/ProfileEditFormStepName'
import ProfileEditFormStepStudyExperience from './components/ProfileEditFormStepStudyExperience/ProfileEditFormStepStudyExperience'
import ProfileEditFormStepSearchStatus from './components/ProfileEditFormStepSearchStatus/ProfileEditFormStepSearchStatus'
import ProfileEditFormStepLocation from './components/ProfileEditFormStepLocation/ProfileEditFormStepLocation'
import { UserContext } from 'contexts/UserContext'

const ProfileEditFormView = (props) => {
    const { shortProfile, setShortProfile, setExperiences, step, handleStepChange, nextStep, previousStep, highlightInputField } = props
    const classes = useStyles()
    const userContext = useContext(UserContext)

    const ThumbComponent = (thumbComponentProps) => {
        return <span {...thumbComponentProps} className={`${classes.sliderThumb} ${step === 4 && classes.sliderThumbEnd}`}>
            <Typography variant='h4' component='span'>
                <FormattedMessage id={`personal_dashboard.profile_edit_form.progress_bar.${step}`} />
            </Typography>
        </span>
    }

    return (
        <Box className={classes.containerBox}>
            <Box className={classes.percentageBox}>
                <Typography variant='h6' className={classes.percentageNumber}>
                    {ProfileEditFormHelper.calculatePercentage(shortProfile, userContext.statusConfig)}
                </Typography>
                <Typography variant='h3' className={classes.percentageText}>
                    <FormattedMessage id='personal_dashboard.profile_edit_form.percentage_text' />
                </Typography>
            </Box>
            <Box className={classes.contentBox}>
                <Slider
                    className={classes.slider}
                    value={step}
                    onChange={handleStepChange}
                    aria-labelledby='discrete-slider'
                    valueLabelDisplay='auto'
                    step={1}
                    min={0}
                    max={ProfileEditFormHelper.MAX_STEP}
                    marks={ProfileEditFormHelper.STEP_MARKS}
                    ThumbComponent={ThumbComponent}
                />
                {step === 0 && <ProfileEditFormStepIntro step={step} nextStep={nextStep}/>}
                {step === 1 && <ProfileEditFormStepName shortProfile={shortProfile} setShortProfile={setShortProfile} step={step} nextStep={nextStep} previousStep={previousStep}/>}
                {step === 2 && <ProfileEditFormStepStudyExperience shortProfile={shortProfile} setExperiences={setExperiences} step={step} nextStep={nextStep} previousStep={previousStep} highlightInputField={highlightInputField}/>}
                {step === 3 && <ProfileEditFormStepSearchStatus step={step} nextStep={nextStep} previousStep={previousStep}/>}
                {step === 4 && <ProfileEditFormStepLocation shortProfile={shortProfile} setShortProfile={setShortProfile} step={step} nextStep={nextStep} previousStep={previousStep} highlightInputField={highlightInputField}/>}
            </Box>
        </Box>
    )
}
export default ProfileEditFormView
