/* eslint-disable react/prop-types */

import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import AboutKotOwnersHelmet from 'helmets/AboutKotOwnersHelmet'

import FilledButton from 'buttons/FilledButton/FilledButton'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import PageWrapper from '../../shared/wrappers/PageWrapper/PageWrapper'
import CardSlider from 'components/CardSlider/CardSlider'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import { Image, Transformation } from 'cloudinary-react'
import RouteMap from 'assets/RouteMap'

class AboutKotOwners extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
    }

    createPageContent () {
        const { classes, user } = this.props
        const callToActionUrl = user ? 'users/kots/new' : 'login'
        const redirectUrl = user ? null : `?redirect=${RouteMap.Page('users/kots/new')}`
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="landing_about_kot_owners.title" />
                        </Typography>
                    </Container>
                </Box>
                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box className={classes.kotBackground}>
                        <Image
                            cloudName="studentbe"
                            publicId='assets/kot-owner-about-banner'
                            alt='Kot banner'
                        >
                            <Transformation flags="progressive" fetchFormat="auto" quality="auto:best" />
                        </Image>
                    </Box>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_kot_owners.subtitle" /></Typography>
                        <Typography><FormattedMessage id="landing_about_kot_owners.description" /></Typography>
                    </Box>
                    <RouteMapLink page={callToActionUrl} paramString={redirectUrl}>
                        <FilledButton
                            className={classes.kotButton}
                            color="secondary"
                            name={<FormattedMessage id="landing_about_kot_owners.post_a_kot" />}
                        />
                    </RouteMapLink>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_kot_owners.publish_kot.title" /></Typography>
                        <Box className={classes.listContainer}>
                            <Typography>
                                <FormattedMessage id="landing_about_kot_owners.publish_kot.step_1.title" />
                                <RouteMapLink page='login' className={classes.studentFont}>
                                    <FormattedMessage id="landing_about_kot_owners.publish_kot.step_1.here" />
                                </RouteMapLink>
                            </Typography>
                            <Typography><FormattedMessage id="landing_about_kot_owners.publish_kot.step_2" /></Typography>
                            <Typography><FormattedMessage id="landing_about_kot_owners.publish_kot.step_3" /></Typography>
                            <Typography><FormattedMessage id="landing_about_kot_owners.publish_kot.step_4" /></Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_kot_owners.ads_online.title" /></Typography>
                        <Box className={classes.breaklines}>
                            <Typography><FormattedMessage id="landing_about_kot_owners.ads_online.step_1" /></Typography>
                            <Typography><FormattedMessage id="landing_about_kot_owners.ads_online.step_2" /></Typography>
                        </Box>
                    </Box>
                    <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_kot_owners.wish_interested_students" /></Typography>
                    <RouteMapLink page={callToActionUrl} paramString={redirectUrl}>
                        <FilledButton
                            className={classes.kotButton}
                            color="secondary"
                            name={<FormattedMessage id="landing_about_kot_owners.post_a_kot" />}
                        />
                    </RouteMapLink>
                </Container>
            </>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <PageWrapper user={this.props.user}>
                <AboutKotOwnersHelmet />
                {this.createPageContent()}
                <Box my={5}>
                    <CardSlider
                        cards={this.props.recentArticles ? this.props.recentArticles : []}
                        maxItem="3"
                        type="article"
                        showLine
                    />
                </Box>
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(AboutKotOwners)
