import React, { useContext } from 'react'
import RecommendedJobsForUser from '../RecommendedJobsForUser/RecommendedJobsForUser'
import RecommendedKotsForUser from '../RecommendedKotsForUser/RecommendedKotsForUser'
import { Box } from '@material-ui/core'
import { useStyles } from '../../PersonalDashboard_style'
import { UserContext } from 'contexts/UserContext'
import RecommendedJobsForUserSectionCTA from './RecommendedJobsForUserSectionCTA'
import PersonalDashboardRenderHelper from '../../PersonalDashboardRenderHelper.js'

const RecommendedJobsForUserSection = (props) => {
    const { openProfileEditFormOnStep } = props
    const classes = useStyles()
    const userContext = useContext(UserContext)

    return (<>
        {PersonalDashboardRenderHelper.shouldRenderFirstJobs(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedJobsForUser
                    key='first_job'
                    componentType='first_job'
                    type='first_job'
                    user={userContext.user}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderInternshipForSchoolCTA(userContext) &&
            <Box className={`${classes.jobSectionBox} ${classes.ctaBox}`}>
                <RecommendedJobsForUserSectionCTA
                    openOnStep={2}
                    titleId='personal_dashboard.recommend_jobs.internships_for_user_school_title'
                    textId='personal_dashboard.recommend_jobs.internships_for_user_school_text'
                    openProfileEditFormOnStep={openProfileEditFormOnStep}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderInternshipForLocationCTA(userContext) &&
            <Box className={`${classes.jobSectionBox} ${classes.ctaBox}`}>
                <RecommendedJobsForUserSectionCTA
                    openOnStep={4}
                    titleId='personal_dashboard.recommend_jobs.internships_for_user_location_title'
                    textId='personal_dashboard.recommend_jobs.internships_for_user_location_text'
                    openProfileEditFormOnStep={openProfileEditFormOnStep}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderInternshipsForUserLocation(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedJobsForUser
                    key='internships_for_user_location'
                    componentType='internships_for_user_location'
                    type='internship'
                    user={userContext.user}
                    textValues={{ city: userContext.user.city }}
                    location={userContext.user.city}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderInternshipsForSchoolLocation(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedJobsForUser
                    key='internships_for_user_school'
                    componentType='internships_for_user_school'
                    type='internship'
                    user={userContext.user}
                    textValues={{ school: userContext.user.current_experience.school_location_name }}
                    location={userContext.user.current_experience.school_location}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderStudentJobsForSchoolLocationCTA(userContext) &&
            <Box className={`${classes.jobSectionBox} ${classes.ctaBox}`}>
                <RecommendedJobsForUserSectionCTA
                    openOnStep={2}
                    titleId='personal_dashboard.recommend_jobs.student_jobs_for_user_school_title'
                    textId='personal_dashboard.recommend_jobs.student_jobs_for_user_school_text'
                    openProfileEditFormOnStep={openProfileEditFormOnStep}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderStudentJobsForUserLocationCTA(userContext) &&
            <Box className={`${classes.jobSectionBox} ${classes.ctaBox}`}>
                <RecommendedJobsForUserSectionCTA
                    openOnStep={4}
                    titleId='personal_dashboard.recommend_jobs.student_jobs_for_user_location_title'
                    textId='personal_dashboard.recommend_jobs.student_jobs_for_user_location_text'
                    openProfileEditFormOnStep={openProfileEditFormOnStep}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderStudentJobsForUserLocation(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedJobsForUser
                    key='student_jobs_for_user_location'
                    componentType='student_jobs_for_user_location'
                    type='student_job'
                    user={userContext.user}
                    textValues={{ city: userContext.user.city }}
                    location={userContext.user.city}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderStudentJobsForSchoolLocation(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedJobsForUser
                    key='student_jobs_for_user_school'
                    componentType='student_jobs_for_user_school'
                    type='student_job'
                    user={userContext.user}
                    textValues={{ school: userContext.user.current_experience.school_location_name }}
                    location={userContext.user.current_experience.school_location}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderKotsForSchoolLocationCTA(userContext) &&
            <Box className={`${classes.jobSectionBox} ${classes.ctaBox}`}>
                <RecommendedJobsForUserSectionCTA
                    openOnStep={2}
                    titleId='personal_dashboard.recommend_jobs.kots_for_user_school_title'
                    textId='personal_dashboard.recommend_jobs.kots_for_user_school_text'
                    openProfileEditFormOnStep={openProfileEditFormOnStep}
                />
            </Box>
        }
        {PersonalDashboardRenderHelper.shouldRenderKots(userContext) &&
            <Box className={classes.jobSectionBox}>
                <RecommendedKotsForUser
                    key='recommended_kots_for_user'
                    user={userContext.user}
                    textValues={{ school: userContext.user.current_experience.school_location_name }}
                    location={userContext.user.current_experience.school_location}
                />
            </Box>
        }
    </>)
}

export default RecommendedJobsForUserSection
