import React from 'react'

// import css from './studentheadhuntcard.scss'

import ReactStars from 'react-stars'

// *** NEW IMPORTS ***

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './StudentHeadhuntCard_css'
import { FormattedMessage } from 'react-intl'

import DateHandler from 'assets/DateHandler'
import CollectionHandler from 'assets/CollectionHandler.js'
import IconFactory from 'icons/IconFactory/IconFactory'

class StudentHeadHuntCard extends React.Component {
    constructor (props) {
        super(props)
        this.checkRef = React.createRef()
        this.divRef = React.createRef()
        this.renderCheckBox = this.renderCheckBox.bind(this)
        this.studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    }

    renderGender () {
        var res = ''
        switch (this.props.studentProfile.gender) {
        case 'Male':
        case 'm':
            res = 'Male'
            break
        case 'Female':
        case 'f':
            res = 'Femme'
            break
        case 'Other':
        case 'other':
            res = 'Autre'
            break
        }
        return res
    }

    renderStudyLevel () {
        let res = ''
        switch (this.props.studentProfile.study_level) {
        case 'master_60_degree':
        case 'master_120_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'master')
            break
        case 'advanced_master_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'advanced_master')
            break
        case 'phd_degree':
            res = CollectionHandler.Translate('studyLevelForCardsCollection', 'phd')
            break
        default:
            res = CollectionHandler.Translate('studyLevelForCardsCollection', this.props.studentProfile.study_level)
        }
        return res
    }

    renderDistance () {
        var res
        if (this.props.studentProfile.distance < 1.0) {
            res = '< 1'
        } else {
            res = this.props.studentProfile.distance.toString().substring(0, 4)
        }
        return res
    }

    renderStudentStatus () {
        var res
        if (this.props.studentProfile.status === 'invited') {
            res = <FormattedMessage id="headhunt.status.waiting" />
        } else if (this.props.studentProfile.status === 'interested') {
            res = <FormattedMessage id="headhunt.status.interested" />
        } else if (this.props.studentProfile.status === 'not_interested') {
            res = <FormattedMessage id="headhunt.status.not_interested" />
        } else {
            res = <FormattedMessage id="headhunt.status.not_invited" />
        }
        return res
    }

    renderStatusStyle () {
        const { classes } = this.props

        var res
        switch (this.props.studentProfile.status) {
        case 'invited':
            res = classes.statusWaiting
            break
        case 'interested':
            res = classes.statusInterested
            break
        case 'not_interested':
            res = classes.statusNotInt
            break
        default:
            res = classes.statusNotInv
        }
        return `${classes.statusContainer} ${res}`
    }

    renderFieldOfStudy () {
        var res

        for (var i = 1; i < this.studyDomainCollection.length; i++) {
            if (this.studyDomainCollection[i].value === this.props.studentProfile.study_domain) {
                res = this.studyDomainCollection[i].name
            }
        }
        if (!res) {
            res = '/'
        }

        return res
    }

    renderFieldOfStudyMobile () {
        var res

        for (var i = 1; i < this.studyDomainCollection.length; i++) {
            if (this.studyDomainCollection[i].value === this.props.studentProfile.study_domain) {
                res = `${this.studyDomainCollection[i].name} (${this.renderStudyLevel()})`
            }
        }
        if (!res) {
            res = this.renderStudyLevel()
        }

        return res
    }

    renderLanguagesLevel (langLevel) {
        var res = (
            <ReactStars
                count={4}
                value={langLevel}
                size={13}
                half={false}
                color2={'var(--internship-color)'}
                color1={'#ECECEC'}
                edit={false}
            />
        )
        return res
    }

    renderStudentName () {
        const { classes } = this.props
        if (this.props.studentProfile.status === 'interested') {
            return (
                <Typography
                    variant='body2'
                    color='primary'
                >
                    {this.props.studentProfile.initials.split('-')[0].toUpperCase() + ' ' + this.props.studentProfile.initials.split('-')[1]}
                </Typography>
            )
        } else {
            return (
                <Typography variant='body2' className={`${classes.initialsContainer}`}>
                    {this.props.studentProfile.initials}
                </Typography >
            )
        }
    }

    renderIconsAction () {
        const { classes } = this.props

        return (
            <Box className={classes.iconsActionContainer}>
                {
                    this.props.studentProfile.status === 'interested' &&
                    <Tooltip title={"Inviter l'étudiant"}>
                        <IconButton
                            aria-label="MessageToStudent"
                            color="primary"
                            style={{ width: 45, height: 45 }}
                            onClick={() => {
                                window.location.href = `${window.location.protocol}//${window.location.host}
                                /employer
                                /Message
                                /${encodeURIComponent(this.props.studentProfile.job_name)}
                                /${this.props.studentProfile.candidacy_id}
                                /StudentJob
                                /${this.props.studentProfile.conversation_id}`
                            }}
                        >
                            <IconFactory icon='chat'/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    this.props.studentProfile.cv_file &&
                    <Tooltip title={'Voir son CV'}>
                        <IconButton
                            aria-label="MessageToStudent"
                            color="primary"
                            style={{ width: 45, height: 45 }}
                            onClick={() => {
                                window.location.href = this.props.studentProfile.cv_file.asset_url
                            }}
                        >
                            <IconFactory icon='chat'/>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        )
    }

    renderCheckBox () {
        const { classes } = this.props
        let res = null
        if(this.props.studentProfile.status === 'not_invited') {
            res = <Checkbox
                icon={<IconFactory icon='checkbox-false' fontSize='1rem'/>}
                checkedIcon={<IconFactory icon='checkbox-true' fontSize='1rem' />}
                color='primary'
                className={classes.checkBox}
                ref={this.checkRef}
                onClick={() => this.props.onCheck(this.props.forwardRef, this.props.studentProfile)}
            />
        } else {
            res = <Box className={classes.checkBox}></Box>
        }
        return res
    }

    render () {
        const { classes } = this.props

        return (
            <Box style={{ width: '100%' }}>
                <Hidden mdDown>
                    <Box style={{ display: 'flex', marginBottom: 10, width: '100%', alignItems: 'center' }}>
                        {this.renderCheckBox()}
                        <Card className={classes.cardContainer} style={{marginRight: (this.props.studentProfile.status === 'interested' ? 0 : '45px')}}>
                            <Box className={this.renderStatusStyle()}>
                                <Typography
                                    variant='body2'
                                    className={classes.statusTypography}
                                >
                                    {this.renderStudentStatus()}
                                </Typography>
                            </Box>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            <FormattedMessage id="job_results.items_card.last_online" />
                                            {
                                                this.props.studentProfile.last_online
                                                    ? ' ' + DateHandler.ToBasicFormat(this.props.studentProfile.last_online)
                                                    : ' /'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                            color='primary'
                                        >
                                            {this.renderStudentName()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {this.renderFieldOfStudy()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {this.renderStudyLevel()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {
                                                this.props.studentProfile.age
                                                    ? this.props.studentProfile.age + ' ans'
                                                    : '/'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {
                                                this.props.studentProfile.gender
                                                    ? this.renderGender()
                                                    : 'N'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {this.renderDistance()} km
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography
                                            variant='body2'
                                            className={classes.cardTypography}
                                        >
                                            {this.props.studentProfile.driving_licence
                                                ? <div className="icon">
                                                    <IconFactory
                                                        icon='car'
                                                        className="driving-license-icon"
                                                    />
                                                </div>
                                                : '/'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer}`}
                                        >
                                            <Box className={classes.langTypography}>{'FR'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.fr)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer}`}
                                        >
                                            <Box className={classes.langTypography}>{'NL'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.nl)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer}`}
                                        >
                                            <Box className={classes.langTypography}>{'EN'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.en)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box display='flex' alignItems='center'>
                                            <IconFactory icon='phone' style={{ height: 15, width: 'auto', marginRight: 10 }} color='primary'/>
                                            <Typography
                                                variant='body2'
                                                className={classes.cardTypography}
                                            >
                                                {this.props.studentProfile.phone_number ? this.props.studentProfile.phone_number : '/'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        {this.renderIconsAction()}
                    </Box>
                </Hidden>
                <Hidden lgUp>
                    <Box style={{ display: 'flex', marginBottom: 15, width: '100%', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        {this.renderCheckBox()}
                        <Card className={classes.cardContainer}>
                            <Box className={this.renderStatusStyle()}>
                                <Typography
                                    variant='body2'
                                    className={classes.statusTypography}
                                >
                                    {this.renderStudentStatus()}
                                </Typography>
                            </Box>
                            <CardContent className={classes.cardContent}>
                                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={8}>
                                        <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                            {this.renderStudentName()}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.dateTypography}`}
                                        >
                                            <FormattedMessage id="job_results.items_card.last_online" />
                                            {
                                                this.props.studentProfile.last_online
                                                    ? ' ' + DateHandler.ToBasicFormat(this.props.studentProfile.last_online)
                                                    : ' /'
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={10}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.cardFieldStudy}
                                                >
                                                    {this.renderFieldOfStudyMobile()}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} className={classes.infoContainer}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.cardTypography}
                                                >
                                                    {
                                                        this.props.studentProfile.gender
                                                            ? this.renderGender()
                                                            : 'N'
                                                    }
                                                </Typography>
                                                <Divider orientation='vertical' />
                                            </Grid>
                                            <Grid item xs={3} className={classes.infoContainer}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.cardTypography}
                                                >
                                                    {
                                                        this.props.studentProfile.age
                                                            ? this.props.studentProfile.age + ' ans'
                                                            : '/'
                                                    }
                                                </Typography>
                                                <Divider orientation='vertical' />
                                            </Grid>

                                            <Grid item xs={4} className={classes.infoContainer}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.cardTypography}
                                                >
                                                    {this.renderDistance()} km
                                                </Typography>
                                                <Divider orientation='vertical' />
                                            </Grid>
                                            <Grid item xs={2} className={classes.infoContainer}>
                                                <Typography
                                                    variant='body2'
                                                    className={classes.cardTypography}
                                                >
                                                    {this.props.studentProfile.driving_licence
                                                        ? <div className="icon">
                                                            <IconFactory
                                                                icon='car'
                                                                className="driving-license-icon"
                                                            />
                                                        </div>
                                                        : '/'
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }} >
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer}`}
                                        >
                                            <Box className={classes.langTypography}>{'FR'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.fr)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer} ${classes.langMiddle}`}
                                        >
                                            <Box className={classes.langTypography}>{'NL'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.nl)}
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            className={`${classes.cardTypography} ${classes.langTypographyContainer}`}
                                        >
                                            <Box className={classes.langTypography}>{'EN'}</Box>
                                            {this.renderLanguagesLevel(this.props.studentProfile.languages.en)}
                                        </Typography>
                                    </Grid>
                                    {
                                        this.props.studentProfile.status === 'interested' &&
                                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                            <IconFactory icon='phone' style={{ height: 15, width: 'auto', marginRight: 5 }} color='primary'/>
                                            <Typography
                                                variant='body2'
                                                className={classes.cardPhoneNumber}
                                            >
                                                {
                                                    this.props.studentProfile.phone_number
                                                        ? this.props.studentProfile.phone_number
                                                        : '/'
                                                }
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                    </Box>
                    {this.renderIconsAction()}
                </Hidden>
            </Box>
        )
    }
}

export default withStyles(useStyles)(StudentHeadHuntCard)
