import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'
import { Box, Container, Typography, Divider, Grid } from '@material-ui/core'

import { useStyles } from './HomeEmployerPage_style'
import PageWrapper from '../../shared/wrappers/PageWrapper/PageWrapper'
import HomeEmployerPageHelmet from 'helmets/HomeEmployerPageHelmet'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'
import { usePipedrive, CHAT_EMPLOYER_GENERAL } from 'hooks/usePipedrive'
import HomeStats from '../HomeStudent/components/HomeStats/HomeStats'
import EmployerFindOutMore from 'shared/components/EmployerFindOutMore/EmployerFindOutMore'
import DateHandler from 'assets/DateHandler'

const HomeEmployerPage = (props) => {
	const { user, employerArticles } = props
    const classes = useStyles()
    const [employerBrandingInfoToggle, setEmployerBrandingInfoToggle] = useState(false)
    const [advertisingInfoToggle, setAdvertisingInfoToggle] = useState(false)
    const [jobPostingInfoToggle, setJobPostingInfoToggle] = useState(false)

    useEffect(() => {
        const { instantiateChat } = usePipedrive()
        instantiateChat(CHAT_EMPLOYER_GENERAL)
    }, [])

    const partners = [
        { imageId: 'Deloitte_Logo_Black', name: 'deloitte-belgium' },
        { imageId: 'toogoodtogo_2', name: 'too-good-to-go' },
        { imageId: 'bpost', name: 'bpost' },
        { imageId: 'Vlaamse_Overheid_logo_Student.be_jobs_Stages_studentenjobs', name: 'vlaamse-overheid' },
        { imageId: 'gsk', name: 'gsk' },
    ]
    const jobPostingUrl = user ? RouteMap.Page('employer/new-ad') : RouteMap.Page('payment')

    return (
        <PageWrapper user={user} disableFooterLists>
			<HomeEmployerPageHelmet />
            {/* BANNER */}
			<Box className={classes.banner}>
				<Container maxWidth='lg' className={classes.bannerContainer}>
					<Typography variant='h6' component='h1' className={classes.pageTitle}>
						<FormattedMessage id="landing_home_employer_page.title" />
					</Typography>
                    <Image
                        className={classes.horizontalArrow}
                        cloudName='studentbe'
                        publicId='assets/arrow_banner'
                        alt='arrow banner'
                    />
					<Box className={classes.bannerCTA}>
                        <RouteMapLink redirectionUrl={jobPostingUrl}>
                            <FilledButton
                                newStyle
                                name={<FormattedMessage id='publish_a_student_job.post_a_job_button' />}
                                color='secondary'
                                size='small'
                                className={`${classes.button} ${classes.squeezeText}`}
                                typoVariant='p'
                                forceWrap
                            />
                        </RouteMapLink>
					</Box>
				</Container>
			</Box>
            {/* WHY US */}
			<Box className={classes.whyUs}>
				<Container maxWidth='lg' className={classes.whyUsContainer}>
                    <Box className={classes.flexCenter}>
                        <Typography variant='h6' component='h2' className={classes.pageTitlFullWidth}>
                            <FormattedMessage id="landing_home_employer_page.why_us.title" />
                        </Typography>
                        <FormattedMessage  id="landing_home_employer_page.why_us.subtitle_html">
                            {translatedText =>
                                <Typography variant='body1' component='p' className={classes.pageSubTitle} dangerouslySetInnerHTML={{ __html: translatedText }}/>
                            }
                        </FormattedMessage>
                    </Box>
                    <Box className={classes.whyUsStats}>
                        <Box className={classes.flexCenter}>
                            <IconFactory className={classes.statIconSmall} icon='speaker'/>
                            <Box>
                                <Typography variant="h2" className={classes.stat}><FormattedMessage id='landing_home_employer_page.why_us_stats.youngsters_in_db_number'/></Typography>
                                <Typography variant="body1" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.youngsters_in_db' />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.whyUsStat}>
                            <IconFactory className={classes.statIconSmall} icon='speaker'/>
                            <Box>
                                <Typography variant="h2" className={classes.stat}>51.000</Typography>
                                <Typography variant="body1" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.application_per_month' />
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.whyUsStat}>
                            <IconFactory className={classes.statIconSmall} icon='speaker'/>
                            <Box>
                                <Typography variant="h2" className={classes.stat}>10.000</Typography>
                                <Typography variant="body1" className={classes.statDescription}>
                                    <FormattedMessage id='landing_home_employer_page.why_us_stats.visitors_per_day' />
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
				</Container>
			</Box>
            {/* WHAT WE OFFER */}
            <Box className={classes.whatWeOffer}>
                <Container maxWidth='lg' className={classes.whyUsContainer}>
                    <Box className={classes.flexCenter}>
                        <Typography variant='h6' component='h2' className={classes.pageTitlFullWidth}>
                            <FormattedMessage  id="landing_home_employer_page.what_we_offer.title" />
                        </Typography>
                        <FormattedMessage  id="landing_home_employer_page.what_we_offer.subtitle_html">
                            {translatedText => <Typography variant='body1' component='p' className={classes.pageSubTitle} dangerouslySetInnerHTML={{ __html: translatedText }}/>}
                        </FormattedMessage>
                    </Box>
                    <Box className={classes.offers}>
                        {/* EMPLOYER BRANDING */}
                        <Box className={classes.offer}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/employer_branding'
                                alt='student.be employer branding offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                            <Box className={classes.offerText}>
                                <Typography variant='h4' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.employer_branding.title" />
                                </Typography>
                                <Typography variant='h6' component='h4' className={classes.offerTagline}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.employer_branding.tagling" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.employer_branding.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setEmployerBrandingInfoToggle(!employerBrandingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldRed}>+</span>
                                            <FormattedMessage  id="landing_home_employer_page.what_we_offer.offers.how" />
                                        </Typography>
                                        {employerBrandingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography>
                                                <FormattedMessage  id="landing_home_employer_page.what_we_offer.employer_branding.how_explanation" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer' >
                                        <Box className={classes.offerCTA}>
                                            <Typography className={classes.offerExplanationTitle}>
                                                <span className={classes.boldRed}>→</span>
                                                <FormattedMessage id="landing_home_employer_page.what_we_offer.offers.contact_us" />
                                            </Typography>
                                        </Box>
                                    </RouteMapLink>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {/* ADVERTISEMENT */}
                        <Box className={`${classes.offer} ${classes.offerMobileReverse}`}>
                            <Box className={classes.offerText}>
                                <Typography variant='h4' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.advertisement.title" />
                                </Typography>
                                <Typography variant='h6' component='h4' className={classes.offerTagline}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.advertisement.tagling" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.advertisement.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setAdvertisingInfoToggle(!advertisingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldRed}>+</span>
                                            <FormattedMessage  id="landing_home_employer_page.what_we_offer.offers.how" />
                                        </Typography>
                                        {advertisingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography>
                                                <FormattedMessage  id="landing_home_employer_page.what_we_offer.advertisement.how_explanation" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer'>
                                        <Box className={classes.offerCTA}>
                                            <Typography className={classes.offerExplanationTitle}>
                                                <span className={classes.boldRed}>→</span>
                                                <FormattedMessage  id="landing_home_employer_page.what_we_offer.offers.contact_us" />
                                            </Typography>
                                        </Box>
                                    </RouteMapLink>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                </Box>
                            </Box>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/advertisement'
                                alt='student.be employer branding offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                        </Box>
                        {/* JOB POSTING */}
                        <Box className={classes.offer}>
                            <Image
                                cloudName='studentbe'
                                publicId='assets/employer_job_posting'
                                alt='student.be job posting offer.'
                                className={classes.offerImage}
                            >
                                <Transformation flags='progressive' fetchFormat='auto' quality='auto:good' crop='fill' />
                            </Image>
                            <Box className={classes.offerText}>
                                <Typography variant='h4' component='h3' className={classes.offerTitle}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.job_posting.title" />
                                </Typography>
                                <Typography variant='h6' component='h4' className={classes.offerTagline}>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.job_posting.tagling" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage  id="landing_home_employer_page.what_we_offer.job_posting.explanation" />
                                </Typography>
                                <Box mt={1}>
                                    <Box className={classes.expandingText} onClick={() => setJobPostingInfoToggle(!jobPostingInfoToggle)}>
                                        <Typography className={classes.offerExplanationTitle}>
                                            <span className={classes.boldRed}>+</span>
                                            <FormattedMessage  id="landing_home_employer_page.what_we_offer.offers.advantages" />
                                        </Typography>
                                        {jobPostingInfoToggle && <Box ml={2} mt={1}>
                                            <Typography>
                                                <FormattedMessage  id="landing_home_employer_page.what_we_offer.job_posting.advantages" />
                                            </Typography>
                                        </Box>}
                                    </Box>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                    <RouteMapLink page='contact-employer' >
                                        <Box className={classes.offerCTA}>
                                            <Typography className={classes.offerExplanationTitle}>
                                                <span className={classes.boldRed}>→</span>
                                                <FormattedMessage  id="landing_home_employer_page.what_we_offer.offers.contact_us" />
                                            </Typography>
                                        </Box>
                                    </RouteMapLink>
                                    <Box className={classes.divider}>
                                        <Divider />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            {/* EMPLOYER FIND OUT MORE */}
            <EmployerFindOutMore imageId='assets/employer_find_out_more' context="HomeEmployerPage"/>
            {/* COMPANIES ON OUR PLATFORM */}
            <Box className={classes.theyTrustUs}>
                <Container maxWidth="lg">
                    <Box className={classes.flexCenter}>
                        <Typography variant="h6" className={classes.pageTitlFullWidth}><FormattedMessage id="landing_employer.they_trust_us.title" /></Typography>
                        <Typography className={classes.pageSubTitle}><FormattedMessage id="landing_employer.they_trust_us.subtitle" /></Typography>
                    </Box>
                    <Grid container justifyContent="center" spacing={2} className={classes.partnerBox}>
                        {partners.map(partner =>
                            <Grid key={partner.imageId} item xs={4} sm={2} spacing={0} className={classes.partnerImageBox}>
                                <RouteMapLink showItemType={'company'} showItemUrl={partner.name} target={'_blank'}>
                                    <Image
                                        className={classes.partnerImage}
                                        cloudName="studentbe"
                                        publicId={`partner-logo/${partner.imageId}`}
                                        alt={`partner image ${partner.imageId}`}
                                    >
                                        <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:best" crop="fill" />
                                    </Image>
                                </RouteMapLink>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </Box>
            {/* EMPLOYER ARTICLES */}
            <Box className={classes.employerArticles}>
                <Container maxWidth='lg' className={classes.employerArticlesContainer}>
                    <Box className={classes.e}>
                        <Typography variant='h6' component='h4' className={classes.employerArticlesTitle}><FormattedMessage id="landing_employer.articles_title" /></Typography>
                        <Typography className={classes.pageSubTitle}><FormattedMessage id="landing_employer.articles_subtitle" /></Typography>
                    </Box>
                    <Box className={classes.articles}>
                        {employerArticles.map(article => {
                            return (
                                <RouteMapLink target="_blank" showItemType='article' showItemUrl={article.url} className={classes.articleCard}>
                                    <Box>
                                        <Typography variant='subtitle1' className={classes.biggerFont}>
                                            {DateHandler.ToBasicFormat(article.publication_date)}
                                        </Typography>
                                        <Typography variant='h6' component='h4' className={classes.articleCardTitle}>
                                            {article.title}
                                        </Typography>
                                    </Box>
                                    <Box className={classes.articleCardDescription}>
                                        <Typography variant='subtitle1' className={classes.biggerFont}>
                                            {article.meta_description}
                                        </Typography>
                                        <Typography className={classes.readMore}>
                                            Lire plus
                                        </Typography>
                                    </Box>
                                </RouteMapLink>
                            )
                        })}
                    </Box>
                    <Box className={classes.employerArticleCTA}>
                        <RouteMapLink articleCategory='employer'>
                            <FilledButton
                                newStyle
                                name={<FormattedMessage id='item_index.show_more_button' />}
                                color='secondary'
                                size='small'
                                className={`${classes.button} ${classes.squeezeText}`}
                                typoVariant='p'
                                forceWrap
                            />
                        </RouteMapLink>
					</Box>
                </Container>
            </Box>
            {/* STUDENT IN NUMBERS */}
            <Container maxWidth='lg'>
                <HomeStats />
            </Container>
		</PageWrapper>
    )
}

export default HomeEmployerPage
