import React from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeBanner_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import HomeCitySearch from '../HomeCitySearch/HomeCitySearch'

const HomeBanner = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.homeBanner}>
            <Hidden smDown>
                <Box className={classes.bannerLeft}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/homebanner.jpg'
                        alt='homebanner'
                        className={classes.homeBannerImage}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width={700} crop='fill' />
                    </Image>
                    <Box className={classes.bannerLeftTextContainer}>
                        <Box className={classes.bannerLeftTitleContainer}>
                            <Typography
                                variant="h6"
                                component="h1"
                                className={classes.bannerLeftTitle}
                            >
                                Hello future,<br />
                                let's talk
                            </Typography>
                        </Box>
                        <Box className={classes.bannerLeftDescriptionContainer}>
                            <Typography
                                variant="h3"
                                component="h2"
                                className={classes.bannerLeftDescription}
                            >
                                <FormattedMessage id="landing_student.banner_left_description" />
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.arrowContainer}>
                    <Image
                        cloudName='studentbe'
                        publicId='assets/arrow_banner.jpg'
                        alt='arrowbanner'
                        className={classes.arrow}
                    >
                        <Transformation width={200} flags='progressive' fetchFormat='auto' quality='auto:best' />
                    </Image>
                </Box>
            </Hidden>
            <Box className={classes.bannerRight}>
                <HomeCitySearch />
            </Box>
        </Box>
    )
}
export default HomeBanner
