/* eslint-disable react/prop-types */
import React from 'react'
import { Image } from 'cloudinary-react'

import { useStyles } from './FiltersWrapper_style'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import IndexTitle from 'shared/components/IndexTitle/IndexTitle'

const FiltersWrapper = ({ children, ...props }) => {
    const { type, selectedLocationTag } = props
    const classes = useStyles()

    return (
        <Box className={classes.backgroundContainer}>
            <Box className={classes.fullBackground}>
                <Box className={classes.leftBackground} />
                <Box className={classes.rightBackground} />
            </Box>
            <Container maxWidth='lg'>
                <Box className={classes.filterContainer}>
                    <Box className={classes.leftContainer}>
                        <IndexTitle
                            selectedLocationTag={selectedLocationTag}
                            type={type}
                        />
                        <Hidden mdDown>
                            <Image
                                className={classes.horizontalArrow}
                                cloudName='studentbe'
                                publicId='assets/arrow-horizontal-layout'
                                alt='arrow-horizontal-layout'
                            />
                        </Hidden>
                    </Box>
                    <Box className={type === 'student_job' ? `${classes.rightContainer} ${classes.studentJobPadding}` : `${classes.rightContainer}`}>
                        { children }
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default FiltersWrapper
