import React, { useEffect } from 'react'
import CompanyProfileFormPage from '../../pages/user/CompanyProfileForm/CompanyProfileFormPage'

const AdminEditCompany = (props) => {
    return (
        <CompanyProfileFormPage
            user={props.user}
            profile={props.profile}
            company={props.company}
            userType={props.userType}
            admin={true}
            disableFooter={true}
            disableNavbar={true}
            companies={props.companies}
            childCompanies={props.childCompanies}
        />
    )
}
export default AdminEditCompany
