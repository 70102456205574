/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import LineButton from 'buttons/LineButton/LineButton'
import RouteMap from 'assets/RouteMap.js'
import { useStyles } from './LetsChatCard_style'
import { FormattedMessage } from 'react-intl'
import AnalyticsHelper from 'assets/AnalyticsHelper'

const LetsChatCard = (props) => {
    const { job, user, handleOpenChat, openLoginRegisterModal, unavailable, openChat } = props
    const classes = useStyles()
    if (!job.chat_only && !job.alreadyApplied) return null
    if (job.application_url) return null
    if (!job.active) return null
    if (unavailable) return null
    if (user && ['employer', 'kot_owner'].includes(user.type)) return null

    const chatLink = RouteMap.Page(job.chat_url)
    let chatText = 'job_chat.button'
    let onClick = () => undefined
    if (user && user.type === 'student') {
        if (job.alreadyApplied || job.chatCreated) {
            onClick = () => { window.location.href = chatLink }
            chatText = 'transl_internship.view_chat'
        } else {
            onClick = () => {
                handleOpenChat(!openChat)
                AnalyticsHelper.sendGTMEvent('employer-chat-click')
            }
        }
    } else {
        onClick = () => {
            openLoginRegisterModal()
        }
    }

    return (
        <Box className={classes.letsChatContainer}>
            <Box className={classes.title}>
                <FormattedMessage id="job_chat.job_show_call_to_action" />
            </Box>
            <Box className={classes.bigTitle}>
                <FormattedMessage id="job_show.lets_chat.title" />
            </Box>
            <LineButton
                newStyle
                fullWidth
                onClick={onClick}
                className={classes.chatButton}
                name={<FormattedMessage id={chatText} />}
            />
        </Box>
    )
}

export default LetsChatCard
