import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    CardBoxShadow: {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 7px 0px rgba(0,0,0,0.12)'
    },
    checkBox: {
        color: 'var(--dark-grey)'
    },
    gridFlex: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignSelf: 'center'
    },
    formControl: {
        width: '100%'
    },
    iconColor: {
        color: 'var(--red)'
    },
    buttonOutlined: {
        color: 'var(--dark-grey)',
        borderColor: 'var(--dark-grey)',
        textTransform: 'capitalize'
    },
    textFont: {
        fontSize: '0.875rem'
    },
    date: {
        width: '100%'
    },
    additional_info: {
        flexWrap: 'inherit'
    },
    wordBreak: {
        wordBreak: 'break-word'
    }
}))
