import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12
    },
    button: {
        padding: 0,
        width: 32,
        height: 32,
        backgroundColor: 'var(--beige)'
    },
    buttonActive: {
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: '#2C2A28'
        }
    },
    icon: {
        color: 'black',
        fontSize: '14px !important'
    },
    iconActive: {
        color: 'white'
    },
}))
