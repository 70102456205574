import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    funnelOverview: {
        width: '30%',
        backgroundColor: 'var(--red)',
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            width: '30%',
            padding: 24
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '100%',
            padding: 16
        }
	},
}))
