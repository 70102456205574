import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    loginContainer: {
        margin: '50px auto',
        [theme.breakpoints.down('md')]: {
            padding: '0 !important'
        }
    },
    selectionTitle: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '0px !important'
        }
    },
    spacing: {
        borderBottom: '1px solid var(--grey)'
    },
    flexJustifyCenter: {
        display: 'flex !important',
        justifyContent: 'center'
    },
    flexEnd: {
        justifyContent: 'flex-end'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    textUnderline: {
        textDecoration: 'underline'
    },
    fieldTitle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxHeight: '20px'
    }
}))
