import React from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import DefaultHelmet from './DefaultHelmet'
import CollectionHandler from 'assets/CollectionHandler.js'
import URLHandler from 'assets/URLHandler.js'

class JobIndexHelmet extends React.Component {
    getHelmetTitle () {
        const { formatMessage } = this.props.intl
        let helmetTitleId = ''
        if (this.props.selectedLocationTag.type === 'location') {
            helmetTitleId = `helmet.${this.props.jobType}.city_index.title_count`
        } else if (this.props.selectedLocationTag.type === 'studyDomain') {
            helmetTitleId = `helmet.${this.props.jobType}.study_domain.title_count`
        } else if (this.props.selectedLocationTag.type === 'freeText') {
            helmetTitleId = `helmet.${this.props.jobType}.free_text.title_count`
        } else if (this.props.selectedLocationTag.type === 'quickFilter') {
            helmetTitleId = `item_offers.${this.props.jobType}.page_title_quick_filter.${this.props.selectedLocationTag.value}`
        } else if (this.props.selectedLocationTag.type === 'sector') {
            helmetTitleId = `item_offers.${this.props.jobType}.page_title_sector`
        } else {
            helmetTitleId = `helmet.${this.props.jobType}.index.title_count`
        }

        return formatMessage({ id: helmetTitleId }, { filter: this.props.selectedLocationTag.name, offer_count: this.props.resultsCount })
    }

    getHelmetDescription () {
        const { formatMessage } = this.props.intl
        let helmetDescriptionId = ''
        if (this.props.selectedLocationTag.type === 'location') {
            helmetDescriptionId = `helmet.${this.props.jobType}.city_index.description_new`
        } else if (this.props.selectedLocationTag.type === 'studyDomain') {
            helmetDescriptionId = `helmet.${this.props.jobType}.study_domain.description_new`
        } else if (this.props.selectedLocationTag.type === 'freeText') {
            helmetDescriptionId = `helmet.${this.props.jobType}.free_text.description_new`
        } else if (this.props.selectedLocationTag.type === 'quickFilter') {
            const seoTextQuickFilterCollection = CollectionHandler.Get('seoTextQuickFilterCollection')
            if (seoTextQuickFilterCollection[URLHandler.GetLocaleFromUrl()][this.props.jobType].includes(this.props.selectedLocationTag.value)) {
                helmetDescriptionId = `index_seo_text.${this.props.jobType}.quick_filter.${this.props.selectedLocationTag.value}`
            } else {
                return this.getStandardHelmetDescription()
            }
            const helmetDescription = formatMessage({ id: helmetDescriptionId }, { filter: this.props.selectedLocationTag.name })

            return this.removeHtmlTags(helmetDescription)
        } else {
            helmetDescriptionId = `helmet.${this.props.jobType}.index.description`
        }

        return formatMessage({ id: helmetDescriptionId }, { filter: this.props.selectedLocationTag.name })
    }

    removeHtmlTags (htmlString) {
        const tempElement = document.createElement('div')
        tempElement.innerHTML = htmlString

        return tempElement.textContent || tempElement.innerText || ''
    }

    getStandardHelmetTitle () {
        const { formatMessage } = this.props.intl
        return formatMessage({ id: `helmet.${this.props.jobType}.index.title_count` }, { offer_count: this.props.resultsCount })
    }

    getStandardHelmetDescription () {
        const { formatMessage } = this.props.intl
        return formatMessage({ id: `helmet.${this.props.jobType}.index.description` })
    }

    render () {
        return (<>
            {this.props.selectedLocationTag
                ? <DefaultHelmet
                    title={this.getHelmetTitle()}
                    description={this.getHelmetDescription()}
                />
                : <DefaultHelmet
                    title={this.getStandardHelmetTitle()}
                    description={this.getStandardHelmetDescription()}
                />
            }
            <Helmet>
                <link rel="canonical" href={this.props.altLangUrls[this.props.lang]} />
                <link rel="alternate" hrefLang="fr-BE" href={this.props.altLangUrls.fr} />
                <link rel="alternate" hrefLang="en-BE" href={this.props.altLangUrls.en} />
                <link rel="alternate" hrefLang="nl-BE" href={this.props.altLangUrls.nl} />
                <link rel="alternate" hrefLang="x-default" href={this.props.altLangUrls.en} />
            </Helmet>
        </>)
    }
}

export default injectIntl(JobIndexHelmet)
