import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Hidden, Typography } from '@material-ui/core'

import PageWrapper from 'shared/wrappers/PageWrapper/PageWrapper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMap from 'assets/RouteMap'
import { useStyles } from './PodcastPage_style'
import PodcastHelmet from '../../helmets/PodcastHelmet'

const episodes = {
    nl: [
        { number: 1, link: 'https://open.spotify.com/episode/1xhKidL8OULVULGiuiWnhB?si=7f634ca12c914d12' },
        { number: 2, link: 'https://open.spotify.com/episode/7pISXS5gwkmrLnTGB9gNSF?si=5422ca373a064574' },
        { number: 3, link: 'https://open.spotify.com/episode/5dRfq5g32AajxBLpjn9o9m?si=6b15198aa1e64de0' },
        { number: 4, link: 'https://open.spotify.com/episode/3BSzmPCntzqsCVtUnbYwFZ?si=f470997d59764b10' },
        { number: 5, link: 'https://open.spotify.com/episode/1O7q3PnAvbxCykgYfZUgYW?si=587209e654d44760' },
        { number: 6, link: 'https://open.spotify.com/episode/6f9uR5HZyNJJWQiYnSyPwk?si=8fa502f880b24038' },
        { number: 7, link: 'https://open.spotify.com/episode/78zvC6x2wDrQF9s7rAtZIE?si=a6dcf5cd45044d57' },
        { number: 8, link: 'https://open.spotify.com/episode/0s5pW6H4vPMAm7UqnlHbS9?si=b4907f539bef4c9e' },
        { number: 9, link: 'https://open.spotify.com/episode/0eixLA5eM9mU1NLZvmm9pS?si=277344bfdfbf4b38' },
        { number: 10, link: 'https://open.spotify.com/episode/0yp3T1HgRsgV1VtUBOSVdx?si=24ffc7e905f84dac' },
    ],
    fr: [
        { number: 1, link: 'https://open.spotify.com/episode/78eEn18Rl0qekMlzFhk5v2?si=c0273818157f44f8' },
        { number: 2, link: 'https://open.spotify.com/episode/27WXA0j4TZ7ICvcARY70Rs?si=443bb0df08d44b7d' },
        { number: 3, link: 'https://open.spotify.com/episode/1sVlXbNyUNSSXSveT2gSKS?si=834e011f3f094896' },
        { number: 4, link: 'https://open.spotify.com/episode/1uyI04HAFY3K0dkL63IqEV?si=d329d788389e440e' },
        { number: 5, link: 'https://open.spotify.com/episode/1tjjHdEiuCn9iFLDBS9pIE?si=3542560cbbc44f8f' },
        { number: 6, link: 'https://open.spotify.com/episode/7KizKoFgQckW0uA19tciOz?si=3ddebc9341eb4385' },
        { number: 7, link: 'https://open.spotify.com/episode/53xtXunr6pGo2AN5FJPuvS?si=12aaadb51a774f5c' },
        { number: 8, link: 'https://open.spotify.com/episode/1JTijUkhw6bW1PjBFVvJls?si=430f4d5452e14b22' },
    ],
    en: []
}

const PodcastPage = (props) => {
    const classes = useStyles()
    const [locale] = useState(RouteMap.GetLocaleFromUrl())
    const [selectedEpisode, setSelectedEpisode] = useState()

    const handleClick = (clickedEpisodeNumber) => {
        if (selectedEpisode != clickedEpisodeNumber) {
            setSelectedEpisode(clickedEpisodeNumber)
        } else {
            setSelectedEpisode()
        }
    }

    const getVideoSource = () => {
        switch (locale) {
        case 'nl':
        case 'en':
            return "https://res.cloudinary.com/studentbe/video/upload/v1678283138/assets/FINISHED_NL.mp4"
        case 'fr':
            return "https://res.cloudinary.com/studentbe/video/upload/v1678285384/assets/FINISHED_FR.mp4"
        }
    }

    const getVideoThumbnail = () => {
        switch (locale) {
        case 'nl':
        case 'en':
            return "https://res.cloudinary.com/studentbe/image/upload/v1678454522/assets/Thumbnail_NL.png"
        case 'fr':
            return "https://res.cloudinary.com/studentbe/image/upload/v1678454522/assets/Thumbnail_FR.png"
        }
    }

    const getSpotifyLink = () => {
        switch (locale) {
        case 'nl':
        case 'en':
            return "https://open.spotify.com/show/2GXp0LE0a6QhbD5YZNsNH2?si=1b9416b684504009"
        case 'fr':
            return "https://open.spotify.com/show/2QCo6zIPpIQhKRPSkQvvnU?si=535ff0f722e6490d"
        }
    }

    return (
        <PageWrapper>
            <PodcastHelmet />
            <Container maxWidth='lg'>
                <Box className={classes.main}>
                    <Box className={classes.leftSide}>
                        <Typography variant='h6' component='h1' className={classes.pageTitle}>
                            <FormattedMessage id='podcast_page.page_title_1' /><br />
                            <FormattedMessage id='podcast_page.page_title_2' />
                        </Typography>
                        <Box className={classes.divider} />
                        <Typography component='p' className={classes.body}>
                            <FormattedMessage id='podcast_page.body_paragraph_1' />
                        </Typography>
                        <Typography component='p' className={classes.body}>
                            <FormattedMessage id='podcast_page.body_paragraph_2' />
                            &nbsp;
                            <RouteMapLink page='first-jobs' target="_blank" rel="noopener noreferrer">
                                <span className={classes.highlight}><FormattedMessage id='podcast_page.body_paragraph_2_highlight' /></span>
                            </RouteMapLink>
                        </Typography>
                        <Hidden mdUp>
                            <Box className={classes.rightSide}>
                                <video controls width="350" className={classes.video} poster={getVideoThumbnail()}>
                                    <source src={getVideoSource()} type="video/mp4" />
                                </video>
                            </Box>
                        </Hidden>
                        <Box className={classes.faq}>
                            <RouteMapLink redirectionUrl={getSpotifyLink()} target="_blank" rel="noopener noreferrer">
                                <Typography variant='h6' component='h2' className={classes.faqTitle}>
                                    <FormattedMessage id='podcast_page.faq_title' />
                                </Typography>
                            </RouteMapLink>
                            {episodes[locale].map((episode) => {
                                return (
                                    <Accordion
                                        key={episode.number}
                                        expanded={selectedEpisode === episode.number}
                                        onClick={() => handleClick(episode.number)}
                                        classes={{root: classes.accordionRoot}}
                                    >
                                        <AccordionSummary expandIcon={<IconFactory icon='more' />} classes={{content: classes.faqQuestion}}>
                                            <Typography variant='h6' component='h3'>
                                                <FormattedMessage id={`podcast_page.faq_title_episode_${episode.number}`} />
                                            </Typography>
                                            &nbsp;&nbsp;
                                            <Typography variant='h2' component='p' className={classes.questionSubtitle}>
                                                <FormattedMessage id={`podcast_page.faq_title_episode_${episode.number}_subtitle`} />
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails classes={{root: classes.faqRoot}}>
                                            <Typography className={classes.faqAnswer}>
                                                <FormattedMessage id={`podcast_page.faq_answer_episode_${episode.number}`} />
                                            </Typography>
                                            <RouteMapLink redirectionUrl={episode.link} target="_blank" rel="noopener noreferrer">
                                                <Typography className={classes.faqCTA}>
                                                    <FormattedMessage id='podcast_page.faq_cta' />
                                                </Typography>
                                            </RouteMapLink>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })}
                            {locale === 'en' && <>
                                <Box my={2} className={classes.englishCTA}>
                                    <RouteMapLink redirectionUrl={getSpotifyLink()} target="_blank" rel="noopener noreferrer">
                                        <Typography className={classes.faqCTA}>
                                            Click here for the Dutch version
                                        </Typography>
                                    </RouteMapLink>
                                </Box>
                                <Box my={2} className={classes.englishCTA}>
                                    <RouteMapLink redirectionUrl={getSpotifyLink()} target="_blank" rel="noopener noreferrer">
                                        <Typography className={classes.faqCTA}>
                                            Click here for the French version
                                        </Typography>
                                    </RouteMapLink>
                                </Box>
                            </>}
                        </Box>
                    </Box>
                    <Hidden smDown>
                        <Box className={classes.rightSide}>
                            <video controls width="350" className={classes.video} poster={getVideoThumbnail()}>
                                <source src={getVideoSource()} type="video/mp4" />
                            </video>
                        </Box>
                    </Hidden>
                </Box>
            </Container>
        </PageWrapper>
    )
}

export default PodcastPage
