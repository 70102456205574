/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Select from '@material-ui/core/Select'
import Avatar from '@material-ui/core/Avatar'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import { useStyles } from 'components/Searchbar/Searchbar_v2_style'
import { FormattedMessage } from 'react-intl'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'

const MultipleSelectFilterView = (props) => {
    const { handleFiltersArray, type, filters, collection, filterTextId, width, showTags, handleTagChange, dropdownPosition, modal} = props
    const classes = useStyles()
    const [openDropdown, setOpenDropdown] = useState(false)

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: dropdownPosition || 'center'
            },
            transformOrigin: {
                vertical: -20,
                horizontal: dropdownPosition !== 'left' && (width / 2)
            },
            MenuListProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            },
            PaperProps: {
                style: {
                    borderRadius: 16,
                    maxHeight: 350,
                    width: width,
                }
            }
        })
    }

    const renderArrow = () => {
        if (filters[type].length) return null

        return <Box className={classes.dropdownContainer}>
            <IconFactory icon='down-solid' fontSize='12px' color='var(--dark-beige)' />
        </Box>
    }

    const renderSelectValue = (selected) => {
        if (selected.length === 0) {
            return (
                <Box className={classes.valueContainer}>
                    <em className={classes.placeholderText}><FormattedMessage id={filterTextId} /></em>
                    {renderArrow()}
                </Box>
            )
        }
        return (
            <Box className={classes.valueContainer}>
                <Box className={classes.tagText}><FormattedMessage id={filterTextId} /></Box>
                <Avatar classes={{ root: classes.tagAvatar }} >{selected.length}</Avatar>
            </Box>
        )
    }

    return (
        <Box >
            <FormControl>
                <Button className={`${classes.baseInput} ${modal ? classes.inputBackground : ''}`}>
                    <Select
                        multiple
                        value={filters[type]}
                        displayEmpty
                        onChange={(event) => { handleFiltersArray(type, event) } }
                        input={<Input className={classes.formControl}/>}
                        classes={{ root: classes.inputProps }}
                        disableUnderline
                        renderValue={(selected) => renderSelectValue(selected)}
                        MenuProps={ selectPaperStyle() }
                        IconComponent={() => <Box/>}
                        open={openDropdown}
                        onClose={() => setOpenDropdown(false)}
                        onOpen={() => setOpenDropdown(true)}
                    >
                        <Box onClick={() => setOpenDropdown(false)} className={classes.boxInputSearchCity}>
                            <MenuItem disabled value={null} />
                            <IconButton
                                aria-label='close'
                                className={classes.closeIconButton}
                            >
                                <IconFactory icon='close' />
                            </IconButton>
                        </Box>
                        {collection.map((item, key) => (
                            <MenuItem className={classes.menuItem} key={key} value={{ name: item.name, value: item.value }}>
                                <Checkbox
                                    disableRipple
                                    classes={{ root: classes.checkbox }}
                                    icon={<IconFactory icon='checkbox-false' fontSize='20px' />}
                                    checkedIcon={<IconFactory icon='checkbox-true-filled' color='var(--red)' fontSize='20px' />}
                                    color='primary'
                                    checked={filters[type].some(elem => elem.value === item.value)}
                                />
                                <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }}/>
                            </MenuItem>
                        ))}
                    </Select>
                </Button>
            </FormControl>
            {showTags &&
                <FilterTagList handleChange={handleTagChange} items={filters[type]}/>
            }
        </Box>
    )
}

export default MultipleSelectFilterView
