import CollectionHandler from 'assets/CollectionHandler'
import DateHandler from 'assets/DateHandler'
import isEqual from 'lodash.isequal'

const shouldRenderFirstJobs = (userContext) => {
    if (DateHandler.yearIsInCurrentAcademicYear(userContext.user.profile.expected_graduation_year)) return true

    return userContext.statusConfig.actively_looking_first_job
}

const shouldRenderInternshipForSchoolCTA = (userContext) => {
    return (
        userContext.statusConfig.actively_looking_internship &&
        !userContext.user.current_experience?.school_location &&
        !userContext.user.current_experience?.other_school
    )
}

const shouldRenderInternshipForLocationCTA = (userContext) => {
    return userContext.statusConfig.actively_looking_internship && !userContext.user.city
}

const shouldRenderInternshipsForUserLocation = (userContext) => {
    return userContext.statusConfig.actively_looking_internship && userContext.user.city
}

const shouldRenderInternshipsForSchoolLocation = (userContext) => {
    if (schoolAndUserHaveSameLocation(userContext)) return false

    return userContext.statusConfig.actively_looking_internship && userContext.user.current_experience?.school_location
}

const shouldRenderStudentJobsForSchoolLocationCTA = (userContext) => {
    return (
        userContext.statusConfig.actively_looking_student_job &&
        !userContext.user.current_experience?.school_location &&
        !userContext.user.current_experience?.other_school
    )
}

const shouldRenderStudentJobsForUserLocationCTA = (userContext) => {
    return userContext.statusConfig.actively_looking_student_job && !userContext.user.city
}

const shouldRenderStudentJobsForUserLocation = (userContext) => {
    return userContext.statusConfig.actively_looking_student_job && userContext.user.city
}

const shouldRenderStudentJobsForSchoolLocation = (userContext) => {
    if (schoolAndUserHaveSameLocation(userContext)) return false

    return userContext.statusConfig.actively_looking_student_job && userContext.user.current_experience?.school_location
}

const shouldRenderKotsForSchoolLocationCTA = (userContext) => {
    return userContext.statusConfig.actively_looking_kot && !userContext.user.current_experience?.school_location
}

const shouldRenderKots = (userContext) => {
    return userContext.statusConfig.actively_looking_kot && userContext.user.current_experience?.school_location
}

const schoolAndUserHaveSameLocation = (userContext) => {
    const userLocationObject = findObjectByCity(userContext.user?.address?.city)
    const schoolLocationObject = findObjectByCity(userContext.user?.current_experience?.school_location)

    if (!userLocationObject) return false
    if (!schoolLocationObject) return false

    return isEqual(userLocationObject, schoolLocationObject)
}

const findObjectByCity = (userLocationCity) => {
    if (!userLocationCity) return null

    const foundCity = CollectionHandler.Get('cityCollection').find(city => {
        return city.en.toLowerCase() == userLocationCity.toLowerCase() || city.fr.toLowerCase() == userLocationCity.toLowerCase() || city.nl.toLowerCase() == userLocationCity.toLowerCase()
    })

    return foundCity
}

export default {
    shouldRenderFirstJobs,
    shouldRenderInternshipForSchoolCTA,
    shouldRenderInternshipForLocationCTA,
    shouldRenderInternshipsForUserLocation,
    shouldRenderInternshipsForSchoolLocation,
    shouldRenderStudentJobsForSchoolLocationCTA,
    shouldRenderStudentJobsForUserLocationCTA,
    shouldRenderStudentJobsForUserLocation,
    shouldRenderStudentJobsForSchoolLocation,
    shouldRenderKotsForSchoolLocationCTA,
    shouldRenderKots
}
