export const useStyles = (theme) => ({
    welcomeContainer: {
        [theme.breakpoints.up('sm')]: {
            margin: '80px auto'
        },
        [theme.breakpoints.down('xs')]: {
            margin: '40px auto'
        },
        '& button p': {
            fontSize: '16px'
        }
    },
    pageTitle: {
        fontFamily: 'var(--title-font)',
        [theme.breakpoints.up('lg')]: {
            fontSize: '40px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '32px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '27px'
        },
    },
    subtitle: {
        fontFamily: 'var(--title-font) !important',
        fontSize: '20px !important'
    },
    textContent: {
        padding: '10px 0 40px',
        [theme.breakpoints.up('lg')]: {
            fontSize: '20px !important'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '18px !important',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 20,
            width: '80%'
        }
    }
})
