/* eslint-disable react/prop-types */
import React from 'react'

import CompanyJobAPI from 'api/CompanyJobAPI'

import LineButton from 'buttons/LineButton/LineButton'
import RouteMap from 'assets/RouteMap'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import DateHandler from 'assets/DateHandler'
import ShareAction from 'components/ShareAction/ShareAction'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'

import { FormattedMessage } from 'react-intl'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './companyjobcard_style'
import Card from '@material-ui/core/Card'

class CompanyJobCard extends React.Component {
    constructor (props) {
        super(props)
        this.checkRef = React.createRef()
        this.containerRef = React.createRef()
        this.state = {
            showPopupBoost: false,
            showPopupDelete: false,
            status: this.props.job.active
        }

        this.showUrl = RouteMap.Show(this.props.job.type, this.props.job.url)
        this.duplicateJob = this.duplicateJob.bind(this)
    }

    duplicateJob (job) {
        CompanyJobAPI.DuplicateCompanyJob(job).then((response) => {
            window.location.href = RouteMap.Edit(response.data.type, response.data.id)
        })
    }

    renderStatus () {
        let statusContainer
        const { classes } = this.props

        if (this.props.user.show_attention_banner) {
            statusContainer = (
                <div className={classes.waitingApproval}>
                    <FormattedMessage id='attention_banner.waiting_approval' />
                </div>
            )
        } else {
            if ((new Date(this.props.job.active_until) < new Date(2100, 1, 1)) || this.props.job.deadline) {
                /* First jobs created by gold licence users have as their active_until date:
                Today + 100.years. Here we check whether the active_until is after 2100 so
                that we can put ‘active’ on the card and not ‘active until -date-'. */
                if (this.props.job.active) {
                    let statusDate
                    if (this.props.job.active_until) {
                        statusDate = DateHandler.ToBasicFormat(this.props.job.active_until)
                    }
                    if (this.props.job.deadline) {
                        statusDate = DateHandler.ToBasicFormat(this.props.job.deadline)
                    }

                    statusContainer = (
                        <div className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                            <span><FormattedMessage id="company_job_card.active_until" /></span>
                            <span className={classes.date}>{statusDate}</span>
                        </div>
                    )
                } else {
                    statusContainer = (
                        <div className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                            <span><FormattedMessage id="company_job_card.deactivated_caps" /></span>
                        </div>
                    )
                }
            } else {
                const statusText = this.props.job.active ? <FormattedMessage id="company_job_card.activated" /> : <FormattedMessage id="company_job_card.deactivated" />

                statusContainer = (
                    <div className={`${classes.companyJobCardColumn} ${classes.companyJobStatus}`}>
                        <span>{statusText}</span>
                    </div>
                )
            }
        }

        return statusContainer
    }

    statusToggle (status) {
        const { classes } = this.props
        return (
            <div onClick={() => this.props.toggleActive(this.props.job)} className={`${classes.statusToggle} ${classes[status]}`}>
                <IconFactory
                    style={{ margin: 5 }}
                    icon={status === 'active' ? 'toggle-on' : 'toggle-off'}
                /> {status === 'active' ? <FormattedMessage id="company_job_card.activated" /> : <FormattedMessage id="company_job_card.deactivated" />}
            </div>
        )
    }

    renderPopup (popupType) {
        this.setState({
            [popupType]: !this.state[popupType]
        })
    }

    renderButtonName () {
        var buttonName
        if (this.props.job.active) {
            buttonName = <FormattedMessage id="company_job_card.buttons.boost" />
        } else {
            if (this.props.job.active_until) {
                buttonName = <FormattedMessage id="company_job_card.buttons.repost" />
            } else {
                buttonName = <FormattedMessage id="company_job_card.buttons.reactivate" />
            }
        }
        return buttonName
    }

    getActiveColor () {
        return this.props.job.active ? 'var(--black)' : 'var(--grey)'
    }

    render () {
        const activeStatus = this.props.job.active ? 'active' : 'inactive'
        const jobType = this.props.job.type?.replace('_', '-')
        const { classes } = this.props
        return (
            <div className={classes.companyJobCardContainer} ref={this.containerRef}>
                <Hidden mdDown>
                    <div className={classes.checkContainer}>
                        <Checkbox
                            color='primary'
                            onClick={() => this.props.handleJobCheck(this.props.job)}
                            checked={this.props.checked}
                            icon={<IconFactory icon='checkbox-false' />}
                            checkedIcon={<IconFactory icon='checkbox-true' />}
                        />
                    </div>
                </Hidden>
                <Card elevation={3} className={`${classes.companyJobCard} ${jobType}`}>
                    <Hidden lgUp>
                        <div className={`${classes.colorContainer} ${classes[activeStatus]}`}></div>
                    </Hidden>
                    <div className={`${classes.companyJobCardColumn} ${classes.companyJobType} ${classes[activeStatus]}`}>
                        <Typography variant='h5' component='div' style={{ wordBreak: 'normal' }}>
                            <FormattedMessage id={`type.${this.props.job.type}.singular`}>
                                {type => type.toUpperCase()}
                            </FormattedMessage>
                        </Typography>
                    </div>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} lg={3} className={`${classes.companyJobCardColumn} ${classes.companyJobName}`}>
                            <RouteMapLink showItemType={this.props.job.type} showItemUrl={this.props.job.url} >
                                {this.props.job.title}
                            </RouteMapLink>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={2}>
                                {this.renderStatus()}
                            </Grid>
                        </Hidden>
                        <Hidden lgUp>
                            {this.renderStatus()}
                        </Hidden>
                        <Grid item xs={12} lg={3} className={`${classes.companyJobCardColumn} ${classes.companyJobStats}`}>
                            <div><span>
                                {this.props.job.index_count || 0}</span> <FormattedMessage id="company_job_card.stats.view" />
                            </div>
                            <div><span>{this.props.job.show_count || 0}</span> <FormattedMessage id="company_job_card.stats.clics" /></div>
                            <div><span>{this.props.job.candidacy_count || 0}</span> <FormattedMessage id="company_job_card.stats.candidats" /></div>
                            <div><span>{this.props.job.favourite_count || 0}</span> <FormattedMessage id="company_job_card.stats.favorites" /></div>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {this.props.job.last_candidacy_date &&
                                <Box className={classes.lastCandidacyDate}>
                                    <FormattedMessage key='active' id='company_job_card.last_candidacy_date'/>: {DateHandler.ToBasicFormat(this.props.job.last_candidacy_date)}
                                </Box>
                            }
                            <Box className={`${classes.companyJobCardColumn} ${classes.companyJobButtons}`}>
                                <Box display="flex">
                                    {EmployerJobIndexHelper.userHasAccessToBoost(this.props.job, this.props.user, true) &&
                                        <LineButton
                                            name={EmployerJobIndexHelper.isJobBoostable(this.props.job) ? <FormattedMessage id="company_job_card.buttons.boost" /> : <FormattedMessage id="company_job_card.buttons.boosted" />}
                                            jobType={jobType + '-color'}
                                            status={activeStatus}
                                            onClick={() => this.props.toggleBoost(this.props.job)}
                                            disabled={!EmployerJobIndexHelper.isJobBoostable(this.props.job)}
                                            className={!EmployerJobIndexHelper.isJobBoostable(this.props.job) && classes.inactive}
                                        />
                                    }
                                    {this.props.job.type === 'student_job' && this.props.showHeadhunt && this.props.job.active &&
                                        <Box ml={1}>
                                            <LineButton
                                                name={<FormattedMessage id="company_job_card.buttons.headhunt" />}
                                                jobType={jobType + '-color'}
                                                href={RouteMap.Page('users') + 'headhunt?jobId=' + this.props.job.id + '&jobTitle=' + this.props.job.title}
                                            />
                                        </Box>
                                    }
                                </Box>
                                <Box ml={1}>
                                    <LineButton
                                        name={<FormattedMessage id="company_job_card.buttons.candidats" />}
                                        status={activeStatus}
                                        href={RouteMap.Page('users') + 'job_results?jobId=' + this.props.job.id + '&jobType=' + this.props.job.type}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                <div className={`${classes.hoverActionContainer} ${jobType} ${classes[activeStatus]}`}>
                    <div className={classes.actionIconContainer}>
                        <Tooltip title={<FormattedMessage id="company_job_card.tooltips.show_offer" />}>
                            <IconButton
                                href={this.showUrl}
                                target='_blank'
                                style={{ color: this.getActiveColor(), padding: 5 }}
                            >
                                <IconFactory icon='view'/>
                            </IconButton>
                        </Tooltip>
                        <ShareAction
                            infos={{
                                title: this.props.job.title,
                                url: RouteMap.Show(this.props.job.type, this.props.job.url),
                                type: this.props.job.type
                            }}
                            tooltip='Partager'
                            size='18px'
                            color={this.getActiveColor()}
                            padding='5px'
                        />
                        <Tooltip title={<FormattedMessage id="company_job_card.tooltips.edit_offer" />}>
                            <IconButton
                                href={RouteMap.Edit(this.props.job.type, this.props.job.id)}
                                target='_blank'
                                style={{ color: this.getActiveColor(), padding: 5 }}
                            >
                                <IconFactory icon='edit'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id="company_job_card.tooltips.duplicate_offer" />}>
                            <IconButton
                                onClick={() => this.duplicateJob(this.props.job)}
                                style={{ color: this.getActiveColor(), padding: 5 }}
                            >
                                <IconFactory icon='duplicate'/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id="company_job_card.tooltips.delete_offer" />}>
                            <IconButton
                                onClick={() => this.props.removeCompanyJob(this.props.job)}
                                style={{ color: this.getActiveColor(), padding: 5 }}
                            >
                                <IconFactory icon='delete'/>
                            </IconButton>
                        </Tooltip>
                    </div>
                    {this.statusToggle(activeStatus)}
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(CompanyJobCard)
