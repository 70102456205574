import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    formControl: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(1)
        },
        [theme.breakpoints.down('sm')]: {
            margin: '8px 0'
        }
    },
    contactContainer: {
        padding: '2rem 0',
        '& .field-input, .custom-select': {
            fontSize: '17px !important'
        },
        [theme.breakpoints.only('sm')]: {
            margin: '0 auto',
            width: '80%'
        }
    },
    item: {
        marginBottom: '20px',
        [theme.breakpoints.down('xs')]: {
            display: 'flex'
        }
    },
    info: {
        zIndex: 0
    },
    [theme.breakpoints.up('md')]: {
        contactForm: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        info: {
            width: '40%'
        },
        writeBox: {
            width: '55%',
            '& .field': {
                height: '100%',
                '& .field-title': {
                    display: 'none'
                },
                '& .text-field': {
                    height: '100%',
                    '& textarea': {
                        height: '92.5%'
                    }
                }
            }
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '0 0 16px'
        }
    },
    [theme.breakpoints.up('sm')]: {
        items: {
            display: 'flex',
            justifyContent: 'space-between',
            '& > div': {
                width: '45%'
            }
        }
    }
}))
