import React from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

import { Image } from 'cloudinary-react'

import IconFactory from 'icons/IconFactory/IconFactory'
import CloudinaryHelper from 'assets/CloudinaryHelper'

import css from './articleimageupload.scss'
import css_responsive from './articleimageupload_responsive.scss'

export default class ArticleImageUpload extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            cloudinaryKey: this.props.cloudinaryKey ? this.props.cloudinaryKey : ''
        }
        this.updateImage = this.updateImage.bind(this)
    }

    shouldComponentUpdate (nextProps, nextState) {
        if (this.props.error != nextProps.error) {
            console.log('RELOADING ERROR')

            return true
        } else if (this.state.cloudinaryKey === nextState.cloudinaryKey) {
            return false
        } else {
            return true
        }
    }

    updateImage (info) {
        const that = this
        axios({
            url: '/api/v1/documents',
            method: 'post',
            data: {
                cloudinary_key: info.public_id,
                format: info.format,
                is_image: true,
                is_used: false
            }
        })
            .then(function (response) {
                that.props.handleChange(response.data.document_object.id)
            })
            .catch(function (error) {
                alert('An error has occurred')
                Bugsnag.notify(error)
            })
        event.preventDefault()
    }

    render () {
        var myWidget = CloudinaryHelper.createImageUploadWidget(
            {
                cropping: false,
                multiple: false
            },
            (error, result) => {
                if (!error && result && result.event === 'success') {
                    console.log('Done! Here is the image info: ', result.info)
                    this.setState({
                        cloudinaryKey: result.info.public_id,
                        updateComponent: true
                    }, () => {
                        this.updateImage(result.info)
                    })
                }
            }
        )

        return (
            <div className="article-image-upload-container">
                {this.props.error && <p className="error">{this.props.error}</p>}
                <div className={this.props.coverImage ? 'cover-image-upload-container' : 'banner-image-upload-container' }>
                    <div className="article-image-upload-content">
                        <div className="article-image-upload">
                            <Image
                                cloudName="studentbe"
                                publicId={this.state.cloudinaryKey}
                                alt="article illustration"
                            />
                            <div
                                className="article-image-overlay"
                                id="upload_widget"
                                onClick={() => myWidget.open()}
                            >
                                <IconFactory icon='edit'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
