import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    companyContentSection: {
        marginBottom: 64
    },
    jobDescriptionBox: {
        marginTop: 64
    },
    contentSection: {
        marginTop: 48
    },
    companyImages: {
        marginBottom: 64,
        '& img': {
            borderRadius: '10px'
        }
    }
}))
