import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    paperCard: {
        border: '1px solid #F2EEEA',
        boxShadow: '0px 2px 8px rgba(40, 41, 61, 0.04), 0px 26px 34px rgba(96, 97, 112, 0.06)',
        borderRadius: 8,
        padding: 16
    },
    titleBox: {
        marginBottom: 24
    },
    applyButtonBox: {
        marginTop: 16,
        '& button': {
            width: '100%'
        }
    }
}))
