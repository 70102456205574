import React from 'react'
import { FormControl, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormattedMessage } from 'react-intl'
import EndAdornment from '../EndAdornment/EndAdornment'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceSchoolInput = (props) => {
    const { inputRef, error, showEndAdornment, studentFormation, handleSchoolChange, schoolCollection, formationKey, InputAdornmentClassName, textFontClassName, disableUnderline } = props
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl}> 
            <Autocomplete
                required
                className={InputAdornmentClassName}
                disableUnderline={disableUnderline}
                disableClearable
                forcePopupIcon={false}
                options={schoolCollection}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                value={studentFormation[formationKey].school}
                onChange={(event, newValue) => { handleSchoolChange(newValue, formationKey) }}
                renderInput={(params) =>
                    <FormattedMessage id='landing_experience_profile.choose_establishment'>
                        {placeholder =>
                            <TextField {...params}
                                required
                                placeholder={placeholder}
                                inputRef={inputRef}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: showEndAdornment && <EndAdornment/>,
                                    disableUnderline: disableUnderline,
                                    classes: { input: `${textFontClassName} ${error && classes.textFieldError}` }
                                }}
                            />
                        }
                    </FormattedMessage>
                }
            />
        </FormControl>
    )
}

export default ExperienceSchoolInput
