import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import { useStyles } from './FiltersModals_style'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'
import useTheme from '@material-ui/core/styles/useTheme'
import Button from '@material-ui/core/Button'

const FiltersModalWrapper = ({ children, ...props }) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const classes = useStyles()
    const { titleId, showModal, closeModal, offerCount, reset, type } = props

    return (
        <Dialog
            PaperProps={{ className: classes.dialogContainer }}
            fullScreen={fullScreen}
            maxWidth='md'
            open={showModal}
            onClose={closeModal}
            disableScrollLock={true}
            aria-labelledby='responsive-dialog-delete'
            fullWidth
        >
            <DialogTitle onClose={closeModal} className={classes.dialogTitle} disableTypography>
                <Typography variant='h6'>
                    <FormattedMessage id={titleId} />
                </Typography>
                <IconButton
                    aria-label='close'
                    onClick={closeModal}
                    className={classes.closeIcon}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {children}
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Box className={classes.buttonContainer}>
                    <Typography onClick={reset} variant='h4' component='span' className={classes.cancelButton}>
                        <FormattedMessage id='filters_modal.reset' />
                    </Typography>
                    <Button
                        size='small'
                        onClick={closeModal}
                        className={classes.showButton}
                    >
                        <Typography variant='h4' component='span'>
                            <FormattedMessage id={`filters_modal.show_jobs.${type}`} values={{ count: offerCount }}/>
                        </Typography>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default FiltersModalWrapper
