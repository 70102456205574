import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    titleContainer: {
        display: 'flex',
        marginBottom: 16,
        alignItems: 'center'
    },
    leftBox: {
        width: '6px',
        height: '21px',
        background: 'var(--red)',
        borderRadius: 2,
        marginRight: 12
    }
}))
