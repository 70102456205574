import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16
    },
    card: {
        display: 'flex',
        '& div': {
            boxShadow: 'none'
        },
        '& iframe': {
            borderRadius: 10,
            borderWidth: 0
        }
    },
    arrowLeft: {
        left: 0,
        zIndex: 2,
        position: 'absolute',
        top: '60%'
    },
    arrowRight: {
        right: 0,
        top: '60%',
        zIndex: 2,
        position: 'absolute'
    },
    arrowBtn: {
        height: 40,
        width: 40,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    arrows: {
        width: 50,
        height: 50,
        color: 'var(--red)'
    },
    disabled: {
        backgroundColor: 'var(--light-grey) !important',
        opacity: 0.5
    }
}))
