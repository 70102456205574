import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import Button from '@material-ui/core/Button'
import { FormattedMessage } from 'react-intl'

const GetTranslationBaseString = () => {
    if (window.innerWidth <= 600) {
        return 'landing_connected_user_without_my'
    } else {
        return 'landing_connected_user'
    }
}

const TabButton = (props) => {
    const status = (props.currentTab.replace(/\//g, '') === props.page.replace(/\//g, '') ? 'active' : '')

    const iconStyle = { fontSize: '24px', color: 'var(--black)' }
    const linkStyle = { color: 'var(--black)' }

    let content

    if (props.page === 'users/settings/edit') {
        content = (
            <Button style={{ minWidth: 'unset', padding: '8px 12px' }}>
                <IconFactory icon='settings' style={iconStyle} />
            </Button>
        )
    } else if (props.page === 'sign_out') {
        content = (
            <Button style={{ minWidth: 'unset', padding: '8px 12px' }}>
                <IconFactory icon='sign-out' style={iconStyle} />
            </Button>
        )
    } else if (props.page === 'messages-icon') {
        content = (
            <RouteMapLink page='messages' key='button-connected-user-navbar-link-to-messages'>
                <Button style={{ minWidth: 'unset', padding: '8px 12px' }}>
                    <IconFactory icon='message' style={{ fontSize: '24px' }} />
                </Button>
            </RouteMapLink>
        )
    } else {
        content = <FormattedMessage id={`${GetTranslationBaseString()}.${props.translateId}`} />
    }

    return (
        <RouteMapLink
            className={`tab-list-item ${status}`}
            page={props.page}
            style={linkStyle}
        >
            {content}
        </RouteMapLink>
    )
}

export default TabButton
