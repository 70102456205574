/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../KotShowBanner/KotShowBanner_style'
import Box from '@material-ui/core/Box'

import RouteMap from 'assets/RouteMap'
import FilledButton from 'buttons/FilledButton/FilledButton'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'

const ApplyBox = ({ className, userType, kot, applyText, unavailable, openLoginModal, apply, setFavourite }) => {
    const classes = useStyles()

    const clickAction = () => {
        if (userType === 'not_connected') {
            openLoginModal()
        } else if (userType === 'student' && kot.application_type === 'application_url') {
            window.location.href = kot.application_url
        } else if (userType === 'student' && !kot.alreadyApplied) {
            apply()
        } else if (kot.alreadyApplied) {
            window.location.href = RouteMap.Page(kot.chat_url)
        }
    }

    return (
        <Box className={className} style={{ width: '100%' }}>
            <Box>
                <FavoriteAction
                    item={kot}
                    userType={userType}
                    size='20px'
                    setFavourite={setFavourite}
                    padding='0px'
                />
            </Box>
            <Box className={classes.iconContainer}>
                <ShareAction
                    infos={{
                        title: kot.title,
                        url: window.location.href
                    }}
                    size='20px'
                    padding='0px'
                    margin = '10px'
                />
            </Box>
            <FilledButton
                newStyle
                name={applyText}
                disabled={unavailable || !['student', 'not_connected'].includes(userType)}
                onClick={clickAction}
                color='secondary'
                style={{ height: '40px', borderRadius: '8px', minWidth: '180px' }}
            />
        </Box>
    )
}

export default ApplyBox
