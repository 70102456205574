/* eslint-disable react/prop-types */
import React from 'react'

import { FormattedMessage } from 'react-intl'

import ExperienceProfile from 'pages/user/Profile/StudentProfile/Components/ExperienceProfile/ExperienceProfile'
import ProfileSimpleInfo from '../../../user/Profile/StudentProfile/Components/ProfileSimpleInfo/ProfileSimpleInfo'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './StudentRegister_style'

const StudentRegisterView = (props) => {
    const {
        handleNext,
        steps,
        activeStep,
        student,
        setStudent,
        studentFormation,
        setStudentFormation,
        loading
    } = props

    const classes = useStyles()

    const renderStudentInformation = () => {
        return (
            <ProfileSimpleInfo
                student={student}
                setStudent={setStudent}
            />
        )
    }

    const renderExperiences = () => {
        return (
            <ExperienceProfile
                setExperienceIsUpdated={() => {}}
                studentFormation={studentFormation}
                setStudentFormation={setStudentFormation}
                disabledSave
                disabledPaper
                condensed
            />
        )
    }

    return (<>
        <Box className={classes.stepperBox}>
            <Container maxWidth='lg'>
                <Stepper alternativeLabel activeStep={activeStep} color='primary' classes={{ root: classes.stepperBoxContainer }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel classes={{ root: classes.stepperIcon }}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Container>
        </Box>
        <Container maxWidth='md' className={classes.registerContainer}>
            {loading &&
                <Box className={classes.progressCircleBox}>
                    <ProgressCircle/>
                </Box>
            }
            {!loading &&
                <form onSubmit={handleNext}>
                    <Box className={classes.titleContainer}>
                        <Typography variant='h1' color='primary'>
                            <FormattedMessage id="landing_login_page.sign_up.title" />
                        </Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {renderStudentInformation()}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {renderExperiences()}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box display='flex' justifyContent='center' alignItems='center' mb={8}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    <FormattedMessage id="landing_login_page.confirm" />
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            }
        </Container>
    </>)
}

export default StudentRegisterView
