import React, { useState, useEffect } from 'react'

import ArticleAPI from 'api/ArticleAPI'
import RecentArticlesSlideshowView from './RecentArticlesSlideshowView'

const RecentArticlesSlideshow = (props) => {
    const [articles, setArticles] = useState()

    const recentArticleParams = {
        category: props.category,
        count: props.nbrItems
    }

    useEffect(() => {
        ArticleAPI.GetRecentArticles(recentArticleParams)
            .then((response) => setArticles(response.data))
    }, [])

    return (
        <RecentArticlesSlideshowView articles={articles} />
    )
}

export default RecentArticlesSlideshow
