import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CompanyImpact_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import NewCarouselCards from 'shared/components/NewCarouselCards/NewCarouselCards'

const CompanyImpact = (props) => {
    const { lastCompanies } = props
    const classes = useStyles()

    return (
        lastCompanies.length > 0 &&
            <Box className={classes.companyImpactContainer}>
                <Typography variant="h6" component="h2" className={classes.companyImpactTitle}><FormattedMessage id="landing_student.company_impact.title" /></Typography>
                <Box className={classes.companyImpactCardsContainer}>
                    <NewCarouselCards
                        type='company'
                        items={lastCompanies}
                        autoPlay={false}
                        cardsNumber={3}
                        allowButtons={true}
                    />
                </Box>
            </Box>
    )
}
export default CompanyImpact
