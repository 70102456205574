import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    passportContainer: {
        display: 'flex'
    },
    accordion: {
        width: '100%',
        backgroundColor: 'var(--beige)',
        borderRadius: '8px !important'
    },
    accordionSummary: {
        minHeight: '0 !important',
        marginTop: '16px !important',
        marginBottom: '16px !important',
        '& div': {
            margin: '0 !important',
            padding: '0px !important'
        }
    },
    wrapBox: {
        flexWrap: 'wrap',
    },
    companyDescription: {
        fontSize: 14,
        '& p': {
            margin: 0
        },
        '& h1, h2, h3, h4, em': {
            fontSize: 14
        }
    },
    contentContainer: {
        paddingTop: 0,
        flexDirection: 'column'
    },
    cta: {
        marginTop: 12,
        fontFamily: "'GintoNormal-Bold', sans-serif",
        fontSize: 14
    },
    companyName: {
        marginRight: 12,
        color: 'var(--red)'
    },
    accordionSummaryTypography: {
        marginRight: 12
    }
}))
