import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        [theme.breakpoints.up('md')]: {
            fontSize: '24px !important'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px !important'
        }
    },
}))
