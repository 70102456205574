export const useStyles = (theme) => ({
    titleContainer: {
        [theme.breakpoints.up('md')]: {
            width: '70%',
            margin: 'auto',
            marginTop: 20
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 20
        }
    },
    BlockContainer: {
        [theme.breakpoints.up('md')]: {
            width: '70%',
            margin: 'auto',
            paddingLeft: 45
        }
    },
    SectionMargin: {
        marginTop: '24px'
    },
    topOfPageSubmit: {
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '70%'
        }
    },
    buttonContainer: {
        margin: '20px auto',
        marginTop: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '70%'
        },
        [theme.breakpoints.down('md')]: {
        }
    },
    errors: {
        margin: '20px auto',
        fontFamily: 'var(--body-font)',
        fontSize: 'var(--error-font-size)',
        color: 'var(--error-red)',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            width: '70%'
        }
    },
    submitButton: {
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    }
})
