import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    PaperShadow: {
        [theme.breakpoints.up('md')]: {
            boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 7px 0px rgba(0,0,0,0.12)',
            padding: 16
        }
    },
    fieldsContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '24px 10px'
        },
        [theme.breakpoints.up('md')]: {
            padding: 24
        }
    },
    fieldText: {
        display: 'flex',
        alignItems: 'center'
    }
}))
