/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'

import SettingsAPI from 'api/SettingsAPI'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMap from 'assets/RouteMap'
import IconFactory from 'icons/IconFactory/IconFactory'

import { useStyles } from './BasicSettings_style'
import { FormattedMessage } from 'react-intl'

const BasicSettings = ({ props }) => {
    const { user } = props
    console.log(user)
    const email = props.user.email
    const classes = useStyles()
    const [language, setLanguage] = useState(user.language_preference)
    const [userState, setUserState] = useState(
        {
            studentNewsletterPreference: user.legal_authorization,
            newsletterPreference: user.newsletter_preference,
            newMessagePreference: user.new_message_preference,
            errors: {}
        }
    )
    const handleChangeLanguage = (event) => {
        setLanguage(event.target.value)
    }
    const handleChangeData = prop => event => {
        setUserState({ ...userState, [prop]: event.target.value })
    }
    const handleCheckBox = prop => event => {
        setUserState({ ...userState, [prop]: event.target.checked })
    }

    const canSubmit = () => {
        var canSubmit = true

        if (userState.currentPassword === '') {
            setUserState({
                ...userState,
                errors: {
                    password: <FormattedMessage id="landing_app_setting_page.errors.missing_password" />
                }
            })
            canSubmit = false
        }
        return canSubmit
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        if (canSubmit()) {
            const data = {
                email: email,
                language_preference: language,
                newsletter_preference: userState.newsletterPreference,
                new_message_preference: userState.newMessagePreference,
                legal_authorization: userState.studentNewsletterPreference,
                password: userState.currentPassword
            }
            console.log(data)
            // eslint-disable-next-line no-undef
            SettingsAPI.EditSettings(data).then(
                (response) => {
                    window.location.href = RouteMap.Page('users/settings/edit', response.data.language_preference)
                }
            ).catch(
                (error) => {
                    console.log(error.response.data)
                    setUserState({ ...userState, errors: error.response.data.errors })
                }
            )
        }
    }

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography
                                variant='h3' component='h2'
                                color='primary'

                            >
                                <FormattedMessage id="landing_app_setting_page.basic_settings" />
                            </Typography>
                        </Box>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant='h4' component='h2'
                                color='primary'
                                className={classes.fieldLabel}
                            >
                                <FormattedMessage id="landing_app_setting_page.language" />
                            </Typography>
                            <FormControl className={classes.field}>
                                <Select
                                    value={language}
                                    onChange={handleChangeLanguage}
                                    inputProps={{MenuProps: {disableScrollLock: true}}}
                                    MenuProps={{
                                        getContentAnchorEl: null,
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }
                                    }}
                                >
                                    <MenuItem value='fr' >
                                        <Typography variant='body2'>
                                            <FormattedMessage id="landing_app_setting_page.choose_language.fr" />
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value='en' >
                                        <Typography variant='body2'>
                                            <FormattedMessage id="landing_app_setting_page.choose_language.en" />
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value='nl' >
                                        <Typography variant='body2'>
                                            <FormattedMessage id="landing_app_setting_page.choose_language.nl" />
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant='h4'
                            component='h2'
                            color='primary'
                            className={classes.fieldLabel}
                        >
                            <FormattedMessage id="landing_app_setting_page.keep_posted" />
                        </Typography>
                        {user.type === 'student' &&
                            <Box display={'flex'} alignItems='center'>
                                <Checkbox
                                    icon={<IconFactory icon='checkbox-false'/>}
                                    checkedIcon={<IconFactory icon='checkbox-true'/>}
                                    color='primary'
                                    checked={userState.studentNewsletterPreference}
                                    onChange={handleCheckBox('studentNewsletterPreference')}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <Typography variant='body2'>
                                    <FormattedMessage id="landing_app_setting_page.offers_adapted" />
                                </Typography>
                            </Box>
                        }
                        {user.type === 'employer' &&
                            <Box display={'flex'} alignItems='center'>
                                <Checkbox
                                    icon={<IconFactory icon='checkbox-false'/>}
                                    checkedIcon={<IconFactory icon='checkbox-true'/>}
                                    color='primary'
                                    checked={userState.newMessagePreference}
                                    onChange={handleCheckBox('newMessagePreference')}
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                <Typography variant='body2'>
                                    <FormattedMessage id="landing_app_setting_page.new_messages_preference" />
                                </Typography>
                            </Box>
                        }
                        <Box display={'flex'} alignItems='center'>
                            <Checkbox
                                icon={<IconFactory icon='checkbox-false'/>}
                                checkedIcon={<IconFactory icon='checkbox-true'/>}
                                color='primary'
                                checked={userState.newsletterPreference}
                                onChange={handleCheckBox('newsletterPreference')}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            {user.type === 'student' &&
                                <Typography variant='body2' component={'div'} >
                                    <FormattedMessage id="landing_app_setting_page.information_and_advice_dedicated_to.students_and_young_graduate" />
                                </Typography>
                            }
                            {user.type === 'employer' &&
                                <Typography variant='body2' component={'div'} >
                                    <FormattedMessage id="landing_app_setting_page.information_and_advice_dedicated_to.employer" />
                                </Typography>
                            }
                            {user.type === 'kot_owner' &&
                                <Typography variant='body2' component={'div'} >
                                    <FormattedMessage id="landing_app_setting_page.information_and_advice_dedicated_to.kot_owner" />
                                </Typography>
                            }

                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                {/*
                                <Typography
                                    variant='h4'
                                    component='h2'
                                    color='primary'
                                    className={classes.fieldLabel}
                                >
                                    <FormattedMessage id="landing_app_setting_page.current_password" />
                                </Typography>
                                */}
                                <FormattedMessage id="landing_app_setting_page.current_password">
                                    {placeholder =>
                                        <TextField
                                            fullWidth
                                            type={'password'}
                                            value={userState.currentPassword}
                                            onChange={handleChangeData('currentPassword')}
                                            error={userState.errors.password}
                                            label={placeholder}
                                            helperText={userState.errors.password}
                                            InputProps={{
                                                classes: {
                                                    input: classes.inputBase
                                                }
                                            }}
                                            classes={{
                                                root: classes.errorText
                                            }}
                                        />
                                    }
                                </FormattedMessage>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box display={'flex'} style={{ height: '100%' }} justifyContent='flex-end' alignItems='flex-end'>
                                    <FilledButton
                                        color='secondary'
                                        type='submit'
                                        name={<FormattedMessage id="landing_app_setting_page.save" />}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}
export default BasicSettings
