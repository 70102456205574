import React, { useState, useEffect, useRef, useContext } from 'react'
import ProfileEditFormStepNameView from './ProfileEditFormStepNameView'
import ProfileEditFormHelper from '../../helpers/ProfileEditFormHelper'
import UserAPI from 'api/UserAPI'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import { UserContext } from 'contexts/UserContext'

const ProfileEditFormStepName = (props) => {
    const { shortProfile, setShortProfile, step, nextStep, previousStep } = props
    const [loading, setLoading] = useState(false)
    const [validationIsEnabled, setValidationIsEnabled] = useState(false)
    const [firstNameRef, lastNameRef] = Array.from({ length: 2 }, () => useRef(null))
    const inputRefs = { firstNameRef: firstNameRef, lastNameRef: lastNameRef }
    const [errors, setErrors] = useState({ firstName: false, lastName: false })
    const userContext = useContext(UserContext)

    const validateStepName = () => {
        const newErrors = {
            firstName: firstNameRef.current?.value === '',
            lastName: lastNameRef.current?.value === ''
        }

        setErrors(newErrors)
        return ProfileEditFormHelper.objectHasTrueValue(newErrors)
    }

    useEffect(() => {
        if (validationIsEnabled) validateStepName()
    }, [shortProfile.first_name, shortProfile.last_name])

    const handleSubmit = (event) => {
        setValidationIsEnabled(true)
        if (validateStepName()) return false
        setLoading(true)

        UserAPI.UpdateName(shortProfile)
            .then((response) => {
                setLoading(false)
                nextStep('Personal Dashboard step 2 name')
                AnalyticsHelper.sendGTMEvent(
                    'Account Updated',
                    AnalyticsHelper.constructAccountUpdatedAnalyticsAttributes(
                        userContext.user,
                        userContext.user,
                        userContext.user.current_experience,
                        userContext.user.profile.birth_date || new Date(),
                        'Personal Dashboard ProfileEditFormStepName step 2'
                    )
                )
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target
        setShortProfile({
            ...shortProfile,
            [name]: value
        })
    }

    return (
        <ProfileEditFormStepNameView
            step={step}
            inputRefs={inputRefs}
            handleSubmit={handleSubmit}
            previousStep={previousStep}
            shortProfile={shortProfile}
            handleChange={handleChange}
            errors={errors}
            loading={loading}
        />
    )
}
export default ProfileEditFormStepName
