import React, { useState, useEffect } from 'react'

import { useStyles } from './ArticleListCompactCard_style'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import CollectionHandler from 'assets/CollectionHandler.js'
import { Box, Chip, Typography } from '@material-ui/core'
import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const ArticleListCompactCard = (props) => {
    const { article } = props
    const classes = useStyles()
    const [category, setCategory] = useState()

    useEffect(() => {
        let categoryString = CollectionHandler.Translate('articleCategoryCollection', article.category)
        if (categoryString === '/') {
            categoryString = CollectionHandler.Translate('articleCategoryCollection', 'student_life')
        }
        setCategory(categoryString)
    }, [])

    return (
        <RouteMapLink showItemType='article' showItemUrl={article.url} style={{ width: '100%' }}>
            <Box className={classes.main}>
                <Typography variant='h3' component='span' className={classes.articleTitle}>
                    {article.title}
                </Typography>
                <Box className={classes.articleInfo}>
                    <Chip
                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                        className={classes.chip}
                        label={category}
                        name={category}
                        color={'var(--dark-grey)'}
                    />
                    <Box className={classes.readTimeAndDate}>
                        <Typography className={classes.minorText}>
                            {`${DateHandler.toReadingFormat(article.date)}`}
                        </Typography>
                        <Typography component='span' className={`${classes.minorText} ${classes.bullet}`}>
                            •
                        </Typography>
                        <IconFactory
                            icon='clock'
                            fontSize='12px'
                            color='var(--grey)'
                            style={{
                                fontFamily: 'GintoNormal-Medium, sans-serif !important',
                                margin: '0 4px'
                            }}
                        />
                        <Typography variant='body2' component='span' className={classes.minorText}>
                            {`${article.read_time} min`}
                        </Typography>
                    </Box>
                </Box>
                <Box id='bottomBorder' className={classes.bottomBorder} />
            </Box>
        </RouteMapLink>
    )
}

export default ArticleListCompactCard
