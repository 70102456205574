/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './KotShowSectionTitle_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const KotShowSectionTitle = (props) => {
    const { textId } = props
    const classes = useStyles()

    return (
        <Box className={classes.titleContainer}>
            <Box className={classes.leftBox}/>
            <Typography variant='h6' component='h2'>
                <FormattedMessage id={textId} />
            </Typography>
        </Box>
    )
}

export default KotShowSectionTitle
