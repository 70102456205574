import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    resetIconContainer: {
        backgroundColor: 'white',
        borderRadius: 12,
        color: 'black',
        cursor: 'pointer',
        padding: '9.5px 14px',
        minWidth: 'initial',
        '&:hover': {
            backgroundColor: 'var(--light-grey) !important'
        }
    },
    contentBox: {
        display: 'flex',
        alignItems: 'center'
    }
}))
