/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react'
import FirstJobFiltersView from './FirstJobFiltersView'
import CollectionHandler from 'assets/CollectionHandler.js'
import debounce from 'lodash.debounce'

const FirstJobFilters = (props) => {
    const { user, handleFilterChange, offerCount, setFilters, selectedLocationTag, type } = props
    const [filterState, setFilterState] = useState({})
    const [searchCities, setSearchCities] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [citiesInput, setCitiesInput] = useState('')
    const [showFirstJobFiltersModal, setShowFirstJobFiltersModal] = useState(false)

    const cityCollection = CollectionHandler.Get('cityCollection')

    let searchText
    if (window.location.search.includes('?search=', 0)) {
        searchText = window.location.search.replace('?search=', '')
    }

    useEffect(() => {
        initSetupState()
    }, [])

    useEffect(() => {
        if (isLoaded) {
            handleFilterChange(filterState)
        }
    }, [filterState.location, filterState.studyDomain, filterState.sector, filterState.companySize, filterState.studyType, filterState.traineeship, filterState.quickFilter])

    const initSetupState = () => {
        const fields = initState()

        setFilterState(fields)
        setSearchCities(cityCollection)
        setIsLoaded(true)
    }

    const initState = (reset) => {
        let searchField = {}
        let locationArray = []
        let studyDomainArray = []
        let sectorArray = []
        let traineeship = null
        let quickFilter = ''
        if (props.location) {
            if (props.location.type === 'location') {
                locationArray = props.location
                    ? [props.location]
                    : locationArray
            }
            if (props.location.type === 'studyDomain') {
                studyDomainArray = props.location
                    ? [props.location]
                    : studyDomainArray
            }
            if (props.location.type === 'freeText') {
                searchText = props.location.name
            }
            if (props.location.type === 'quickFilter') {
                quickFilter = props.location.value
            }
            if (props.location.type === 'sector') {
                sectorArray = [props.location]
            }
        }
        if (reset) {
            locationArray = []
            studyDomainArray = []
            traineeship = null
        }

        searchField = {
            title: searchText || '',
            studyDomain: studyDomainArray,
            location: locationArray,
            sector: sectorArray,
            companySize: [],
            studyType: [],
            quickFilter: quickFilter,
            traineeship: traineeship
        }
        if (!reset && props.urlFilters) {
            searchField = {
                ...searchField,
                ...props.urlFilters
            }
        }

        setFilters(searchField)
        return searchField
    }

    const reset = () => {
        const newFilterState = initState(true)
        setFilterState(newFilterState)
        handleFilterChange(newFilterState)
        window.history.pushState('', '', location.pathname)
    }

    const debouncedFilterChange = useCallback(
        debounce(handleFilterChange, 1000), []
    )

    return (
        <>
            {isLoaded &&
                <FirstJobFiltersView
                    debouncedFilterChange={debouncedFilterChange}
                    offerCount={offerCount}
                    searchCities={searchCities}
                    citiesInput={citiesInput}
                    setSearchCities={setSearchCities}
                    setCitiesInput={setCitiesInput}
                    setFilters={setFilterState}
                    filters={filterState}
                    selectedLocationTag={selectedLocationTag}
                    type={type}
                    handleFilterChange={handleFilterChange}
                    showFirstJobFiltersModal={showFirstJobFiltersModal}
                    setShowFirstJobFiltersModal={setShowFirstJobFiltersModal}
                    reset={reset}
                    user={user}
                />
            }
        </>
    )
}

export default FirstJobFilters
