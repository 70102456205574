import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: '14px !important'
    },
    seeAll: {
        borderRadius: '8px',
        padding: '10px 14px',
        cursor: 'pointer',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    }
}))
