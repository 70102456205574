import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    errorText: {
        fontSize: 12,
        color: 'var(--red)'
    },
    textField: {
        fontFamily: "'GintoNormal-Regular', sans-serif",
        padding: 12,
        backgroundColor: 'var(--beige)',
        borderRadius: 8,
        width: '100%',
        height: '100%',
        fontSize: 14,
        '&::placeholder': {
            color: 'var(--grey)',
            opacity: 1
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 16, // prevents browser zoom on input
        }
    },
    shortField: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    maxWidth: {
        width: '100%'
    },
    formContainer: {
        width: '100%'
    },
    formField: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    quillStyling: {
        '& .ql-toolbar': {
            borderRadius: '4px 4px 0 0',
            background: 'var(--beige)',
        },
        '& .ql-container': {
            borderRadius: '0 0 4px 4px',
        },
        '& .ql-editor': {
            minHeight: 250
        }
    },
    dateInput: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    dateSelection: {
        background: 'var(--beige)',
        padding: "0 8px",
        borderRadius: 8,
        '& input': {
            background: 'var(--beige)'
        },
    },
    iosSwitch: {
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff !important',
            '& + .MuiSwitch-track': {
                backgroundColor: '#2ECA45 !important',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d !important',
            border: '6px solid #fff !important',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: 'var(--grey)',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
            duration: 500,
            }),
        }
    }
}))
