/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'
import GenericHelper from 'assets/GenericHelper'

import Hidden from '@material-ui/core/Hidden'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './candidacycard_style'

class CandidacyCard extends React.Component {
    constructor (props) {
        super(props)
        this.checkRef = React.createRef()
        this.divRef = React.createRef()

        this.pathname = window.location.pathname
        this.lang = this.pathname.match(/^\/([^\/]*)\//)[1]
    }

    render () {
        const { classes } = this.props
        var jobType = this.props.candidacy.type.replace('_', '-')

        return (
            <div className={classes.candidacyCardContainer} ref={this.divRef}>
                <Card elevation={1} className={classes.candidacyCardInfo}>

                    <Hidden mdUp>
                        <div className={classes.jobBackgroundColor}></div>
                    </Hidden>
                    <div className={`${classes.candidacyCardColumn} ${classes.jobBackgroundColor} ${classes.candidacyType} ${jobType}`}>
                        <Hidden smDown>
                            <Typography variant='h5' component='div' className={`${classes.jobTypeFormat} ${classes.candidacyType}`}>
                                <FormattedMessage id={`type.${this.props.candidacy.type}.singular`} />
                            </Typography>
                        </Hidden>
                    </div>

                    <Grid container spacing={3} alignItems="center" className={`${classes.jobInfo} ${jobType}`}>
                        <Hidden mdUp>
                            <Grid item xs={12} className={`${classes.jobTypeFormat} ${classes.jobColor}`}>
                                <FormattedMessage id={`type.${this.props.candidacy.type}.singular`} />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} md={3} className={classes.candidacyCompanyName}>
                            {this.props.candidacy.company_name}
                        </Grid>
                        <Grid item xs={12} md={5}>
                            {this.props.candidacy.title}
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.candidacyJobStatus}>
                            {!this.props.candidacy.job_active &&
                                <FormattedMessage id={'transl_internship.completed'} />
                            }
                        </Grid>
                        <Grid item xs={12} md={2} className={classes.candidacyDate}>
                            <Hidden smDown>
                                {GenericHelper.formatDateObject(this.props.candidacy.created_at)}
                            </Hidden>
                            <Hidden mdUp>
                                <FormattedMessage id="candidacy_card.candidacy_date" />
                                {' ' + GenericHelper.formatDateObject(this.props.candidacy.created_at)}
                            </Hidden>
                        </Grid>
                    </Grid>
                </Card>
                <div className={`${classes.actionIconContainer} ${jobType}`}>
                    <RouteMapLink className={classes.routeMapLinkStyle} showItemType={this.props.candidacy.type} showItemUrl={this.props.candidacy.url}>
                        <IconFactory
                            tooltip={<FormattedMessage id="candidacy_card.check_ads" />}
                            icon='view'
                            className={classes.jobColor}
                        />
                    </RouteMapLink>
                    {this.props.candidacy.cv_file &&
                        <RouteMapLink
                            className={classes.routeMapLinkStyle}
                            target="_blank"
                            redirectionUrl={this.props.candidacy.cv_file.asset_url}
                        >
                            <Tooltip title={<FormattedMessage id="candidacy_card.check_cv" />}>
                                <IconButton
                                    aria-label="CV"
                                    color="primary"
                                    className={classes.iconButtonStyle}
                                >
                                    <Typography
                                        variant='body2'
                                        className={classes.jobColor}
                                    >
                                        CV
                                    </Typography>
                                </IconButton>
                            </Tooltip>
                        </RouteMapLink>
                    }
                    {this.props.candidacy.conversation_id &&
                        <RouteMapLink redirectionUrl={RouteMap.Page(this.props.candidacy.chat_url)}>
                            <IconFactory
                                tooltip={<FormattedMessage id="candidacy_card.check_message" />}
                                icon='chat'
                                className={classes.jobColor}
                            />
                        </RouteMapLink>
                    }
                </div>
            </div>
        )
    }
}

export default withStyles(useStyles)(CandidacyCard)
