import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    homeBanner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        maxHeight: 640,
        width: '100%',
        background: 'var(--red)'
    },
    bannerLeft: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '50%',
        position: 'relative'
    },
    bannerRight: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '50%',
        padding: 40,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    arrowContainer: {
        position: 'absolute',
        top: '25%',
        left: '45%',
        [theme.breakpoints.down('lg')]: {
            left: '35%'
        },
        [theme.breakpoints.down('md')]: {
            top: '22%',
            left: '35%'
        }
    },
    arrow: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
            width: '70%',
            height: '100%'
        }
    },
    homeBannerImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        opacity: 0.9,
        filter: 'brightness(0.73)'
    },
    bannerLeftTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        position: 'absolute',
        margin: '80px 80px 80px 280px',
        [theme.breakpoints.down('lg')]: {
            margin: 80
        }
    },
    bannerLeftTitleContainer: {
        display: 'flex',
        maxWidth: 400
    },
    bannerLeftTitle: {
        color: 'white',
        fontSize: 45,
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: 30
        }
    },
    bannerLeftDescriptionContainer: {
        display: 'flex',
        maxWidth: 500
    },
    bannerLeftDescription: {
        color: 'white',
        fontSize: '25px !important',
        fontFamily: 'GintoNormal-Regular, sans-serif !important',
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: 20
        }
    }
}))
