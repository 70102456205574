import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    articleHeaderContainer: {
        marginBottom: 30
    },
    mainGridContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    backButtonContainer: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            height: 44
        }
    },
    backButtonBox: {
        position: 'absolute',
        left: 0,
        top: 16
    },
    leaderBoardAd: {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '-90px'
        }
    },
    articleContentItem: {
        padding: '8px 0 !important'
    },
    articleContentContainer: {
        margin: 'auto'
    },
    imageStyle: {
        width: '100%',
        borderRadius: '12px'
    },
    articleTextContainer: {
        paddingTop: '20px !important',
        overflowY: 'visible'
    },
    ebookDownloadContainer: {
        borderRadius: '12px',
        backgroundColor: 'var(--red)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: {
            padding: '30px'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: '20px',
            padding: '16px'
        }
    },
    downloadButton: {
        padding: '10px 34px',
        borderRadius: '8px',
        backgroundColor: 'white !important',
        color: 'black',
        height: '40px',
        '& p': {
            fontSize: '12px !important',
            fontFamily: 'GintoNormal-Medium, sans-serif !important'
        },
        '&:hover': {
            backgroundColor: 'var(--beige) !important'
        },
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    ebookContainerTitle: {
        color: 'white',
        fontSize: '16px',
        fontFamily: 'GintoNord-Bold, sans-serif !important'
    },
    ebookDownloadButton: {
        display: 'flex',
        justifyContent: 'center'
    }
}))
