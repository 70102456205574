import React from 'react'
import { Container, Grid, Box, Hidden } from '@material-ui/core'
import { useStyles } from './AboutPublishStudentJob_style'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

const IntroBlobBackground = (props) => {
    const classes = useStyles()

    return (
        <Container disableGutters maxWidth='false'>
            <Hidden mdUp smDown>
                <Image
                    cloudName='studentbe'
                    publicId='assets/publish-a-student-job'
                    alt='student.be publish a student job'
                    className={classes.backgroundImage}
                >
                    <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                </Image>
            </Hidden>
            <Hidden xsDown >
                <Grid container className={classes.backgroundIntro}>
                    <Grid item xs={6} className={classes.blobBox}/>
                    <Grid item xs={6} className={classes.blobBox}>
                        <Image
                            cloudName='studentbe'
                            publicId='assets/publish-a-student-job-2'
                            alt='student.be publish a student job'
                            className={classes.blob}
                        >
                            <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' crop='fill' />
                        </Image>

                    </Grid>
                </Grid>
            </Hidden>
        </Container>
    )
}
export default IntroBlobBackground
