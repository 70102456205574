import React from 'react'

import { FormattedMessage } from 'react-intl'
import ReactQuill from 'react-quill'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import CollectionHandler from 'assets/CollectionHandler.js'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import { useStyles } from './CandidateForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import { QUILL_MODULES } from '../../../settings'

const CandidateForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleUpdate, handleAttributeChange } = props
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')

    const handleSelectedItems = (selectedItems, attribute) => {
        const formattedItems = selectedItems.map(item => item.value)
        handleUpdate({ [attribute]: formattedItems })
    }

    return (
        <Box className={sharedClasses.formContainer}>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.study_domains" />} />
                <TagMultiSelect
                    collection={studyDomainCollection}
                    selectedItems={jobForm.candidateStudyDomains.map(studyDomain => ({ value: studyDomain }))}
                    setSelectedItems={(items) => handleSelectedItems(items, 'candidateStudyDomains')}
                />
                <FieldError errorMessage={errors.candidateStudyDomains} />
                <FieldDivider />
            </Box>
            <Box className={sharedClasses.formField}>
                <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.searched_profile" />} />
                <Box className={sharedClasses.quillStyling}>
                    <ReactQuill
                        modules={QUILL_MODULES}
                        value={jobForm.candidateSearchedProfile || ''}
                        name="candidateSearchedProfile"
                        onChange={(value) => handleAttributeChange('candidateSearchedProfile', value)}
                    />
                </Box>
                <FieldError errorMessage={errors.candidateSearchedProfile} />
            </Box>
        </Box>
    )
}

export default CandidateForm
