import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemBox: {
        position: 'relative',
        width: '100%'
    },
    frame: {
        width: '100%',
        position: 'absolute'
    },
    cardFrame2: {
        [theme.breakpoints.up('md')]: {
            top: '110px'
        },
        [theme.breakpoints.down('sm')]: {
            top: '47%'
        }
    },
    title: {
        textAlign: 'center',
        fontSize: 38,
        [theme.breakpoints.up('lg')]: {
            fontSize: '38px'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            fontSize: '30px'
        },
        [theme.breakpoints.only('xs')]: {
            fontSize: '25px'
        }
    },
    subTitle: {
        width: '80%',
        margin: '0 auto',
        textAlign: 'center',
        fontSize: '20px !important',
    },
    overrideCardSlider: {
        '& .card-slider-container': {
            height: '100%',
            padding: 0
        },
        '& .card-slider-title-responsive': {
            display: 'none'
        }
    },
    previewTitle: {
        fontSize: '22px !important',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    cardSliderContainerInnerPage: {
        [theme.breakpoints.up('md')]: {
            marginTop: '25px'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '10px'
        }

    }
}))
