import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    headerContainer: {
        background: '#76243A',
        color: 'white',
        padding: '80px 0',
        marginBottom: '48px'
    },
    bodyContainer: {
        padding: '40px 0 8px 0',
        margin: '56px 0 0px 0',
        background: '#F0EDE8'
    },
    pageTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 40
    },
    pageTitle: {
        fontSize: 40,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            textAlign: 'center',
            lineHeight: 1.2,
            maxWidth: 280
        }
    },
    pageSubTitle: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center'
        }
    },
    creditPackagesContainer: {
        backgroundColor: 'var(--beige)',
        paddingTop: 60,
        paddingBottom: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 60
        }
    },
    containerOverride: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    publishJob: {
        marginTop: 24,
        '& p': {
            fontWeight: 'bold'
        }
    },
    marginBottom1: {
        marginBottom: '20px'
    },
    packagesContainer: {
        margin: '50px 0'
    },
    descriptionText: {
        color: 'grey',
        marginBottom: 40,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textMaxWidth: {
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    textItalic: {
        fontStyle: 'italic'
    }
}))
