import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    newsletterBannerContainer: {
        width: '100%',
        backgroundColor: 'var(--red)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 30,

        [theme.breakpoints.up('lg')]: {
            borderRadius: 20,
            padding: '28px 32px'
        },
        [theme.breakpoints.down('md')]: {
            padding: '32px 12px'
        }
    },
    newsletterTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        alignItems: 'center'
    },
    newsletterSubTitle: {
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        fontSize: '12px !important',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    newsletterTitle: {
        fontFamily: 'GintoNord-Bold, sans-serif !important',
        fontSize: '24px !important',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        }
    },
    newsletterActions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
        width: '100%'
    },
    newsletterInput: {
        backgroundColor: 'white',
        height: '100%',
        padding: 12,
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            borderRadius: 10,
            width: 800
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: 8,
            width: '100%'
        }
    },
    newsletterField: {
        flexGrow: 1,
        paddingLeft: 4
    },
    newsletterButton: {
        height: 40,
        padding: '9px 14px',
        borderRadius: '8px',
        boxShadow: 'none',
        '& p': {
            fontFamily: "'GintoNormal-Medium', sans-serif !important"
        },
        '&:hover': {
            boxShadow: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            width: '30%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '& p': {
                fontFamily: "'GintoNord-Bold', sans-serif !important"
            }
        }
    },
    inputProps: {
        fontFamily: "'GintoNormal-Medium', sans-serif",
        padding: 0,
        width: '100%',
        height: '100%',
        fontSize: 14,
        '&::placeholder': {
            opacity: 1,
            fontFamily: "'GintoNormal-Regular', sans-serif !important",
            fontSize: 14,
            fontStyle: 'normal',
            color: 'var(--dark-beige)'
        },
        [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
            width: '100%'
        }
    }
}))
