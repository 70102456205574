import React from 'react'

import { useStyles } from './LanguageFilter_style'
import Chip from '@material-ui/core/Chip'

const LanguageFilter = (props) => {
    const { language, nbJob, isSelected, onSelect } = props
    const circleClassName = nbJob === 0 ? 'circle circleDisabled' : 'circle'
    const classes = useStyles()

    const buttonClass = isSelected ? `${classes.button} ${classes.buttonSelected}` : classes.button

    const handleFilterCards = () => {
        onSelect(language)
    }

    return (
        <Chip
            label={
                <div className={classes.languageFilter}>
                    <span className='languageText'>{language}</span>
                    <span className={circleClassName}>{nbJob >= 20 ? '20+' : nbJob}</span>
                </div>
            }
            className={buttonClass}
            onClick={handleFilterCards}
            clickable
            disabled={nbJob === 0}
        />
    )
}

export default LanguageFilter
