import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        cursor: 'pointer',
        background: 'white',
        zIndex: 1,
        width: 'fit-content',
        minWidth: '55px',
        '& svg:first-child': {
            margin: '0 8px 0 4px'
        },
        '& div:nth-child(2)': {
            flexGrow: 1
        }
    },
    selected: {
        border: 'solid var(--black) 1px',
        borderRadius: '8px',
        padding: '4px'
    },
    dropdown: {
        margin: '4px 0',
        width: 'fit-content',
        zIndex: 10
    },
    options: {
        background: 'white',
        border: 'solid var(--grey) 1px',
        borderRadius: '8px',
        padding: '8px'
    },
    chips: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer'
    },
    simpleChips: {
        display: 'flex',
        cursor: 'pointer',
        gap: 4,
        flexWrap: 'wrap'
    },
    blackChips: {
        width: '100%',
        gap: 12
    },
    blackChip: {
        backgroundColor: 'white',
        flexGrow: 1,
        borderRadius: 8,
        height: 44,
        cursor: 'pointer',
        border: 'none',
        fontSize: 14
    },
    chip: {
        marginBottom: '10px',
        width: 'fit-content',
        paddingLeft: '12px',
        cursor: 'pointer',
        fontSize: '0.875rem',
        transition: 'transform ease-in-out 0.1s',
        '&:hover': {
            transform: 'scaleX(0.99)'
        }
    },
    filled: {
        border: 'none',
        color: 'white',
        fontWeight: 'bold',
        '& > svg': {
            color: 'white'
        }
    },
    simpleChip: {
        borderRadius: '48',
        border: 'none',
        padding: 8,
        backgroundColor: 'var(--beige)',
        marginBottom: '10px',
        width: 'fit-content',
        cursor: 'pointer',
        fontSize: '0.875rem',
        '&:hover': {
            opacity: '0.9'
        },
        '& > span': {
            padding: 0
        }
    },
    simpleFilled: {
        border: 'none',
        color: 'white',
        fontWeight: 'bold'
    },
    modalContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'fit-content',
        background: 'white',
        borderRadius: '4px',
        padding: '40px',
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            padding: '20px'
        }
    },
    modalDashboardContent: {
        [theme.breakpoints.up('sm')]: {
            width: 400
        }
    },
    modalDashboardTextBox: {
        textAlign: 'center',
        justifyContent: 'center'
    },
    modalDashboardButton: {
        marginTop: '20px',
        float: 'center'
    },
    modalButton: {
        marginTop: '20px',
        float: 'right'
    },
    modalTitle: {
        marginBottom: '32px'
    },
    modalClose: {
        position: 'absolute',
        right: '4px',
        top: '4px',
        width: '42px',
        [theme.breakpoints.down('sm')]: {
            right: '0',
            top: '0'
        }
    }
}))
