import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './IndexTitle_style'
import { Typography } from '@material-ui/core'

const IndexTitle = (props) => {
    const { selectedLocationTag, type } = props
    const classes = useStyles()

    const getItemsOfferPageTitle = () => {
        if (!selectedLocationTag) {
            return `item_offers.${type}.page_title`
        } else {
            switch (selectedLocationTag.type) {
            case 'location':
                return `item_offers.${type}.page_title_location`
            case 'studyDomain':
                return `item_offers.${type}.page_title_study_domain`
            case 'freeText':
                return `item_offers.${type}.page_title_free_text`
            case 'quickFilter':
                return `item_offers.${type}.page_title_quick_filter.${selectedLocationTag.value}`
            case 'sector':
                return `item_offers.${type}.page_title_sector`
            }
        }
    }

    const getFilterName = () => {
        if (!selectedLocationTag) return ''

        if (selectedLocationTag.type === 'location') {
            return selectedLocationTag.name
        } else {
            return selectedLocationTag.name.toLowerCase()
        }
    }

    return (
        <Typography component='h1' className={classes.pageTitle} mt={3}>
            <FormattedMessage id={getItemsOfferPageTitle()} values={{ filter: getFilterName() }} />
        </Typography>
    )
}

export default IndexTitle
