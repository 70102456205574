/* eslint-disable indent */
import React from 'react'

import { useStyles } from './TrendMainArticleCard_style'
import { Box, Typography, Chip } from '@material-ui/core'
import { Image, Transformation } from 'cloudinary-react'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'
import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const TrendMainArticleCard = (props) => {
    const classes = useStyles()

    const { article } = props
    return (
        <RouteMapLink showItemType='article' showItemUrl={article.url}>
            <span className={classes.trendMainArticle} role='img' aria-label='sector image'>
                <Image
                    cloudName="studentbe"
                    className={classes.trendImage}
                    publicId={article.cover_image.cloudinary_key}
                >
                    <Transformation flags="progressive" fetchFormat="auto" height={MEDIUM_SIZE} quality="auto:good" crop="fill" />
                    <Transformation effect="gradient_fade" y="-0.5" />
                    <Transformation background="#222222" opacity="90" />
                </Image>
                <Box className={classes.articleInfoContainer}>
                    <Box>
                    <Chip
                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                        className={classes.chip}
                        label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
                        name={article.category}
                    />
                    </Box>
                    <Box className={classes.articleDataContainer}>
                        <Typography variant='h3' className={classes.articleTitle}>
                            {article.title}
                        </Typography>
                        <Box className={classes.readTimeAndDate}>
                            <Box className={classes.readtimeContainer}>
                                <IconFactory
                                    icon='clock'
                                    fontSize='14px'
                                    color='white'
                                    style={{
                                        fontFamily: 'GintoNormal-Medium, sans-serif !important'
                                    }}
                                />
                                <Typography variant='body2' component='span' className={classes.minorText}>
                                    {`${article.read_time} min reading`}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </span>
        </RouteMapLink>
    )
}

export default TrendMainArticleCard
