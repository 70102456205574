import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    root: {
        '--search-bar-width': '850px'
    },
    searchBarContainer: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
    },
    searchBar: {
        boxShadow: 'var(--shadow-paper)',
        width: 'var(--search-bar-width)',
        margin: 'auto',
        height: 84,
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            borderRadius: 16,
            padding: 16
        },
        [theme.breakpoints.down('sm')]: {
            padding: 12,
            width: '100%'
        }
    },
    active: {
        borderRadius: '16px 16px 0 0'
    },
    searchBarInput: {
        backgroundColor: 'var(--light-beige)',
        borderRadius: 10,
        height: '100%',
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    inputProps: {
        fontFamily: "'GintoNormal-Medium', sans-serif",
        padding: 0,
        width: '100%',
        height: '100%',
        fontSize: 14,
        '&::placeholder': {
            opacity: 1,
            fontFamily: "'GintoNormal-Regular', sans-serif !important",
            fontSize: 14,
            fontStyle: 'normal',
            color: 'var(--dark-beige)'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 'unset',
            width: '100%'
        }
    },
    searchBarSupplement: {
        position: 'absolute',
        padding: '12px 16px 16px 16px',
        borderRadius: '0 0 16px 16px',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.up('md')]: {
            left: 'calc((100% - var(--search-bar-width)) / 2 + 0px)',
            width: 'var(--search-bar-width)',
            margin: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    sectionTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8
    },
    sectionTitle: {
        fontFamily: "'GintoNord-Bold', sans-serif  !important"
    },
    sectionCounter: {
        backgroundColor: 'var(--red)',
        color: 'white',
        fontFamily: "'GintoNord-Bold', sans-serif  !important",
        fontSize: 10,
        width: 22,
        height: 22
    },
    bubbleCount: {
        backgroundColor: 'var(--red)',
        color: 'white',
        padding: 3,
        width: 22,
        height: 22,
        fontFamily: "'GintoNord-Bold', sans-serif  !important",
        borderRadius: '100%',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    categoryChipsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 14,
        height: 34,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        cursor: 'pointer'
    },
    articlesContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    menuItem: {
        borderRadius: 6,
        padding: '8px 10px',
        minHeight: 54,
        whiteSpace: 'normal',
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    },
    articleTitle: {
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    }
}))
