import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles((theme) => ({
    titleEvent: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: '24px !important'
    },
    spanBold: {
        fontWeight: 'bold'
    },
    subListLeft: {
        paddingLeft: 15
    },
    scheduleContainer: {
        margin: '50px auto'
    },
    imageStyle: {
        width: '100%'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    partnerBox: {
        alignItems: 'center'
    },
    partnerImageBox: {
        width: '100%',
        '& a': {
            display: 'flex'
        }
    },
    partnerImage: {
        width: '80%',
        objectFit: 'contain',
        margin: '1rem auto',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.05)',
            transition: '.2s'
        }
    }
}))
