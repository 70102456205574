import React, { useState } from 'react'

import { useStyles } from './MobileAppBar_style'
import { useStyles as useDarkModeStyles } from './MobileAppBarDarkMode_style'
import {
    AppBar, Grid, Hidden,
    IconButton, Toolbar
} from '@material-ui/core'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import MobileBurgerMenu from '../MobileBurgerMenu/MobileBurgerMenu'
import ProfileHelper from 'assets/ProfileHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import LogoHelper from 'assets/LogoHelper'

const MobileAppBar = (props) => {
    const { altLangUrls, user, paramsForUrlTranslation, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    const [open, setOpen] = useState(false)

    const openModal = () => {
        setOpen(true)
    }

    const closeModal = () => {
        setOpen(false)
    }

    const renderUserActionsFromUserStatus = () => {
        return user
            ? <RouteMapLink page={ProfileHelper.GetProfileUrl(user.type)}>
                <IconButton edge='end' >
                    <IconFactory icon='account' className={classes.mobileIcon} />
                </IconButton>
            </RouteMapLink>
            : <RouteMapLink page='login'>
                <FilledButton
                    color='secondary'
                    className={classes.loginButton}
                    name='Login'
                />
            </RouteMapLink>
    }

    return (
        <Hidden lgUp>
            <MobileBurgerMenu
                open={open}
                closeModal={closeModal}
                user={user}
                altLangUrls={altLangUrls}
                paramsForUrlTranslation={paramsForUrlTranslation}
            />
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={3} className={classes.gridItem}>
                            <IconButton
                                edge='start'
                                onClick={openModal}
                            >
                                <IconFactory icon='bars' className={classes.mobileIcon} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={6} className={`${classes.gridItem} ${classes.logoContainer}`}>
                            <RouteMapLink page='/'>
                                <img
                                    src={CloudinaryURLHelper.getImageUrl('DEFAULT_LOGO_STUDENT', `student-be-logos/${LogoHelper.getStudentLogo(darkMode)}`)}
                                    alt='Student.be Logo'
                                    className={classes.logoImage}
                                />
                            </RouteMapLink>
                        </Grid>
                        <Grid item xs={3} className={`${classes.gridItem} ${classes.userContainer}`}>
                            {renderUserActionsFromUserStatus()}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Hidden>
    )
}

export default MobileAppBar
