import axios from 'axios'
import { KOT_RECOMMENDED_API } from './env'

const GetRecommendedKots = (data) => {
    return axios({
        url: KOT_RECOMMENDED_API,
        method: 'get',
        params: data
    })
}

export default { GetRecommendedKots }
