/* eslint-disable react/prop-types */
import React from 'react'
import TextFilterView from './TextFilterView'

const TextFilter = (props) => {
    const { debouncedFilterChange, handleFilterChange, filters, setFilters, filterTextId, modal } = props

    const keyPress = (event) => {
        if (event.key === 'Enter') {
            handleFilterChange(filters)
        }
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFilters({ ...filters, [name]: value })
        debouncedFilterChange({ ...filters, [name]: value })
    }

    return (
        <TextFilterView
            handleInputChange={handleInputChange}
            filterTextId={filterTextId}
            keyPress={keyPress}
            filters={filters}
            modal={modal}
        />
    )
}

export default TextFilter
