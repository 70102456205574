import React, { useState, useContext, useEffect, useRef } from 'react'
import ReactOnRails from 'react-on-rails'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import ChatWindow from 'components/ChatWindow/ChatWindow'
import JobShowHelmet from 'helmets/JobShowHelmet'
import SimilarJobs from './components/SimilarJobs/SimilarJobs'
import SimilarArticles from './components/SimilarArticles/SimilarArticles'
import HalfPageAds from './components/HalfPageAds/HalfPageAds'
import LeaderBoardAd from './components/LeaderBoardAd/LeaderBoardAd'
import BottomAd from './components/BottomAd/BottomAd'
import JobShowBanner from './components/JobShowBanner/JobShowBanner'
import ApplyCard from './components/ApplyCard/ApplyCard'
import CompanyJobs from './components/CompanyJobs/CompanyJobs'
import JobShowContent from './components/JobShowContent/JobShowContent'
import LetsChatCard from './components/LetsChatCard/LetsChatCard'
import CompanyPageImages from 'pages/user/CompanyProfile/CompanyPageImages/CompanyPageImages'
import ActionButtons from './components/ActionButtons/ActionButtons'
import JobPassport from './components/JobPassport/JobPassport'
import SectionMainTitle from 'components/SectionMainTitle/SectionMainTitle'
import VerticalVideos from 'components/VerticalVideos/VerticalVideos'
import PreviewBanner from './components/PreviewBanner/PreviewBanner'
import URLParamsHandler from 'assets/URLParamsHandler.js'
import PopupFactory from 'modals/Popup/PopupFactory'

import { useStyles } from './jobShow_style'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'

import { JOB_SHOW } from 'assets/AuthentificationHelper'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { ApplyProcessContext, useApplyProcessContext } from 'shared/contexts/ApplyProcessContext'

const JobShow = (props) => {
    const { user, job, ads, unavailable } = props
    const classes = useStyles()
    const [openChat, setOpenChat] = useState(user?.type === 'student' && URLParamsHandler.loadProcessFromURL() === 'message_to_employer')
    const loginRegisterContext = useContext(LoginRegisterContext)
    const applyProcessContext = useApplyProcessContext(props)
    const [companyImages, setCompanyImages] = useState([])
    const companyImagesDivRef = useRef(null)
    const verticalVideosDivRef = useRef(null)
    const [showApplyCardUnderCompanyImages, setShowApplyCardUnderCompanyImages] = useState(false)
    const MINIMUM_HEIGHT_TO_SHOW_APPLY_CARD_ABOVE_COMPANY_IMAGES = 400

    useEffect(() => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        AnalyticsHelper.sendGTMEvent(
            'Job Details Viewed',
            AnalyticsHelper.constructJobDetailsViewedAnalyticsAttributes(user, job, {
                id: job.company_id,
                name: job.company_name,
                sector: job.sector,
                size: job.company_size
            })
        )
    }, [])

    useEffect(() => {
        if (job.type === 'student_job') {
            const shuffledImages = job.company_images.sort(() => 0.5 - Math.random())
            const selectedImages = shuffledImages.slice(0, 2)
            setCompanyImages(selectedImages)
        } else {
            setCompanyImages(job.company_images)
        }
    }, [])

    const openLoginRegisterModal = () => {
        loginRegisterContext.openLoginRegisterModal({
            origin: JOB_SHOW,
            afterAuthAction: afterAuthAction
        })
    }

    const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'message_to_employer' })
    }

    const handleOpenChat = (value) => {
        if (!user) {
            openLoginRegisterModal()
        } else {
            setOpenChat(value)
        }
    }

    const calculateCompanyImagesDivHeight = () => {
        if (companyImagesDivRef.current) {
            const heightImages = companyImagesDivRef.current.clientHeight
            const heightVideos = verticalVideosDivRef.current.clientHeight
            const height = heightImages + heightVideos
            const showApplycard = height > MINIMUM_HEIGHT_TO_SHOW_APPLY_CARD_ABOVE_COMPANY_IMAGES

            setShowApplyCardUnderCompanyImages(showApplycard)
        }
    }

    return (
        <>
            <JobShowHelmet job={job} noindex={unavailable}/>
            <ApplyProcessContext.Provider value={applyProcessContext}>
                {/* ===== MODALS ===== */}
                {applyProcessContext.showApplyModal &&
                    <PopupFactory
                        type="apply-popup"
                        popupExit={applyProcessContext.toggleApplyPopup}
                        user={user}
                        jobInfo={job}
                        loginRegisterParams={user ? null : applyProcessContext.loginRegisterParams}
                        openShare={applyProcessContext.showApplyModal}
                    />
                }
                <ChatWindow
                    user={user}
                    job={job}
                    handleOpenChat={handleOpenChat}
                    openChat={openChat}
                />
                <JobShowBanner
                    user={user}
                    job={job}
                    unavailable={unavailable}
                />
                <Container maxWidth='lg'>
                    {unavailable && !job.current_user_is_job_owner &&
                    <Paper elevation={4} className={classes.unavailablePaper} >
                        <Typography variant='body2' style={{ color: 'white' }}>
                            <FormattedMessage id='transl_internship.completed_banner' />
                        </Typography>
                    </Paper>
                    }
                </Container>
                <Container maxWidth='lg'>
                    {unavailable && job.current_user_is_job_owner &&
                        <PreviewBanner userValidated={user.validated} />
                    }
                </Container>
                <Hidden mdUp>
                    <Container maxWidth='lg'>
                        <LeaderBoardAd ads={ads} type={job.type}/>
                        {(!unavailable || job.current_user_is_job_owner) && <>
                            <JobPassport
                                job={job}
                                unavailable={unavailable}
                                user={user}
                            />
                            <ActionButtons user={user} job={job} />
                            <JobShowContent job={job} />
                            <Box className={classes.applyCardContainerEnd}>
                                <ApplyCard
                                    job={job}
                                    user={user}
                                    unavailable={unavailable}
                                />
                            </Box>
                        </>}
                    </Container>
                </Hidden>
                <Hidden smDown>
                    {!unavailable &&
                        <Container maxWidth='lg'>
                            <LeaderBoardAd ads={ads} type={job.type}/>
                        </Container>
                    }
                    {(!unavailable || job.current_user_is_job_owner) &&
                        <Container maxWidth='lg' className={classes.desktopContainer}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={3}>
                                    <JobPassport
                                        job={job}
                                        unavailable={unavailable}
                                        user={user}
                                    />
                                    <LetsChatCard
                                        job={job}
                                        user={user}
                                        openChat={openChat}
                                        handleOpenChat={handleOpenChat}
                                        openLoginRegisterModal={openLoginRegisterModal}
                                        unavailable={unavailable}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <JobShowContent job={job} />
                                    <Box className={classes.applyCardContainer}>
                                        <ApplyCard
                                            job={job}
                                            user={user}
                                            unavailable={unavailable}
                                        />
                                    </Box>
                                    <Box ref={verticalVideosDivRef}>
                                        <VerticalVideos
                                            type='company'
                                            cardsNumber={2}
                                            companyId={job.company_self_or_parent_id}
                                        />
                                    </Box>
                                    {(((!unavailable || job.current_user_is_job_owner) && companyImages && companyImages.length > 0)) &&
                                        <Grid container spacing={5}>
                                            <Grid item xs={12} md={12} className={classes.companyImagesTitle}>
                                                <Box ref={companyImagesDivRef}>
                                                    <Box className={classes.companyImages}>
                                                        <SectionMainTitle textId='job_resume.company_images'/>
                                                    </Box>
                                                    <CompanyPageImages
                                                        noTitle
                                                        images={companyImages}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    }
                                    {showApplyCardUnderCompanyImages &&
                                        <Box className={classes.applyCardContainerEnd}>
                                            <ApplyCard
                                                job={job}
                                                user={user}
                                                unavailable={unavailable}
                                            />
                                        </Box>
                                    }
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <HalfPageAds ads={ads} />
                                </Grid>
                            </Grid>
                        </Container>
                    }
                </Hidden>
                <Container maxWidth='lg'>
                    <Box className={classes.companyImagesTitle}>
                        <Hidden mdUp>
                            <LetsChatCard
                                job={job}
                                user={user}
                                openChat={openChat}
                                handleOpenChat={handleOpenChat}
                                openLoginRegisterModal={openLoginRegisterModal}
                                unavailable={unavailable}
                            />
                        </Hidden>
                    </Box>
                </Container>
                <BottomAd ads={ads} type={job.type} placementIndex={2} />
                {job.type === 'first_job' && <>
                    <Box className={classes.topSimilarJobsBox}>
                        <CompanyJobs user={user} job={job} ads={ads}/>
                    </Box>
                    <Box className={classes.bottomAdBox}>
                        <BottomAd ads={ads} type={job.type} placementIndex={3} />
                    </Box>
                    <Box className={classes.similarJobsBox}>
                        <SimilarJobs user={user} job={job} />
                    </Box>
                </>}
                {['internship', 'student_job'].includes(job.type) && <>
                    <Box className={classes.topSimilarJobsBox}>
                        <SimilarJobs user={user} job={job} />
                    </Box>
                    <Box className={classes.bottomAdBox}>
                        <BottomAd ads={ads} type={job.type} placementIndex={3} />
                    </Box>
                    <Box className={classes.similarJobsBox}>
                        <CompanyJobs user={user} job={job} ads={ads}/>
                    </Box>
                </>}
                <Box className={classes.bottomAdBox}>
                    <BottomAd ads={ads} type={job.type} placementIndex={4} />
                </Box>
                <Box className={classes.similarArticlesBox}>
                    <SimilarArticles user={user} baseItem={job} category={job.type} />
                </Box>
            </ApplyProcessContext.Provider>
        </>
    )
}

export default JobShow

JobShow.defaultProps = {
    job: {
        logo: {
            cloudinary_key: 'qmicd21i4d1bgkqogpop'
        },
        company: {
            banner_image_id: 'goii6xdh52hvldjmd19m'
        }
    },
    defaultCloud: 'goii6xdh52hvldjmd19m'
}
