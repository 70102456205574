import React from 'react'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../PersonalDashboard_style'
import FilledButton from 'buttons/FilledButton/FilledButton'

const RecommendedJobsForUserSectionCTA = (props) => {
    const { openProfileEditFormOnStep, titleId, textId, openOnStep } = props
    const classes = useStyles()

    return (<>
        <Typography variant='h6' component='h2' className={classes.recommendedJobsCtaTitle}>
            <FormattedMessage id={titleId} />
        </Typography>
        <Typography variant='h3' className={classes.recommendedJobsCtaText}>
            <FormattedMessage id={textId} />
            <FilledButton
                newStyle
                newStyleWhite
                className={classes.recommendedJobsCtaButton}
                color='white'
                onClick={() => openProfileEditFormOnStep(openOnStep, true)}
                name={<FormattedMessage id='personal_dashboard.recommend_jobs.student_jobs_for_user_school_button' />}
            />
        </Typography>
    </>)
}

export default RecommendedJobsForUserSectionCTA
