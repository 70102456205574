import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {},
    dialogTitleRoot: {
        marginBottom: 8
    },
    dialogContentRoot: {
        paddingBottom: 16
    },
    dialogContentTextRoot: {
        marginBottom: 24
    },
    dividerText: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    jobSelectionForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    },
    companyOption: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
        padding: '8px 16px',
        border: '1px solid var(--grey)',
        borderRadius: 12,
        cursor: 'pointer',
        '&:hover': {
            borderColor: 'var(--red)'
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    selectedCompany: {
        borderColor: 'var(--red)',
        borderWidth: 2
    },
    optionDetails: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        flex: '1 0 50%',
        maxWidth: '50%',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
        }
    },
    companyName: {
        fontSize: '16px !important'
    },
    userName: {
        fontSize: '14px !important',
    },
    submitButton: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}))
