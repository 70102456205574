import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { useStyles } from './contactmain_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SectionMainTitle from 'components/SectionMainTitle/SectionMainTitle'
import ContactHelmet from 'helmets/ContactHelmet'
import ContactForm from 'components/ContactForm/ContactForm'
import { FormattedMessage } from 'react-intl'

const ContactMain = (props) => {
    const { user } = props
    const classes = useStyles()

    return (
        <PageWrapper user={user}>
            <ContactHelmet />
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth="md">
                    <Typography variant="h1" className={classes.titlePage} >
                        <FormattedMessage id="landing_contact_main.page_title" />
                    </Typography>
                </Container>
            </Box>
            <Container maxWidth="lg">
                <Box mb={5}>
                    <SectionMainTitle textId='landing_contact_main.title'/>
                </Box>
                <ContactForm user={user} />
            </Container>
        </PageWrapper>
    )
}
export default ContactMain
