/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from './ApplyCard_style'
import { FormattedMessage } from 'react-intl'
import ApplyButton from '../ApplyButton/ApplyButton'

const ApplyCard = (props) => {
    const { job, user, unavailable } = props
    const classes = useStyles()
    if (unavailable) return null
    if (job.alreadyApplied) return null

    return (
        <Box className={classes.applyBox}>
            <Box>
                <Box className={classes.title}>
                    <FormattedMessage id='job_show.apply_now.title' />
                </Box>
            </Box>
            <Box className={classes.buttonBox}>
                <ApplyButton
                    parentComponent={'ApplyButton'}
                    job={job}
                    user={user}
                    unavailable={unavailable}
                    newStyleWhite
                    flexColumn
                />
            </Box>
        </Box>
    )
}

export default ApplyCard
