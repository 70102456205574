import RouteMap from './RouteMap'
import CollectionHandler from './CollectionHandler'
import DocumentAPI from 'api/DocumentAPI'

const widgetText = CollectionHandler.Get('cloudinaryWidgetText')
const CLOUDINARY_PUBLIC_KEY = 652387696778223
const TEN_MEGABYTE = 10485760
const FIVE_MEGABYTE = 5242880

const cloudinaryStyle = () => {
    return {
        palette: {
            window: '#F1EDE8',
            windowBorder: '#212121',
            tabIcon: '#FF6762',
            menuIcons: '#020202',
            textDark: 'var(--black)',
            textLight: '#FFFFFF',
            link: '#FF6762',
            action: '#FF620C',
            inactiveTabIcon: '#212121',
            error: '#F44235',
            inProgress: '#FF6762',
            complete: '#20B832',
            sourceBg: '#FFFFFF'
        },
        fonts: {
            default: null,
            "'GintoNord-Bold', sans-serif": {
                url: null,
                active: true
            }
        }
    }
}

const cloudinaryText = () => {
    return widgetText
}

const createImageUploadWidget = (options, innerFunction) => {
    if (typeof cloudinary === 'undefined') return null

    return cloudinary.createUploadWidget({
        cloudName: 'studentbe',
        uploadPreset: 'y4bgjukh',
        sources: [
            'local',
            'url',
            'camera',
            'google_drive',
            'dropbox'
        ],
        cropping: options.cropping, // Allows picture cropping after selection
        showAdvancedOptions: false,
        multiple: options.multiple,
        defaultSource: 'local',
        styles: cloudinaryStyle(),
        language: RouteMap.GetLocaleFromUrl(),
        text: cloudinaryText(),
        maxFileSize: TEN_MEGABYTE
    }, (error, result) => {
        innerFunction(error, result)
    })
}

const createDocumentUploadWidget = (options, innerFunction) => {
    if (typeof cloudinary === 'undefined') return null

    const defaultOptions = {
        api_key: CLOUDINARY_PUBLIC_KEY,
        cloudName: 'studentbe',
        uploadPreset: 'upload_pdf_preset',
        sources: [
            'local'
        ],
        cropping: false, // Allows picture cropping after selection
        showAdvancedOptions: false,
        multiple: false,
        defaultSource: 'local',
        styles: cloudinaryStyle(),
        language: RouteMap.GetLocaleFromUrl(),
        text: cloudinaryText(),
        uploadSignature: generateSignature,
        maxFileSize: FIVE_MEGABYTE
    }
    return cloudinary.createUploadWidget(
        {
            ...defaultOptions,
            ...options
        }, (error, result) => {
            innerFunction(error, result)
        }
    )
}

const generateSignature = (callback, params) => {
    DocumentAPI.getSignature(params).then(response => {
        return callback(response.data.signature)
    })
}

export default { createImageUploadWidget, createDocumentUploadWidget }

/* ===== DOC =====

    USAGE :

    Two functions are exported in the module:
    * createImageUploadWidget
    * createDocumentUploadWidget

    var myWidget = CloudinaryHelper.createImageUploadWidget(
        {
            cropping: false,
            multiple: false
        },
        (error, result) => {
            if (!error && result && result.event === 'success') {
                console.log('Done! Here is the image info: ', result.info)
                this.setState({
                    cloudinaryKey: result.info.public_id,
                    updateComponent: true
                }, () => {
                    this.updateImage(result.info)
                })
            }
        }
    )

    var myWidget = cloudinary.createDocumentUploadWidget(
        {},
        (error, result) => {
            if (!error && result && result.event === 'success') {
                console.log('Done! Here is the document info: ', result.info)
                this.updateDocument(result.info)
            }
        }
    )

    Mandatory:
        * createImageUploadWidget:
            @option: a hash object that contains <cropping> and <multiple> boolean
            @innerFunction: the then/catch function called after the axios request
        * createDocumentUploadWidget:
            @option: a hash object that contains all options
            @innerFunction: same as createImageUploadWidget

    OPTIONAL:
        None

    ===== END =====
*/
