/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import CityFilter from '../shared/CityFilter/CityFilter'
import TextFilter from '../shared/TextFilter/TextFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import StudyRelevantFilter from '../shared/StudyRelevantFilter/StudyRelevantFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersWrapper from 'components/Searchbar/shared/FiltersWrapper/FiltersWrapper'
import StudentJobFiltersModal from 'modals/FilterModals/StudentJobFiltersModal/StudentJobFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import StudentJobQuickFilters from './StudentJobQuickFilters'

const StudentJobFiltersView = (props) => {
    const { offerCount, debouncedFilterChange, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showStudentJobFiltersModal, setShowStudentJobFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studentJobSchedulesCollection = CollectionHandler.Get('studentJobSchedulesCollection')

    return (
        <Box className={classes.container}>
            <StudentJobFiltersModal
                showModal={showStudentJobFiltersModal}
                setShowModal={setShowStudentJobFiltersModal}
                debouncedFilterChange={debouncedFilterChange}
                handleFilterChange={handleFilterChange}
                filters={filters}
                offerCount={offerCount}
                handleInputSearchCity={handleInputSearchCity}
                searchCities={searchCities}
                citiesInput={citiesInput}
                setSearchCities={setSearchCities}
                setCitiesInput={setCitiesInput}
                setFilters={setFilters}
                reset={reset}
            />
            <FiltersWrapper type={type} selectedLocationTag={selectedLocationTag}>
                <Box className={classes.filters}>
                    <Box className={classes.filterBox}>
                        <TextFilter
                            debouncedFilterChange={debouncedFilterChange}
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            setFilters={setFilters}
                            filterTextId='search_bar.search.job'
                        />
                    </Box>
                    <Hidden xsDown mdUp>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='studyDomain'
                                collection={studyDomainCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={400}
                                filterTextId='job_filters.study_fields.title'
                            />
                        </Box>
                    </Hidden>
                    <Hidden xsDown>
                        <Box className={classes.filterBox}>
                            <CityFilter
                                handleInputSearchCity={handleInputSearchCity}
                                searchCities={searchCities}
                                citiesInput={citiesInput}
                                setSearchCities={setSearchCities}
                                setCitiesInput={setCitiesInput}
                                setFilters={setFilters}
                                filters={filters}
                                width={350}
                            />
                        </Box>
                    </Hidden>
                    <Hidden smDown>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='jobSchedule'
                                collection={studentJobSchedulesCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='job_filters.schedule.title'
                            />
                        </Box>
                    </Hidden>
                    <MoreFilters setShowModal={setShowStudentJobFiltersModal} filters={filters}/>
                    <ResetFilters reset={reset}/>
                </Box>
                <Box mb={3}>
                    <StudyRelevantFilter
                        typeOfJob={type}
                        filters={filters}
                        setFilters={setFilters}
                    />
                </Box>
            </FiltersWrapper>
            <Container maxWidth='lg'>
                <StudentJobQuickFilters
                    filters={filters}
                    setFilters={setFilters}
                />
            </Container>
        </Box>
    )
}

export default StudentJobFiltersView
