export const useStyles = (theme) => ({
    companyProfileBannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            marginBottom: '15px',
            flexDirection: 'column'
        }
    },
    logoContainer: {
        margin: '20px 0',
        marginRight: '30px',
        padding: '15px',
        border: '1px solid var(--light-grey)',
        boxShadow: '5px 5px 10px 0px var(--light-grey)',
        width: 'fit-content',
        '& img': {
            borderRadius: '4px',
            height: '25px'
        }
    },
    companyName: {
        [theme.breakpoints.down("md")]: {
            margin: '25px 0',
            textAlign: 'center'
        }
    },
    buttonContainer: {
        [theme.breakpoints.between("sm", "md")]: {
            margin: '1rem 0'
        },
        [theme.breakpoints.down("xs")]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        '& a:last-child': {
            [theme.breakpoints.up("sm")]: {
                marginLeft: '15px',
            },
            [theme.breakpoints.down("xs")]: {
                marginTop: '0.5rem',
            }
        }
    }
})
