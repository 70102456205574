import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    mainContainer: {
        backgroundColor: 'var(--super-dark-grey)',
        display: 'flex',
        justifyContent: 'center'
    },
    subtitle2: theme.typography.h6,
    banner: {
        marginTop: 40
    },
    bannerText: {
        color: 'white',
    },
    bannerPaper: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'var(--red)',
        padding: '12px 16px'
    },
    similarJobs: {
        width: '100%'
    },
    similarJobsBox: {
        paddingTop: 40,
        paddingBottom: 24,
        backgroundColor: 'var(--super-dark-grey)'
    },
    similarArticlesBox: {
        paddingTop: 24,
        paddingBottom: 80,
        backgroundColor: 'var(--super-dark-grey)'
    }
}))
