import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import SwipeableViews from 'react-swipeable-views'

import { useStyles } from './CardImageCarousel_style'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'

const CardImageCarousel = ({ kot }) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const maxSteps = kot.images?.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === maxSteps - 1
                ? (prevActiveStep = 0)
                : prevActiveStep + 1
        )
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === 0
                ? (prevActiveStep = maxSteps - 1)
                : prevActiveStep - 1
        )
    }
    const handleStepChange = (step) => {
        setActiveStep(step)
    }

    return (
        <Box className={classes.topContainer}>
            <Box className={classes.arrowLeft}>
                <IconButton
                    onClick={handleBack}
                    className={classes.arrowBtn}
                >
                    <KeyboardArrowLeft
                        fontSize='medium'
                        className={classes.arrows}
                    />
                </IconButton>
            </Box>
            <Box className={classes.arrowRight}>
                <IconButton
                    onClick={handleNext}
                    className={classes.arrowBtn}
                >
                    <KeyboardArrowRight
                        fontSize='medium'
                        className={classes.arrows}
                    />
                </IconButton>
            </Box>
            <Box className={classes.kotPriceContainer}>
                {kot.active
                    ? <Typography component='p' className={classes.kotPrice}>
                        {kot.charges === 0
                            ? <>€{kot.rent}</>
                            : <>€{kot.rent} (+€{kot.charges})</>
                        }
                    </Typography>
                    : <Typography component='p' className={classes.kotPrice}>
                        <FormattedMessage id='transl_internship.kot_completed'/>
                    </Typography>
                }
            </Box>
            <SwipeableViews
                axis='x'
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {kot.images.map((step, key) => {
                    return (
                        <CardMedia
                            key={key.toString()}
                            className={classes.media}
                            image={CloudinaryURLHelper.getImageUrl(
                                'KOT_CARD',
                                step.cloudinary_key
                            )}
                            alt={step.cloudinary_key}
                            id={'img-' + key}
                        />
                    )
                })}
            </SwipeableViews>
        </Box>
    )
}

export default CardImageCarousel
