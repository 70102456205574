import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectedLocationTag: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 50,
        backgroundColor: 'var(--red)',
        color: 'white',
        padding: '6px 8px'
    },
    unSelectedToggleTag: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 50,
        backgroundColor: 'var(--beige)',
        padding: '6px 8px'
    },
    tagCross: {
        marginRight: 10
    }
}))
