import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './HomeStats_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import SVGFactory from 'icons/SVGFactory/SVGFactory'

const HomeStats = (props) => {
    const classes = useStyles()
    const HOME_STATS = {
        student_job: 4597,
        internship: 924,
        first_job: 831,
        company: 5443
    }

    const formatNumber = (number) => {
        return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

    return (
        <Box className={classes.statBoxContainer}>
            <Box className={classes.statBox}>
                <SVGFactory className={classes.statIcon} name="student-job-stat" />
                <Box className={classes.statText}>
                    <Typography variant="h2" component="p" className={classes.stat}>{formatNumber(HOME_STATS.student_job)}</Typography>
                    <Typography variant="body1" className={classes.statDescription}><FormattedMessage id="landing_student.student_jobs" /></Typography>
                </Box>
            </Box>
            <Box className={classes.statBox}>
                <SVGFactory className={classes.statIcon} name="internship-stat" />
                <Box className={classes.statText}>
                    <Typography variant="h2" component="p" className={classes.stat}>{formatNumber(HOME_STATS.internship)}</Typography>
                    <Typography variant="body1" className={classes.statDescription}><FormattedMessage id="landing_student.internships" /></Typography>
                </Box>
            </Box>
            <Box className={classes.statBox}>
                <SVGFactory className={classes.statIcon} name="first-job-stat" />
                <Box className={classes.statText}>
                    <Typography variant="h2" component="p" className={classes.stat}>{formatNumber(HOME_STATS.first_job)}</Typography>
                    <Typography variant="body1" className={classes.statDescription}><FormattedMessage id="landing_student.first_jobs" /></Typography>
                </Box>
            </Box>
            <Box className={classes.statBox}>
                <SVGFactory className={classes.statIcon} name="enterprise-stat" />
                <Box className={classes.statText}>
                    <Typography variant="h2" component="p" className={classes.stat}>{formatNumber(HOME_STATS.company)}</Typography>
                    <Typography variant="body1" className={classes.statDescription}><FormattedMessage id="landing_student.companies" /></Typography>
                </Box>
            </Box>
        </Box>
    )
}
export default HomeStats
