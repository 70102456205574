import { makeStyles } from '@material-ui/core/styles'

export const useStyles = theme => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: '#FF6762',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        color: '#FFFFFF',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
        }
    },
    pageContainer: {

        marginBottom: '5rem'
    },
    titleCGU: {
        marginBottom: 15,
        textAlign: 'initial'
    },
    CGUBox: {
        padding: '15px 0',
    },
    listContainer: {
        margin: '20px 0px 0px 40px',
        "& p": {
            marginBottom: '7px',
            "&:before": {
                display: 'inline-block',
                content: '""',
                backgroundColor: 'rgba(165, 165, 165, 0.88) !important',
                height: 7,
                width: 7,
                borderRadius: 100,
                margin: '0 0.9rem 0.05rem -1.35rem'
            }
        }
    },
    listContainer2: {
        margin: '20px 0px 20px 40px',
        "& p": {
            "&:before": {
                backgroundColor: 'initial !important',
                border: '2px solid rgba(165, 165, 165, 0.88) !important'
            },
        }
    },
    link: {
        textDecoration: 'underline'
    },
    breakline: {
        marginBottom: '1.43rem'
    },
    breaklines: {
        "& p": {
            marginBottom: '1.43rem',
            "&:last-child": {
                marginBottom: 0
            }
        }
    }
})
