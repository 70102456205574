import React, { useState } from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'

import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'

import KotListCardDelete from './components/kotListCardDelete'
import KotOwnerAPI from 'api/KotOwnerAPI'
import PopupFactory from 'modals/Popup/PopupFactory'
import IconFactory from 'icons/IconFactory/IconFactory'

import KotCard from '../KotCard/KotCard'

import { useStyles } from './KotListCardAlt_style'
import { FormattedMessage } from 'react-intl'

import axios from 'axios'

const KotListCardAlt = (props) => {
    const { kot } = props

    const classes = useStyles()
    const [status, setStatus] = useState(kot.active)
    const [open, setOpen] = useState(false)
    const [openShare, setOpenShare] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClickOpenShare = () => {
        setOpenShare(true)
    }
    const handleActive = () => {
        const data = {
            update_active: true,
            active: !status, // considere that everyone are the same
            type: kot.kot_type,
            id: [kot.id]
        }
        // eslint-disable-next-line no-undef
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        KotOwnerAPI.KotOwnerKot('edit', data).then(
            () => {
                setStatus(!status)
            })
    }

    const renderPopup = () => {
        setOpenShare((openShare) => !openShare)
    }

    const [isHidden, setIsHidden] = useState('block')
    const duplicate = () => {
        // eslint-disable-next-line no-undef
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        console.log(kot.id)
        KotOwnerAPI.KotOwnerKotDuplicate(kot.id)
            .then(
                (response) => {
                    // document.location.reload(true)
                    window.open(RouteMap.Edit('kot', response.data.duplicated_kot_id))
                }
            )
            .catch(
                (e) => { console.log(e) }
            )
    }
    console.log(kot)

    return (
        <>
            <Grid item xs={12} md={6} lg={4} xl={4} style={{ display: isHidden, filter: status ? 'grayscale(0)' : 'grayscale(1)' }}>
                {/* START CARD KOT */}

                <KotCard
                    {...props}
                />

                {/* START END CARD KOT */}
                {/* START MENU OPTIONS */}
                <Box mt={1} >
                    {/* TODO: ==== STATS V2 =====  */}
                    {/* <Box mt={2} display={'flex'} justifyContent={'center'}>
                        <Box display={'flex'} justifyContent={'space-between'} className={classes.stats}>
                            <Typography paragraph component={'div'} >
                                {kot.index_count}
                            </Typography>
                            <Tooltip title="Vues">
                                <IconFactory icon='view' className={classes.iconFix} />
                            </Tooltip>

                        </Box>
                        <Box ml={2} display={'flex'} justifyContent={'space-between'} className={classes.stats}>
                            <Typography
                                paragraph
                                component={'div'}
                            >
                                {kot.show_count}
                            </Typography>
                            <Tooltip title="Clics">
                                <EqualizerSharpIcon className={classes.iconFix} />
                            </Tooltip>
                        </Box>
                        <Box ml={2} display={'flex'} justifyContent={'space-between'} className={classes.stats}>
                            <Typography
                                paragraph
                                component={'div'}
                            >
                                {kot.numberOfFavorites}
                            </Typography>
                            <Tooltip title="Favoris">
                                <FavoriteSharpIcon className={classes.iconFix} />
                            </Tooltip>
                        </Box>
                    </Box> */}
                    <Box display={'flex'} justifyContent={'center'}>
                        <Box>
                            <RouteMapLink showItemType='kot' showItemUrl={kot.url} >
                                <Tooltip title={<FormattedMessage id="kot_list_card_alt.tooltips.see" />}>
                                    <IconButton
                                        aria-label="VisibilityIcon"
                                        color={kot.active ? 'secondary' : 'primary.light'}
                                        className={classes.iconCard}>
                                        <IconFactory icon='view'/>
                                    </IconButton>
                                </Tooltip>
                            </RouteMapLink>
                            <Tooltip title={<FormattedMessage id="kot_list_card_alt.tooltips.share" />}>
                                <IconButton
                                    aria-label="ExitToAppIcon"
                                    color={kot.active ? 'secondary' : 'primary.light'}
                                    className={classes.iconCard}
                                    onClick={handleClickOpenShare}>
                                    <IconFactory icon='share' />
                                </IconButton>
                            </Tooltip>
                            <RouteMapLink itemType='kot' editItemId={kot.id} target="_blank" >
                                <Tooltip title={<FormattedMessage id="kot_list_card_alt.tooltips.edit" />}>
                                    <IconButton
                                        aria-label="EditIcon"
                                        color={kot.active ? 'secondary' : 'primary.light'}
                                        className={classes.iconCard}>
                                        <IconFactory icon='edit' />
                                    </IconButton>
                                </Tooltip>
                            </RouteMapLink>
                            <Tooltip title={<FormattedMessage id="kot_list_card_alt.tooltips.duplicate" />}>
                                <IconButton
                                    onClick={duplicate}
                                    aria-label="FileCopyIcon" color={kot.active ? 'secondary' : 'primary.light'}
                                    className={classes.iconCard}>
                                    <IconFactory icon='duplicate' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="kot_list_card_alt.tooltips.delete" />}>
                                <IconButton
                                    aria-label="FileCopyIcon"
                                    color={kot.active ? 'secondary' : 'primary.light'}
                                    className={classes.iconCard}
                                    onClick={handleClickOpen}>
                                    <IconFactory icon='delete' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id={`kot_list_card_alt.tooltips.${status ? 'deactivate' : 'activate'}`} />}>
                                <Switch size='small' checked={status} onClick={handleActive} color={kot.active ? 'secondary' : 'primary.light'} />
                            </Tooltip>
                        </Box>
                    </Box>

                </Box>
                {/* END MENU OPTIONS */}

            </Grid>
            {
                openShare &&
                    <PopupFactory
                        type="share-offer"
                        popupExit={renderPopup}
                        infos={{
                            title: kot.title,
                            url: RouteMap.Show(kot.type, kot.url),
                            type: kot.type
                        }}
                        openShare={openShare}
                    />
            }
            <KotListCardDelete
                setIsHidden={setIsHidden}
                kot={kot}
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

export default KotListCardAlt
