/* eslint-disable react/prop-types */
import React from 'react'

import Map from 'wrappers/MapProvider/components/Map/Map'

import CardContainer from 'containers/CardContainer/CardContainer'
import ListContainer from 'containers/ListContainer/ListContainer'
import RecentArticles from 'containers/RecentArticles/RecentArticles'
import AdBanner from 'components/AdBanner/AdBanner'
import NewOfferButton from 'components/NewOfferButton/NewOfferButton'
import ArticleBanner from '../ArticleBanner/ArticleBanner'
import AlertAd from '../AlertAd/AlertAd'
import SEOBlock from '../SEOBlock/SEOBlock'
import AlertStickyButton from '../AlertStickyButton/AlertStickyButton'

import css from './itemindex.scss'
import css_responsive from './itemindex_responsive.scss'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Hidden from '@material-ui/core/Hidden'

import { FormattedMessage } from 'react-intl'
import RouteMap from 'assets/RouteMap'
import VerticalVideos from 'components/VerticalVideos/VerticalVideos'

class ItemIndex extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            showAlertButton: true
        }
        this.showMore = this.showMore.bind(this)
        this.createListContainers = this.createListContainers.bind(this)
        this.createCardContainers = this.createCardContainers.bind(this)
    }

    showMore () {
        const allList = document.getElementsByClassName('page-list-to-hide')
        const allCard = document.getElementsByClassName('page-card-to-hide')
        let pageToShow = 3
        if (window.innerWidth <= 1140) {
            pageToShow = 2
        }
        for (let i = 0; i < pageToShow; i++) {
            if (allList.length) {
                allList[0].classList.add('page-list-to-show')
                allList[0].classList.remove('page-list-to-hide')
            }
            if (allCard.length) {
                allCard[0].classList.add('page-card-to-show')
                allCard[0].classList.remove('page-card-to-hide')
            }
        }
        if (allList.length === 0) {
            const showMore = document.getElementsByClassName('show-more-container')
            showMore[0].classList.add('hide')
            showMore[1].classList.add('hide')
        }
    }

    createListContainers (itemList, pageToShow) {
        var listContainers = []
        for (var i = 0; i < itemList.length; i++) {
            listContainers.push(
                <Box key={'listcontainer_' + i} className={'page-list-to-' + (i < pageToShow ? 'show' : 'show')}>
                    <ListContainer
                        showAds
                        ads={this.props.ads}
                        key={'listcontainer_' + i}
                        type={this.props.type}
                        userType={this.props.userType}
                        displayShow={this.props.displayShow}
                        listCards={itemList[i]}
                        mapProviderProps={this.props.mapProviderProps}
                        index={i}
                    />
                    { this.props.type !== 'company' &&
                        <Box id={`listAds-mobile-${i}`} mb={4} mt={3} >
                            {this.listAds(i)}
                        </Box>
                    }
                    {false && this.createCardSlider(i)}
                </Box>
            )
        }
        return listContainers
    }

    createCardContainers (itemList, pageToShow) {
        var cardContainer = []
        for (var i = 0; i < itemList.length; i++) {
            cardContainer.push(
                <Box
                    className={'page-card-to-' + (i < pageToShow ? 'show' : 'show')}
                    key={'cardcontainer_' + i}
                    ref={i === itemList.length - 1 && this.props.jumpingRef}
                    id={`cardContainer_${i}`}
                >
                    {i !== 0 &&
                        <Box id={`listAds-desktop-${i}`} mb={4} mt={3} >
                            {this.listAds(i)}
                        </Box>
                    }
                    <CardContainer
                        key={'cardcontainer_' + i}
                        type={this.props.type}
                        displayShow={this.props.displayShow}
                        cards={itemList[i]}
                        userAddress={this.props.userAddress}
                        userType={this.props.userType}
                        pageName={this.props.pageName}
                        mapProviderProps={this.props.mapProviderProps}
                    />
                    {false && this.createCardSlider(i)}
                </Box>
            )
        }
        return cardContainer
    }

    halfPageAds () {
        const locale = RouteMap.GetLocaleFromUrl()
        var ads = []
        var places = []
        var localizedAds = this.props.ads.filter(ad => ad.langage === locale)
        for (const [adIndex, ad] of localizedAds.entries()) {
            for (const [placeIndex, place] of ad.places.entries()) {
                if (place.includes('Index_Half page')) {
                    places.push(
                        place
                    )
                }
            }
        }
        if (places.length === 0 && this.props.type !== 'article') {
            return (
                <Box pl={2}>
                    <RecentArticles key='recent-articles-kot' category={this.props.type}/>
                </Box>
            )
        }
        places.sort()
        for (const [index, place] of places.entries()) {
            if (this.props.type === 'article') {
                ads.push(
                    <AdBanner
                        key={`ad-banner-article-${index}`}
                        ads={localizedAds}
                        place={place}
                        device='desktop'
                        style={{ marginBottom: 20 + 'px' }}
                        width={300}
                    />
                )
            } else {
                ads.push(
                    <AdBanner
                        key={`ad-banner-${index}`}
                        ads={localizedAds}
                        place={place}
                        device='desktop'
                        width={300}
                        noMarginBottom
                    />
                )
                if (index === 0) {
                    ads.push(
                        <Box px={4} style={{ width: '100%' }}>
                            <RecentArticles
                                key={`recent-articles-${index}`}
                                category={this.props.type}
                            />
                        </Box>
                    )
                }
            }
        }
        return ads
    }

    listAdForArticleTag () {
        // Have to ask Florence if she wants to be able to display different ads depending on the tag
        switch (this.props.selectedArticleTagValue) {
        case 'first_job':
            return 'Blog_Index_Leaderboard_'
        case 'internship':
            return 'Blog_Index_Leaderboard_'
        default:
            return 'Blog_Index_Leaderboard_'
        }
    }

    listAdForJobType () {
        switch (this.props.type) {
        case 'first_job':
            return 'Firstjobs_Index_Leaderboard_'
        case 'internship':
            return 'Internships_Index_Leaderboard_'
        case 'student_job':
            return 'Studentjobs_Index_Leaderboard_'
        case 'kot':
            return 'Kots_Index_Leaderboard_'
        default:
            break
        }
    }

    listAds (index) {
        const place = this.listAdForArticleTag() + index
        // index = index + 2 because xxx_Index_Leaderboard_1 is the ad above filters
        if (this.props.type === 'article' && this.props.selectedArticleTagObject?.value === 'education' && index === 1) {
            return <VerticalVideos cardsNumber={3} type='education_index' />
        }
        const isIndexEven = index % 2 === 0
        if (this.props.type !== 'article' && ['Mobile', 'Leaderboard'].includes(place.split('_')[2])) {
            const isJobType = ['first_job', 'student_job', 'internship'].includes(this.props.type)

            if (isJobType && !isIndexEven) {
                return <ArticleBanner offset={index / 2} category={this.props.type} />
            }
            return isJobType && (this.props.userType === 'student' || !this.props.user)
                ? <AlertAd type={this.props.type} openAlertJobAd={this.props.openAlertJobAd} />
                : <ArticleBanner offset={index} category={this.props.type} />
        }
        index = index + 2
        switch (this.props.type) {
        case 'article':
            return <AdBanner
                ads={this.props.ads}
                key={this.listAdForArticleTag()}
                place={place}
                device='desktop' width={728}
            />
        default:
            return <AdBanner
                ads={this.props.ads}
                key={this.listAdForJobType()}
                place={this.listAdForJobType() + index}
                device='desktop' width={728}
            />
        }
    }

    showMoreClick () {
        this.props.filterItems(false)
        if (this.state.showAlertButton) {
            this.props.openAlertJobSeeMoreBtn()
            this.setState({ showAlertButton: false })
        }
    }

    leaderBoardAdPlace () {
        switch (this.props.type) {
        case 'first_job':
            return 'Firstjobs_Index_'
        case 'internship':
            return 'Internships_Index_'
        case 'student_job':
            return 'Studentjobs_Index_'
        case 'kot':
            return 'Kots_Index_'
        default:
        }
    }

    render () {
        // Chunk the item list into arrays of 9 items
        var chunckedItems = []
        var i; var j; var temparray; var chunk = 9
        for (i = 0, j = this.props.items.length; i < j; i += chunk) {
            temparray = this.props.items.slice(i, i + chunk)
            chunckedItems.push(temparray)
        }

        // Constructing the list of ListContainer components
        let pageToShow = 3
        if (window.innerWidth <= 1140) {
            pageToShow = 2
        }

        var listContainers = this.createListContainers(chunckedItems, pageToShow)

        var cardContainer = this.createCardContainers(chunckedItems, pageToShow)

        // Constructing the list of CardContainer & LeaderBoard components
        let showMore
        if (this.props.loadMoreStatus) {
            showMore = (
                <Box my={4} display={'flex'} justifyContent={'center'} >
                    {!this.props.loadingMore &&
                        <Button style={{ maxWidth: 300 }} fullWidth color={'primary'} variant={'contained'} onClick={() => this.showMoreClick()}>
                            <FormattedMessage id="item_index.show_more_button" />
                        </Button>
                    }
                </Box>
            )
        }

        return (
            <Box className="item-index-container">
                <Box className={'cards-and-list ' + this.props.type + '-type'} >
                    {this.props.loading &&
                            <div className="loading-cirular">
                                <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                            </div>
                    }
                    {!this.props.loading && this.props.items.length > 0 &&
                            <>
                                {Array.isArray(this.props.ads) &&
                                    this.props.ads.length > 0 &&
                                    <>
                                        <AdBanner ads={this.props.ads} place={this.leaderBoardAdPlace() + 'Leaderboard_1'} device='desktop' width={728} />
                                        <AdBanner ads={this.props.ads} place={this.leaderBoardAdPlace() + 'Mobile_1'} device='mobile' width={320} />
                                    </>
                                }
                                <Hidden smDown>
                                    <Box className='buttonsMobileContainer'>
                                        <NewOfferButton
                                            userType={this.props.userType}
                                            type={this.props.type}
                                        />
                                        {this.props.alertButton}
                                    </Box>
                                </Hidden>
                                {
                                    listContainers &&
                                    <Box className="item-index-list closed" ref={this.props.listRef}>
                                        {listContainers}
                                        {showMore}
                                    </Box>
                                }
                                <Box className="item-index-cards" ref={this.props.cardRef}>
                                    {cardContainer}
                                    {showMore}
                                </Box>
                            </>
                    }
                    {this.props.loadingMore &&
                            <Box display='flex' justifyContent='center' fullWidth my={4}>
                                <CircularProgress color='primary' style={{ width: '80px', height: '80px' }}/>
                            </Box>
                    }
                    {!this.props.loading && this.props.items.length === 0 &&
                            <Box mt={4} mb={8}>
                                <Typography variant='body2'>
                                    <FormattedMessage id="item_index.no_found_offer" />
                                </Typography>
                            </Box>
                    }
                    <SEOBlock
                        type={this.props.type}
                        selectedLocationTag={this.props.selectedLocationTag}
                    />
                </Box>
                <Box className="item-index-right-panel">
                    {((this.props.type === 'kot' && this.props.isCheckMap) || this.props.type !== 'kot') &&
                            <Box className="half-page-ads">
                                {['first_job', 'internship', 'student_job', 'kot', 'article'].includes(this.props.type) &&
                                    <>
                                        <Box className='buttons-container'>
                                            {this.props.alertButton}
                                            <NewOfferButton
                                                userType={this.props.userType}
                                                type={this.props.type}
                                            />
                                        </Box>
                                        {this.halfPageAds()}
                                    </>
                                }
                            </Box>
                    }
                    {this.props.itemsGeocoding && ['kot'].includes(this.props.type) && !this.props.isCheckMap &&
                            <Box className={'map-container map-shown'}>
                                <Map
                                    type={this.props.type}
                                    itemsGeocoding={this.props.itemsGeocoding}
                                    ref={this.props.mapRef}
                                    mapProviderProps={this.props.mapProviderProps}
                                />
                            </Box>
                    }
                </Box>
                {['first_job', 'student_job', 'internship'].includes(this.props.type) && (this.props.userType === 'student' || !this.props.user) &&
                    <Hidden smUp>
                        <AlertStickyButton stickyAlertBtn={this.props.stickyAlertBtn} type={this.props.type}/>
                    </Hidden>
                }
            </Box>
        )
    }
}
export default ItemIndex
