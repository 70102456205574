import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 12
    },
    itemLineBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        fontFamily: "'GintoNormal-Medium', sans-serif  !important",
        fontSize: '12px !important',
        whiteSpace: 'break-spaces'
    },
    chipLabelText: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 8,
        alignItems: 'center',
        fontFamily: "'GintoNormal-Medium', sans-serif  !important",
        fontSize: '12px !important',
        whiteSpace: 'break-spaces'
    }
}))
