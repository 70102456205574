export const styles = theme => ({
    cardContainer: {
        marginBottom: 20,
        padding: 0,
        cursor: 'pointer',
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    cardMediaContainer: {
        minWidth: '40%',
        paddingLeft: '40%'
    },
    cardContentContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '8px 16px !important',
        minHeight: 150
    },
    cardContentBox: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%'
    },
    typographyDate: {
        color: 'var(--grey)',
        fontSize: 12
    },
    typographyTitle: {
        fontSize: 14,
        margin: '0 !important'
    },
    categoryBoxContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    articleTitleBox: {
        display: '-webkit-box',
        lineClamp: '4',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    articleListLink: {
        width: '100%',
        background: 'black'
    }
})
