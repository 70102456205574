export const styles = (theme) => ({
    wrapper: {
        width: '100%'
    },
    cardRoot: {
        display: 'flex',
        flexDirection: 'column',
        height: 330,
        minWidth: 280,
        maxWidth: 320,
        width: '100%',
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12,
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    kotCityAndRoom: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 16
    },
    titleRoom: {
        textAlign: 'left',
        marginLeft: 8,
        fontFamily: "'GintoNormal-Regular', sans-serif !important"
    },
    kotRoom: {
        marginRight: 30,
        display: 'flex',
        alignItems: 'center'
    },
    kotCity: {
        display: 'flex',
        alignItems: 'center'
    },
    titleCity: {
        textTransform: 'capitalize',
        textAlign: 'left',
        marginLeft: 8,
        fontFamily: "'GintoNormal-Regular', sans-serif !important",
        width: 100,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    kotCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 16
    },
    kotTitleAndFavourite: {
        display: 'flex',
        flexDirection: 'row'
    },
    kotTitle: {
        flexGrow: 1,
        overflow: 'hidden',
        textOverflow: 'clip',
        lineHeight: 1.4,
        textTransform: 'capitalize',
        maxHeight: 'calc(16px * 1.4 * 3)' // title max 2 lines -> calc(fontSize * lineHeight * number of lines)
    },
    kotFavourite: {
        display: 'flex',
        alignItems: 'baseline'
    },
    kotDetails: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: 'var(--medium-grey)',
        whiteSpace: 'nowrap',
        overflowX: 'clip'
    },
    type: {
        fontFamily: "'GintoNormal-Regular', sans-serif !important",
        fontSize: '14px !important',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.46)'
    }
})
