import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    dropdownContainer: {
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        gap: 12
    },
    select: {
        padding: 12,
        fontSize: 14,
        borderRadius: 20,
        fontFamily: "'GintoNord-Normal', sans-serif",
        color: 'var(--dark-beige)',
        backgroundColor: 'var(--beige)',
        '&:focus': {
            background: 'var(--beige) !important',
            borderRadius: '20px !important'
        }
    },
}))
