/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Image, Transformation } from 'cloudinary-react'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import { useStyles } from '../JobShowBanner_style'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ApplyButton from '../../ApplyButton/ApplyButton'
import FilledButton from 'buttons/FilledButton/FilledButton'
import EmployerJobIndexHelper from 'pages/user/EmployerJobIndex/EmployerJobIndexHelper'

const JobShowBannerDesktop = (props) => {
    const { user, job, unavailable, toggleBoost } = props
    const classes = useStyles()
    const userHasAccessToBoost = EmployerJobIndexHelper.userHasAccessToBoost(job, user, job.current_user_is_job_owner)
    const isJobBoostable = EmployerJobIndexHelper.isJobBoostable(job)

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.infoBannerContainer}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={job.company_url}
                    className={classes.logoBox}
                >
                    <Image
                        className={classes.logo}
                        cloudName="studentbe"
                        publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                        alt={`${job.company_logo_cloudinary_key} logo`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                    </Image>
                </RouteMapLink>
                <Box className={classes.jobInfoContainer}>
                    <Typography variant='h6' component='h1' className={classes.jobTitleBox}>
                        {job.title}
                    </Typography>
                    <Box className={classes.companyNameBox}>
                        <RouteMapLink
                            showItemType='company'
                            showItemUrl={job.company_url}
                        >
                            {job.company_name?.toUpperCase()}
                        </RouteMapLink>
                    </Box>
                    <Box className={classes.tagBox}>
                        {job.company_size &&
                            <Chip
                                className={classes.tag}
                                label={CollectionHandler.Translate('companySizeCollection', job.company_size)}
                            />
                        }
                        {job.sector &&
                            <Chip
                                className={classes.tag}
                                label={CollectionHandler.Translate('sectorCollection', job.sector)}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            {!unavailable &&
                <Box className={classes.ctaBox}>
                    <FavoriteAction
                        userType={user?.type}
                        type={job.type}
                        id={job.id}
                        item={job}
                        size='20px'
                        padding='8px'
                    />
                    <Box className={classes.iconContainer}>
                        <ShareAction
                            infos={{
                                title: job.title,
                                url: window.location.href,
                                type: job.type,
                                shareText: 'transl_internship.share_button'
                            }}
                            userType={user?.type}
                            size='20px'
                            padding='8px'
                        />
                    </Box>
                    <ApplyButton
                        job={job}
                        user={user}
                        unavailable={unavailable}
                        className={classes.applyButton}
                    />
                    {userHasAccessToBoost && <FilledButton
                        newStyle
                        color='secondary'
                        onClick={toggleBoost}
                        name={isJobBoostable ? <FormattedMessage id="company_job_card.buttons.boost" /> : <FormattedMessage id="company_job_card.buttons.boosted" />}
                        disabled={!isJobBoostable}
                        className={isJobBoostable ? classes.boostable : classes.boosted}
                        forceWrap
                    />}
                </Box>
            }
        </Box>
    )
}

export default JobShowBannerDesktop
