/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobPassport_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import ApplyButton from '../ApplyButton/ApplyButton'
import ContractTypeLine from './components/ContractTypeLine'
import DeadlineLine from './components/DeadlineLine'
import DurationLine from './components/DurationLine'
import FunctionTitleLine from './components/FunctionTitleLine'
import LanguageLine from './components/LanguageLine/LanguageLine'
import LocationLine from './components/LocationLine'
import MobilityLines from './components/MobilityLines'
import OpenPositionsLine from './components/OpenPositionsLine'
import PaidLine from './components/PaidLine'
import RegimeLine from './components/RegimeLine'
import ScheduleLine from './components/ScheduleLine'
import StartDateLine from './components/StartDateLine'
import StudyDomainLine from './components/StudyDomainLine'

const JobPassport = (props) => {
    const { job, user, unavailable } = props
    const classes = useStyles()

    const renderStudentJobInfo = () => {
        return <>
            <LocationLine job={job} />
            <ScheduleLine job={job} />
            <StartDateLine job={job} />
            <MobilityLines job={job} />
            <LanguageLine job={job} />
            <StudyDomainLine job={job} />
        </>
    }

    const renderInternshipInfo = () => {
        return <>
            <LocationLine job={job} />
            <DurationLine job={job} />
            <RegimeLine job={job} />
            <PaidLine job={job} />
            <StudyDomainLine job={job} />
        </>
    }

    const renderFirstJobInfo = () => {
        return <>
            <LocationLine job={job} />
            <RegimeLine job={job} />
            <ContractTypeLine job={job} />
            <FunctionTitleLine job={job} />
            <StudyDomainLine job={job} />
            <OpenPositionsLine job={job} />
            <DeadlineLine job={job} />
        </>
    }

    const renderJobInfo = () => {
        if (job.type === 'student_job') return renderStudentJobInfo()
        else if (job.type === 'internship') return renderInternshipInfo()
        else if (job.type === 'first_job') return renderFirstJobInfo()
    }

    return (
        <Paper className={classes.paperCard} elevation={4}>
            <Box className={classes.titleBox}>
                <Typography variant='h6' component='h2'>
                    <FormattedMessage id='job_show.job_info_card.title' />
                </Typography>
            </Box>
            {renderJobInfo()}
            <Hidden smDown>
                <Box className={classes.applyButtonBox}>
                    <ApplyButton
                        job={job}
                        user={user}
                        unavailable={unavailable}
                        flexColumn
                    />
                </Box>
            </Hidden>
        </Paper>
    )
}

export default JobPassport
