import React from 'react'

import NotificationCard from './NotificationCard'
import NoNotificationsCard from './NoNotificationsCard'
import IconFactory from 'icons/IconFactory/IconFactory'

import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './NotificationsView_style'
import { useStyles as useDarkModeStyles } from './NotificationsViewDarkMode_style'
import { FormattedMessage } from 'react-intl'

const NotificationsView = (props) => {
    const {
        darkMode, seeNotifications, notifications,
        notificationCount, isLastPage, loadMore
    } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    const [open, setOpen] = React.useState(false)
    const [anchorEl, setAnchor] = React.useState(null)
    const anchorRef = React.useRef(null)

    const handleOpen = (event) => {
        setOpen(true)
        setAnchor(event.currentTarget)
        seeNotifications(notifications.map((notif) => notif.id))
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return
        setOpen(false)
        setAnchor(null)
    }

    const notificationList = () => {
        if (notifications.length === 0) return <NoNotificationsCard />

        const notificationCards = notifications.map((notification) => {
            return <NotificationCard key={notification.id} notification={notification}/>
        })
        return notificationCards
    }

    return (
        <Box>
            <Button onClick={handleOpen} className={classes.button}>
                <Badge badgeContent={notificationCount} color="error" overlap="circle">
                    <IconFactory icon='alert' className={classes.notificationIcon} />
                </Badge>
            </Button>
            <Popper placement='top-end' open={open} anchorEl={anchorEl} transition disablePortal >
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList className={classes.menuList} autoFocusItem={Boolean(anchorEl)} id="menu-list-grow" onMouseLeave={handleClose}>
                                    <Box px={2} py={1} className={classes.dropdownTitle} >
                                        <Typography variant='body2' noWrap>
                                            <FormattedMessage id='notifications.name' />
                                        </Typography>
                                    </Box>
                                    {notificationList()}
                                    {!isLastPage && notifications.length > 0 &&
                                        <Box p={2}>
                                            <Button
                                                color={'primary'}
                                                variant={'contained'}
                                                fullWidth
                                                onClick={loadMore}
                                            >
                                                <FormattedMessage id="item_index.show_more_button" />
                                            </Button>
                                        </Box>
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    )
}

export default NotificationsView
