/* eslint-disable react/prop-types */

import React from 'react'

import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import LineButton from 'buttons/LineButton/LineButton'
import FilledButton from 'buttons/FilledButton/FilledButton'
import CarouselCards from 'components/CarouselCards/CarouselCards'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'

import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ConfettiCannon from '../../../shared/components/ConfettiCannon/ConfettiCannon'

export default class Congratulation extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            items: this.props.items
        }
    }

    componentDidMount () {
        var newItems = this.state.items
        for (var i = 0; i < newItems.length; i++) {
            if (newItems[i].id === this.props.id) {
                newItems.splice(i, 1)
            }
        }
        this.setState({ items: newItems })
    }

    componentDidUpdate () {
        setTimeout(() => ConfettiCannon(), 300)
    }

    getRedirectionUrl () {
        switch (this.props.type) {
        case 'first_job':
            return 'first-jobs'
        case 'student_job':
            return 'student-jobs'
        case 'internship':
            return 'internships'
        }
    }

    renderButtons () {
        return (
            <>
                <RouteMapLink page='users/candidacies'>
                    <LineButton name={<FormattedMessage id='congratulation.buttons.create_alert'/>} />
                </RouteMapLink>
                <RouteMapLink page={this.getRedirectionUrl()}>
                    <FilledButton
                        name={<FormattedMessage id='congratulation.buttons.show_offers' />}
                        color='secondary'
                        href={this.getRedirectionUrl()}
                    />
                </RouteMapLink>
            </>
        )
    }

    render () {
        return (
            <PageWrapper user={this.props.user}>
                <Container maxWidth='lg' className={this.props.type.replace('_', '-')}>
                    <Box mb={4} mt={4}>
                        <Typography variant='h1' style={{ margin: '20px 0' }}>
                            <FormattedMessage id='congratulation.title'/>
                        </Typography>
                    </Box>
                    <Typography variant="h2" ><FormattedMessage id="congratulation.slider.title" /></Typography>
                    <Box my={4}>
                        <CarouselCards
                            cards={this.state.items.length === 0 ? [] : this.state.items}
                            type={this.props.type}
                            userType={this.props.user ? this.props.user.type : 'not_connected'}
                            id='similar-offers'
                        />
                    </Box>
                    <Hidden xsDown>
                        <Box display='flex' justifyContent='space-between' mb={3} className='congratulation-button-container'>
                            {this.renderButtons()}
                        </Box>
                    </Hidden>
                    <Hidden smUp>
                        <Box style={{ height: '5rem' }} display='flex' flexDirection="column" alignItems="center" justifyContent='space-between' mb={3} className='congratulation-button-container'>
                            {this.renderButtons()}
                        </Box>
                    </Hidden>
                </Container>
            </PageWrapper>
        )
    }
}
