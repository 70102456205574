/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import FormControl from '@material-ui/core/FormControl'
import { List } from 'react-virtualized'
import { useStyles } from './HomeCityFilter_style'
import { FormattedMessage } from 'react-intl'

const HomeCityFilter = (props) => {
    const { location, handleChangeLocation, handleInputSearchCity, cityCollection, cityInput, openDropdown, setOpenDropdown } = props
    const classes = useStyles()

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = cityCollection[index]

        
        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleChangeLocation(option)}
            >
                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
        )
    }

    const renderSelectValue = (selected) => {
        return (
            <Box className={classes.placeholderText}>
                {selected.name ? selected.name : <FormattedMessage id="home_search.city" />}
            </Box>
        )
    }

    return (
        <Select
            disableUnderline
            value={location}
            displayEmpty
            onChange={handleChangeLocation}
            input={<Input className={classes.formControl}/>}
            className={`${classes.inputField} ${classes.underline}`}
            focusable={false}
            renderValue={(selected) => renderSelectValue(selected)}
            IconComponent={(props) => (<IconFactory icon='down-solid' {...props} />)}
            open={openDropdown}
            onClose={() => setOpenDropdown(false)}
            onOpen={() => setOpenDropdown(true)}
        >
            <Box className={classes.boxInputSearchCity}>
                <Box className={classes.selectSearchbar}>
                    <Box className={classes.iconContainer}>
                        <IconFactory icon='search-regular' color='primary' fontSize='15px'/>
                    </Box>
                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                        <FormattedMessage id="search_bar.search.city">
                            {placeholder =>
                                <InputBase
                                    classes={{ input: classes.inputBase }}
                                    placeholder={placeholder}
                                    name="citySearch"
                                    onChange={handleInputSearchCity}
                                    value={cityInput}
                                    fullWidth
                                    onKeyDown={e => e.stopPropagation()}
                                    onClick={e => e.stopPropagation()}
                                />
                            }
                        </FormattedMessage>
                    </Typography>
                </Box>
                <IconButton
                    aria-label='close'
                    onClick={() => setOpenDropdown(false)}
                    className={classes.closeIconButton}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </Box>
            <List
                width={500}
                height={300}
                rowCount={cityCollection.length}
                rowHeight={45}
                rowRenderer={rowRenderer}
            />
        </Select>
    )
}

export default HomeCityFilter
