/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import ArticleAPI from 'api/ArticleAPI'
import PreviewCard from 'components/PreviewCard/PreviewCard'
import { FormattedMessage } from 'react-intl'

const ArticleSimilarJobs = (props) => {
    const { userType, article } = props
    const [similarJobs, setSimilarJobs] = useState([])

    useEffect(() => {
        getSimilarJobs()
    }, [])

    const getSimilarJobs = () => {
        const data = {
            id: article.id
        }
        ArticleAPI.GetSimilarJobs(data).then((response) => {
            setSimilarJobs(response.data)
        })
    }

    if (similarJobs.length === 0) return null

    return (
        <PreviewCard
            title={article.typeOffer === 'other_articles' ? <FormattedMessage id="landing_article_show.studentbe_is_also" /> : <FormattedMessage id="landing_article_show.interest_ads" />}
            subTitle={<FormattedMessage id="landing_article_show.see_all_jobs" />}
            cards={similarJobs}
            type={article.typeOffer}
            userType={userType}
            fullWidth
        />
    )
}

export default ArticleSimilarJobs
