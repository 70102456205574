import React from 'react'
import { Box } from '@material-ui/core'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './Tag_style'

const Tag = (props) => {
    const classes = useStyles()
    const { item, isSelected, handleClick } = props

    return (
        <Box
            className={isSelected ? classes.selectedLocationTag : classes.unSelectedToggleTag}
            key={`selected-location-${item.name}`}
            onClick={() => handleClick(item)}
        >
            {isSelected && <IconFactory icon='cross' className={classes.tagCross} fontSize='12px'/>}
            {item.name}
        </Box>
    )
}

export default Tag
