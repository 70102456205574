import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    topContainer: {
        position: 'relative'
    },
    media: {
        height: 0,
        paddingTop: '58.50%'
    },
    arrowLeft: {
        zIndex: 2,
        position: 'absolute',
        top: '50%',
        marginLeft: 16
    },
    arrowRight: {
        right: 0,
        top: '50%',
        zIndex: 2,
        position: 'absolute',
        marginRight: 16
    },
    arrowBtn: {
        width: 25,
        height: 25,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)'
        }
    },
    arrows: {
        color: 'white'
    },
    kotPriceContainer: {
        zIndex: 2,
        position: 'absolute',
        right: 0,
        top: '75%',
        backgroundColor: 'var(--red)',
        padding: '3px 8px',
        borderRadius: '5px 0px 0px 5px',
        minWidth: 100,
        textAlign: 'center'
    },
    kotPrice: {
        fontFamily: "'GintoNormal-Regular', sans-serif !important",
        fontSize: '14px !important',
        fontWeight: 400,
        color: '#fff'
    }
}))
