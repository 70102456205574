import React from 'react'

import LanguageProfileView from './LanguageProfileView'

const LanguageProfile = (props) => {
    const {
        blankLanguage,
        disabledSave,
        setStudent,
        setStudentLanguage,
        student,
        studentLanguage,
        userLanguages
    } = props

    const handleHideLanguage = (index) => {
        studentLanguage[index].deleted = true
        setStudentLanguage([...studentLanguage])
    }
    const handleChangeLanguage = (index, prop) => (event) => {
        studentLanguage[index][prop] = event.target.value
        setStudentLanguage([...studentLanguage])
    }
    const handleChangeRequiredLanguage = language => (event) => {
        setStudent({ ...student, [language]: event.target.value })
    }

    const addNewStudentLanguage = () => {
        setStudentLanguage([...studentLanguage, { ...blankLanguage }])
    }

    return (
        <LanguageProfileView
            addNewStudentLanguage={addNewStudentLanguage}
            disabledSave={disabledSave}
            handleChangeLanguage={handleChangeLanguage}
            handleChangeRequiredLanguage={handleChangeRequiredLanguage}
            handleHideLanguage={handleHideLanguage}
            studentLanguage={studentLanguage}
            userLanguages={userLanguages}
            student={student}
        />
    )
}
export default LanguageProfile
