/* eslint-disable react/prop-types */

import React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import LineButton from 'buttons/LineButton/LineButton'

import { useStyles } from './SearchBarWrapper_style'
import { FormattedMessage } from 'react-intl'

const SearchBarWrapper = ({ children, ...props }) => {
    const { reset, paper } = props
    const classes = useStyles()

    return (
        <Paper className={paper ? classes.paperContainer : classes.noPaperContainer}>
            <Box className={classes.boxContainerPage}>
                <Grid container spacing={3} >
                    <Grid item xs={12} md={10} className={classes.filterItemContainer}>
                        { children }
                    </Grid>
                    <Hidden only={['xs', 'sm']}>
                        <Grid item xs={12} md={2} className={classes.desktopResetGridItem}>
                            <Box display={'flex'} justifyContent={'flex-end'}>
                                <LineButton
                                    className={classes.desktopResetButton}
                                    color='primary'
                                    onClick={reset}
                                    name={<FormattedMessage id='search_bar.reinit_button_1' />}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                    <Hidden only={['md', 'lg', 'xl']}>
                        <Grid item xs={12} md={3}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <LineButton
                                    fullWidth
                                    size='small'
                                    color='primary'
                                    onClick={reset}
                                    name={<FormattedMessage id='search_bar.reinit_button_1' />}
                                />
                            </Box>
                        </Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Paper>
    )
}

export default SearchBarWrapper
