import React from 'react'
import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import { Box, CircularProgress } from '@material-ui/core'
import { useStyles } from './StepButtons_style'
import { FormattedMessage } from 'react-intl'
import ProfileEditFormHelper from '../../helpers/ProfileEditFormHelper'

const StepButtons = (props) => {
    const { step, previousStep, nextStep, loading } = props
    const classes = useStyles()

    const getNextStepTextId = () => {
        if ((step + 1) === ProfileEditFormHelper.MAX_STEP) {
            return 'personal_dashboard.profile_edit_form.finish'
        }
        return 'personal_dashboard.profile_edit_form.continue'
    }

    if (loading) {
        return (
            <Box className={classes.stepButtonBox}>
                <CircularProgress color='white' className={classes.circularProgress}/>
            </Box>
        )
    }

    return (
        <Box className={classes.stepButtonBox}>
            {step !== 0 &&
                <FilledButton
                    newStyle
                    color='primary'
                    onClick={previousStep}
                    className={classes.previousStepButton}
                >
                    <FormattedMessage id='personal_dashboard.profile_edit_form.previous_step' />
                </FilledButton>
            }
            <LineButton
                newStyle
                newStyleWhite
                color='primary'
                onClick={nextStep}
                className={classes.nextStepButton}
            >
                <Box className={classes.nextStepButtonLabel}>
                    <FormattedMessage id={getNextStepTextId()} />
                    <IconFactory icon='arrow-right' />
                </Box>
            </LineButton>
        </Box>

    )
}
export default StepButtons
