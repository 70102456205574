/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import FormControl from '@material-ui/core/FormControl'
import { List } from 'react-virtualized'
import { useStyles } from 'components/Searchbar/Searchbar_v2_style'
import { FormattedMessage } from 'react-intl'
import FilterTagList from 'components/Searchbar/shared/FilterTagList/FilterTagList'

const CityFilterView = (props) => {
    const { filters, handleSearchCities, handleInputSearchCity, searchCities, citiesInput, width, showTags, dropdownPosition, modal } = props
    const classes = useStyles()
    const [openDropdown, setOpenDropdown] = useState(false)

    const selectPaperStyle = () => {
        return ({
            disableScrollLock: true,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'bottom', horizontal: dropdownPosition || 'center' },
            transformOrigin: { vertical: -20, horizontal: dropdownPosition !== 'left' && (width / 2) },
            PaperProps: {
                style: {
                    borderRadius: 16
                }
            }
        })
    }

    const rowRenderer = (row) => {
        const { key, index, style } = row
        const option = searchCities[index]

        return (
            <MenuItem
                key={key}
                style={style}
                value={option.value}
                className={classes.menuItem}
                onClick={() => handleSearchCities(option)}
            >
                <ListItemText primary={`${option.name} (${option.value})`} primaryTypographyProps={{ variant: 'body2' }}/>
            </MenuItem>
        )
    }

    const renderArrow = () => {
        if (filters.location.length) return null

        return <Box className={classes.dropdownContainer}>
            <IconFactory icon='down-solid' fontSize='12px' color='var(--dark-beige)'/>
        </Box>
    }

    const renderSelectValue = (selected) => {
        if (selected.length === 0) {
            return (
                <Box className={classes.valueContainer}>
                    <em className={classes.placeholderText}><FormattedMessage id='search_bar.city' /></em>
                    {renderArrow()}
                </Box>
            )
        }
        return (
            <Box className={classes.valueContainer}>
                <Box className={classes.tagText}><FormattedMessage id="search_bar.city" /></Box>
                <Avatar className={classes.tagAvatar} >{selected.length}</Avatar>
            </Box>
        )
    }

    return (
        <Box className={classes.cityFilterContainer}>
            <FormControl>
                <Button className={`${classes.baseInput} ${modal ? classes.inputBackground : ''}`}>
                    <Select
                        multiple
                        disableUnderline
                        value={filters.location}
                        displayEmpty
                        onChange={handleSearchCities}
                        input={<Input className={classes.formControl}/>}
                        classes={{ root: classes.inputProps }}
                        focusable={false}
                        renderValue={(selected) => renderSelectValue(selected)}
                        MenuProps={ selectPaperStyle() }
                        IconComponent={() => <Box/>}
                        open={openDropdown}
                        onClose={() => setOpenDropdown(false)}
                        onOpen={() => setOpenDropdown(true)}
                    >
                        <Box className={classes.boxInputSearchCity}>
                            <Box className={classes.selectSearchbar}>
                                <Box className={classes.iconContainer}>
                                    <IconFactory icon='search-regular' color='primary' fontSize='15px'/>
                                </Box>
                                <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                    <FormattedMessage id="search_bar.search.city">
                                        {placeholder =>
                                            <InputBase
                                                classes={{ input: classes.inputBase }}
                                                placeholder={placeholder}
                                                name="citySearch"
                                                onChange={handleInputSearchCity}
                                                value={citiesInput}
                                                fullWidth
                                                onKeyDown={e => e.stopPropagation()}
                                            />
                                        }
                                    </FormattedMessage>
                                </Typography>
                            </Box>
                            <IconButton
                                aria-label='close'
                                onClick={() => setOpenDropdown(false)}
                                className={classes.closeIconButton}
                            >
                                <IconFactory icon='close' />
                            </IconButton>
                        </Box>
                        <Box className={classes.tagList}>
                            <FilterTagList small handleChange={handleSearchCities} items={filters.location}/>
                        </Box>
                        <List
                            width={500}
                            height={300}
                            rowCount={searchCities.length}
                            rowHeight={45}
                            rowRenderer={rowRenderer}
                        />
                    </Select>
                </Button>
            </FormControl>
            {showTags &&
                <Box className={classes.filterTagList}>
                    <FilterTagList handleChange={handleSearchCities} items={filters.location} />
                </Box>
            }
        </Box>
    )
}

export default CityFilterView
