/* eslint-disable react/prop-types */

import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import IconFactory from '../../../shared/icons/IconFactory/IconFactory'
import { THEME } from '../../../../assets/GlobalTheme'
import { withStyles } from '@material-ui/core/styles'

const useStyles = (theme) => ({
    boxTitle: {
        display: 'flex',
        marginBottom: 10,
        alignItems: 'center'
    },
    avatarStyle: {
        backgroundColor: 'white',
        color: THEME.palette.primary.main,
        border: '1px solid' + THEME.palette.primary.main,
        fontFamily: THEME.typography.h1.fontFamily,
        marginRight: 15,
        width: 30,
        height: 30,
        fontSize: 18
    },
    typoStyle: {
        margin: '0 !important',
        display: 'flex',
        alignItems: 'center'
    }
})

class SectionTitle extends React.Component {
    constructor (props) {
        super(props)

        this.var = this.props.variant ? this.props.variant : 'h2'
    }

    render () {
        const { classes } = this.props

        return (
            <>
                <Box className={classes.boxTitle}>
                    <Avatar
                        className={classes.avatarStyle}
                    >{this.props.step ? this.props.step : <IconFactory icon='user' />}</Avatar>
                    <Typography
                        variant={this.var}
                        component={this.props.component ? this.props.component : null}
                        color='primary'
                        className={classes.typoStyle}
                    >
                        {this.props.title}
                    </Typography>
                </Box>
            </>
        )
    }
}

export default withStyles(useStyles)(SectionTitle)
