import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import { useStyles } from './JobCard_style'
import { SMALL_SIZE, SMALLER_SIZE } from 'assets/CloudinaryURLHelper'
import JobCardWrapper from './JobCardWrapper'
import CollectionHandler from 'assets/CollectionHandler.js'
import JobCardTags from 'components/JobCardTags/JobCardTags'
import JobCardChips from './components/JobCardChips/JobCardChips'
import URLHandler from 'assets/URLHandler'

const JobCard = (props) => {
    const classes = useStyles()
    const { job, onCardClick } = props
    const jobCardYoutubeId = job.job_video_youtube_id
    const jobCardImageId = job.job_image_cloudinary_key || `placeholders/${job.sector || 'other_industries'}`
    const youtubeImageUrl = URLHandler.youtubeImageUrlBuilder(jobCardYoutubeId)

    return (
        <JobCardWrapper onCardClick={onCardClick} jobType={job.type} jobUrl={job.url} jobId={job.id}>
            <Box className={classes.container}>
                <span className={classes.jobCardImage} role='img' aria-label='sector image'>
                    {jobCardYoutubeId
                        ? <img
                            className={classes.cardMedia}
                            style={{
                                backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0) 100%), url(${youtubeImageUrl})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: 'black'
                            }}
                        />
                        : <Image
                            cloudName="studentbe"
                            className={classes.cardMedia}
                            publicId={jobCardImageId}
                            alt='sector image'
                        >
                            <Transformation flags="progressive" fetchFormat="auto" height={SMALL_SIZE} quality="auto:good" crop="fill" />
                            <Transformation effect="gradient_fade" y="-0.5" />
                            <Transformation background="#222222" opacity="90" />
                            <Transformation defaultImage="placeholders:other_industries.png" />
                        </Image>
                    }
                    <Box className={classes.company}>
                        {job.company_logo_cloudinary_key &&
                            <Image
                                cloudName="studentbe"
                                publicId={job.company_logo_cloudinary_key}
                                alt={`Company logo ${job.company_name}`}
                            >
                                <Transformation flags="progressive" fetchFormat="auto" width={SMALLER_SIZE} quality="auto:good" crop="fill" gravity="center" />
                                <Transformation radius="20" />
                            </Image>
                        }
                        <Box className={classes.companyInfo}>
                            <Typography variant='h4' component='p' className={classes.companyInfoName}>
                                {job.company?.name}
                            </Typography>
                            <Typography variant='span' className={classes.companyInfoType}>
                                {CollectionHandler.Translate('companySizeCollection', job.company?.size)}
                            </Typography>
                        </Box>
                    </Box>
                    <JobCardTags showJobType={job.type} job={job} />
                </span>
                <Box className={classes.jobCardText}>
                    <Box className={classes.jobTitleAndFavourite}>
                        <Typography variant='h3' component='p' className={classes.jobTitle}>
                            {job.title}
                        </Typography>
                        <Box className={classes.jobFavourite}>
                            <FavoriteAction
                                userType={props.userType}
                                color='var(--black)'
                                item={job}
                                onItemCard
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box my={1} className={classes.jobDetails}>
                            <Typography className={classes.city}>{job.city}</Typography>
                            {job.people_wanted > 1 && <>
                                <Typography variant='span' className={classes.bullet}>&nbsp;•&nbsp;</Typography>
                                <Typography variant='h5' component='span'>
                                    {job.people_wanted} <FormattedMessage id={`job_card.people_wanted.${job.type}`} />
                                </Typography>
                            </>}
                        </Box>
                        {job.job_tags &&
                            <JobCardChips job={job} />
                        }
                    </Box>
                </Box>
            </Box>
        </JobCardWrapper>
    )
}

export default JobCard
