import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: '#FF6762',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        color: '#FFFFFF',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
        }
    },
    categoryContainer: {
        backgroundColor: '#EFEAE4',
        pointerEvents: 'none'
    },
    content: {

        marginBottom: '5rem'
    },
    answerContainer: {
        opacity: '0.9'
    },
    studentFont: {
        fontFamily: theme.typography.h1.fontFamily
    },
}))
