/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './SectionMainTitle_style'
import { FormattedMessage } from 'react-intl'

const SectionMainTitle = (props) => {
    const { textId, marginBottom, className } = props
    const classes = useStyles()

    return (
        <Box className={`${classes.titleContainer} ${className}`} mb={marginBottom || '16px'}>
            <Typography className={classes.title} variant='h6' component='h2'>
                <FormattedMessage id={textId} />
            </Typography>
        </Box>
    )
}

export default SectionMainTitle
