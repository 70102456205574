/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import FavoriteAction from 'components/FavoriteAction/FavoriteAction'
import ShareAction from 'components/ShareAction/ShareAction'
import { useStyles } from '../JobShowBanner_style'
import { Image, Transformation } from 'cloudinary-react'
import ApplyButton from '../../ApplyButton/ApplyButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Typography from '@material-ui/core/Typography'

const JobShowBannerDesktopSticky = (props) => {
    const { job, user, unavailable } = props
    const classes = useStyles()

    return (
        <Box className={`${classes.bannerContainer} ${classes.bannerContainerSticky}`}>
            <Box className={classes.infoBannerContainer}>
                <RouteMapLink
                    showItemType='company'
                    showItemUrl={job.company_url}
                    className={classes.logoBox}
                >
                    <Image
                        className={`${classes.logo} ${classes.logoSticky}`}
                        cloudName="studentbe"
                        publicId={job.company_logo_cloudinary_key ? job.company_logo_cloudinary_key : 'default-company-logo-black'}
                        alt={`${job.company_logo_cloudinary_key} logo`}
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width="150" quality="auto:good" crop="scale" />
                    </Image>
                </RouteMapLink>
                <Box className={classes.jobInfoContainer}>
                    <Typography variant='h6' component='h1' className={`${classes.jobTitleBox} ${classes.titleBoxSticky} ${classes.jobTitleBoxSticky}`}>
                        {job.title}
                    </Typography>
                    <Box className={`${classes.companyNameBox} ${classes.companyNameBoxSticky}`}>
                        <RouteMapLink
                            showItemType='company'
                            showItemUrl={job.company_url}
                        >
                            {job.company_name?.toUpperCase()}
                        </RouteMapLink>
                    </Box>
                </Box>
            </Box>
            {!unavailable &&
                <Box className={`${classes.ctaBox} ${classes.ctaBoxSticky}`}>
                    <FavoriteAction
                        userType={user?.type}
                        type={job.type}
                        id={job.id}
                        item={job}
                        size='20px'
                        padding='8px'
                    />
                    <Box className={classes.iconContainer}>
                        <ShareAction
                            infos={{
                                title: job.title,
                                url: window.location.href,
                                type: job.type,
                                shareText: 'transl_internship.share_button'
                            }}
                            userType={user?.type}
                            size='20px'
                            padding='8px'
                        />
                    </Box>
                    <ApplyButton
                        job={job}
                        user={user}
                        unavailable={unavailable}
                        className={classes.applyButton}
                    />
                </Box>
            }
        </Box>
    )
}

export default JobShowBannerDesktopSticky
