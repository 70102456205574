import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NavigationUserActionsLogout_style'
import { useStyles as useDarkModeStyles } from './NavigationUserActionsLogoutDarkModeBis_style'
import { Box } from '@material-ui/core'

import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const NavigationUserActionsLogout = (props) => {
    const { darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    return (
        <Box className={classes.userActionsLogoutContainer}>
            <RouteMapLink page='employers'>
                <LineButton
                    newStyle
                    name={<FormattedMessage id="page_footer_navbar_data.employer_button" />}
                    className={classes.userActionsLogoutButton}
                />
            </RouteMapLink>
            <RouteMapLink page='login'>
                <FilledButton
                    color="secondary"
                    newStyle
                    name={<FormattedMessage id="page_footer_navbar_data.navbar" />}
                />
            </RouteMapLink>
        </Box>
    )
}

export default NavigationUserActionsLogout
