import React, { useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import { useStyles } from './FunnelSubmitBar_style'
import { PUBLICATION_STEP } from '../../settings'

const FunnelSubmitBar = (props) => {
    const { handleBack, handleSubmit, isLoading, stepsTotal, currentStep, currentStepIndex, isBuyingCredits, payWithCredits } = props
    const classes = useStyles()
    const currentStepPosition = currentStepIndex + 1

    return (
		<Box className={classes.funnelSubmitBar}>
            {isLoading && <Box className={classes.loadingOverlay}/>}
            <Box
                className={classes.progressBar}
                width={currentStepPosition / stepsTotal}
            />
            {currentStepPosition > 1 &&
                <Box className={classes.backButton} onClick={handleBack}>
                    <LineButton
                        name={<FormattedMessage id="job_creation_funnel.back" />}
                        color='primary'
                    />
                </Box>
            }
            {stepsTotal > 1 &&
                <Box className={classes.funnelPosition}>
                    <span>{currentStepPosition} / {stepsTotal}</span>
                </Box>
            }
            <Box className={classes.submitButton}>
                {isLoading
                ? <CircularProgress color='primary' style={{ width: '32px', height: '32px' }}/>
                : currentStep === PUBLICATION_STEP && !isBuyingCredits
                    ? <FilledButton
                        color="secondary"
                        onClick={payWithCredits}
                        name={<FormattedMessage id="job_creation_funnel.publish" />}
                    />
                    : <FilledButton
                        color='secondary'
                        onClick={handleSubmit}
                        name={<FormattedMessage id="new_ad.save_and_next" />}
                        disabled={currentStep === PUBLICATION_STEP}
                    />
                }
            </Box>
        </Box>
    )
}

export default FunnelSubmitBar
