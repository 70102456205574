import React, { useEffect, useState } from 'react'


import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from '../CreditPackages_style'
import FilledButton from 'buttons/FilledButton/FilledButton'
import CollectionHandler from 'assets/CollectionHandler'
import SVGFactory from 'icons/SVGFactory/SVGFactory'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const FirstJobCreditPackage = (props) => {
    const classes = useStyles()
    const { handlePayment } = props
    const creditPackagesCollection = CollectionHandler.Get('creditPackagesCollection')
    const [firstJobPackage, setFirstJobPackage] = useState({})

    useEffect(() => {
		const packageFirstJob = creditPackagesCollection.find(creditPackage => creditPackage.name.includes('firstjob'))
		setFirstJobPackage(packageFirstJob)
	}, [])

    const handleClick = () => {
        handlePayment(firstJobPackage)
     }

    return (
		<Box className={classes.packageCard}>
            <Box className={classes.infoSection}>
                <Box className={classes.cardIcon}>
                    <SVGFactory name='zeppelin' />
                </Box>
                <Typography variant='h6' component='h3' color='primary' className={`${classes.cardTitle} ${classes.uppercase} ${classes.withMinHeight}`}>
                    <FormattedMessage id='credit_packages.first_job' />
                </Typography>
                <Box className={classes.creditFeatures}>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.1_first_job_credit' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.40_days' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.head_of_list' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.personalised_company_page' /></Typography>
                    </Box>
                    <Box className={classes.creditFeature}>
                        <IconFactory icon='check-regular' />
                        <Typography><FormattedMessage id='credit_packages.features.integration_job_alerts' /></Typography>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.CTASection}>
                <Typography variant='h6' component='p' color='primary' className={classes.cardTitle}>
                    <FormattedMessage id='credit_packages.total_value' values={{ value: firstJobPackage.value }} />
                </Typography>
                <Box className={classes.cardButton}>
                    <FilledButton
                        onClick={handleClick}
                        newStyle
                        name={<FormattedMessage id='credit_packages.buy' />}
                        color='secondary'
                        size='small'
                        className={classes.button}
                        typoVariant='p'
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default FirstJobCreditPackage
