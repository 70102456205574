import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    buttonOutlined: {
        color: 'var(--red)',
        borderColor: 'var(--red)'

    },
    documentBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    documentText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    button: {
        backgroundColor: 'var(--dark-grey)',
        color: 'white',
        '&:hover': {
            backgroundColor: 'var(--dark-grey)'
        }
    },
    radioGroup: {
        width: '100%'
    },
    radioContainer: {
        marginBottom: '10px',
        display: 'flex',
        justifyContent: 'space-around'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    }
}))
