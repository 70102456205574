import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    text: {
        color: 'white',
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        }
    }
}))
