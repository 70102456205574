import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    stepperBoxContainer: {
        backgroundColor: 'var(--beige)',
        width: '100%',
        justifyContent: 'center'
    },
    stepperBox: {
        backgroundColor: 'var(--beige)',
        width: '100%',
        marginBottom: 10
    },
    stepperIcon: {
        '& span': {
            zIndex: 1
        }
    },
    titleContainer: {
        margin: '30px 0'
    },
    progressCircleBox: {
        minWidth: 960
    },
    registerContainer: {
        [theme.breakpoints.up('md')]: {
            padding: '0 80px'
        }
    }
}))
