/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Container from '@material-ui/core/Container'

import CityFilter from '../shared/CityFilter/CityFilter'
import TextFilter from '../shared/TextFilter/TextFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersWrapper from 'components/Searchbar/shared/FiltersWrapper/FiltersWrapper'
import InternshipFiltersModal from 'modals/FilterModals/InternshipFiltersModal/InternshipFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'
import InternshipQuickFilters from './InternshipQuickFilters'

const InternshipFiltersView = (props) => {
    const { offerCount, debouncedFilterChange, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showInternshipFiltersModal, setShowInternshipFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const studyTypeCollection = CollectionHandler.Get('studyTypeCollection')

    return (
        <Box className={classes.container}>
            <InternshipFiltersModal
                showModal={showInternshipFiltersModal}
                setShowModal={setShowInternshipFiltersModal}
                debouncedFilterChange={debouncedFilterChange}
                handleFilterChange={handleFilterChange}
                filters={filters}
                offerCount={offerCount}
                handleInputSearchCity={handleInputSearchCity}
                searchCities={searchCities}
                citiesInput={citiesInput}
                setSearchCities={setSearchCities}
                setCitiesInput={setCitiesInput}
                setFilters={setFilters}
                reset={reset}
            />
            <FiltersWrapper type={type} selectedLocationTag={selectedLocationTag}>
                <Box className={classes.filters}>
                    <Box className={classes.filterBox}>
                        <TextFilter
                            debouncedFilterChange={debouncedFilterChange}
                            handleFilterChange={handleFilterChange}
                            filters={filters}
                            setFilters={setFilters}
                            filterTextId='search_bar.search.internship'
                        />
                    </Box>
                    <Hidden xsDown>
                        {false &&
                            <Box className={classes.filterBox}>
                                <MultipleSelectFilter
                                    type='studyType'
                                    collection={studyTypeCollection}
                                    filters={filters}
                                    setFilters={setFilters}
                                    width={250}
                                    filterTextId='job_form.step_3.study_level_title'
                                />
                            </Box>
                        }
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='studyDomain'
                                collection={studyDomainCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={350}
                                filterTextId='search_bar.study.field_singular'
                            />
                        </Box>
                        <Box className={classes.filterBox}>
                            <CityFilter
                                handleInputSearchCity={handleInputSearchCity}
                                searchCities={searchCities}
                                citiesInput={citiesInput}
                                setSearchCities={setSearchCities}
                                setCitiesInput={setCitiesInput}
                                setFilters={setFilters}
                                filters={filters}
                                width={350}
                            />
                        </Box>
                    </Hidden>
                    <Hidden smDown>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='companySize'
                                collection={companySizeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.company_size'
                            />
                        </Box>
                    </Hidden>
                    <MoreFilters setShowModal={setShowInternshipFiltersModal} filters={filters} type={type} />
                    <ResetFilters reset={reset}/>
                </Box>
            </FiltersWrapper>
            <Container maxWidth='lg'>
                <InternshipQuickFilters
                    filters={filters}
                    setFilters={setFilters}
                />
            </Container>
        </Box>
    )
}

export default InternshipFiltersView
