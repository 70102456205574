import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    packageCard: {
        maxWidth: 330,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 20,
    },
    CTASection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    cardIcon: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 100,
        '& svg': {
            fill: 'var(--red)'
        }
    },
    cardTitle: {
        textAlign: 'center',
        minWidth: 170,
        maxWidth: 200,
        margin: '0 auto',
        fontSize: '1.2rem',
        lineHeight: 1.4,
    },
    withMinHeight: {
        minHeight: 50
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    creditFeatures: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        padding: '20px 0',
    },
    creditFeature: {
        display: 'flex',
        alignItems: 'flex-start',
        gap: 12,
        '& > svg': {
            color: 'var(--red-burgundy)'
        }
    },
    cardButton: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    button: {
        width: 220,
        fontSize: 14,
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontWeight: 500,
    },
    squeezeText: {
        lineHeight: '1rem',
    },
    creditCostInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'center',
        margin: '0 auto',
    },
    creditSelect: {
        borderBottom: '2px solid var(--red)',
        minWidth: 200
    },
    overrideSelectIcon: {
        color: 'var(--black)'
    },
    selected: {
        fontWeight: 'bold'
    },
    placeholder: {
        color: 'var(--grey-middle)'
    },
    buyCredits: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogContainer: {},
    dialogTitleRoot: {},
    dialogTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}))
