import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import 'yet-another-react-lightbox/dist/styles.css'
import 'yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css'
import 'yet-another-react-lightbox/dist/plugins/counter/counter.css'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom'
import Inline from 'yet-another-react-lightbox/dist/plugins/inline'
import Thumbnails from 'yet-another-react-lightbox/dist/plugins/thumbnails'
import Counter from 'yet-another-react-lightbox/dist/plugins/counter'
import { useWindowSize } from 'hooks/useWindowSize'

import { useStyles } from './KotCaroussel_style'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CardMedia from '@material-ui/core/CardMedia'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

import IconFactory from 'icons/IconFactory/IconFactory'
import { Typography } from '@material-ui/core'

const KotCaroussel = ({ images }) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [index, setIndex] = useState(0)
    const [maxZoomPixelRatio, setMaxZoomPixelRatio] = useState(2)
    const { isLargeScreen } = useWindowSize()
    const [activeStep, setActiveStep] = useState(0)
    const thumbnailContainerRef = useRef(null)
    const maxSteps = images?.length

    const THUMBNAIL_WIDTH = 156 // 156 = thumbnail image width + margin

    const toggleOpen = (state) => () => {
        setOpen(state)
        if (state) {
            setActiveStep(index)
        }
    }

    useEffect(() => {
        const handleOpen = () => {
            if (thumbnailContainerRef.current) {
                thumbnailContainerRef.current.scrollTo({
                    left: activeStep * THUMBNAIL_WIDTH,
                    behavior: 'smooth'
                })
            } else {
                setTimeout(handleOpen, 100) // wait for thumbnailContainerRef to be set to scroll to the current image
            }
        }

        if (open) {
            handleOpen()
        }
    }, [open])

    const updateIndex = ({ index: current }) => setIndex(current)

    const handleNext = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === maxSteps - 1
                ? (prevActiveStep = 0)
                : prevActiveStep + 1
        )

        if (thumbnailContainerRef.current) {
            const scrollAmount = thumbnailContainerRef.current.scrollLeft + THUMBNAIL_WIDTH
            thumbnailContainerRef.current.scrollTo({
                left: activeStep === maxSteps - 1 ? 0 : scrollAmount,
                behavior: 'smooth'
            })
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) =>
            activeStep === 0
                ? (prevActiveStep = maxSteps - 1)
                : prevActiveStep - 1
        )

        if (thumbnailContainerRef.current) {
            const scrollAmount = thumbnailContainerRef.current.scrollLeft - THUMBNAIL_WIDTH
            thumbnailContainerRef.current.scrollTo({
                left: activeStep === 0 ? maxSteps * THUMBNAIL_WIDTH : scrollAmount,
                behavior: 'smooth'
            })
        }
    }

    const handleThumbnailClick = (index) => {
        setActiveStep(index)

        if (thumbnailContainerRef.current) {
            const thumbnailElement = thumbnailContainerRef.current.children[index]
            if (thumbnailElement) {
                thumbnailContainerRef.current.scrollTo({
                    left: thumbnailElement.offsetLeft - thumbnailElement.clientWidth,
                    behavior: 'smooth'
                })
            }
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                handleNext()
            } else if (event.key === 'ArrowLeft') {
                handleBack()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleNext, handleBack])

    return (
        <Box className={classes.clickableCarousel}>
            <Lightbox
                index={index}
                slides={images}
                plugins={[Inline]}
                styles={{
                    container: {
                        borderRadius: 12
                    },
                    button: {
                        borderRadius: 40,
                        padding: 0,
                        margin: '0px 12px',
                        backgroundColor: 'rgba(0, 0, 0, 0.3)'
                    }
                }}
                on={{
                    view: updateIndex,
                    click: toggleOpen(true)
                }}
                carousel={{
                    padding: 0,
                    spacing: 0,
                    imageFit: 'cover'
                }}
                inline={{
                    style: {
                        width: '100%',
                        maxWidth: '900px',
                        maxHeight: '500px',
                        aspectRatio: '3 / 2',
                        margin: '0 auto'
                    }
                }}
            />
            <Box className={classes.fullScreenButton} onClick={toggleOpen(true)}>
                <IconFactory icon='image' className={classes.iconImageCaroussel} />
                <Typography variant='h3' component='p' className={classes.textCaroussel}>
                    <FormattedMessage id='landing_card_kot_page.in_photo' />
                </Typography>
            </Box>

            {isLargeScreen && <Dialog open={open} onClose={toggleOpen(false)} maxWidth='lg' className={classes.dialog}>
                <DialogTitle className={classes.dialogTitle} disableTypography>
                    <Typography variant='h6' className={classes.dialogTitleText}>
                        <FormattedMessage id='landing_card_kot_page.in_photo' />
                    </Typography>
                    <IconButton
                        edge='end'
                        color='inherit'
                        onClick={toggleOpen(false)}
                        aria-label='close'
                        className={classes.closeButton}
                    >
                        <IconFactory icon='close'/>
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Box className={classes.dialogContainer}>
                        <Box className={classes.dialogImageCounter}>
                            <IconFactory icon='image' className={classes.iconImage} />
                            {activeStep + 1}/{images.length}
                        </Box>
                        <Box className={classes.arrowLeft}>
                            <IconButton
                                onClick={handleBack}
                                className={classes.arrowBtn}
                            >
                                <KeyboardArrowLeft
                                    fontSize='medium'
                                    className={classes.arrows}
                                />
                            </IconButton>
                        </Box>
                        <Box className={classes.arrowRight}>
                            <IconButton
                                onClick={handleNext}
                                className={classes.arrowBtn}
                            >
                                <KeyboardArrowRight
                                    fontSize='medium'
                                    className={classes.arrows}
                                />
                            </IconButton>
                        </Box>
                        <Box className={classes.imageContainer}>
                            <img
                                className={classes.mediaDialog}
                                src={images[activeStep].src}
                                alt={images[activeStep].originalAlt}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.dialogThumbnailContainer} ref={thumbnailContainerRef}>
                        {images.map((image, index) => (
                            <Box
                                key={index}
                                className={`${classes.dialogImageThumbnail} ${index === activeStep ? classes.dialogThumbnailSelected : ''}`}
                                onClick={() => handleThumbnailClick(index)}
                            >
                                <CardMedia
                                    className={classes.media}
                                    image={image.src}
                                    alt={image.originalAlt}
                                />
                            </Box>
                        ))}

                    </Box>
                </DialogContent>
            </Dialog>

            }

            {!isLargeScreen && <Lightbox
                open={open}
                plugins={[Zoom, Thumbnails, Counter]}
                close={toggleOpen(false)}
                index={index}
                slides={images}
                on={{ view: updateIndex }}
                styles={{
                    container: {
                        backgroundColor: '#ffffff'
                    },
                    button: {
                        borderRadius: 40,
                        padding: 0,
                        margin: '0px 12px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }}
                animation={{ fade: 0 }}
                zoom={{
                    maxZoomPixelRatio
                }}
                thumbnails={{
                    imageFit: 'cover',
                    padding: 0
                }}
                counter={{
                    style: {
                        color: 'rgba(0, 0, 0, 0.5)',
                        padding: '0px 8px'
                    }
                }}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
            />
            }
        </Box>
    )
}

export default KotCaroussel
