import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsLogginContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px'
    },
    userActionsLoginButton: {
        padding: '9px 14px',
        borderRadius: '8px',
        height: '36px',
        '& p': {
            fontFamily: "'GintoNormal-Medium', sans-serif !important"
        },
        '&:hover': {
            backgroundColor: 'var(--beige)'
        }
    }
}))
