/* eslint-disable react/display-name */

import React from 'react'
import SearchbarComponent from './SearchbarComponent'
import FirstJobFilters from './FirstJobFilters/FirstJobFilters'
import StudentJobFilters from './StudentJobFilters/StudentJobFilters'
import InternshipFilters from './InternshipFilters/InternshipFilters'
import CompanyFilters from './CompanyFilters/CompanyFilters'
import KotFilters from './KotFilters/KotFilters'

const Searchbar = (props) => {
    switch (props.type) {
    case 'first_job':
        return <FirstJobFilters {...props} />
    case 'internship':
        return <InternshipFilters {...props} />
    case 'student_job':
        return <StudentJobFilters {...props} />
    case 'company':
        return <CompanyFilters {...props} />
    case 'kot':
        return <KotFilters {...props} />
    default:
        return <SearchbarComponent {...props} />
    }
}

export default Searchbar
