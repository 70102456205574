import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height: 420,
        minWidth: 280,
        maxWidth: 380,
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 12
    },
    routeMapLinkStyle: {
        width: '100%',
        height: '100%'
    },
    companyCardImage: {
        height: '40%',
        position: 'relative'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '12px 12px 0 0',
        objectFit: 'cover'
    },
    companyLogo: {
        position: 'absolute',
        display: 'flex',
        left: '8%',
        bottom: '-24px',
        textAlign: 'center',
        '& img': {
            width: 85,
            height: 85,
            objectFit: 'contain',
            borderRadius: 16,
            backgroundColor: 'white',
            boxShadow: '0 0 4px 0 rgba(0,0,0,.2)',
            [theme.breakpoints.down('md')]: {
                width: 80,
                height: 80
            }
        }
    },
    baseTag: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
        textTransform: 'lowercase',
        padding: '6px 10px',
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        fontSize: 10,
        fontWeight: 300,
        lineHeight: '0.8rem'
    },
    recruitingTag: {
        color: 'white',
        backgroundColor: 'var(--green)',
        top: 12,
        right: 12
    },
    recruitingTagText: {
        '&:first-letter': {
            textTransform: 'capitalize'
        }
    },
    companyCardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: 32,
        paddingTop: 12,
        paddingBottom: 16
    },
    companyName: {
        display: '-webkit-box',
        lineClamp: '2',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginTop: 22
    },
    companyTitle: {},
    companyDescription: {
        display: '-webkit-box',
        lineClamp: '3',
        boxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 4
    },
    chipRoot: {
        height: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 10,
        padding: '6px 10px',
        color: 'var(--black)',
        cursor: 'pointer'
    },
    peopleWantedContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        gap: 8
    },
    peopleWanted: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4
    },
    peopleWantedText: {
        fontSize: 10,
        textTransform: 'lowercase'
    }
}))
