export const useStyles = (theme) => ({
    employerBannerContainer: {
        width: '100%',
        zIndex: 1,
        position: 'relative',

    },
    employerBannerImageContainer: {
        position: 'relative',
        width: '100%',
        minHeight: '100px',
    },
    employerHeaderContainer: {
        position: 'relative',
        '& img': {
            width: '100%',
            objectFit: 'cover',
            maxHeight: '420.69px',
            minHeight: '200px'
        }
    },
    employerHeaderNoPicture: {
        minHeight: '300px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.up("md")]: {
            height: '100%',
            width: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            height: '100%'
        }
    },
    employerSummaryContainer: {
        width: '100%',
        maxWidth: '1280px',
        margin: '0 auto',
        position: 'relative'
    },
    employerBanner: {
        position: 'absolute',
        background: '#ffffff',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        [theme.breakpoints.up("md")]: {
            bottom: '-80px',
            left: '20px',
            height: '140px'
        },
        [theme.breakpoints.down("sm")]: {
            bottom: '-60px',
            right: '10%',
            width: '80%',
            padding: '10px',
            justifyContent: 'space-evenly'
        },
        [theme.breakpoints.down("xs")]: {
            bottom: '-100px',
        }
    },
    employerSummary: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        [theme.breakpoints.down("xs")]: {
            flexDirection: 'column'
        }
    },
    employerSummaryLogoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        '& img': {
            maxHeight: '100px',
            maxWidth: '200px'
        }
    },
    employerSummaryItem: {
        height: '60px',
        textAlign: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        [theme.breakpoints.up("md")]: {
            padding: '20px',
            borderLeft: 'solid grey 1px',
        },
    },
    iconContainer: {
        position: 'absolute',
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '5px',
        top: '20px',
        right: '20px',
    },
    hoverableFilledButton: {
        position: 'absolute',
        left: '20px',
        [theme.breakpoints.up("md")]: {
            top: '20px'
        },
        [theme.breakpoints.down("sm")]: {
            top: '20px'
        }
    }
})
