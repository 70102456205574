import React from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { useStyles } from '../../ProfileEditForm_style'
import StepButtons from '../StepButtons/StepButtons'

const ProfileEditFormStepNameView = (props) => {
    const { step, handleSubmit, previousStep, shortProfile, handleChange, loading, errors, inputRefs } = props
    const classes = useStyles()

    return (
        <Box className={classes.mainStepBox}>
            <Typography variant='body1' className={classes.text}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.name_step.cta' />
            </Typography>
            <Box className={classes.nameInputBox}>
                <FormattedMessage id='personal_dashboard.profile_edit_form.name_step.first_name_placeholoder'>
                    {placeholder =>
                        <TextField
                            name='first_name'
                            inputRef={inputRefs.firstNameRef}
                            value={shortProfile.first_name}
                            className={`${classes.textField} ${errors.firstName && classes.textFieldError}`}
                            onChange={handleChange}
                            InputProps={{ disableUnderline: true, classes: { input: classes.textFieldInput } }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
                <FormattedMessage id='personal_dashboard.profile_edit_form.name_step.last_name_placeholoder'>
                    {placeholder =>
                        <TextField
                            required
                            name='last_name'
                            inputRef={inputRefs.lastNameRef}
                            value={shortProfile.last_name}
                            className={`${classes.textField} ${errors.lastName && classes.textFieldError}`}
                            onChange={handleChange}
                            InputProps={{ disableUnderline: true, classes: { input: classes.textFieldInput } }}
                            placeholder={placeholder}
                        />
                    }
                </FormattedMessage>
            </Box>
            <StepButtons
                loading={loading}
                step={step}
                nextStep={handleSubmit}
                previousStep={previousStep}
            />
        </Box>
    )
}
export default ProfileEditFormStepNameView
