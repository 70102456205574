import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    chatWindowMainContainer: {
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 99,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
    },
    fabTypography: {
        marginLeft: '8px',
        textTransform: 'none'
    }
}))
