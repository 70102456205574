import React from 'react'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import useTheme from '@material-ui/core/styles/useTheme'

import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

const MessageAllModalView = (props) => {
    const theme = useTheme()

    const {
        closeModal,
        handleMessageChange,
        message,
        openShare,
        sendMessageToSelected
    } = props

    return (
        <Dialog
            aria-labelledby="responsive-dialog-delete"
            disableScrollLock={true}
            fullWidth
            maxWidth='sm'
            onClose={closeModal}
            open={openShare}
        >
            <DialogTitle onClose={closeModal}>
                <Typography
                    variant='h2'
                    style={{ marginTop: '1rem' }}
                >
                    <FormattedMessage id='message_all_popup.popup_title' />
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    style={{
                        color: theme.palette.grey[500],
                        height: 35,
                        position: 'absolute',
                        right: theme.spacing(1),
                        top: 5,
                        width: 35
                    }}
                >
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box m={2}>
                    <FormattedMessage id='message_all_popup.input_label'>
                        {label =>
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label={label}
                                multiline
                                onChange={handleMessageChange}
                                rows={8}
                                value={message}
                                variant="outlined"
                            />
                        }
                    </FormattedMessage>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box>
                    <LineButton
                        name={<FormattedMessage id="job_chat.cancel" />}
                        onClick={closeModal}
                        size='small'
                    />
                    <FilledButton
                        color='secondary'
                        name={<FormattedMessage id='message_all_popup.action_button' />}
                        onClick={sendMessageToSelected}
                        size='small'
                        style={{ marginLeft: 10 }}
                    />
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default MessageAllModalView
