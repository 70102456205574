import React from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'

import css from './singleimageuploader.scss'
import css_responsive from './singleimageuploader_responsive.scss'

import FilledButton from 'buttons/FilledButton/FilledButton'
import CloudinaryHelper from 'assets/CloudinaryHelper'

import IconFactory from 'icons/IconFactory/IconFactory'

export default class SingleImageUploader extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            pictureId: props.pictureId,
            cloudinaryKey: ''
        }

        this.myWidget = CloudinaryHelper.createImageUploadWidget(
            {
                cropping: true,
                multiple: false
            },
            (error, result) => {
                console.log(result)
                if (!error && result && result.event === 'success') {
                    console.log('Done! Here is the image info: ', result.info.public_id)
                    this.setState({
                        pictureId: result.info.public_id,
                        updateComponent: true
                    }, () => {
                        this.props.functionOnSuccess
                            ? this.props.functionOnSuccess(result.info)
                            : ''
                    })

                    if (this.props.inProfile == true) {
                        this.updatePicture(result.info)
                    }
                }
            }
        )

        this.updatePicture = this.updatePicture.bind(this)
    }

    shouldComponentUpdate (nextProps, nextState) {
        if (this.state.pictureId === nextState.pictureId) {
            return false
        } else {
            return true
        }
    }

    updatePicture (public_id) { // for the profile
        const { pictureId } = this.state
        axios({
            url: '/api/v1/users/update_picture',
            method: 'patch',
            data: {
                profile_id: this.props.profileId,
                picture_id: pictureId
            }
        })
            .then(function (response) {
                console.log(response)
            })
            .catch(function (error) {
                alert('An error has occurred')
                Bugsnag.notify(error)
            })
        event.preventDefault()
    }

    upload () {
        this.myWidget.open()
    }

    render () {
        return (
            <div className="upload-image-box">
                <div
                    className="image-overlay"
                    id="upload_widget"
                    onClick={() => this.myWidget.open()}
                >
                    {this.props.type == 'icon' &&
                        <IconFactory icon='edit' />
                    }
                    {this.props.type == 'button' &&
                        <FilledButton
                            name={this.props.name}
                            type="submit"
                            onMouseEnter={() => { this.props.onMouseEnter && this.props.onMouseEnter() }}
                            onMouseLeave={this.props.onMouseLeave}
                        />
                    }
                </div>
            </div>

        )
    }
}
