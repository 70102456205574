import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    citylistContainer: {
        zIndex: 9000,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: '80px 0',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0'
        }
    },
    citylistTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            gap: 20,
            marginBottom: 20
        }
    },
    title: {
        fontSize: 45,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            lineHeight: 1
        }
    },
    description: {
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: 1
        }
    },
    titleColumn: {
        lineHeight: 1,
        marginBottom: 10,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px !important'
        }
    },
    link: {
        listStyle: 'none',
        fontSize: 20,
        paddingLeft: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    },
    linksContainerGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        margin: 0,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            gap: 20
        }
    },
    linksContainer: {
        display: 'flex',
        width: '25%',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '45%'
        }
    },
    linksBox: {
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,
        }
    },
    allCity: {
        color: 'var(--red) !important',
        fontFamily: 'GintoNormal-Medium',
        textDecoration: 'none !important',
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    }
}))
