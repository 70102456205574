import React, { useState } from 'react'

import { useStyles } from './MobileBurgerMenu_style'
import { Box } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import MobileBurgerMenuContainer from '../wrappers/MobileBurgerMenuContainer'
import MobileJobTypesCards from '../shared/MobileJobTypesCard/MobileJobTypesCard'
import MobileJobWallet from '../MobileJobWallet/MobileJobWallet'
import MobileWalletCard from '../shared/MobileWalletCard/MobileWalletCard'

const MobileBurgerMenu = (props) => {
    const classes = useStyles()
    const [jobTypeSelection, setJobTypeSelection] = useState(false)

    return (
        <MobileBurgerMenuContainer {...props}
            jobTypeSelection={jobTypeSelection}
            setJobTypeSelection={setJobTypeSelection}
        >
            <Box className={classes.burgerMenuContainer}>
                {jobTypeSelection
                    ? <>
                        <MobileJobWallet jobTypeSelection={jobTypeSelection} />
                    </>
                    : <>
                        <MobileJobTypesCards setJobTypeSelection={setJobTypeSelection} />
                        <MobileWalletCard name={<FormattedMessage id='page_footer_navbar_data.companies_burger' />} page='companies' />
                        <MobileWalletCard name={<FormattedMessage id='page_footer_navbar_data.education_new' />} page='student-life/categories/education' />
                        <MobileWalletCard name={<FormattedMessage id='page_footer_navbar_data.kot_button' />} page='student-rooms' />
                        <MobileWalletCard name={<FormattedMessage id='page_footer_navbar_data.news' />} page='student-life' />
                        <MobileWalletCard name={<FormattedMessage id='page_footer_navbar_data.employer_button_burger' />} page='employers' secondary />
                    </>
                }
            </Box>
        </MobileBurgerMenuContainer>
    )
}

export default MobileBurgerMenu
