import React from 'react'

import { Box } from '@material-ui/core'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { Image, Transformation } from 'cloudinary-react'
import { SMALL_SIZE } from 'assets/CloudinaryURLHelper'

import { useStyles } from './ArticleCardV2_style'
import ArticleCardContent from 'components/ArticleCardContent/ArticleCardContent'

const ArticleCardV2 = (props) => {
    const { article } = props
    const classes = useStyles()

    return (
        <RouteMapLink
            showItemType='article'
            showItemUrl={article.url}
            className={classes.articleLink}
        >
            <Box className={classes.articleCardContainer}>
                <span className={classes.articleCardImage} role='img' aria-label='article image'>
                    <Image
                        cloudName="studentbe"
                        className={classes.cardMedia}
                        publicId={article.cover_image.cloudinary_key}
                        alt='sector image'
                    >
                        <Transformation flags="progressive" fetchFormat="auto" height={SMALL_SIZE} quality="auto:good" crop="fill" />
                    </Image>
                </span>
                <ArticleCardContent article={article} />
            </Box>
        </RouteMapLink>
    )
}

export default ArticleCardV2
