import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    selectPlaceholder: {
        fontStyle: 'italic',
        lineHeight: 1
    },
    menuItem: {
        height: '48px !important',
        margin: '0 !important'
    },
    boxContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    boxInput: {
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        }
    },
    iconBox: {
        marginRight: 10,
        display: 'flex'
    },
    inputBase: {
        fontFamily: theme.typography.subtitle1.fontFamily,
        // fontSize: theme.typography.subtitle1.fontSize,
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        },
        '&::placeholder': {
            fontStyle: 'italic',
            fontSize: 14
        }
    },
    formControl: {
        [theme.breakpoints.down('md')]: {
            marginBottom: 10
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 10
        }
    },
    myAdsButtonContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    myAdsButtonLink: {
        width: '100%',
        padding: 'auto 40px',
        marginLeft: '4px'
    },
    myAdsButton: {
        color: 'white',
        width: '100%'
    }
}))
