import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { useStyles } from '../FiltersModals_style'
import { FormattedMessage } from 'react-intl'

const MoreFilterSectionWrapper = ({ children, ...props }) => {
    const classes = useStyles()
    const { titleId, underTitleId, disableDivider } = props

    return (
        <>
            <Box className={classes.boxContainer}>
                <Typography variant='h6'>
                    <FormattedMessage id={titleId} />
                </Typography>
                <Typography variant='h5' className={classes.underTitle}>
                    <FormattedMessage id={underTitleId} />
                </Typography>
                {children}
            </Box>
            {!disableDivider && <Divider className={classes.divider}/>}
        </>

    )
}

export default MoreFilterSectionWrapper
