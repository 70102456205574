import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'
import SimilarJobs from '../SimilarJobs/SimilarJobs'
import SimilarArticles from '../SimilarArticles/SimilarArticles'
import { FormattedMessage } from 'react-intl'
import URLHandler from 'assets/URLHandler.js'
import { useStyles } from './NoJobShow_style'

const NoJobShow = (props) => {
    const { user } = props
    const jobType = URLHandler.getJobTypeFromUrl()
    const classes = useStyles()

    return (<>
        <Helmet>
            <meta name='robots' content='noindex' />
            <meta name='googlebot' content='noindex' />
        </Helmet>
        <Box className={classes.mainContainer}>
            <Container maxWidth='lg'>
                <Grid item xs={12} className={classes.banner}>
                    <Paper elevation={4} className={classes.bannerPaper}>
                        <Typography variant='h3' className={classes.bannerText}>
                            <FormattedMessage id='transl_jobs.does_not_exist_banner' />
                        </Typography>
                    </Paper>
                </Grid>
            </Container>
        </Box>
        <Box className={classes.similarJobsBox}>
            <SimilarJobs user={user} job={{ type: jobType }} />
        </Box>
        <Box className={classes.similarArticlesBox}>
            <SimilarArticles user={user} category={jobType} baseItem={{ type: jobType }}/>
        </Box>
    </>)
}

export default NoJobShow
