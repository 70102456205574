import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import CardSlider from 'components/CardSlider/CardSlider'

const RecentArticlesSlideshowView = (props) => {
    const { articles } = props

    return (
        <Box>
            {articles &&
                <CardSlider
                    cards={articles}
                    maxItem="3"
                    onlyCard
                />
            }
        </Box>
    )
}

RecentArticlesSlideshowView.propTypes = {
    articles: PropTypes.array
}

export default RecentArticlesSlideshowView
