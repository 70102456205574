import React, { useState } from 'react'
import { Box, Paper, Divider } from '@material-ui/core'
import ProfileIdentityBox from './components/ProfileIdentityBox/ProfileIdentityBox'
import JobSearchStatusBox from './components/JobSearchStatusBox/JobSearchStatusBox'
import GoToProfileBox from './components/GoToProfileBox/GoToProfileBox'
import NotificationBox from './components/NotificationBox/NotificationBox'
import StudyExperienceBox from './components/StudyExperienceBox/StudyExperienceBox'
import { useStyles } from './ProfilePassport_style'

const ProfilePassport = (props) => {
    const { user, openProfileEditFormOnStep } = props
    const classes = useStyles()
    const [imageAvatar, setImageAvatar] = useState([])

    return (
        <Paper className={classes.paper}>
            <Box className={classes.sectionBox}>
                <ProfileIdentityBox
                    user={user}
                    imageAvatar={imageAvatar}
                    setImageAvatar={setImageAvatar}
                />
            </Box>
            <Divider />
            <Box className={classes.sectionBox}>
                <JobSearchStatusBox user={user} />
            </Box>
            <Divider />
            {user.current_experience && <>
                <Box className={classes.sectionBoxWidth}>
                    <StudyExperienceBox user={user} openProfileEditFormOnStep={openProfileEditFormOnStep} />
                </Box>
                <Divider />
            </>}
            <Box className={classes.sectionBoxWidth}>
                <NotificationBox user={user} />
            </Box>
            <Divider />
            <Box className={classes.sectionBox}>
                <GoToProfileBox />
            </Box>
        </Paper>
    )
}
export default ProfilePassport
