import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    funnelContent: {
        width: '70%',
        position: 'relative',
        overflow: 'scroll',
        overflowX: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderTop: '2px var(--light-grey) solid',
        [theme.breakpoints.down('md')]: {
            width: '70%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            background: 'white',
            borderRadius: '12px 12px 0 0'
        }
    },
}))
