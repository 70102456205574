/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import { useStyles } from './AboutFAQPage_style'
import AboutFAQHelmet from 'helmets/AboutFAQHelmet'
import URLHandler from 'assets/URLHandler.js'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Accordion from '@material-ui/core/Accordion'
import Box from '@material-ui/core/Box'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import { FormattedMessage } from 'react-intl'
import Link from '@material-ui/core/Link'
import RouteMap from 'assets/RouteMap'
import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import ResponsiveVideo from 'components/ResponsiveVideo/ResponsiveVideo'

const DisplayPageContent = (props) => {
    const classes = useStyles()
    const language = URLHandler.GetLocaleFromUrl()

    return (
        <>
            <Box className={classes.aboutPageBanner}>
                <Container maxWidth="md">
                    <Typography variant='h1' className={classes.titlePage}>
                        <FormattedMessage id="landing_about_faq.title" />
                    </Typography>
                </Container>
            </Box>

            <Container maxWidth='md' className={classes.content}>
                <Box mb={5}>
                    <Typography>
                        <FormattedMessage id="landing_about_faq.description_1" />
                    </Typography>
                    <Typography>
                        <FormattedMessage id="landing_about_faq.description_2" />
                        <RouteMapLink page='contact' className={classes.studentFont}>
                            <FormattedMessage id="landing_about_faq.contact_us" />
                        </RouteMapLink>
                    </Typography>
                </Box>
                <Accordion className={classes.categoryContainer} bgColor='primary'>
                    <AccordionSummary>
                        <Typography variant='body1' component='h2' color="primary"><FormattedMessage id="landing_about_faq.category.job" /></Typography>
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.studentbe_work.title" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.intro" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.step_1" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.step_2" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.step_3" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.step_4" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.how_to.studentbe_work.outro" /></Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.apply_for_a_job" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.apply_for_a_job" />
                                <br />
                                <br />
                                <FormattedMessage id="landing_about_faq.answer.apply_for_a_job_form_cv" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.have_answer_from_employer" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.apply_again" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.apply_again_2" /></Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.cancel_job_application" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.cancel_job_application" /></Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.get_hours_left" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.get_hours_left" /></Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.read_message" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.read_message" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.read_message_go_to_2" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.read_message_go_to_3" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.know_if_ads_are_out_of_date" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.know_if_ads_are_out_of_date_list.step_1" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.know_if_ads_are_out_of_date_list.step_2" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.know_if_ads_are_out_of_date_list.step_3" /></Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography><FormattedMessage id="landing_about_faq.answer.know_if_ads_are_out_of_date" /></Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.delete_account" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.delete_account" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.recover_my_login_information" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.student.content_1" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.student.content_2" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <br />
                <Accordion className={classes.categoryContainer}>
                    <AccordionSummary>
                        <Typography variant='body1' component='h2' color="primary"><FormattedMessage id="landing_about_faq.category.employer" /></Typography>
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.delete_ads" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.delete_ads" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.problem_not_solve_2" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.place_a_job" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.place_a_job" />
                            </Typography>
                        </AccordionDetails>
                        {language === 'nl' &&
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/15xDMkKlmgc' fixedWidth />
                            </AccordionDetails>
                        }
                        {language === 'fr' &&
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/yUQu7F2iWxY' fixedWidth />
                            </AccordionDetails>
                        }
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.problem_not_solve_2" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.see_candidate_applications" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.see_candidate_applications.title" />
                            </Typography>
                        </AccordionDetails>
                        {language === 'nl' &&
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/Xxv0RgH_ZN8' fixedWidth />
                            </AccordionDetails>
                        }
                        {language === 'fr' &&
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/mMHNKyyLL8Y' fixedWidth />
                            </AccordionDetails>
                        }
                    </Box>
                </Accordion>
                {language === 'nl' &&
                    <Accordion>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.optimize_company_page" /></Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/kE_4nl0ZMO4' fixedWidth />
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                }
                {language === 'nl' &&
                    <Accordion>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.post_article" /></Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/ZonGDriITj8' fixedWidth />
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                }
                {language === 'fr' &&
                    <Accordion>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.optimize_company_page" /></Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/FWaWy06NQ_A' fixedWidth />
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                }
                {language === 'fr' &&
                    <Accordion>
                        <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                            <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.post_article" /></Typography>
                        </AccordionSummary>
                        <Box className={classes.answerContainer}>
                            <AccordionDetails>
                                <ResponsiveVideo videoUrl='https://youtu.be/JEYffsSyxos' fixedWidth />
                            </AccordionDetails>
                        </Box>
                    </Accordion>
                }
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.recover_my_login_information" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.not_student.content_1" /><br />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.not_student.content_2" /><br />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <br />
                <Accordion className={classes.categoryContainer}>
                    <AccordionSummary>
                        <Typography variant='body1' component='h2' color="primary"><FormattedMessage id="landing_about_faq.category.kot_owner" /></Typography>
                    </AccordionSummary>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.solve_announcement_pending" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.solve_announcement_pending" /><br/><br/>
                                <FormattedMessage id="landing_about_faq.answer.contact_by_phone" /><br/><br/>
                                <FormattedMessage id="landing_about_faq.answer.not_answer_remove" /><br/>
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.remove_kot_announcement" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.remove_kot_announcement.title" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.remove_kot_announcement.step_1" /><br />
                                <FormattedMessage id="landing_about_faq.answer.remove_kot_announcement.step_2" /><br />
                                <FormattedMessage id="landing_about_faq.answer.remove_kot_announcement.step_3" /><br />
                                <FormattedMessage id="landing_about_faq.answer.remove_kot_announcement.step_4" /><br />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.get_kot_info" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.get_kot_info" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.get_a_kot" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.get_a_kot" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.search_kots" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.good_luck" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.place_kot_announcement_search" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.place_kot_announcement_search" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.check_out_kot_announcement" />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.good_luck" />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<IconFactory icon='more' />}>
                        <Typography color='primary'><FormattedMessage id="landing_about_faq.how_to.recover_my_login_information" /></Typography>
                    </AccordionSummary>
                    <Box className={classes.answerContainer}>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.not_student.content_1" /><br />
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography>
                                <FormattedMessage id="landing_about_faq.answer.recover_my_login_information.not_student.content_2" /><br />
                            </Typography>
                        </AccordionDetails>
                    </Box>
                </Accordion>
            </Container>
        </>
    )
}

const AboutFAQPage = ({ props }) => {
    return (
        <>
            <AboutFAQHelmet />
            {DisplayPageContent(props)}
        </>
    )
}

export default AboutFAQPage
