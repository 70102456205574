import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    itemOffersMainContainer: {
        marginTop: 12,
        marginBottom: 12,
        display: 'flex',
        alignItems: 'center'
    },
    itemOffersTextBox: {
        display: 'flex',
        flexDirection: 'column'
    },
    pageSubTitle: {
        fontFamily: "'GintoNormal-Bold', sans-serif !important",
        '& .titleSpan': {
            color: 'var(--red)'
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10
        }

    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))
