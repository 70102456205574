import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    introSectionContainer: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: 440,
        [theme.breakpoints.down('sm')]: {
            minHeight: 350
        }
    },
    mainContainer: {
        position: 'relative',
        marginBottom: 120
    },
    beigeContainer: {
        backgroundColor: 'var(--beige)',
        paddingTop: 60,
        paddingBottom: 80,
        marginBottom: 80,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 40,
            paddingBottom: 60
        }
    },
    packagesTextBox: {
        textAlign: 'center',
        marginBottom: 80,
    },
    blobBox: {
        position: 'relative',
        overflow: 'hidden',
        height: 500
    },
    backgroundIntro: {
        position: 'absolute'
    },
    blob: {
        zIndex: -1,
        position: 'absolute',
        top: '-100px',
        left: 0,
        width: 800,
        [theme.breakpoints.down('sm')]: {
            width: 600
        }
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%'
    },
    ribbonBox: {
        width: '100%',
        overflow: 'hidden'
    },
    ribbon: {
        position: 'absolute',
        overflow: 'hidden',
        width: '100%',
        top: 500,
        marginTop: 40,
        marginBottom: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--red-burgundy)',
        padding: '5px 9px',
        color: 'white',
        fontSize: 20,
        textTransform: 'uppercase',
        textWrap: 'nowrap',
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            top: 420,
            left: '-300px',
            width: 'calc(100% + 300px)'
        }
    },
    titleBox: {
        position: 'relative',
        marginTop: 110,
        [theme.breakpoints.up('sm')]: {
            marginTop: 150,
            width: '50%',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 20,
            marginTop: 80,
        }
    },
    title: {
        textAlign: 'center',
        fontSize: 48,
        color: 'var(--red)',
        lineHeight: '44px',
        marginBottom: 14,
        paddingLeft: 24,
        paddingRight: 24,
        [theme.breakpoints.down('md')]: {
            fontSize: 36,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
            lineHeight: '36px'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    subTitle: {
        textAlign: 'center',
        fontSize: '24px !important',
        lineHeight: '26px',
        paddingLeft: 32,
        paddingRight: 32,
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '20px !important',
        },
    },
    sectionMainTitle: {
        '& h2': {
            fontSize: '24px !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px !important'
            }
        }
    },
    buttonBox: {
        marginTop: 30,
        display: 'flex',
        gap: 14,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    partnerContainer: {
        display: 'flex',
        gap: 36,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: 8
        }
    },
    partnerBox: {
        margin: '3rem 0 5rem',
        display: 'flex',
        alignItems: 'center',
        overflowX: 'scroll',
        gap: 36,
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE 10+ */
        '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
            height: 0,
            background: 'transparent'
        }
    },
    sliderArrowBox: {
        width: 40,
        height: 40
    },
    partnerImageBox: {
        width: '100%',
        '& a': {
            display: 'flex'
        }
    },
    partnerImage: {
        height: 100,
        objectFit: 'contain',
        margin: '1rem auto',
        cursor: 'pointer',
        filter: 'grayscale(100%)',
        opacity: 0.8,
        '&:hover': {
            transform: 'scale(1.05)',
            transition: '.2s'
        },
        [theme.breakpoints.down('md')]: {
            height: 80
        },
        [theme.breakpoints.down('sm')]: {
            height: 60
        }
    },
    companiesCtaBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 24
    },
    companiesTitle: {
        color: 'var(--red)'
    },
    whyContainer: {
        position: 'relative',
        zIndex: 1000,
        [theme.breakpoints.down('sm')]: {
        }
    },
    rightWhyGridItem: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    whyBox: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 90,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 36
        }
    },
    whyImage: {
        height: 'fit-content',
        width: 120
    },
    whyImageBox: {
        height: 140,
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            height: 100
        }
    },
    whyTitle: {
        fontSize: '20px !important',
        color: 'var(--red-burgundy)',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    paymentTitle: {
        fontSize: '48px !important',
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    paymentButton: {
        marginTop: 24,
        fontSize: 14,
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontWeight: 500,
    },
    whyTextBox: {
        textAlign: 'center'
    },
    whyText: {
        fontSize: 18
    },
    whyButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        gap: 14,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '& p': {
            minWidth: 250,
            fontSize: '18px !important',

            [theme.breakpoints.down('xs')]: {
                fontSize: '16px !important'
            }
        }
    },
    bigButton: {

        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
        '& p': {
            minWidth: 250,
            fontSize: '20px !important'
        }
    },
    faqCta: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationThickness: '2px',
        cursor: 'pointer',
        fontSize: '18px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    accordionText: {
        marginBottom: 24,
        fontSize: '18px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    accordionDetails: {
        display: 'block'
    },
    accordionBox: {
        maxWidth: 900
    }
}))
