import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    checkboxLine: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    checkboxText: {
        fontSize: 14,
        marginLeft: 4
    },
    checkbox: {
        padding: 7
    }
}))
