import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    // === Trend Image ===
    trendMainArticle: {
        display: 'flex',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: '356px'
        },
        [theme.breakpoints.down('sm')]: {
            height: 200
        }
    },
    trendImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        [theme.breakpoints.up('md')]: {
            borderRadius: '12px'
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: '8px'
        }
    },
    articleInfoContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            padding: 20
        },
        [theme.breakpoints.down('sm')]: {
            padding: 12
        }
    },
    chipRoot: {
        height: 'fit-content',
        width: 'fit-content',
        cursor: 'pointer'
    },
    chipLabel: {
        padding: 0,
        lineHeight: 1.4
    },
    chip: {
        backgroundColor: 'var(--beige)',
        borderRadius: 6,
        fontSize: 12,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    articleDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            gap: 20,
        },
        [theme.breakpoints.down('sm')]: {
            gap: 12
        }
    },
    articleTitle: {
        color: 'white !important',
        fontFamily: 'GintoNormal-Bold, sans-serif !important',
        [theme.breakpoints.up('md')]: {
            fontSize: '24px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    },
    readTimeAndDate: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12
    },
    minorText: {
        fontSize: '12px !important',
        color: 'white',
        fontFamily: 'GintoNormal-Bold, sans-serif !important'
    },
    bullet: {
        fontSize: '10px !important',
        color: 'white',
        fontFamily: 'GintoNormal-Bold, sans-serif !important'
    },
    readtimeContainer: {
        display: 'flex',
        gap: 6,
        alignItems: 'center'
    }
}))
