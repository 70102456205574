import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    arrowLeft: {
        left: -10,
        zIndex: 2,
        top: '20%',
        position: 'absolute',
        marginLeft: 8,
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
            borderRadius: '50%'
        }
    },
    arrowRight: {
        right: -5,
        zIndex: 2,
        top: '20%',
        position: 'absolute',
        marginRight: 8,
        '&:hover': {
            boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
            borderRadius: '50%'
        }
    },
    arrowBtn: {
        height: 30,
        width: 30,
        backgroundColor: 'var(--super-light-grey)',
        border: '1px solid var(--light-grey)',
        '&:hover': {
            backgroundColor: 'var(--super-light-grey)',
            height: 32,
            width: 32
        }
    },
    arrows: {
        width: 24,
        height: 24,
        color: 'var(--black)',
        '&:hover': {
            height: 26,
            width: 26
        }
    },
    atScrollEnd: {
        maskImage: 'linear-gradient(to left, transparent, black 20px, black 90%, transparent)'
    },
    atScrollStart: {
        maskImage: 'linear-gradient(to right, transparent, black 20px, black 90%, transparent)'
    }
}))
