import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    sliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    titleContainer: {
        [theme.breakpoints.up('md')]: {
            width: 1280,
            display: 'flex',
            justifyContent: 'space-between'
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 12px'
        }
    },
    title: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        [theme.breakpoints.up('md')]: {
            fontSize: '24px !important'
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '16px !important'
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12
    },
    button: {
        padding: 0,
        width: 32,
        height: 32,
        backgroundColor: 'var(--beige)'
    },
    buttonActive: {
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: '#2C2A28'
        }
    },
    icon: {
        color: 'black',
        fontSize: '14px !important'
    },
    iconActive: {
        color: 'white'
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        columnGap: 20,
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE 10+ */
        '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
            height: 0,
            background: 'transparent'
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 12px'
        },
        scrollSnapType: 'x mandatory'
    }
}))
