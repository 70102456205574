import React from 'react'

import { Box, Typography } from '@material-ui/core'

import RecentArticlesSlideshow from '../RecentArticlesSlideShow/RecentArticlesSlideshow'
import { useStyles } from '../RecentArticlesSlide_style'

const RecentArticlesSlideDefault = (props) => {
    const classes = useStyles()

    return (
        <Box className={classes.overrideCardSlider}>
            <Typography
                variant='h2'
                className={classes.previewTitle}
            >
                {props.title}
            </Typography>
            <Box className={classes.cardSliderContainerInnerPage}>
                <RecentArticlesSlideshow {...props}/>
            </Box>
        </Box>
    )
}

export default RecentArticlesSlideDefault
