/* eslint-disable react/prop-types */
import React, { useState } from 'react'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

import SearchBarWrapper from 'wrappers/SearchBarWrapper/SearchBarWrapper'
import IconFactory from 'icons/IconFactory/IconFactory'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './EmployerJobIndexSearchBarView_style'
import { FormattedMessage } from 'react-intl'

const EmployerJobIndexSearchBarView = (props) => {
    const { reset, handleFilterChange, filters, onEnterKeyPress } = props

    const SEARCH_PARAMS = [
        [<FormattedMessage id='chat.first_job'/>, 'first_job'],
        [<FormattedMessage id='chat.internship'/>, 'internship'],
        [<FormattedMessage id='chat.student_job'/>, 'student_job']
    ]

    const classes = useStyles()
    return (
        <Grid container>
            <Grid item xs={12} md={9}>
                <SearchBarWrapper reset={reset}>
                    <Box className={classes.boxContainer}>
                        <Box className={classes.boxInput}>
                            <Box className={classes.iconBox}>
                                <IconFactory icon='search' color='primary'/>
                            </Box>
                            <FormattedMessage id="search_bar.search.job">
                                {placeholder =>
                                    <TextField
                                        InputProps={{
                                            classes: {
                                                input: classes.inputBase
                                            }
                                        }}
                                        placeholder={placeholder}
                                        value={filters.title}
                                        name='title'
                                        onChange={handleFilterChange}
                                        className={classes.inputBase}
                                        fullWidth
                                        onKeyPress={onEnterKeyPress}
                                    />
                                }
                            </FormattedMessage>
                        </Box>
                        <FormControl fullWidth className={classes.formControl}>
                            <Select
                                name='type'
                                value={filters.type}
                                onChange={handleFilterChange}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: 350,
                                            width: 500
                                        }
                                    }
                                }}
                                displayEmpty
                                inputProps={{ MenuProps: { disableScrollLock: true } }}
                            >
                                <MenuItem disabled value={''}>
                                    <Typography variant='subtitle1' className={classes.selectPlaceholder}>
                                        <FormattedMessage id="search_bar.job_type" />
                                    </Typography>
                                </MenuItem>
                                {
                                    SEARCH_PARAMS.map((type, key) => (
                                        <MenuItem value={type[1]} key={key}>
                                            <Typography variant='body2'>
                                                {type[0]}
                                            </Typography>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </SearchBarWrapper>
            </Grid>
            <Grid item xs={12} md={3} className={classes.myAdsButtonContainer}>
                <RouteMapLink page='employer/new-ad' className={classes.myAdsButtonLink}>
                    <FilledButton
                        newStyle
                        name={<FormattedMessage id='item_index.post_offer' />}
                        color='secondary'
                        className={classes.myAdsButton}
                    />
                </RouteMapLink>
            </Grid>
        </Grid>
    )
}

export default EmployerJobIndexSearchBarView
