import React from 'react'

import css from './itemindexmain.scss'
import css_mobile from './itemindexmain_mobile.scss'

import axios from 'axios'

import AdBanner from 'components/AdBanner/AdBanner'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import ItemIndex from './ItemIndex/ItemIndex'
import RouteMap from 'assets/RouteMap.js'
import Searchbar from 'components/Searchbar/Searchbar'
import URLParamsHandler from 'assets/URLParamsHandler.js'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

export default class ItemIndexMainArticles extends React.Component {
    constructor (props) {
        super(props)
        this.createSearchBar = this.createSearchBar.bind(this)
        this.mapRef = React.createRef()
        this.searchBarRef = React.createRef()
        this.lang = RouteMap.GetLocaleFromUrl()

        this.state = {
            loading: true,
            itemDivSelected: '',
            itemSelected: '',
            items: [],
            applyJob: false,
            listRef: React.createRef(),
            cardRef: React.createRef(),
            indexRef: React.createRef(),
            showRef: React.createRef(),
            needRedirect: false,
            redirect_to: '',
            offerCount: 0,
            currentPage: URLParamsHandler.loadCurrentPage(),
            loadPreviousPage: URLParamsHandler.loadPreviousPageBool(),
            loadMoreStatus: true,
            filteringStatus: false,
            textFiltered: ''
        }

        this.urlFilters = URLParamsHandler.loadUrlFilters(this.props.type)

        this.articleTagCollection = CollectionHandler.Get('articleTagCollection')

        this.selectedArticleTagValue = this.props.selectedArticleTagObject ? this.props.selectedArticleTagObject.value : this.props.selectedArticleTag
        this.closeShowTag = this.closeShowTag.bind(this)
        this.setFilters = this.setFilters.bind(this)
        this.filterItems = this.filterItems.bind(this)
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
    }

    componentDidMount () {
        this.sendGTMEvent()
    }

    sendGTMEvent () {
        AnalyticsHelper.sendGTMEvent(
            'Article List Viewed',
            AnalyticsHelper.constructIndexListViewedAnalyticsAttributes(this.props.user)
        )
    }

    closeShowTag (tag) {
        this.searchBarRef.current.selectOnly(tag)
    }

    setFilters (filters) {
        this.setState({ filters: filters })
    }

    /*
        Axios request to filter items based on this.filters
    */
    async filterItems (tmpFilters) {
        const loadingMore = !tmpFilters
        const filters = tmpFilters || this.state.filters
        const tmpPosition = window.pageYOffset

        // *** Page url param loading ***
        //page var defined by state and the 'loadMore' button
        const currentPage = loadingMore && !this.state.loadPreviousPage
            ? this.state.currentPage + 1
            : this.state.loadPreviousPage
                ? this.state.currentPage
                : 1

        this.setState({
            filters: filters,
            currentPage: currentPage,
            loading: !loadingMore,
            loadingMore: loadingMore,
            filteringStatus: true
        }, () => {
            axios.get('/api/v1/articles/filter', {
                params: {
                    categories: filters.tagList,
                    filter_text: filters.title,
                    page: currentPage,
                    // load_previous_pages: this.state.loadPreviousPage,
                    locale: this.lang
                }
            }).then((response) => {
                // #region *** URL PARAMS SYSTEM ***
                if (this.state.loadPreviousPage) {
                    this.setState({
                        currentPage: currentPage,
                        // loadPreviousPage: false
                    })
                }

                URLParamsHandler.createURLFilters(this.props.type, filters, currentPage)
                // #endregion *** END ***

                let loadMoreStatus = true
                if (response.data.res.length < 18) {
                    loadMoreStatus = false
                }
                if (response.data.search_count === response.data.res.length) {
                    loadMoreStatus = false
                }
                let items = loadingMore ? this.state.items : []
                items = items.concat(response.data.res)
                if (response.data.search_count === items.length) {
                    loadMoreStatus = false
                }
                this.setState({
                    loadMoreStatus: loadMoreStatus,
                    items: items,
                    offerCount: response.data.search_count,
                    filteringStatus: false,
                    loading: false,
                    textFiltered: response.config.params.filter_text
                }, () => {
                    window.prerenderReady = true
                })
            })
        })
    }

    /*
  This method send an axios request to the server with the filters so we can
  get back the filtered items.
  */

    createSearchBar () {
        return (
            <Searchbar
                key='searchbar'
                type={this.props.type}
                searchParams={this.props.searchParams}
                handleFilterChange={this.filterItems}
                setFilters={this.setFilters}
                tags={this.articleTagCollection}
                shouldFetchArticles={this.props.shouldFetchArticles}
                selectedArticleTag={this.props.selectedArticleTag}
                selectedArticleTagValue={this.selectedArticleTagValue}
                filteringStatus={this.state.filteringStatus}
                textFiltered={this.state.textFiltered}
                renderingTags={this.props.renderingTags}
                hideTag={this.props.renderTags}
                pageTitle={this.props.pageTitle}
                page={this.props.page}
                urlFilters={this.urlFilters}
            />
        )
    }

    listAdForArticleTag () {
        switch (this.props.selectedArticleTag) {
        case 'first_job':
            return <AdBanner ads={this.props.ads} place='Blog_Index_Firstjobs_Leaderboard_1' device='desktop' width={728} />
        case 'internship':
            return <AdBanner ads={this.props.ads} place='Blog_Index_Internships_Leaderboard_1' device='desktop' width={728} />
        case 'student_job':
            return <AdBanner ads={this.props.ads} place='Blog_Index_Studentjobs_Leaderboard_1' device='desktop' width={728}/>
        case 'kot':
            return <AdBanner ads={this.props.ads} place='Blog_Index_Kots_Leaderboard_1' device='desktop' width={728} />
        default:
            return (
                <div>
                    <AdBanner ads={this.props.ads} place='Blog_Index_Mobile_1' device='mobile' width={320} />
                    <AdBanner ads={this.props.ads} place='Blog_Index_Leaderboard_1' device='desktop' width={728} />
                </div>
            )
        }
    }

    topAds () {
        switch (this.props.type) {
        case 'article':
            return this.listAdForArticleTag()
        default:
            break
        }
    }

    render () {
        return (
            <div className="item-index-page-container">
                <div className="item-index-main-container">
                    <div className="item-index-main" ref={this.state.indexRef}>
                        <Container maxWidth="lg">
                            {Array.isArray(this.props.ads) &&
                                this.props.ads.length > 0 &&
                                <Box mt={2}>{this.topAds()}</Box>
                            }
                            {this.createSearchBar()}
                            <ItemIndex
                                {...{ ...this.props }}
                                displayShow={this.toggleShow}
                                type={this.props.type}
                                items={this.state.items}
                                cardSlider={this.props.cardSlider}
                                cardRef={this.state.cardRef}
                                listRef={this.state.listRef}
                                mapRef={this.mapRef}
                                userAddress={this.props.userAddress}
                                urls={this.props.urls}
                                currentPage={this.state.currentPage}
                                offerCount={this.state.offerCount}
                                filterItems={this.filterItems}
                                loadMoreStatus={this.state.loadMoreStatus}
                                ads={this.props.ads}
                                selectedArticleTagValue={this.props.selectedArticleTagValue}
                                loading={this.state.loading}
                            />
                        </Container>
                    </div>
                </div>
            </div>
        )
    }
}
