import React from 'react'

import { useStyles } from './CardsSliderArrows_style'
import { Box, IconButton, Hidden } from '@material-ui/core'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

const CardsSliderArrows = (props) => {
    const classes = useStyles()
    const { sliderRef, shift = 500 } = props

    const slide = (slide) => {
        sliderRef.current.scrollLeft += slide
    }

    return (
		<Hidden mdDown>
            <Box className={classes.buttonsContainer}>
                <IconButton
                    onClick={() => slide(-shift)}
                    className={classes.button}
                >
                    <IconFactory className={classes.icon} icon='left-regular' />
                </IconButton>
                <IconButton
                    onClick={() => slide(shift)}
                    className={`${classes.button} ${classes.buttonActive}`}
                >
                    <IconFactory className={`${classes.icon} ${classes.iconActive}`} icon='right-regular' />
                </IconButton>
            </Box>
        </Hidden>
    )
}

export default CardsSliderArrows
