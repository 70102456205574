/* eslint-disable react/prop-types */

import React from 'react'
import RouteMap from 'assets/RouteMap.js'

import CompanyProfileBanner from '../CompanyProfileBanner/CompanyProfileBanner'
import CompanyPageImages from '../CompanyPageImages/CompanyPageImages'
import CompanyPageDescription from '../CompanyPageDescription/CompanyPageDescription'
import CompanyProfileHelmet from 'helmets/CompanyProfileHelmet'
import EmployerBanner from '../EmployerBanner/EmployerBanner'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import SharedTabs from 'components/SharedTabs/SharedTabs'
import css from './company_profile.scss'
import css2 from './company_profile_responsive.scss'
import axios from 'axios'
import { FormattedMessage } from 'react-intl'
import CollectionHandler from 'assets/CollectionHandler.js'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import JobCardsSlider from 'shared/components/CardsSlider/JobCardsSlider/JobCardsSlider'
import ArticleCardsSlider from 'shared/components/CardsSlider/ArticleCardsSlider/ArticleCardsSlider'
import VerticalVideos from 'components/VerticalVideos/VerticalVideos'

export default class CompanyProfile extends React.Component {
    constructor (props) {
        super(props)
        this.employerEdit = props.edit === true && this.props.user && props.user.type == 'employer' && (props.user.company == props.company.name)
        this.currentPopupRef = React.createRef()
        this.state = {
            videoUrls: [],
            color: props.company.color || 'var(--red)',
            selectedUrl: props.company.selected_video_url || '',
            description: props.company['description_' + RouteMap.GetLocaleFromUrl()] || props.company.description,
            street: props.company.address && (props.company.address.street || ''),
            zip: props.company.address && (props.company.address.zip || ''),
            city: props.company.address && (props.company.address.city || ''),
            sectors: props.company.sectors || '',
            name: props.company.name || '',
            mainImageCloudinaryKey: props.company.main_image_cloudinary_key || '',
            logoCloudinaryKey: props.company.logo_cloudinary_key || '',
            mainShowVideo: props.company.main_show_video || '',
            mainVideoUrl: props.company.main_video_url || '',
            highlightedArticle: props.highlightedArticle,
            highlightedOffer: props.highlightedOffer,
            showPopupVideoEdit: false,
            showPopupShare: false,
            showPopupCompanyInfosEdit: false,
            showPopupPicturesEdit: false,
            showPopupDescriptionEdit: false,
            showPopupTestimoniesEdit: false
        }

        this.formParams = {
            averageAges: CollectionHandler.Get('averageAgeCollection'),
            size: CollectionHandler.Get('companySizeCollection'),
            nbrEmployees: CollectionHandler.Get('nbrEmployeeCollection'),
            nbrEmployeesBelow30: CollectionHandler.Get('nbrEmployeesBelow30Collection'),
            nbrYoungGraduatedPerYear: CollectionHandler.Get('nbrYoungGraduatedPerYearCollection'),
            sectorCollection: CollectionHandler.Get('sectorCollection')
        }
    }

    componentDidMount () {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = ReactOnRails.authenticityToken()
        window.prerenderReady = true

        this.sendGTMEvent()
    }

    sendGTMEvent () {
        AnalyticsHelper.sendGTMEvent(
            'Company Details Viewed',
            AnalyticsHelper.constuctCompanyDetailsViewedAnalyticsAttributes(this.props.user, this.props.company)
        )
    }

    render () {
        let redirectionButtonArticles = null
        if (this.employerEdit && this.props.user && this.props.user.create_articles) {
            redirectionButtonArticles = {
                name: <FormattedMessage id="company_profile.publish_new_article" />,
                onClick: () => window.location.href = this.props.newArticleUrl,
                color: this.state.color,
                href: RouteMap.Page('users/articles/new?redirect=company-profile')
            }
        }
        let redirectionButtonOffers = null
        if (this.employerEdit) {
            redirectionButtonOffers = {
                name: <FormattedMessage id="company_profile.publish_new_job" />,
                onClick: () => window.location.href = RouteMap.Page('employer/new-ad'),
                color: this.state.color,
                href: RouteMap.Page('employer/new-ad')
            }
        }

        return (
            <div className="company-main" style={{ '--main-color': this.state.color }}>
                <PageWrapper user={this.props.user} page={this.props.page}>
                    <CompanyProfileHelmet company={this.props.company} logoCloudinaryKey={this.state.logoCloudinaryKey} />
                    {this.employerEdit
                        ? <SharedTabs user={this.props.user} currentTab='users/company/edit' />
                        : null
                    }
                    {this.employerEdit &&
                        <CompanyProfileBanner
                            user={this.props.user}
                            companyData={this.props.company}
                            companyName={this.state.name}
                            companyLogoCloudinaryKey={this.state.logoCloudinaryKey}
                        />
                    }
                    <Box itemScope={true} itemType="https://schema.org/Organization">
                        <EmployerBanner
                            companyBanner={this.props.company.banner_image_cloudinary_key}
                            showPopupCompanyInfosEdit={this.state.showPopupCompanyInfosEdit}
                            showPopupShare={this.state.showPopupShare}
                            popupRef={this.currentPopupRef}
                            company_id={this.props.company.id}
                            companyData={this.props.company}
                            edit={this.employerEdit}
                            companyName={this.state.name}
                            companyLogoCloudinaryKey={this.props.company.logo_cloudinary_key}
                            formParams={this.formParams}
                            user={this.props.user}
                        />
                        <CompanyPageDescription
                            showPopupVideoEdit={this.state.showPopupVideoEdit}
                            showPopupDescriptionEdit={this.state.showPopupDescriptionEdit}
                            selectedUrl={this.state.selectedUrl}
                            popupRef={this.currentPopupRef}
                            company_id={this.props.company.id}
                            companyName={this.state.name}
                            companyDescription={this.state.description}
                            companyStreet={this.state.street}
                            companyZip={this.state.zip}
                            companyCity={this.state.city}
                            companyData={this.props.company}
                            mainImageCloudinaryKey={this.state.mainImageCloudinaryKey}
                            mainVideoUrl={this.state.mainVideoUrl}
                            mainShowVideo={this.state.mainShowVideo}
                            edit={this.employerEdit}
                            formParams={this.formParams}
                            highlightedArticle={this.props.highlightedArticle}
                            highlightedOffer={this.props.highlightedOffer}
                        />
                    </Box>
                    <Container maxWidth="lg" className="job-images-container">
                        <VerticalVideos type='company' companyId={this.props.company.id}
                        />
                        {((this.props.offers && this.props.offers.length > 0) || this.employerEdit) &&
                            <JobCardsSlider
                                title={<FormattedMessage id="company_profile.job_preview_card_title" values={{ company_name: this.state.name }}/>}
                                subTitle={<FormattedMessage id="company_profile.job_preview_card_button_sub_title" />}
                                edit={this.employerEdit}
                                cards={this.props.offers}
                                frenchCards={this.props.frenchJobs}
                                dutchCards={this.props.dutchJobs}
                                redirectionButton={redirectionButtonOffers}
                                subTitleRedirection={this.props.jobsofferCompanyUrl}
                                userType={this.props.user && this.props.user.type}
                            />
                        }
                        {((this.props.offers && this.props.offers.length == 0) && this.employerEdit) &&
                            <div className="empty-box-container">
                                <RouteMapLink className="box-shadow-card-empty" redirectionUrl={redirectionButtonOffers.href}>
                                    <IconFactory
                                        className="icon"
                                        icon='edit'
                                    />
                                </RouteMapLink>
                            </div>
                        }
                        {/* TODO: include in following PR - SEO Buttons */}
                        {/* <Box>
                            <Box className="cta-jobs-banner">
                                <RouteMapLink
                                    target='_blank'
                                    page='student-jobs'
                                >
                                    <FilledButton className="cta-button" name={<FormattedMessage id="cta_block.see_all_jobs" values={{ type_name: <FormattedMessage id='cta_block.student_job' /> }} />} />
                                </RouteMapLink>
                                <RouteMapLink
                                    target='_blank'
                                    page='internships'
                                >
                                    <FilledButton className="cta-button" name={<FormattedMessage id="cta_block.see_all_jobs" values={{ type_name: <FormattedMessage id='cta_block.internship' /> }} />} />
                                </RouteMapLink>
                                <RouteMapLink
                                    target='_blank'
                                    page='first-jobs'
                                >
                                    <FilledButton className="cta-button" name={<FormattedMessage id="cta_block.see_all_jobs" values={{ type_name: <FormattedMessage id='cta_block.first_job' /> }} />} />
                                </RouteMapLink>
                            </Box>
                        </Box> */}
                        {((this.props.articles && this.props.articles.length > 0 && this.props.company.show_articles && !this.employerEdit) || (this.props.user && this.props.user.create_articles && this.employerEdit)) &&
                            <ArticleCardsSlider
                                title={<FormattedMessage id="company_profile.article_preview_card_title" />}
                                subTitle={<FormattedMessage id="company_profile.article_preview_card_button_sub_title" />}
                                edit={this.employerEdit}
                                cards={this.props.articles}
                                redirectionButton={redirectionButtonArticles}
                                subTitleRedirection={RouteMap.Page('users/articles')}
                            />
                        }
                        {this.props.articles && this.props.articles.length == 0 && this.props.user && this.props.user.create_articles && this.employerEdit &&
                            <div className="empty-box-container">
                                <RouteMapLink className="box-shadow-card-empty" redirectionUrl={redirectionButtonArticles.href}>
                                    <IconFactory className="icon" icon='edit' />
                                </RouteMapLink>
                            </div>
                        }
                        {((this.props.company.gallery_images && this.props.company.gallery_images.length > 0) || this.employerEdit) &&
                            <Box mb={7}>
                                <CompanyPageImages images={this.props.company.gallery_images} />
                            </Box>
                        }
                    </Container>
                </PageWrapper>
            </div>
        )
    }
}
