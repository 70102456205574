import React, { useState, useEffect } from 'react'
import DashboardAPI from 'api/DashboardAPI'
import JobCard from 'cards/JobCard/JobCard'
import RecommendedItemsContainer from 'components/RecommendedItemsContainer/RecommendedItemsContainer'

const LastChanceJobs = (props) => {
    const { statusConfig, user } = props
    const [lastChanceJobs, setLastChanceJobs] = useState([])

    useEffect(() => {
        getLastChanceJobs()
    }, [user, statusConfig])

    const getJobType = () => {
        if (statusConfig.actively_looking_first_job) return 'first_job'
        if (statusConfig.actively_looking_internship) return 'internship'
        if (statusConfig.actively_looking_student_job) return 'student_job'

        return 'first_job'
    }

    const getLastChanceJobs = () => {
        const data = {
            type: getJobType(),
            component_type: 'last_chance_jobs',
            amount: 3
        }

        DashboardAPI.GetLastChanceJobs(data).then((response) => {
            setLastChanceJobs(response.data.last_chance_jobs)
        })
    }

    if (lastChanceJobs?.length === 0) return null

    return (
        <RecommendedItemsContainer
            user={user}
            type='last_chance_jobs'
            titleId='personal_dashboard.last_chance_jobs.title'
            subTitleId='personal_dashboard.last_chance_jobs.sub_title'
            recommendedItemType='job'
        >
            {lastChanceJobs.map((lastChanceJob) => {
                return (
                    <JobCard
                        key={`job-card-recently-viewed-${lastChanceJob.id}`}
                        job={lastChanceJob}
                        userType={user.type}
                    />
                )
            })}
        </RecommendedItemsContainer>
    )
}
export default LastChanceJobs
