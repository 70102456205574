import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    CardBoxShadow: {
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 7px 0px rgb(0 0 0 / 12%)'
    },
    noCardShadow: {
        [theme.breakpoints.down('md')]: {
            boxShadow: 'none !important'
        },
        [theme.breakpoints.up('md')]: {
            boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 7px 0px rgb(0 0 0 / 12%)'
        }
    },
    condensedShadow: {
        boxShadow: 'none !important'
    },
    condensedPadding: {
        paddingLeft: '0 !important'
    },
    noPaperPadding: {
        [theme.breakpoints.down('md')]: {
            padding: '0 !important'
        }
    },
    gridFlex: {
        alignSelf: 'center'
    },
    gridElem: {
        marginBottom: '12px !important'
    },
    boxContainer: {
        [theme.breakpoints.down('md')]: {
            padding: '24px 10px 0 10px'
        },
        [theme.breakpoints.up('md')]: {
            padding: '24px',
            border: '1px solid var(--light-grey)'
        }
    },
    buttonsContainer: {
        [theme.breakpoints.down('md')]: {
            borderBottom: '1px dashed var(--light-grey)'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 16
        }
    },
    formControl: {
        width: '100%',
        margin: 0,
        '& .MuiInputBase-root': {
            cursor: 'pointer'
        }
    },
    studentType: {
        color: 'var(--red)'
    },
    datePicker: {
        color: 'white',
        textColor: 'white',
        calendarTextColor: 'white',
        selectColor: 'white',
        selectTextColor: 'white',
        calendarYearBackgroundColor: 'white',
        headerColor: 'white'
    },
    buttonOutlined: {
        borderColor: 'var(--dark-grey)'

    },
    textFont: {
        '&::placeholder': {
            color: 'black !important'
        }
    },
    textFontBlack: {
        backgroundColor: 'white',
        padding: '10px 12px !important',
        borderRadius: 8,
        '& .MuiInputBase-input': {
            padding: 0,
            '&::placeholder': {
                color: 'var(--dark-beige) !important',
                opacity: '1 !important'
            }
        },
        '&::placeholder': {
            color: 'var(--dark-beige) !important',
            opacity: '1 !important'
        }
    },
    textFieldError: {
        boxShadow: 'inset 0px 0px 0px 2px var(--red) !important',
        '& .MuiInputBase-input::placeholder': {
            color: 'var(--red) !important'
        },
        '& span': {
            color: 'var(--red) !important'
        },
        '&::placeholder': {
            color: 'var(--red) !important',
            opacity: '1 !important'
        }
    },
    placeholderText: {
        color: 'var(--dark-beige)'
    },
    date: {
        width: '100%',
        fontSize: '0.875rem'
    },
    dateBlack: {
        backgroundColor: 'white',
        width: '100%',
        fontSize: '0.875rem',
        borderRadius: 8,
        '& .MuiInputBase-input': {
            padding: '10px 12px'
        }
    },
    adornmentContainer: {
        marginRight: 10
    },
    autocomplete: {
        margin: 0
    },
    displayNone: {
        display: 'none'
    },
    highSchoolYearInputBoxBlack: {
        maxWidth: 60,
        width: '100%'
    },
    highSchoolYearInputBoxContainerBlack: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}))
