import React from 'react'
import PropTypes from 'prop-types'
import RouteMap from 'assets/RouteMap'
import Link from '@material-ui/core/Link'
import URLHandler from 'assets/URLHandler.js'

const CreateUrl = (props) => {
    let url = ''
    if (props.showItemType && props.showItemUrl) {
        url = RouteMap.Show(props.showItemType, props.showItemUrl)
    } else if (props.itemType && props.editItemId) {
        url = RouteMap.Edit(props.itemType, props.editItemId)
    } else if (props.redirectionUrl) {
        url = props.redirectionUrl
    } else if (props.location && props.itemType) {
        if(props.location === 'city'){
            url = RouteMap.Page(props.itemType)
        } else{
            url = RouteMap.Tag(props.itemType, 'cityCollection', props.location)
        }
    } else if (props.domain && props.itemType) {
        url = RouteMap.Tag(props.itemType, 'seoStudyDomainCollection', props.domain)
    } else if (props.articleCategory) {
        url = RouteMap.ArticleCategory(props.articleCategory)
    } else {
        url = RouteMap.Page(props.page)
    }
    if (props.paramString) {
        url = url + props.paramString
    }
    return url
}

const RouteMapLink = ({ children, ...props }) => {
    const url = CreateUrl(props)
    return (
        <Link
            className={props.className}
            target={props.target}
            color='initial'
            rel={props.target ? 'noopener noreferrer' : undefined}
            href={url}
            hreflang={`${props.hreflang ? props.hreflang : URLHandler.GetLocaleFromUrl()}-BE`}
            onClick={(e) => {
                e.preventDefault()
                props.target ? window.open(url) : window.location.href = url
            } }
            style={{ textDecoration: 'none', cursor: 'pointer', color: props.color ? props.color : 'var(--black)', ...props.style } }
        >
            { children }
        </Link>
    )
}

RouteMapLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
    ]),
    page: PropTypes.string,
    redirectionUrl: PropTypes.string,
    showItemType: PropTypes.string,
    showItemUrl: PropTypes.string,
    editItemId: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string,
    location: PropTypes.string,
    articleCategory: PropTypes.string,
    itemType: PropTypes.string,
    hreflang: PropTypes.string
}

export default RouteMapLink
