import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    actionButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        marginTop: 30,
        marginBottom: 30
    },
    actionButton: {
        border: '1px solid #E9EAF0',
        borderColor: '#E9EAF0',
        height: 43
    },
    favoriteActionButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    shareActionButton: {
        [theme.breakpoints.down('xs')]: {
            width: 150
        }
    }
}))
