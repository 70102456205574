import React, { useState, useEffect, useRef } from 'react'

import { useStyles } from './JobCardsSlider_style'
import { Box, Hidden } from '@material-ui/core'

import RouteMap from 'assets/RouteMap'
import LanguageFilter from '../../PreviewCard/LanguageFilter/LanguageFilter'
import JobCard from '../../../cards/JobCard/JobCard'
import CardsSliderTitle from '../components/CardsSliderTitle/CardsSliderTitle'
import CardsSliderArrows from '../components/CardsSliderArrows/CardsSliderArrows'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'

const JobCardsSlider = (props) => {
    const classes = useStyles()
    const { title, subTitle, redirectionButton, subTitleRedirection, userType, edit, cards, frenchCards, dutchCards } = props
    const sliderRef = useRef()
    const currentLanguage = RouteMap.GetLocaleFromUrl()
    const [nbJobFR, setNbJobFR] = useState(0)
    const [nbJobNL, setNbJobNL] = useState(0)
    const [filteredCards, setFilteredCards] = useState(cards)
    const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage)

    useEffect(() => {
        const countFR = frenchCards.filter(card => card.fr).length
        const countNL = dutchCards.filter(card => card.nl).length

        setNbJobFR(countFR)
        setNbJobNL(countNL)
        if ((currentLanguage === 'fr' && countFR === 0) || (currentLanguage === 'nl' && countNL === 0)) {
            setSelectedLanguage(null)
        } else {
            filterCards()
        }
    }, [])

    useEffect(() => {
        filterCards()
    }
    , [selectedLanguage])

    const handleLanguageSelect = (language) => {
        setSelectedLanguage((prevLanguage) => (prevLanguage === language ? null : language))
    }

    const filterCards = () => {
        if (selectedLanguage === 'fr') {
            setFilteredCards(frenchCards)
        } else if (selectedLanguage === 'nl') {
            setFilteredCards(dutchCards)
        } else {
            setFilteredCards(cards)
        }
    }

    return (
        <Box className={classes.sliderContainer}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <CardsSliderTitle title={title} />
                    <Box className={classes.actionButtons}>
                        <Box className={classes.languagesContainer}>
                            <LanguageFilter
                                language="all"
                                selectedLanguage={selectedLanguage}
                                nbJob={cards.length}
                                isSelected={!selectedLanguage || selectedLanguage === 'en' || selectedLanguage === 'all'}
                                onSelect={handleLanguageSelect}
                            />
                            <LanguageFilter
                                language={'fr'}
                                selectedLanguage={selectedLanguage}
                                nbJob={nbJobFR}
                                isSelected={selectedLanguage === 'fr'}
                                onSelect={handleLanguageSelect}
                            />
                            <LanguageFilter
                                language={'nl'}
                                selectedLanguage={selectedLanguage}
                                nbJob={nbJobNL}
                                isSelected={selectedLanguage === 'nl'}
                                onSelect={handleLanguageSelect}
                            />
                        </Box>
                        {redirectionButton && edit &&
                            <Box style={{ '--main-color': redirectionButton.color }} >
                                <RouteMapLink redirectionUrl={redirectionButton.href}>
                                    <FilledButton
                                        newStyle
                                        name={redirectionButton.name}
                                        color='primary'
                                        style={{ width: 200 }}
                                    />
                                </RouteMapLink>
                            </Box>
                        }
                    </Box>
                </Box>
                <Hidden smDown>
                    <Box className={classes.arrowContainer}>
                        <CardsSliderArrows sliderRef={sliderRef} />
                        
                            {edit &&
                                <ArrowButton
                                    redirectionUrl={subTitleRedirection}
                                    name={subTitle}
                                />
                            }
                    </Box>
                </Hidden>
            </Box>
            <Box className={classes.cardsContainer} ref={sliderRef}>
                {filteredCards.map(job =>
                    <JobCard
                        job={job}
                        id={'jobcard_' + job.id}
                        key={'jobcard_' + job.id}
                        userType={userType}
                    />
                )}
            </Box>
            <Hidden mdUp>
                {props.edit &&
                    <ArrowButton
                        redirectionUrl={props.subTitleRedirection}
                        onClick={props.closeJob}
                        name={props.subTitle}
                    />
                }
            </Hidden>
        </Box>
            
    )
}

export default JobCardsSlider
