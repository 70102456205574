import React, { useRef } from 'react'

import { Box, Hidden } from '@material-ui/core'

import { useStyles } from './ArticleCardsSlider_style'
import ArticleCardNew from 'cards/ArticleCardNew/ArticleCardNew'
import CardsSliderTitle from '../components/CardsSliderTitle/CardsSliderTitle'
import CardsSliderArrows from '../components/CardsSliderArrows/CardsSliderArrows'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import FilledButton from 'buttons/FilledButton/FilledButton'

const ArticleCardsSlider = (props) => {
    const classes = useStyles()
    const { title, subTitle, redirectionButton, subTitleRedirection, edit, cards } = props
    const sliderRef = useRef()


    return (
        <Box className={classes.sliderContainer}>
            <Box className={classes.topContainer}>
                <Box className={classes.titleContainer}>
                    <CardsSliderTitle title={title} />
                    {redirectionButton && edit &&
                        <Box style={{ '--main-color': redirectionButton.color }} >
                            <RouteMapLink redirectionUrl={redirectionButton.href}>
                                <FilledButton
                                    newStyle
                                    name={redirectionButton.name}
                                    color='primary'
                                    style={{ width: 200 }}
                                />
                            </RouteMapLink>
                        </Box>
                    }
                </Box>
                <Hidden smDown>
                    <Box className={classes.arrowContainer}>
                        <CardsSliderArrows sliderRef={sliderRef} />
                            {edit &&
                                <ArrowButton
                                    redirectionUrl={subTitleRedirection}
                                    name={subTitle}
                                />
                            }
                    </Box>
                </Hidden>
            </Box>
            <Box className={classes.cardsContainer} ref={sliderRef}>
                {cards.map(article =>
                    <ArticleCardNew
                        article={article}
                        id={'articlearticle_' + article.id}
                        key={'articlearticle_' + article.id}
                    />
                )}
            </Box>
            <Hidden mdUp>
                {props.edit &&
                    <ArrowButton
                        redirectionUrl={props.subTitleRedirection}
                        onClick={props.closeJob}
                        name={props.subTitle}
                    />
                }
            </Hidden>
        </Box>
            
    )
}

export default ArticleCardsSlider
