/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import SimilarItemsView from 'components/SimilarItemsView/SimilarItemsView'

const CompanyJobs = (props) => {
    const { job, user } = props
    if (job.company_jobs.length === 0) return null

    return (
        <SimilarItemsView
            type='company'
            items={job.company_jobs}
            userType={user && user.type}
            title=<FormattedMessage id='transl_internship.job_show.preview_card_1_title' values={{ company_name: job.company_name }} />
            moreItemsText=<FormattedMessage id='job_show_similar_items.see_more_from_same_employer' values={{ company_name: job.company_name }} />
            baseItem={{ name: job.company_name, url: job.company.url }}
        />
    )
}

export default CompanyJobs
