export const useStyles = theme => ({
    candidacyCardContainer: {
        display: 'flex',
        width: '100% !important',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            height: '100px',
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '290px',
            flexDirection: 'column'
        }
    },
    candidacyCardInfo: {
        display: 'flex',
        width: '80%',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '7px'
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: '10px'
        },
        '& > *': {
            maxHeight: '100%',
            fontSize: '1rem'
        }
    },
    candidacyCardColumn: {
        height: '100%',
        padding: '0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    candidacyType: {
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            color: '#ffffff',
            width: '35px',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '12px'
        }
    },
    candidacyCompanyName: {
        [theme.breakpoints.down('xs')]: {
            '&:before': {
                content: '"@ "'
            }
        }
    },
    candidacyJobStatus: {
        textAlign: 'center',
        color: 'var(--red)'
    },
    actionIconContainer: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            cursor: 'pointer',
            color: 'var(--grey)',
            fontSize: '1em'
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '1.5%'
        }
    },
    routeMapLinkStyle: {
        marginRight: '8px'
    },
    iconButtonStyle: {
        with: 45,
        height: 45
    },
    candidacyDate: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end'
        }
    },
    jobColor: {
        color: 'var(--main-color) !important',
        fontSize: 16
    },
    jobBackgroundColor: {
        background: 'var(--main-color) !important'
    },
    jobTypeFormat: {
        textTransform: 'uppercase',
        [theme.breakpoints.up('md')]: {
            transform: 'rotate(-90deg)',
            textAlign: 'center',
            wordBreak: 'normal'
        }
    },
    jobInfo: {
        [theme.breakpoints.up('md')]: {
            padding: '0 2rem'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0.5rem'
        }
    }
})
