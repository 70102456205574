import React from 'react'
import { Box } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobCardTags_style'
import IconFactory from 'icons/IconFactory/IconFactory'
import useJobIcon from 'hooks/useJobIcon'

const JobCardTags = (props) => {
    const { job, showJobType } = props
    const classes = useStyles()

    const renderJobStatusTag = () => {
        if (job.urgent) {
            return (
                <Box className={`
                    ${classes.baseTag}
                    ${classes.baseTagTopLeft}
                    ${classes.newTag}
                `}>
                    ⚡ URGENT
                </Box>
            )
        } else if (!job.active || job.is_new) {
            return (
                <Box className={`
                    ${classes.baseTag}
                    ${classes.baseTagTopLeft}
                    ${job.is_new ? classes.newTag : ''}
                    ${!job.active ? classes.notActiveTag : ''}
                `}>
                    {!job.active
                        ? <FormattedMessage id="transl_internship.completed" />
                        : <FormattedMessage id="job_card.tags.new" />
                    }
                </Box>
            )
        }
    }

    return (
		<>
            {renderJobStatusTag()}
            {showJobType &&
                <Box className={`${classes.baseTag} ${classes.baseTagTopRight} ${classes.jobTypeTag}`}>
                    <IconFactory icon={useJobIcon(job.type)} fontSize='12px' style={{marginRight: 6}} />
                    <span className={classes.baseTageText}><FormattedMessage id={`type.${job.type}.singular`} /></span>
                </Box>
            }
        </>
    )
}

export default JobCardTags
