import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '24px',
        }
    },
    buttons: {
        '& a:first-child': {
            [theme.breakpoints.up('xs')]: {
                marginRight: '24px',
            }
        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'space-around',
        }
    },
    warning: {
        marginBottom: '12px',
        color: 'var(--red)'
    }
}))
