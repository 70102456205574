import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    kotShowBanner: {
        backgroundColor: 'white',
        zIndex: 9,
        position: 'sticky',
        top: 0,
        marginBottom: 16
    },
    bannerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 16,
        paddingTop: 32,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
            flexDirection: 'column'
        }
    },
    bannerContainerSticky: {
        marginBottom: 12,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 16,
            paddingTop: 16
        }
    },
    infoBannerContainer: {
        display: 'flex'
    },
    kotInfoContainer: {
        marginLeft: 20,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0
        }
    },
    kotDate: {
        color: 'var(--grey)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    },
    titleBoxSticky: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 12,
            marginBottom: 12
        },
        gap: 12
    },
    kotTitleBox: {
        fontFamily: 'GintoNord-Bold',
        fontSize: 20,
        lineHeight: '26.18px',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            marginBottom: 12,
            marginRight: 12,
            fontSize: 14,
            lineHeight: '15.71px'
        }
    },
    kotTitleBoxSticky: {
        fontSize: 12,
        lineHeight: '18px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 0
        }

    },
    kotLocalisationContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 12
        }
    },
    kotLocalisationContainerSticky: {
        marginBottom: 0
    },
    iconContainer: {
        marginLeft: 20,
        marginRight: 20
    },
    mapIcon: {
        marginRight: '18px',
        fontSize: '22px',
        color: 'var(--main-color)',
        [theme.breakpoints.down('sm')]: {
            marginRight: 8
        }
    },
    mapIconSticky: {
        marginRight: 8
    },
    bold: {
        fontFamily: theme.typography.h1.fontFamily
    },
    address: {
        textTransform: 'capitalize',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important'
        }
    },
    addressSticky: {
        fontSize: '12px !important'
    },
    applyBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 14
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 12
        }
    },
    applyBoxSticky: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'center'
        }
    },
    kotPriceContainer: {
        marginTop: 0,
        minWidth: 150,
        [theme.breakpoints.down('sm')]: {
            minWidth: 120
        }
    },
    kotPrice: {
        fontFamily: 'GintoNord-Bold',
        fontSize: 20,
        lineHeight: '26.18px',
        color: 'var(--red)',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    kotPriceSticky: {
        fontSize: 14,
        lineHeight: '18px',
        color: 'var(--red)'
    },
    kotTitleAndPriceContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }
    },
    textLight: {
        color: 'var(--grey)',
        maxWidth: 280,
        marginLeft: 12,
        marginTop: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px !important',
            maxWidth: '100%',
            marginLeft: 0
        }
    }
}))
