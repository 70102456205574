/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

const languageRegex = new RegExp(/^\/([^\/]*)/)
const telephoneNumberRegex = new RegExp(/\d[\d\ \/\.\(\)\-]{7,20}\d/)
const emailPresentInTextRegex = new RegExp(/(?:[a-z0-9+!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)
const applicationEmailRegex = new RegExp(/^([\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+)$/i)
const urlRegex = new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)
const youTubeRegex = new RegExp(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/)
const dailyMotionRegex = new RegExp(/(?:https?:\/\/)?(?:www\.)?(?:dailymotion\.com\/video|dai\.ly)\/([a-zA-Z0-9]+)/g)
const vimeoRegex = new RegExp(/^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)\/?([\-_0-9a-zA-Z]+)?/)
const styleTagRegex = new RegExp(/style="[^"]*"/g)
const emptyLineRegex = new RegExp(/<p><br><\/p>/g)
const removeHtmlRegex = new RegExp(/<\/?[^>]+(>|$)/g)
const sanitizeForApplicationJsonRegex = new RegExp(/"/g)
// Match the News Indexes only (News Index, Category Index)
const indexNewsURLRegex = new RegExp(/\/student-life(\/categories\/[^/]+\/?|\/?)$/)

export default {
    applicationEmailRegex,
    dailyMotionRegex,
    emailPresentInTextRegex,
    indexNewsURLRegex,
    languageRegex,
    telephoneNumberRegex,
    urlRegex,
    vimeoRegex,
    styleTagRegex,
    emptyLineRegex,
    removeHtmlRegex,
    sanitizeForApplicationJsonRegex,
    youTubeRegex
}
