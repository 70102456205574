import React from 'react'
import IconFactory from 'icons/IconFactory/IconFactory'

const OpenIcon = (props) => {
    const { open } = props

    if (open) {
        return <IconFactory icon='up-solid' fontSize='12px' color='black' />
    } else {
        return <IconFactory icon='down-solid' fontSize='12px' color='black' />
    }
}

export default OpenIcon
