import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Bugsnag from '@bugsnag/js'

import { useStyles } from './NewJobForm_style'
import { Box, Typography } from '@material-ui/core'
import IconFactory from 'shared/icons/IconFactory/IconFactory'

import AnalyticsHelper from 'assets/AnalyticsHelper'
import CollectionHandler from 'assets/CollectionHandler.js'
import FunnelContentWrapper from '../shared/wrappers/FunnelContentWrapper/FunnelContentWrapper'
import FunnelFormWrapper from '../shared/wrappers/FunnelFormWrapper/FunnelFormWrapper'
import FunnelOverviewWrapper from '../shared/wrappers/FunnelOverviewWrapper/FunnelOverviewWrapper'
import FunnelSubmitBar from '../shared/FunnelSubmitBar/FunnelSubmitBar'
import FunnelTableOfContents from '../shared/FunnelTableOfContents/FunnelTableOfContents'
import JobCreationFunnelWrapper from 'wrappers/JobCreationFunnelWrapper/JobCreationFunnelWrapper'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap.js'
import SessionAPI from 'api/SessionAPI'
import useJobIcon from 'hooks/useJobIcon'

const NewJobForm = (props) => {
    const classes = useStyles()
    const { user, selectedType, urls } = props
    const [selectedTypeOfJob, setSelectedTypeOfJob] = useState(selectedType)
    const [redirectionUrl, setRedirectionUrl] = useState(urls[selectedType])
    const [steps] = useState([{ value: 'type_of_job', isValid: false }])
    const jobTypes = CollectionHandler.Get('itemTypeCollection').filter(itemType => itemType.value !== 'kot')
    const currentStep = steps[0].value

    const handleClick = (jobType) => {
        setSelectedTypeOfJob(jobType)
        setRedirectionUrl(urls[jobType])
    }

    const handleSubmit = () => {
        if (redirectionUrl) { submitForm() }
    }

    const submitForm = () => {
        const redirectionUrl = RouteMap.Page(urls[selectedTypeOfJob])

        SessionAPI.UpdateUserStoredLocation({
            updateUserStoredLocation: urls.updateUserStoredLocation,
            redirectionUrl: redirectionUrl
        }).then(() => {
            sendAnalyticsFunnelContinue()
            window.location.href = redirectionUrl
        }).catch((error) => {
            Bugsnag.notify('Error while  updating user location url: ' + error)
        })
    }

    const sendAnalyticsFunnelContinue = () => {
        AnalyticsHelper.sendGTMEvent(
            'Clicked Job Funnel Continue',
            AnalyticsHelper.constructJobFunnelAnalyticsAttributes(
                user, 
                user.company_card_info,
                selectedTypeOfJob
        ))
    }

    return (
        <PageWrapper user={user} disableFooter>
            <JobCreationFunnelWrapper>
                <FunnelOverviewWrapper>
                    <FunnelTableOfContents steps={steps} currentStep={currentStep} />
                </FunnelOverviewWrapper>
                <FunnelContentWrapper>
                    <FunnelFormWrapper>
                        <Box className={classes.jobSelectionForm}>
                            {jobTypes.map(jobType => {
                                const isSelected = selectedTypeOfJob === jobType.value
                                return (
                                    <Box
                                        className={`
                                            ${classes.jobType}
                                            ${isSelected ? classes.selectedJobType : '' }
                                        `}
                                        onClick={() => handleClick(jobType.value)}
                                        key={jobType.value}
                                    >
                                        <IconFactory icon={useJobIcon(jobType.value)} fontSize='12px' className={classes.jobIcon} />
                                        <Typography component='span' className={classes.jobTitle}>
                                            <FormattedMessage id={`type.${jobType.value}.singular`} />
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </FunnelFormWrapper>
                    <FunnelSubmitBar handleSubmit={handleSubmit} />
                </FunnelContentWrapper>
            </JobCreationFunnelWrapper>
        </PageWrapper>
    )
}

export default NewJobForm
