import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    paper: {
        minWidth: 300,
        height: 'fit-content',
        boxShadow: 'var(--shadow-paper)',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    sectionBox: {
        padding: 16,
        [theme.breakpoints.up('md')]: {
            width: 300
        }
    },
    sectionBoxWidth: {
        [theme.breakpoints.up('md')]: {
            width: 300
        }
    }
}))
