import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    containerBox: {
        display: 'flex',
        width: '100%',
        borderRadius: 20,
        backgroundColor: 'black',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            borderRadius: 0,
            marginBottom: 60
        }
    },
    percentageBox: {
        padding: 40,
        [theme.breakpoints.down('sm')]: {
            padding: '32px 12px 20px 12px',
            display: 'flex',
            justifyContent: 'center',
            gap: 12
        }
    },
    contentBox: {
        padding: '24px 40px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px 32px 12px'
        }
    },
    percentageNumber: {
        width: 180,
        color: 'white',
        fontSize: 68,
        marginBottom: 12,
        lineHeight: 'normal',
        [theme.breakpoints.down('sm')]: {
            width: 'unset',
            fontSize: 56
        }
    },
    percentageText: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    formBox: {
        color: 'white'
    },
    slider: {
        marginBottom: 12,
        '& .MuiSlider-rail': {
            color: 'var(--light-grey)',
            height: 8,
            borderRadius: 100
        },
        '& .MuiSlider-track': {
            width: 'calc(100% + 5px)',
            height: 8,
            borderRadius: '20px 0 0 20px',
            backgroundColor: 'var(--red)'
        },
        '& .MuiSlider-mark': {
            display: 'none'
        }
    },
    sliderThumb: {
        position: 'absolute',
        minWidth: 128,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        height: '32px !important',
        paddingLeft: 12,
        paddingRight: 12,
        border: '2px solid var(--red)',
        borderRadius: 100,
        marginTop: '-12px',
        '&:focus-visible': {
            outline: 'none'
        }
    },
    sliderThumbEnd: {
        left: 'none',
        right: 0
    },
    title: {
        color: 'white',
        marginBottom: 4,
    },
    text: {
        color: 'white',
        fontSize: '14px !important',
        marginBottom: 12
    },
    startStepButtonBox: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 24
    },
    textField: {
        width: '100%',
        backgroundColor: 'white',
        color: 'black',
        fontSize: 14,
        padding: '10px 12px',
        borderRadius: 8,
        boxSizing: 'border-box'
    },
    textFieldError: {
        boxShadow: 'inset 0px 0px 0px 2px var(--red)',
        '& .MuiInputBase-input::placeholder': {
            color: 'var(--red) !important'
        }
    },
    errorText: {
        color: 'var(--red) !important'
    },
    textFieldInput: {
        padding: 0,
        '&::placeholder': {
            color: 'var(--dark-beige)',
            opacity: '1 !important'
        }
    },
    nameInputBox: {
        display: 'flex',
        gap: 12
    }
}))
