import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import FilledButton from 'buttons/FilledButton/FilledButton'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useStyles } from './GoToProfileBox_style'

const GoToProfileBox = (props) => {
    const classes = useStyles()

    return (
        <Box>
            <Typography component='h2' className={classes.title}>
                <FormattedMessage id='personal_dashboard.go_to_profile_title' />
            </Typography>
            <Typography variant='body1' className={classes.text}>
                <FormattedMessage id='personal_dashboard.go_to_profile_text_2' />
            </Typography>
            <Box className={classes.buttonBox}>
                <RouteMapLink page={'users/profile/edit'}>
                    <FilledButton
                        newStyle
                        fullWidth
                        color='secondary'
                        name={<FormattedMessage id='personal_dashboard.go_to_profile_button' />}
                    />
                </RouteMapLink>
            </Box>
        </Box>
    )
}
export default GoToProfileBox
