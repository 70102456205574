import React, { useState, useEffect, useRef } from 'react'
import Carousel from 'react-material-ui-carousel'

import { useStyles } from './NewCarouselCards_style'
import Box from '@material-ui/core/Box'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Hidden from '@material-ui/core/Hidden'

import { useWindowSize } from 'hooks/useWindowSize'
import { BREAKPOINT_SMALL, BREAKPOINT_LARGE } from 'assets/WindowHelper'
import CardFactory from 'shared/cards/CardFactory/CardFactory'

const SMALL_SCREEN_CARDS = 1
const MIN_CARDS = 2
const DEFAULT_CARDS_NUMBER = 3

const NewCarouselCards = (props) => {
    const {
        items,
        cardsNumber = DEFAULT_CARDS_NUMBER,
        autoPlay,
        cycleNavigation = true,
        allowButtons, //IF YOU WANT THE ARROW TO BE DISPLAYED IN YOUR COMPONENT, YOU ALSO HAVE TO PUT A RELATIVE POSITION TO THE PARENT CONTAINER
        hideButtonOnMobile = false,
        btnStyles,
        arrowStyles,
        type,
        displayShow,
        cardsRef,
        userType,
        userAddress
    } = props
    const classes = useStyles()
    const { windowSize } = useWindowSize()
    const sliderRef = useRef(null)
    const [numberCards, setNumberCards] = useState(cardsNumber)
    const [currentIndex, setCurrentIndex] = useState(0)
    const leftButtonDisabled = currentIndex === 0 && !cycleNavigation
    const rightButtonDisabled = (currentIndex + 1) * numberCards >= items.length && !cycleNavigation

    const handleSlideChange = (index) => {
        setCurrentIndex(index)
    }

    useEffect(
        () => {
            window.addEventListener('resize', screenSizeListener)
            screenSizeListener()
            return () => window.removeEventListener('resize', screenSizeListener)
        }, [windowSize]
    )

    const handlePrevSlide = (e) => {
        e.preventDefault()
        if (sliderRef?.current) {
            sliderRef.current.prev()
        }
    }

    const handleNextSlide = (e) => {
        e.preventDefault()
        if (sliderRef?.current) {
            sliderRef.current.next()
        }
    }

    const screenSizeListener = () => {
        const width = windowSize.width
        let cardsNumberTmp = cardsNumber
        if (width < BREAKPOINT_SMALL) {
            cardsNumberTmp = SMALL_SCREEN_CARDS
        } else if (width < BREAKPOINT_LARGE) {
            cardsNumberTmp = cardsNumber - 1 > MIN_CARDS ? cardsNumber - 1 : MIN_CARDS
        } else {
            cardsNumberTmp = cardsNumber
        }
        setNumberCards(cardsNumberTmp)
    }

    const generateCardsContainer = () => {
        return items.reduce((container, _, index) => {
            const isNewContainer = index % numberCards === 0
            if (isNewContainer) {
                const startIndex = index
                const endIndex = startIndex + numberCards
                const cards = generateCards(startIndex, endIndex)
                container.push(
                    <Box className={classes.cardContainer} key={'cardContainer-' + index}>
                        {cards}
                    </Box>
                )
            }
            return container
        }, [])
    }

    const generateCards = (startIndex, endIndex) => {
        return items.slice(startIndex, endIndex).map((item, index) => (
            <Box className={classes.card} key={startIndex + index}>
                <CardFactory
                    card={item}
                    type={type}
                    cardsRef={cardsRef ? cardsRef[startIndex + index] : null}
                    displayShow={displayShow}
                    userType={userType}
                    userAddress={userAddress}
                    showJobType
                    key={startIndex + index}
                />
            </Box>
        ))
    }

    const buttons = (
        <>
            <Box className={classes.arrowLeft}>
                <IconButton
                    onClick={handlePrevSlide}
                    className={`${btnStyles || classes.arrowBtn} ${leftButtonDisabled ? classes.disabled : ''}`}
                    disabled={leftButtonDisabled}
                >
                    <KeyboardArrowLeft
                        fontSize='medium'
                        className={`${arrowStyles || classes.arrows}`}
                    />
                </IconButton>
            </Box>
            <Box className={classes.arrowRight}>
                <IconButton
                    onClick={handleNextSlide}
                    className={`${btnStyles || classes.arrowBtn} ${rightButtonDisabled ? classes.disabled : ''}`}
                    disabled={rightButtonDisabled}
                >
                    <KeyboardArrowRight
                        fontSize='medium'
                        className={`${arrowStyles || classes.arrows}`}
                    />
                </IconButton>
            </Box>
        </>
    )

    return (
        <Box className={classes.container}>
            <Box>
                <Carousel
                    autoPlay={autoPlay}
                    interval={5000}
                    animation='slide'
                    navButtonsAlwaysInvisible
                    indicators={items.length > numberCards}
                    navButtonsAlwaysVisible={false}
                    ref={sliderRef}
                    cycleNavigation={cycleNavigation}
                    onChange={handleSlideChange}
                >
                    {generateCardsContainer()}
                </Carousel>
            </Box>
            {allowButtons && (
                hideButtonOnMobile ? (
                    <Hidden smDown>
                        {buttons}
                    </Hidden>
                ) : (
                    <Box>
                        {buttons}
                    </Box>
                )
            )}
        </Box>
    )
}

export default NewCarouselCards
