import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    walletCardButton: {
        width: '100%',
        height: 64,
        padding: '20px',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        borderRadius: '12px',
        boxShadow: 'var(--shadow-paper)',
        background: 'white',
        textTransform: 'none'
    },
    walletFilterCardButton: {
        height: 54,
        padding: '19px 16px'
    },
    cardNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px'
    },
    walletCardChip: {
        backgroundColor: 'var(--soft-green)',
        borderRadius: '6px',
        fontSize: '10px',
        height: '24px',
        '& .MuiChip-label': {
            color: 'var(--green)',
            padding: '0 10px'
        }
    },
    walletCardArrow: {
        fontSize: 16,
        color: 'grey'
    },
    secondary: {
        background: theme.palette.secondary.main,
        '& p, & svg': {
            color: 'white'
        }
    }
}))
