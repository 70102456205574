import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    letsChatContainer: {
        backgroundColor: 'black',
        borderRadius: 12,
        padding: 16,
        marginTop: 12
    },
    chatButton: {
        backgroundColor: 'white',
        fontSize: 12
    },
    title: {
        color: 'white',
        fontSize: 12
    },
    bigTitle: {
        fontFamily: 'GintoNord-Bold',
        fontSize: 20,
        color: 'white',
        marginTop: 8,
        marginBottom: 20
    }
}))
