/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useRef, useState } from 'react'

import KotPopup from './KotPopup/KotPopup'
import SharePopup from './SharePopup/SharePopup'
import ShowDocumentPopup from './ShowDocumentPopup/ShowDocumentPopup'
import DeleteOfferPopup from './DeleteOfferPopup/DeleteOfferPopup'

import CompanyPicturesManagerPopup from './CompanyPicturesManagerPopup/CompanyPicturesManagerPopup'
import ApplyPopup from './ApplyPopup/AppluPopup'
import DangerZonePopup from './DangerZonePopup/DangerZonePopup'
import CreateAlertPopup from './CreateAlertPopup/CreateAlertPopup'

import useTheme from '@material-ui/core/styles/useTheme'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'

import FilledButton from 'buttons/FilledButton/FilledButton'
import LineButton from 'buttons/LineButton/LineButton'
import IconFactory from '../../../shared/icons/IconFactory/IconFactory'

const PopupFactory = (props) => {
    const theme = useTheme()
    const popupRef = useRef()

    const [popupContent, setPopupContent] = useState({
        popupTitle: 'default',
        popupActionButton: 'default',
        popupCancelButton: 'default',
        onlyClose: false,
        doublebuttons: false,
        inversedActions: false,
        noAction: false,
        maxWidth: 'sm'
    })

    const handleClose = () => {
        props.popupExit()
    }

    const retrievePopupContent = (newPopupContent) => {
        setPopupContent({
            ...popupContent,
            ...newPopupContent
        })
    }

    const renderPopup = () => {
        switch (props.type) {
        case 'kot-offer-message':
            return <KotPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'share-offer':
            return <SharePopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'show-document':
            return <ShowDocumentPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'delete-offer':
            return <DeleteOfferPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'manage-company-pictures':
            return <CompanyPicturesManagerPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'apply-popup':
            return <ApplyPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'danger-zone-popup':
            return <DangerZonePopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        case 'create-alert-popup':
            return <CreateAlertPopup
                {...props}
                ref={popupRef}
                getPopupContent={retrievePopupContent}
            />
        default:
            return <p>Something went wrong</p>
        }
    }

    const renderButtonsActions = () => {
        if (!popupContent.noAction) {
            if (popupContent.doublebuttons) {
                return (
                    <Box>
                        <LineButton
                            name={popupContent[popupContent.inversedActions ? 'popupActionButton' : 'popupCancelButton']}
                            onClick={popupContent.inversedActions ? popupContent.actionPopup : handleClose}
                            size='small'
                        />
                        <FilledButton
                            size='small'
                            color='secondary'
                            onClick={popupContent.inversedActions ? handleClose : popupContent.actionPopup}
                            name={popupContent[popupContent.inversedActions ? 'popupCancelButton' : 'popupActionButton']}
                            style={{ marginLeft: 10 }}
                        />
                    </Box>
                )
            } else if (popupContent.onlyClose) {
                return (
                    <FilledButton
                        size='small'
                        color='secondary'
                        onClick={handleClose}
                        name={popupContent.popupCancelButton}
                    />
                )
            } else {
                return (
                    <Box style={{ paddingBottom: 20, display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <FilledButton
                            size='small'
                            color='secondary'
                            onClick={popupContent.actionPopup}
                            name={popupContent.popupActionButton}
                        />
                    </Box>

                )
            }
        }
    }

    return (
        <Dialog
            maxWidth={popupContent.maxWidth}
            open={props.openShare}
            onClose={handleClose}
            disableScrollLock={true}
            aria-labelledby="responsive-dialog-delete"
            fullWidth
        >
            <DialogTitle onClose={handleClose}>
                <Typography
                    variant='h2'
                    style={{ marginTop: '1rem' }}
                >
                    {popupContent.popupTitle}
                </Typography>
                <IconButton aria-label="close" onClick={handleClose} style={{
                    position: 'absolute',
                    right: theme.spacing(1),
                    top: 5,
                    color: theme.palette.grey[500],
                    height: 35
                }}>
                    <IconFactory icon='close' />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box my={2}>
                    {
                        renderPopup()
                    }
                </Box>
            </DialogContent>

            <DialogActions>
                {
                    renderButtonsActions()
                }
            </DialogActions>

        </Dialog>
    )
}

export default PopupFactory

/* ===== DOC =====

    USAGE :

    <PopupFactory
        type="boost-offer"
        infos={this.state.infos}
        actionPopup={this.boostJob}
        openShare={this.state.showPopupBoost}
        popupExit={() => {
            this.renderPopup('showPopupBoost')
            this.setState({ errorList: {} })
        }}
    />

    Mandatory:
        @type: type of popup
        @infos: object of infos used in the popup
        @popupExit: function to close the popup
        @openShare: boolean used to open/close popup

    OPTIONAL:
        @actionPopup: function called by the action button in the popup
        ... any other props can be passed to the PopupFactory and used within
            child popup

    The idea of this popupFactory is to create a general factory. Only custom content
    will be defined within child component. There is no need to define this content
    from the origin page. For this reason, the PopupFactory needs to retrieve this
    custom content from the child. This will be achived by passing to the child component
    some sets function, the retrievePopupContent function.

    The following need to be defined by this function called within child:
    *   popupTitle: title of the popup
    *   popupCancelButton: name of the button
    *   popupActionButton: name of the button
    *   actionPopup: function call by the action button

    The following attributes are options depending on the style of the popup:
    * onlyClose: if the popup only needs the close button
    * doublebuttons: if the popup needs two actions buttons
    * inversedActions: if doublebuttons, then you can swap the action and close buttons

    These should be defined following that syntax within the child component at
    mounting time:
    useEffect(
        () => {
            props.getPopupContent({
                popupTitle: renderTitle(),
                popupActionButton: <FormattedMessage id="landing_app_setting_page.danger_zone.confirm_yes"/>,
                popupCancelButton: <FormattedMessage id="landing_app_setting_page.danger_zone.confirm_no"/>,
                doublebuttons: true,
                inversedActions: true,
                actionPopup: () => actionPopup()
            })
        }, [])

    ===== END =====
*/
