import React from 'react'

import { Grid, Box, Typography, Checkbox, TextField, FormControlLabel, Switch, RadioGroup, Radio } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import IconFactory from 'icons/IconFactory/IconFactory'
import FieldTitle from '../../components/FieldTitle/FieldTitle'
import FieldSubtitle from '../../components/FieldSubtitle/FieldSubtitle'
import FieldError from '../../components/FieldError/FieldError'
import FieldDivider from '../../components/FieldDivider/FieldDivider'
import { useStyles } from './SettingsForm_style'
import { useStyles as useSharedStyles } from '../../../FirstJobFunnel/FirstJobFunnel_style'
import { FIRST_JOB, STUDENT_JOB } from '../../../settings'

const SettingsForm = (props) => {
    const classes = useStyles()
    const sharedClasses = useSharedStyles()
    const { jobForm, errors, handleChange, handleAttributeChange } = props

    const jobLanguages = [
        { name: 'fr', value: jobForm.fr },
        { name: 'nl', value: jobForm.nl }
    ]

    const clearDate = () => {
        const dateInput = document.getElementById('deadlineToApply')
        dateInput.value = ''
        handleAttributeChange('deadlineToApply', null)
    }

    return (
        <Box className={sharedClasses.formContainer}>
            {/* NUMBER OF CANDIDATES SEARCHED */}
            {jobForm.jobType === FIRST_JOB &&
                <Box className={sharedClasses.formField}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.nbr_of_candidates" />} />
                    <FormattedMessage id="jobcreationfunnel.placeholders.nbr_of_candidates">
                        {placeholder =>
                            <TextField
                                name='nbrOfCandidates'
                                value={jobForm.nbrOfCandidates}
                                onChange={handleChange}
                                InputProps={{ classes: { input: `${sharedClasses.textField} ${sharedClasses.shortField}` }, disableUnderline: true }}
                                placeholder={placeholder}
                            />
                        }
                    </FormattedMessage>
                    <FieldError errorMessage={errors.nbrOfCandidates} />
                    <FieldDivider />
                </Box>
            }
            {/* LANGUAGE SELECTION */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.languages" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_4.language_sub_title" />} />
                </Box>
                <Box>
                    {jobLanguages.map((language) => {
                        return (
                            <Grid container item xs={12} alignItems={'center'} key={`checkbox-${language.name}`}>
                                <Checkbox
                                    name={language.name}
                                    value={language.value}
                                    checked={language.value}
                                    color='secondary'
                                    onClick={() => handleAttributeChange(language.name, !language.value)}
                                />
                                <Typography variant='body2' >
                                    <FormattedMessage id={`landing_app_setting_page.choose_language.${language.name}`} />
                                </Typography>
                            </Grid>
                        )
                    })}
                </Box>
                <FieldError errorMessage={errors.language} />
                <FormattedMessage id="job_form.step_4.translate_description">
                    {translatedText => <FieldSubtitle dangerouslySetInnerHTML={{ __html: translatedText }} />}
                </FormattedMessage>
                <FieldDivider />
            </Box>
            {/* DEADLINE TO APPLY */}
            {jobForm.jobType === FIRST_JOB &&
                <Box className={sharedClasses.formField}>
                    <Box className={classes.titles}>
                        <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.deadline" />} />
                        <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_4.deadline_description" />} />
                    </Box>
                    <Grid container>
                        <Grid item xs={11} md={4} className={sharedClasses.dateSelection}>
                            <FormattedMessage id="job_form.step_2.specific_period_placeholder">
                                {placeholder =>
                                    <TextField
                                        type="date"
                                        id='deadlineToApply'
                                        name="deadlineToApply"
                                        value={jobForm.deadlineToApply}
                                        classes={{ root: sharedClasses.dateInput }}
                                        placeholder={placeholder}
                                        onChange={handleChange}
                                    />
                                }
                            </FormattedMessage>
                        </Grid>
                        <Grid item xs={1} md={2}>
                            <IconFactory
                                type='button'
                                onClick={clearDate}
                                icon='close'
                                color='var(--black)'
                            />
                        </Grid>
                    </Grid>
                    <FieldError errorMessage={errors.deadlineToApply} />
                    <FieldDivider />
                </Box>
            }
            {/* APPLICATION TYPE */}
            <Box className={sharedClasses.formField}>
                <Box className={classes.titles}>
                    <FieldTitle title={<FormattedMessage id="jobcreationfunnel.titles.application_type" />} />
                    <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_4.application_sub_title" />} />
                </Box>
                <Box className={classes.applicationContainer}>
                    <RadioGroup
                        name="applicationType"
                        defaultValue={jobForm.applicationType}
                        value={jobForm.applicationType}
                        onChange={handleChange}
                    >
                        <Box className={classes.radioGroupOption}>
                            <FormControlLabel
                                value={'email'}
                                control={<Radio />}
                                label={<FormattedMessage id="job_form.step_4.application_by_email" />}
                                className={classes.radioGroupLabel}
                            />
                            {jobForm.applicationType === 'email' &&
                            <FormattedMessage id="job_form.step_4.application_email_placeholder">
                                {placeholder =>
                                    <TextField
                                        name='applicationEmail'
                                        value={jobForm.applicationEmail}
                                        onChange={handleChange}
                                        placeholder={placeholder}
                                        className={classes.radioGroupField}
                                        InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                                    />
                                }
                            </FormattedMessage>
                            }
                        </Box>
                        <FieldError errorMessage={errors.applicationEmail} />
                        <Box className={classes.radioGroupOption}>
                            <FormControlLabel
                                value={'url'}
                                control={<Radio />}
                                label={<FormattedMessage id="job_form.step_4.application_url" />}
                                className={classes.radioGroupLabel}
                            />
                            {jobForm.applicationType === 'url' &&
                            <FormattedMessage id="job_form.step_4.application_placeholder">
                                {placeholder =>
                                    <TextField
                                        name='applicationUrl'
                                        value={jobForm.applicationUrl}
                                        onChange={handleChange}
                                        placeholder={placeholder}
                                        className={classes.radioGroupField}
                                        InputProps={{ classes: { input: `${sharedClasses.textField}` }, disableUnderline: true }}
                                    />
                                }
                            </FormattedMessage>
                            }
                        </Box>
                        <FieldError errorMessage={errors.applicationUrl} />
                    </RadioGroup>
                </Box>
                <FieldDivider />
            </Box>
            {jobForm.applicationType === 'email' && <>
                <Box className={sharedClasses.formField}>
                    <Box className={classes.titles}>
                        <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.candidate_questions" />} />
                        <FieldSubtitle subtitle={<FormattedMessage id="job_chat.description" />} />
                    </Box>
                    <Switch
                        name='chatOnly'
                        classes={{ root: sharedClasses.iosSwitch }}
                        checked={jobForm.chatOnly}
                        value={jobForm.chatOnly}
                        onChange={() => handleAttributeChange('chatOnly', !jobForm.chatOnly)}
                    />
                    <FieldDivider />
                </Box>
                {jobForm.jobType === STUDENT_JOB &&
                    <Box className={sharedClasses.formField}>
                        <Box className={classes.titles}>
                            <FieldTitle isOptional title={<FormattedMessage id="jobcreationfunnel.titles.motivation_letter" />} />
                            <FieldSubtitle subtitle={<FormattedMessage id="job_form.step_4.motivation_sub_title" />} />
                        </Box>
                        <Switch
                            name='askMotivationLetter'
                            classes={{ root: sharedClasses.iosSwitch }}
                            checked={jobForm.askMotivationLetter}
                            value={jobForm.askMotivationLetter}
                            onChange={() => handleAttributeChange('askMotivationLetter', !jobForm.askMotivationLetter)}
                        />
                        <FieldDivider />
                    </Box>
                }
            </>}
        </Box>
    )
}

export default SettingsForm
