import React from 'react'
import { Box, Chip } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'

import { useStyles } from './JobCardChips_style'

const JobCardChips = (props) => {
    const classes = useStyles()
    const { job } = props
    
    return (
        <Box className={classes.chips}>
            {job.job_tags.map(jobTag => 
                <Chip
                    classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                    className={classes.chip}
                    label={<FormattedMessage id={`job_card.tags.${jobTag}`} />}
                    key={jobTag}
                    name={jobTag}
                    color={'var(--dark-grey)'}
                />
            )}
        </Box>
    )
}

export default JobCardChips
