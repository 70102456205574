import React, { useEffect, useState } from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'

import { Link } from '@material-ui/core'
import { useStyles } from './CompanyCardAlt_style'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

import IconFactory from 'icons/IconFactory/IconFactory'
import NavigationAPI from 'api/NavigationAPI'
import RouteMap from 'assets/RouteMap'
import useJobIcon from 'hooks/useJobIcon'

const CompanyCardAlt = (props) => {
    const classes = useStyles()
    const { filter } = props
    const language = RouteMap.GetLocaleFromUrl()
    const [nbInternship, setNbInternship] = useState(0)
    const [nbStudentJob, setNbStudentJob] = useState(0)
    const [nbFirstJob, setNbFirstJob] = useState(0)

    useEffect(() => {
        NavigationAPI.GetCompanyFilterJobsCount({
            sector: filter.sector ? [filter.sector] : '',
            quick_filter: filter?.quickFilter
        }).then((response) => {
            setNbInternship(response.data.internships)
            setNbStudentJob(response.data.studentJobs)
            setNbFirstJob(response.data.firstJobs)
        })
    }, [])

    return (
        <Link href={`${RouteMap.Page('companies')}${filter[language]}/`} className={classes.link}>
            <Box className={classes.container}>
                <Box className={classes.cardImageContainer}>
                    <Image
                        cloudName='studentbe'
                        publicId={`assets/quickfilter_${filter.value}.png`}
                        alt='home-alert-background'
                        className={classes.cardMedia}
                    >
                        <Transformation flags='progressive' fetchFormat='auto' quality='auto:best' width={400} crop='fill' />
                    </Image>
                </Box>
                <Box className={classes.cardContent}>
                    <Box className={classes.cardText}>
                        <Box className={classes.cardTitleContainer}>
                            <Typography variant='h6' component='h3' className={classes.cardTitle}>
                                <FormattedMessage id={`landing_student.carousel.${filter.value}`} />
                            </Typography>
                        </Box>
                        <Box className={classes.cardDescriptionContainer}>
                            <Typography variant='body2' component='p' className={classes.cardDescription}>
                                <FormattedMessage id={`landing_student.carousel.${filter.value}_description`} />
                            </Typography>
                        </Box>
                    </Box>
                    <Box className={classes.bottomCardContainer}>
                        <Divider orientation='horizontal' className={classes.divider} />
                        <Box className={classes.jobAvailableContainer}>
                            <Box className={classes.jobAvailable}>
                                <IconFactory icon={useJobIcon('student_job')} color='var(--black)' fontSize='14px' />
                                <Box className={classes.jobAvailableText}>
                                    {nbStudentJob}&nbsp;<FormattedMessage id='student_jobs'/>
                                </Box>
                            </Box>
                            <Box className={classes.jobAvailable}>
                                <IconFactory icon={useJobIcon('internship')} color='var(--black)' fontSize='14px' />
                                <Box className={classes.jobAvailableText}>
                                    {nbInternship}&nbsp;<FormattedMessage id='internships'/>
                                </Box>
                            </Box>
                            <Box className={classes.jobAvailable}>
                                <IconFactory icon={useJobIcon('first_job')} color='var(--black)' fontSize='14px' />
                                <Box className={classes.jobAvailableText}>
                                    {nbFirstJob}&nbsp;<FormattedMessage id='first_jobs'/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Link>
    )
}

export default CompanyCardAlt
