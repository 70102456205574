import React from 'react'

import { useStyles } from './ArticleCardContent_style'
import { Box, Chip, Typography } from '@material-ui/core'

import DateHandler from 'assets/DateHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler'

const ArticleCardContent = (props) => {
    const classes = useStyles()
    const { article } = props

    return (
        <Box className={classes.articleContent}>
            <Box className={classes.readTimeAndDate}>
                <Typography className={classes.minorText}>
                    {`${DateHandler.toReadingFormat(article.date)}`}
                </Typography>
                <Typography component='span' className={`${classes.minorText} ${classes.bullet}`}>
                            •
                </Typography>
                <Box className={classes.readTimeContainer}>
                    <IconFactory
                        icon='clock'
                        fontSize='12px'
                        color='var(--grey)'
                        style={{
                            fontFamily: 'GintoNormal-Medium, sans-serif !important',
                            marginRight: '4px'
                        }}
                    />
                    <Typography variant='body2' component='span' className={classes.minorText}>
                        {`${article.read_time} min`}
                    </Typography>
                </Box>
            </Box>
            <Typography variant='h3' className={classes.articleTitle}>
                {article.title}
            </Typography>
            <Chip
                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                className={classes.chip}
                label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
            />
        </Box>
    )
}

export default ArticleCardContent
