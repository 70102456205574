import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    seoBlockContainer: {
        paddingTop: 30,
        marginBottom: 30,
        width: '100%'
    },
    seoTextContainer: {
        paddingBottom: 20
    },
    seoTextBlock: {
        marginTop: 12
    },
    accordionTitle: {
        display: 'flex',
        flexDirection: 'column'
    },
    accordionText: {
        '& a': {
            color: 'var(--red)',
            fontWeight: 'bold'
        }
    },
    accordionDetails: {
        cursor: 'pointer'
    }
}))
