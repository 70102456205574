import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    backgroundContainer: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        minHeight: 160
    },
    fullBackground: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    leftBackground: {
        backgroundColor: '#76243A',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: 100,
            height: 'initial'
        }
    },
    rightBackground: {
        backgroundColor: 'var(--red)',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            minHeight: 100
        }
    },
    horizontalArrow: {
        position: 'absolute',
        zIndex: 2,
        left: '60%',
        top: '50%'
    },
    filterContainer: {
        display: 'flex',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    },
    leftContainer: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        width: '30%',
        backgroundColor: '#76243A',
        padding: '40px 40px 40px 0px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            minHeight: 100,
            justifyContent: 'center',
            textAlign: 'center',
            padding: 20
        }
    },
    studentJobPadding: {
        padding: '40px 50px 0px !important',
        [theme.breakpoints.down('md')]: {
            padding: '20px !important',
            '& .MuiBox-root': {
                marginBottom: '0px !important'
            }
        }
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '40px 40px 40px 50px',
        justifyContent: 'center',
        gap: 12,
        backgroundColor: 'var(--red)',
        [theme.breakpoints.down('md')]: {
            minHeight: 100,
            alignItems: 'center',
            padding: 20
        }
    }
}))
