/* eslint-disable react/prop-types */
import React, { useState, useImperativeHandle, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import Field from 'inputs/Field/Field'
import CollectionHandler from 'assets/CollectionHandler'
import UploadImageV2 from 'modals/UploadImageV2/UploadImageV2'

import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './CompanyProfileFormGeneralInfo_style'

const CompanyProfilesGeneralInfo = (props) => {
    const { company, handleCompanyLogoChange, handleChange, handleChangeCheckBox, classes } = props

    const averageAges = CollectionHandler.Get('averageAgeCollection')
    const nbrEmployees = CollectionHandler.Get('nbrEmployeeCollection')
    const nbrEmployeesBelow30 = CollectionHandler.Get('nbrEmployeesBelow30Collection')
    const nbrYoungGraduatedPerYear = CollectionHandler.Get('nbrYoungGraduatedPerYearCollection')

    useEffect(() => {
        // Met à jour le titre du document via l’API du navigateur
        checkFeaturedCheckBoxes(company.selectedFields, company.nbrCheckedCheckboxes)
    }, [company.selectedFields, company.nbrCheckedCheckboxes])

    const openColorPicker = () => {
        document.getElementById('colorpicker').focus()
        document.getElementById('colorpicker').value = company.color
        document.getElementById('colorpicker').click()
    }

    const createCheckboxField = (name, title, value, listValues, dbAttributeName, type) => {
        // the dbAttributeName param will allow the remember the 3 selected checkbox
        // another solution is to put the state attribute concerned in an object and send it to employer banner to do the job
        if (type === 'hashes-select') {
            return (
                <Box style={{ display: 'flex' }}>
                    <input
                        type="checkbox"
                        name="checkbox"
                        value={dbAttributeName}
                        onClick={handleChangeCheckBox}
                    />
                    <Box style={{ marginLeft: 10, width: '100%' }} mb={2}>
                        <Typography variant='subtitle1' className={classes.inputTitle}>
                            {title}
                        </Typography>
                        <FormControl className={classes.formControl}>
                            <Select
                                native
                                value={value}
                                onChange={handleChange}
                                inputProps={{ name: name, id: `${name}-selection` }}
                            >
                                <option aria-label="None" value="" />
                                {listValues.map((item) => (
                                    <option key={`type-selection-${item.value}`} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            )
        } else {
            return (
                <Box style={{ display: 'flex' }}>
                    <input
                        type="checkbox"
                        name="checkbox"
                        checked={company.selectedFields.indexOf(dbAttributeName) === -1 ? '' : true}
                        value={dbAttributeName}
                        onClick={handleChangeCheckBox}
                    />
                    <Box style={{ marginLeft: 10, width: '100%' }} mb={2} >
                        <Typography variant='subtitle1' className={classes.inputTitle}>
                            {title}
                        </Typography>
                        <FormControl className={classes.formControl}>
                            <TextField
                                required
                                fullWidth
                                value={value}
                                onChange={handleChange}
                                InputProps={{ name: name, id: `${name}-input` }}
                            />
                        </FormControl>
                    </Box>
                </Box>
            )
        }
    }

    const checkFeaturedCheckBoxes = (selectedFields, nbrCheckedCheckboxed) => {
        selectedFields.forEach((selectedField) => {
            document.querySelector('[value=' + selectedField + ']').checked = true
        })
        if (nbrCheckedCheckboxed === 3) {
            const checkboxes = document.querySelectorAll('[name=checkbox]')
            checkboxes.forEach((checkbox) => {
                if (checkbox.checked === false) checkbox.disabled = true
            })
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant='h4' component='h3'>
                    <FormattedMessage id="company_profile.employer_banner.info_manager_popup.company_logo" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <UploadImageV2
                    image={company.companyLogo}
                    setImage={handleCompanyLogoChange}
                />
            </Grid>
            <Grid item xs={12}>
                <FormattedMessage id="company_profile.employer_banner.info_manager_popup.color_placeholder">
                    {placeholder =>
                        <Field
                            name="color"
                            title={<FormattedMessage id="company_profile.employer_banner.info_manager_popup.color" />}
                            placeholder={placeholder}
                            value={company.color}
                            onClick={openColorPicker}
                            type="color"
                            handleChange={handleChange}
                            additionalMessage={<FormattedMessage id="company_profile.edit_profile.color_additional_text" />}
                        />
                    }
                </FormattedMessage>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4' component='h3'>
                    <FormattedMessage id="company_profile.edit_profile.company_summary_keys" />
                </Typography>
                <Typography variant='subtitle1'>
                    <FormattedMessage id="company_profile.employer_banner.info_manager_popup.description" />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'nbrCountries',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.nbrCountries" />,
                            company.nbrCountries, '',
                            'nbr_countries',
                            'input'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'nbrEmployees',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.nbrEmployees" />,
                            company.nbrEmployees, nbrEmployees, 'nbr_employees',
                            'hashes-select'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'averageAges',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.averageAges" />,
                            company.averageAges, averageAges, 'employees_average_ages',
                            'hashes-select'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'nbrYoungGraduatedPerYear',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.nbrYoungGraduatedPerYear" />,
                            company.nbrYoungGraduatedPerYear, nbrYoungGraduatedPerYear, 'nbr_young_graduated_hired_per_year',
                            'hashes-select'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'ceoName',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.ceoName" />,
                            company.ceoName, '',
                            'ceo_name',
                            'input'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'nbrEmployeesBelow30',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.nbrEmployeesBelow30" />,
                            company.nbrEmployeesBelow30, nbrEmployeesBelow30, 'nbr_employees_below_thirthy',
                            'hashes-select'
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        {createCheckboxField(
                            'salesRevenue',
                            <FormattedMessage id="company_profile.employer_banner.info_manager_popup.salesRevenue" />,
                            company.salesRevenue, '',
                            'sales_revenues',
                            'input'
                        )}
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default withStyles(useStyles)(CompanyProfilesGeneralInfo)
