import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    homeSearchContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            gap: 20
        }
    },
    title: {
        fontSize: 40,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        gap: 16,
        [theme.breakpoints.down('sm')]: {
            gap: 10
        }
    },
    formField: {
        display: 'contents'
    },
    inputField: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
        height: 60,
        borderRadius: 12,
        [theme.breakpoints.down('sm')]: {
            height: 40
        }
    },
    validateBtn: {
        textTransform: 'uppercase !important',
        height: 60,
        backgroundColor: '#76243A',
        color: 'white',
        padding: '6px 150px',
        borderRadius: 12,
        '&:hover': {
            backgroundColor: '#76243A'
        },
        '& p': {
            fontFamily: "'GintoNord-Bold', sans-serif !important",
            fontSize: 25,
            [theme.breakpoints.down('sm')]: {
                fontSize: 16
            }
        },
        [theme.breakpoints.down('sm')]: {
            height: 40,
            padding: '6px 80px !important'
        }
    },
    underline: {
        '&:before': {
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            position: 'absolute',
            transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            pointerEvents: 'none'
        },
        '& svg': {
            color: 'var(--red)',
            margin: '4px 20px 0px',
            width: 16,
            height: 16,
            [theme.breakpoints.down('sm')]: {
                width: 12,
                height: 12,
                margin: '6px 10px 0px'
            }
        },
        '& div': {
            paddingLeft: 24,
            fontSize: 20,
            borderRadius: '12px !important',
            display: 'flex ',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 12,
                paddingRight: 12,
                fontSize: 16
            }
        }
    }
}))
