import React from 'react'
import { useStyles } from './CTABlock_style'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FilledButton from 'buttons/FilledButton/FilledButton'
import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const CTABlock = (props) => {
    const { selectedLocationTag, type, page } = props
    const classes = useStyles()

    return (
        <Box className={classes.ctaContainer}>
            <Container maxWidth="lg">
                <Box className={classes.maxWidth}>
                    <Typography variant="h2">
                        <FormattedMessage id="cta_block.dont_find" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} />, location_name: selectedLocationTag.name }} />
                    </Typography>
                    <Box my={5}>
                        <Typography variant="body1">
                            <FormattedMessage id="cta_block.text1" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} />, location_name: selectedLocationTag.name }} />
                        </Typography>
                        <Typography variant="body1">
                            <FormattedMessage id="cta_block.text2" values={{ location_name: selectedLocationTag.name }}/>
                        </Typography>
                    </Box>
                    <RouteMapLink page={page}>
                        <FilledButton
                            newStyle 
                            name=<FormattedMessage id="cta_block.see_all_jobs" values={{ type_name: <FormattedMessage id={`cta_block.${type}`} /> }} /> 
                            className={classes.button} 
                        />
                    </RouteMapLink>
                </Box>
            </Container>
        </Box>
    )
}

export default CTABlock
