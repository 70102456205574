/* eslint-disable react/prop-types */
import React from 'react'

import { useStyles } from '../Searchbar_v2_style'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'

import CityFilter from '../shared/CityFilter/CityFilter'
import MultipleSelectFilter from '../shared/MultipleSelectFilter/MultipleSelectFilter'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersWrapper from 'components/Searchbar/shared/FiltersWrapper/FiltersWrapper'
import KotFiltersModal from 'modals/FilterModals/KotFiltersModal/KotFiltersModal'
import MoreFilters from '../shared/MoreFilters/MoreFilters'
import ResetFilters from '../shared/ResetFilters/ResetFilters'


const KotFiltersView = (props) => {
    const { offerCount, debouncedFilterChange, handleFilterChange, filters, setFilters, reset, handleInputSearchCity, searchCities, setSearchCities, citiesInput, setCitiesInput, showKotFiltersModal, setShowKotFiltersModal, selectedLocationTag, type } = props
    const classes = useStyles()

    const kotDisponibilityCollection = CollectionHandler.Get('kotDisponibilityCollection')
    const kotPriceCollection = CollectionHandler.Get('kotPriceCollection')
    const kotRoomCountCollection = CollectionHandler.Get('kotRoomCountCollection')
    const kotTypeCollection = CollectionHandler.Get('kotTypeCollection')

    return (
        <Box className={classes.container}>
            <KotFiltersModal
                showModal={showKotFiltersModal}
                setShowModal={setShowKotFiltersModal}
                debouncedFilterChange={debouncedFilterChange}
                handleFilterChange={handleFilterChange}
                filters={filters}
                offerCount={offerCount}
                handleInputSearchCity={handleInputSearchCity}
                searchCities={searchCities}
                citiesInput={citiesInput}
                setSearchCities={setSearchCities}
                setCitiesInput={setCitiesInput}
                setFilters={setFilters}
                reset={reset}
            />
            <FiltersWrapper type={type} selectedLocationTag={selectedLocationTag}>
                <Box className={classes.filters}>
                    <Box className={classes.filterBox}>
                        <CityFilter
                            handleInputSearchCity={handleInputSearchCity}
                            searchCities={searchCities}
                            citiesInput={citiesInput}
                            setSearchCities={setSearchCities}
                            setCitiesInput={setCitiesInput}
                            setFilters={setFilters}
                            filters={filters}
                            width={350}
                        />
                    </Box>
                    <Hidden xsDown>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='kotType'
                                collection={kotTypeCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.kot_type.title'
                            />
                        </Box>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='disponibility'
                                collection={kotDisponibilityCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={400}
                                filterTextId='search_bar.disponibility.title'
                            />
                        </Box>
                    </Hidden>
                    <Hidden mdDown>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='price'
                                collection={kotPriceCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.price.title'
                            />
                        </Box>
                        <Box className={classes.filterBox}>
                            <MultipleSelectFilter
                                type='roomCount'
                                collection={kotRoomCountCollection}
                                filters={filters}
                                setFilters={setFilters}
                                width={250}
                                filterTextId='search_bar.rooms.title'
                            />
                        </Box>
                    </Hidden>
                    <MoreFilters setShowModal={setShowKotFiltersModal} type={type} filters={filters} />
                    <ResetFilters reset={reset}/>
                </Box>
            </FiltersWrapper>
        </Box>
    )
}

export default KotFiltersView
