/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import IconFactory from '../../icons/IconFactory/IconFactory'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

const PasswordInput = (props) => {
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    return (
        <Box>
            <FormControl variant='outlined' fullWidth={true}>
                <TextField
                    value={props.value}
                    onChange={props.onChange}
                    error={props.error}
                    helperText={props.helperText}
                    required={true}
                    type={showPassword ? 'text' : 'password'}
                    name={props.name}
                    autoComplete='on'
                    FormHelperTextProps={{ style: { color: 'var(--error-red)' } }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                <IconFactory icon={showPassword ? 'eye' : 'eye-slash'} style={{width: '22px'}}/>
                            </IconButton>
                        </InputAdornment>
                    }}
                />
            </FormControl>
        </Box>
    )
}

export default PasswordInput
