import React from 'react'
import ExperienceProfileViewDefault from './ExperienceProfileViewDefault'
import ExperienceProfileViewBlack from './ExperienceProfileViewBlack'

const ExperienceProfileView = (props) => {
    const { black } = props

    if (black) {
        return <ExperienceProfileViewBlack {...props} />
    } else {
        return <ExperienceProfileViewDefault {...props} />
    }
}

export default ExperienceProfileView
