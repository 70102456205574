import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    stepButtonBox: {
        height: 45,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        gap: 8,
        marginTop: 24,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    },
    previousStepButton: {
        backgroundColor: 'black !important',
        [theme.breakpoints.down('sm')]: {
            width: 'unset'
        }
    },
    nextStepButton: {
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]: {
            width: 'unset'
        }
    },
    nextStepButtonLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    circularProgress: {
        '& .MuiCircularProgress-svg': {
            '& .MuiCircularProgress-circle': {
                stroke: 'white'
            }
        }
    }
}))
