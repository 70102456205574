import React, { useContext, useState } from 'react'
import { Box } from '@material-ui/core'

import { useStyles } from './CreditPackages_style'
import { UserContext } from 'contexts/UserContext'
import { LoginRegisterContext } from 'shared/contexts/LoginRegisterContext'
import { EMPLOYER_BUYING_CREDITS } from 'assets/AuthentificationHelper'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import URLParamsHandler from 'assets/URLParamsHandler'
import PaymentAPI from 'api/PaymentAPI'
import isEmpty from 'lodash.isempty'
import FreeCreditPackage from './components/FreeCreditPackage'
import StudentInternCreditPackages from './components/StudentInternCreditPackages'
import FirstJobCreditPackage from './components/FirstJobCreditPackage'
import StudentTriesToBuyCreditsModal from './components/StudentTriesToBuyCreditsModal'

const CreditPackages = (props) => {
    const { noFirstJobPackage, analyticsId } = props
    const classes = useStyles()
	const userContext = useContext(UserContext)
	const loginRegisterContext = useContext(LoginRegisterContext)
	const [errorStudentNotEmployer, setErrorStudentNotEmployer] = useState(false)
	const closeDialog = () => setErrorStudentNotEmployer(false)

	const afterAuthAction = () => {
        URLParamsHandler.reloadPageAndAddParams({ process: 'buying_credits' })
    }

	const handlePayment = (selectedCreditPackage) => {
		if (userContext?.user?.user_type === 'student') return setErrorStudentNotEmployer(true)
		if (isEmpty(selectedCreditPackage)) return

        if (isEmpty(userContext?.user)) {
			loginRegisterContext.openLoginRegisterModal({
				origin: EMPLOYER_BUYING_CREDITS,
				afterAuthAction: afterAuthAction
			})
        } else {
            PaymentAPI.newPayment({
                package_name: selectedCreditPackage.name,
                package_credits_cost: selectedCreditPackage.credits,
                process: 'buying_credits',
                success_url: '/',
                cancel_url: window.location.href
            },
            AnalyticsHelper.constructPricingPageCheckoutAnalyticsAttributes(
                userContext?.user,
                userContext?.user?.company_card_info,
                selectedCreditPackage
            ))
			AnalyticsHelper.sendGTMEvent(
				'Stripe Begin Checkout',
				AnalyticsHelper.constructStripeCheckoutAnalyticsAttributes(selectedCreditPackage, userContext?.user, userContext?.user?.company_card_info, analyticsId)
			)
        }
    }

    return (
		<Box className={classes.container}>
			<StudentTriesToBuyCreditsModal
				openModal={errorStudentNotEmployer}
				closeModal={closeDialog}
			/>
			<FreeCreditPackage />
			<StudentInternCreditPackages handlePayment={handlePayment} />
			{!noFirstJobPackage &&
				<FirstJobCreditPackage handlePayment={handlePayment} />
			}
		</Box>
    )
}

export default CreditPackages
