import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    avatar: {
        width: 90,
        height: 90,
        backgroundColor: 'var(--red)'
    },
    avatarBox: {
        cursor: 'pointer',
        marginRight: 16
    },
    fab: {
        width: '30px !important',
        height: '30px !important',
        '& svg': {
            fontSize: '12px !important'
        }
    },
    fullName: {
        fontSize: 12
    },
    email: {
        fontFamily: "'GintoNormal-Light', sans-serif !important",
        fontSize: 12,
        color: 'var(--grey-middle)',
        wordBreak: 'break-all'
    },
    identityBox: {
        display: 'flex'
    }
}))
