import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import RouteMap from 'assets/RouteMap'

import css from './objecttag.scss'
import css_responsive from './objecttag_responsive.scss'

import Chip from '@material-ui/core/Chip'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './ObjectTag_syle'

class ObjectTag extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            checked: this.props.value
        }

        this.toggleChecked = this.toggleChecked.bind(this)
        this.reset = this.reset.bind(this)
        this.activate = this.activate.bind(this)
    }

    reset () {
        this.setState({
            checked: false
        })
    }

    activate () {
        this.setState({
            checked: true
        })
    }

    toggleChecked () {
        if (this.props.openLinkUrl) {

        } else {
            this.setState({
                checked: !this.state.checked
            }, () => {
                if (this.props.onEngineerClick && this.props.item.value === 'engineering') {
                    this.props.onEngineerClick()
                } else {
                    this.props.onTagClick()
                }
            })
        }
    }

    render () {
        const { classes } = this.props
        let chip = (
            <Chip
                label={this.props.item && this.props.item.name}
                name={this.props.item.name}
                className={classes.unlinkableTag}
                clickable
                color={this.state.checked ? 'primary' : 'default'}
                onClick={this.toggleChecked}
            />
        )

        if (this.props.articleTag) {
            chip = (
                <RouteMapLink redirectionUrl={RouteMap.ArticleCategory(this.props.item.value)} >
                    <Chip
                        label={this.props.item && this.props.item.name}
                        className={classes.linkableTag}
                        name={this.props.item.name}
                        clickable
                        color={this.state.checked ? 'primary' : 'default'}
                    />
                </RouteMapLink>
            )
        }

        return chip
    }
}

export default withStyles(useStyles)(ObjectTag)
