/* eslint-disable react/prop-types */

import React from 'react'

import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'

import { useStyles } from './SortByDropdown_style'
import { FormattedMessage } from 'react-intl'

import IconFactory from 'icons/IconFactory/IconFactory'

const SortByDropdown = (props) => {
    const classes = useStyles()

    const createChevron = (key, sortbykey) => {
        if (key !== sortbykey) {
            return (
                <IconFactory
                    icon='down'
                    style={{ margin: '4px', color: 'var(--grey)' }}
                    fontSize='18px'
                />
            )
        } else if (props.order === 1) {
            return (
                <IconFactory
                    icon='up'
                    style={{ margin: '4px', color: 'var(--black)' }}
                    fontSize='18px'
                />
            )
        }
        return (
            <IconFactory
                icon='down'
                style={{ margin: '4px', color: 'var(--black)' }}
                fontSize='18px'
            />
        )
    }

    return (
        <Box className={classes.justifyBox}>
            <FormControl className={classes.sortByContainer}>
                <InputLabel id="sort-by-label" style={{ fontSize: '14px' }}>
                    <Typography variant='body2'>
                        <FormattedMessage id="sort_by.sort" />
                    </Typography>
                </InputLabel>
                <Select
                    onChange={(event) => { props.onClick(event.target.value) }}
                    inputProps={{ style: { fontSize: '14px' } }}
                    value={props.sortby}
                    inputProps={{MenuProps: {disableScrollLock: true}}}
                >
                    {
                        props.keys.map((category) => {
                            return (
                                <MenuItem
                                    value={category[1]}
                                    key={'sort-by-' + category[1]}
                                >
                                    <Typography variant='body2'>
                                        {category[0]}
                                    </Typography>
                                </MenuItem>
                            )
                        })
                    }
                </Select>
            </FormControl>
            {props.sortby &&
                <Box onClick={() => { props.onClick(props.sortby) }} className={`${classes.justifyBox} ${classes.orderBox}`}>
                    <Typography variant='body2' style={{marginRight: '10px'}}>
                        <FormattedMessage id="sort_by.order" />
                    </Typography>
                    {createChevron(props.sortby, props.sortby)}
                </Box>
            }
        </Box>
    )
}

export default SortByDropdown
