import { useState, useEffect } from 'react'
import { LARGE_SIZE } from '../assets/CloudinaryURLHelper'

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined })
    const isLargeScreen = windowSize?.width >= LARGE_SIZE

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight })
        }
        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return { windowSize, isLargeScreen }
}
