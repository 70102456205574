import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    userActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px'
    },
    langSelect: {
        marginRight: '-12px'
    }
}))
