import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    alertContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    alertLeft: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'var(--red)',
        color: 'white',
        width: '50%',
        padding: '80px 80px 80px 0',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 40,
            textAlign: 'center'
        }
    },
    alertRight: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 200
        }
    },
    alertTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center'
        }
    },
    alertTitleContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 540
    },
    alertTitle: {
        fontSize: 30,
        lineHeight: 1.2,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    alertDescriptionContainer: {
        maxWidth: 400
    },
    alertDescription: {
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: 1
        }
    },
    imageBackground: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    alertButton: {
        textTransform: 'uppercase !important',
        height: 60,
        backgroundColor: 'var(--red)',
        color: 'white',
        padding: '9px 30px',
        borderRadius: 12,
        marginTop: '20%',
        marginBottom: '5%',
        '&:hover': {
            backgroundColor: 'var(--red)'
        },
        '& p': {
            fontFamily: "'GintoNord-Bold', sans-serif !important",
            fontSize: 16,
            [theme.breakpoints.down('sm')]: {
                fontSize: 12
            }
        },
        [theme.breakpoints.down('sm')]: {
            height: 40,
            width: '80% !important',
            padding: '9px 16px !important',
            borderRadius: 8
        }
    }
}))
