import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    mainContainer: {
        backgroundColor: 'var(--super-dark-grey)',
        display: 'flex',
        justifyContent: 'center'
    },
    mainBox: {
        [theme.breakpoints.up('md')]: {
            minWidth: 904
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 1256,
        }
    },
    cardGrid: {
        gap: 32,
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-around'
        }
    },
    jobCardContainer: {
        width: 280,
        [theme.breakpoints.down('xs')]: {
            width: 320 ,
        }
    },
    title: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontSize: '24px !important',
        color: 'white',
        marginBottom: 40,
        maxWidth: 592,
        [theme.breakpoints.up('md')]: {
            maxWidth: 904
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 320,
        }
    },
    titleBox: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    fullWidth: {
        paddingTop: 80,
        paddingBottom: 80,
        backgroundColor: 'var(--super-dark-grey)'
    },
}))
