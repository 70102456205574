/* eslint-disable no-return-assign */
import React from 'react'
import { Box, Grid, Chip, Typography } from '@material-ui/core'

import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from './ArticleCardNew_style'
import { SMALL_SIZE } from 'assets/CloudinaryURLHelper'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import CollectionHandler from 'assets/CollectionHandler.js'
import RouteMap from 'assets/RouteMap.js'

const ArticleCardNew = (props) => {
    const classes = useStyles()
    const { article, articleOwner, remove } = props
    const coverImage = article.cover_image.cloudinary_key || 'default-company-logo-black'

    return (
        <Box className={classes.articleCardWrapper} id={`article-card-${article.id}`} key={`article-card-${article.id}`}>
            <RouteMapLink
                showItemType='article'
                showItemUrl={article.url}
                className={classes.routeMapLinkStyle}
            >
                <Box className={classes.container}>
                    <span className={classes.articleCardImage} role='img' aria-label='article-cover-image'>
                        <Image
                            cloudName="studentbe"
                            className={classes.cardMedia}
                            publicId={coverImage}
                            alt='sector image'
                        >
                            <Transformation flags="progressive" fetchFormat="auto" height={SMALL_SIZE} quality="auto:good" crop="fill" />
                            <Transformation defaultImage="placeholders:other_industries.png" />
                        </Image>
                    </span>
                    <Box className={classes.articleCardText}>
                        <Typography variant='h3' component='p' className={classes.articleTitle}>
                            {article.title}
                        </Typography>
                        <Box className={classes.articleDetails}>
                            <Chip
                                classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                                className={classes.chip}
                                label={CollectionHandler.Translate('articleCategoryCollection', article.category)}
                                key={article.category}
                                name={article.category}
                            />
                            <Typography
                                variant='body2'
                                component='span'
                                className={classes.timeToReadBox}
                            >
                                <IconFactory icon='clock' fontSize={14} />
                                {article.read_time} mins
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </RouteMapLink>
            {articleOwner &&
                <Grid container direction='column' justify='flex-start'>
                    <Grid item>
                        <IconFactory
                            button
                            icon='view'
                            className="action-icon"
                            onClick={() => window.location.href = RouteMap.Show('article', article.url)}
                        />
                    </Grid>
                    <Grid item>
                        <IconFactory
                            button
                            icon='edit'
                            className="action-icon"
                            onClick={() => window.location.href = RouteMap.Edit('article', article.id)}
                        />
                    </Grid>
                    <Grid item>
                        <IconFactory
                            button
                            icon='delete'
                            className="action-icon"
                            onClick={() => remove(article)}
                        />
                    </Grid>
                </Grid>
            }
        </Box>
    )
}

export default ArticleCardNew
