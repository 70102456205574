import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    applyBox: {
        backgroundColor: 'var(--red)',
        borderRadius: 12,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 24,
        padding: '24px 28px',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: 16
        }
    },
    title: {
        fontFamily: 'GintoNord-Bold',
        fontSize: 16,
        color: 'white'
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))
