import React, { useState } from 'react'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import OpenIcon from '../OpenIcon/OpenIcon'
import { useStyles } from '../../ExperienceProfile_style'

const ExperienceSelectInput = (props) => {
    const { type, inputRef, error, formation, collection, textFontClassName, disableUnderline, placeholderTextClassName, handleChange, placeholderTextId } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()

    return (
        <FormControl className={classes.formControl} required onClick={() => setOpen(!open)}>
            <Select
                className={`${textFontClassName} ${error && classes.textFieldError}`}
                value={formation[type]}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                open={open}
                disableUnderline={disableUnderline}
                onChange={handleChange}
                IconComponent={() => <OpenIcon open={open}/>}
                displayEmpty
                inputRef={inputRef}
            >
                {formation[type] === '' &&
                    <MenuItem value=''>
                        <span className={placeholderTextClassName}>
                            <FormattedMessage id={placeholderTextId} />
                        </span>
                    </MenuItem>
                }
                {collection.map((item, itemKey) => {
                    return <MenuItem key={itemKey.toString()} value={item.value}>{item.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}

export default ExperienceSelectInput
