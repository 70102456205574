import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8
    },
    articleInfo: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12
    },
    readTimeAndDate: {
        display: 'flex',
        alignItems: 'center',
        gap: 8
    },
    readContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 4
    },
    minorText: {
        fontSize: '12px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    bullet: {
        fontSize: '8px !important',
        color: 'var(--grey)',
        fontFamily: 'GintoNormal-Medium, sans-serif !important'
    },
    articleTitle: {
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        lineHeight: 1.4,
        [theme.breakpoints.up('md')]: {
            width: '90%',
            fontSize: '18px !important',
            transition: 'color 0.3s ease-in-out',
            '&:hover': {
                color: 'var(--red) !important',
                '& #hidden-text': {
                    opacity: 1
                }
            }
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '14px !important'
        }
    },
    readMore: {
        transition: 'opacity 0.3s ease-in-out',
        opacity: 0,
        fontSize: '12px !important',
        marginLeft: 6,
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}))
