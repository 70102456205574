import React from 'react'

import MainTitle from 'components/MainTitle/MainTitle'
import CarouselCards from 'components/CarouselCards/CarouselCards'

import css from './cardslider.scss'
import css_responsive from './cardslider_responsive.scss'
import { Hidden, Container } from '@material-ui/core'

export default class CardSlider extends React.Component {

    /*
        README :
        If you want to use the linebackground,
            - just add 'showLine'
            - remove the Cardslider to the parent Container

        ex:
        <Container> ... </Container>
        <CardSlider
            cards={this.props.recentArticles}
            type="article"
            side="left"
            maxItem="3"
            showLine
        />

        without the showLine you can keep the CardSlider into a Container
        and add onlyCard if you just want the card
        <Container> ...
            <CardSlider
                cards={this.props.recentArticles}
                type="article"
                side="left"
                maxItem="3"
                (onlyCard)
            />
        </Container>
    */

    constructor (props) {
        super(props)
        this.state = {
            innerWidth: window.innerWidth
        }
        this.handleResize = this.handleResize.bind(this)
        this.getShiaGif = this.getShiaGif.bind(this)
        this.getCardSlider = this.getCardSlider.bind(this)

        window.addEventListener('resize', this.handleResize)
    }

    handleResize () {
        this.setState({ innerWidth: window.innerWidth })
    }

    getShiaGif () {
        const array = [
            `https://media1.giphy.com/media/GcSqyYa2aF8dy/giphy.gif?cid=ecf05e47u7okt4ur2r6t8qqur2cr65b2roi4xb33tdh4gvfx&rid=giphy.gif`,
            `https://media.giphy.com/media/87xihBthJ1DkA/giphy.gif`,
            `https://i.gifer.com/13tq.gif`,
            `https://i.gifer.com/4vsp.gif`,
            `https://i.gifer.com/Tv5h.gif`
        ]

        return array[Math.floor(Math.random() * array.length)]
    }

    getCardSlider () {
        const titleResponsive = this.props.titleResponsive ? this.props.titleResponsive : this.props.title
        return (
            <>
                <div className="card-slider-title-responsive">
                    {this.props.isMainTitleResponsive
                        ? <MainTitle text={titleResponsive} />
                        : titleResponsive
                    }
                </div>
                <div className="card-slider-sub-title-responsive">
                    {this.props.subTitleResponsive
                        ? this.props.subTitleResponsive
                        : this.props.subTitle
                    }
                </div>
                <div className="card-slider-card">
                    <CarouselCards
                        cards={this.props.cards}
                        type={this.props.type}
                        userType={this.props.userType}
                        userAddress={this.props.userAddress}
                        id='card-slider'
                    />
                </div>
            </>
        )
    }

    render () {
        return (
            <div
                className={
                    'card-slider-container' +
                    this.props.mainColorType + ' ' +
                    this.props.side +
                    (this.props.isMainTitleResponsive ? ' responsive-main' : '')
                }
                id={'CardSlider-' + this.props.id}
            >
                {this.props.showLine ?
                    <>
                        <Hidden smDown>
                            <svg className="card-slider-line" xmlns="http://www.w3.org/2000/svg" width="1920.467" height="392.899" viewBox={`0 0 ${(this.state.innerWidth < 1920 ? this.state.innerWidth + 30 : 1920)} 392.899`}>
                            <path id="Stroke_1" data-name="Stroke 1" d={`M-1280,12.01H260.467s53.41,3.574,53.41,72.974v41.463c0,38.776,38.4,54.885,67.085,55.33,44.628.692,200.6.164,${(this.state.innerWidth < 1920 ? this.state.innerWidth - 700 : 1147.636)}-.061,91.74.487,75.284,70.038,75.679,80.485v26.525c0,50.555,39.03,61.609,55.433,62.665h1280`} transform="translate(0.942 14.75)" fill="none" stroke="#ff6762" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="43.52"/>
                            </svg>
                        </Hidden>
                        <Hidden mdUp >
                            <svg className="card-slider-line" xmlns="http://www.w3.org/2000/svg" width="852" height="200" viewBox="0 0 852 302.521">
                                <path id="Stroke_2" data-name="Stroke 2" d="M1280,0H282.277C245.674,0,216,27.87,216,62.249S245.674,124.5,282.277,124.5H562.232c36.6,0,66.277,27.87,66.277,62.249S598.838,249,562.232,249H-300" transform="translate(0 26.76)" fill="none" stroke="#ff6762" strokeMiterlimit="10" strokeWidth="53.521"/>
                            </svg>
                        </Hidden>
                        <Container maxWidth="lg">
                            {this.getCardSlider()}
                        </Container>
                    </>
                    :
                    <>
                        {this.getCardSlider()}
                        {!this.props.onlyCard &&
                            <div className="card-slider-title">
                                <img src={this.getShiaGif()} style={{ width: '100%' }} alt="Just do it" />
                            </div>
                        }
                    </>
                }
                {/* <div className="card-slider-card">
                    <ArrowProxy
                        target="cards"
                        maxItem={this.props.maxItem}
                        maxItemResponsive={this.props.maxItemResponsive}
                    >
                        <CardContainer
                            type={this.props.type}
                            cards={this.props.cards}
                            displayShow={this.props.displayShow}
                            userAddress={this.props.userAddress}
                            userType={this.props.userType}
                        />
                    </ArrowProxy>
                </div> */}
                {false &&
                    <div className="card-slider-title">
                        {false && <div className={'card-slider-back dynamic-lightBlue-back dynamic-lightBlue-back-extend ' + this.props.side}/>}
                        <div className={'card-slider-back dynamic-lightBlue-back ' + this.props.side}>
                            <div className={'card-slider-title-container ' + this.props.side}>
                                <MainTitle text={this.props.title} backgroundColor="grey"/>
                                <div className="btj-subtitle dynamic-blue-font">{this.props.subTitle}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

CardSlider.defaultProps = {
    type: 'article',
    side: 'right',
    isMainTitleResponsive: true,
    mainColorType: '',
    maxItem: -1,
    maxItemResponsive: -1
}
