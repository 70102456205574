import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { useStyles } from './FunnelTableOfContents_style'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import { FormattedMessage } from 'react-intl'

const FunnelTableOfContents = (props) => {
    const classes = useStyles()
    const { steps, currentStep, handleStepChange, navigationBlocked } = props

    const handleClick = (selectedStep) => {
        handleStepChange && handleStepChange(selectedStep)
    }

    return (
		<Box className={classes.tableOfContents}>
            {steps.map(step => {
                const isSelected = step.value === currentStep
                const isValid = step.isValid
                return (
                    <Box
                        onClick={() => handleClick(step.value)}
                        className={`
                            ${classes.tocTitle} 
                            ${isSelected ? classes.selectedTocTitle : ''} 
                            ${isValid ? classes.validStep : ''}
                            ${navigationBlocked ? classes.navigationBlocked : classes.navigationAllowed}
                        `}
                        key={`funnelStep-${step.value}`}
                    >
                        <Typography variant='span'>
                            <FormattedMessage id={`job_creation_funnel.table_of_content.${step.value}`} />
                        </Typography>
                        {isValid && <IconFactory icon={'circle-check'} fontSize={isSelected ? '22px' : '18px'} />}
                    </Box>
                )
            })}
        </Box>
    )
}

export default FunnelTableOfContents
