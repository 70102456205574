/* eslint-disable react/prop-types */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image, Transformation } from 'cloudinary-react'

import { useStyles } from '../AboutFirstJobs/aboutabout_style'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import AboutUsHelmet from 'helmets/AboutUsHelmet'
import CarouselCards from 'components/CarouselCards/CarouselCards'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import RouteMap from 'assets/RouteMap'
import { MEDIUM_SIZE } from 'assets/CloudinaryURLHelper'

class AboutUs extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            innerWidth: window.innerWidth
        }
        this.teamsInfo = [
            { name: 'Florence Loumaye', role: 'Co-founder', image: 'florence-loumaye-co-founder-color' },
            { name: 'Matthieu Verstraete', role: 'Co-founder', image: 'matthieu-verstraete-co-founder-color' },
            { name: 'Ben Vanparys', role: 'Associate Partner & Business Development Manager', image: 'ben-vanparys-associate-partner-color' },
            { name: 'Héloïse Loumaye', role: 'Business Development Manager', image: 'heloise-circle' },
            { name: 'Edouard De Prez', role: 'Full Stack Developer', image: 'edouard-circle' },
            { name: 'Margaux Helsmoortel', role: 'Digital Marketing & Digital Campaign Manager', image: 'margaux-circle-2' },
            { name: 'Maximilien Romain', role: 'Full Stack Developer', image: 'maximilien-romain-2' },
            { name: 'Christophe Gillis', role: 'Full Stack Developer', image: 'chris-shoot' },
            { name: 'Klara Vansteeland', role: 'Digital Campaign Manager', image: 'klara-circle' },
            { name: 'Lisa Van Hoogenbemt', role: 'Recruitment & Selection Manager', image: 'lisa-shoot.jpg' },
            { name: 'Merel Vioen', role: 'Digital Campaign Manager', image: 'merel-shoot.jpg' },
            { name: 'Camille Polet', role: 'Digital Campaign Manager', image: 'camille-shoot.jpg' },
            { name: 'Colienne de Lovinfosse', role: 'Digital Campaign Manager', image: 'colienne.jpg' }
        ]

        this.createPageContent = this.createPageContent.bind(this)
        this.showLine = this.showLine.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.renderContentFromLanguage = this.renderContentFromLanguage.bind(this)

        window.addEventListener('resize', this.handleResize)
    }

    handleResize () {
        this.setState({ innerWidth: window.innerWidth })
    }

    showLine () {
        const { classes } = this.props
        return (
            <>
                <Hidden mdDown>
                    <svg className={classes.frame} xmlns="http://www.w3.org/2000/svg" width="1920.467" height="392.899" viewBox={`0 0 ${(this.state.innerWidth < 1920 ? this.state.innerWidth + 30 : 1920)} 392.899`}>
                        <path id="Stroke_1" data-name="Stroke 1" d={`M-1280,12.01H${(this.state.innerWidth < 1400 ? 200 : 260)}.467s53.41,3.574,53.41,72.974v41.463c0,38.776,38.4,54.885,67.085,55.33,44.628.692,200.6.164,${(this.state.innerWidth < 1920 ? (this.state.innerWidth < 1400 ? this.state.innerWidth - 630 : this.state.innerWidth - 700) : 1147.636)}-.061,91.74.487,75.284,70.038,75.679,80.485v26.525c0,50.555,39.03,61.609,55.433,62.665h1280`} transform="translate(0.942 14.75)" fill="none" stroke="#ff6762" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="43.52"/>
                    </svg>
                </Hidden>
                <Hidden lgUp >
                    <svg className={classes.frame} xmlns="http://www.w3.org/2000/svg" width="852" height="200" viewBox="0 0 852 302.521">
                        <path id="Stroke_2" data-name="Stroke 2" d="M1920,0H282.277C245.674,0,216,27.87,216,62.249S245.674,124.5,282.277,124.5H562.232c36.6,0,66.277,27.87,66.277,62.249S598.838,249,562.232,249H-800" transform="translate(0 26.76)" fill="none" stroke="#ff6762" strokeMiterlimit="10" strokeWidth="53.521"/>
                    </svg>
                </Hidden>
            </>
        )
    }

    teamBox () {
        const { classes } = this.props
        const teamInfo = this.teamsInfo.map((item, index) => {
            return (
                <Grid key={`team-box-${index}`} item xs={6} md={4} className={classes.teamBox}>
                    <Image
                        cloudName="studentbe"
                        publicId={`student-be-team/${item.image}`}
                        alt={`student.be team ${item}`}
                        width='220px'
                        height='220px'
                    >
                        <Transformation flags="progressive" fetchFormat="auto" width={MEDIUM_SIZE} quality="auto:eco" crop="fill" />
                    </Image>
                    <Typography>{item.name}</Typography>
                    {
                        item.role.indexOf('&') !== -1
                            ? <Typography className={classes.teamRole}>{item.role.split(' & ')[0]} &<br />{item.role.split(' & ')[1]}</Typography>
                            : <Typography className={classes.teamRole}>{item.role}</Typography>
                    }
                </Grid>
            )
        })
        return teamInfo
    }

    renderContentFromLanguage () {
        const { classes } = this.props

        if (RouteMap.GetLocaleFromUrl() === 'fr') {
            return (<>
                <Typography variant='h2' className={classes.helloFuture}>
                    <FormattedMessage id="landing_student.hello_future" /> <FormattedMessage id="landing_student.lets_talk" />
                </Typography>
                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}>
                            <FormattedMessage id="about_us_page.our_mission"/>
                        </Typography>
                        <Typography className={classes.ourMissionText}>
                            <FormattedMessage id="about_us_page.our_mission_description" />
                        </Typography>

                        <Typography variant="h2" className={classes.subtitle}>
                            <FormattedMessage id="about_us_page.community_strenght"/>
                        </Typography>
                        <Typography>
                            <FormattedMessage id="about_us_page.community_strenght_description" />
                        </Typography>

                        <Typography variant="h2" className={classes.subtitle}>
                            <FormattedMessage id="about_us_page.we_believe"/>
                        </Typography>
                        <Typography variant="h3" className={classes.subsubtitle}>
                            <FormattedMessage id="about_us_page.no_bs"/>
                        </Typography>
                        <Typography>
                            <FormattedMessage id="about_us_page.we_believe_description" />
                        </Typography>

                        <Typography variant="h3" className={classes.subsubtitle}>
                            <FormattedMessage id="about_us_page.innovation"/>
                        </Typography>
                        <Typography>
                            <FormattedMessage id="about_us_page.innovation_description" />
                        </Typography>

                        <Typography variant="h3" className={classes.subsubtitle}>
                            <FormattedMessage id="about_us_page.going_further"/>
                        </Typography>
                        <Typography>
                            <FormattedMessage id="about_us_page.going_further_description" />
                        </Typography>
                    </Box>
                </Container>
            </>)
        } else {
            return (<>
                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="about_us_page.intro"/></Typography>
                        <Typography><FormattedMessage id="about_us_page.description" /></Typography>

                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="about_us_page.our_reason_for_being.title"/></Typography>
                        <Box className={classes.breaklines}>
                            <Typography>
                                <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_1.title"/></span>
                                <FormattedMessage id="about_us_page.our_reason_for_being.point_1.description"/>
                            </Typography>
                            <Typography>
                                <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_2.title"/></span>
                                <FormattedMessage id="about_us_page.our_reason_for_being.point_2.description"/>
                            </Typography>
                            <Typography>
                                <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_3.title"/></span>
                                <FormattedMessage id="about_us_page.our_reason_for_being.point_3.description"/>
                            </Typography>
                            <Typography>
                                <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_4.title"/></span>
                                <FormattedMessage id="about_us_page.our_reason_for_being.point_4.description"/>
                            </Typography>
                            <Box>
                                <Typography className={classes.noMargin}>
                                    <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_5.title"/></span>
                                    <FormattedMessage id="about_us_page.our_reason_for_being.point_5.description"/>
                                </Typography>
                                <Box className={`${classes.listContainer} ${classes.listContainerMargin}`}>
                                    <Typography><FormattedMessage id="about_us_page.our_reason_for_being.point_5.list.content_1"/></Typography>
                                    <Typography><FormattedMessage id="about_us_page.our_reason_for_being.point_5.list.content_2"/></Typography>
                                    <Typography><FormattedMessage id="about_us_page.our_reason_for_being.point_5.list.content_3"/></Typography>
                                    <Typography><FormattedMessage id="about_us_page.our_reason_for_being.point_5.list.content_4"/></Typography>
                                </Box>
                            </Box>
                            <Typography>
                                <span className={classes.studentFont}><FormattedMessage id="about_us_page.our_reason_for_being.point_6.title"/></span>
                                <FormattedMessage id="about_us_page.our_reason_for_being.point_6.description"/>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
                <Box mt={5} className={classes.identityContainer}>
                    <Typography className={classes.identityTitle} variant="h6" component="p">
                        <Hidden xsDown>
                            <FormattedMessage id="landing_student.hello_future" /> <FormattedMessage id="landing_student.lets_talk" />
                        </Hidden>
                        <Hidden smUp>
                            <FormattedMessage id="landing_student.hello_future" /><br />
                            <FormattedMessage id="landing_student.lets_talk" />
                        </Hidden>
                    </Typography>
                    {this.showLine()}
                </Box>
                <Container maxWidth='md'>
                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="about_us_page.our_culture.title"/></Typography>
                        <Box mb={2} className={classes.sloganAuthor}>
                            <Typography><FormattedMessage id="about_us_page.our_culture.slogan.title"/></Typography>
                            <Typography><FormattedMessage id="about_us_page.our_culture.slogan.author"/></Typography>
                        </Box>
                        <Box className={classes.breaklines}>
                            <Typography>
                                <Typography className={classes.studentFont}><FormattedMessage id="about_us_page.our_culture.point_1.title"/></Typography>
                                <FormattedMessage id="about_us_page.our_culture.point_1.description"/>
                            </Typography>
                            <Typography>
                                <Typography className={classes.studentFont}><FormattedMessage id="about_us_page.our_culture.point_2.title"/></Typography>
                                <FormattedMessage id="about_us_page.our_culture.point_2.description"/>
                            </Typography>
                            <Typography>
                                <Typography className={classes.studentFont}><FormattedMessage id="about_us_page.our_culture.point_3.title"/></Typography>
                                <FormattedMessage id="about_us_page.our_culture.point_3.description"/>
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </>)
        }
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="about_us_page.title" />
                        </Typography>
                    </Container>
                </Box>

                {this.renderContentFromLanguage()}

                <Box my={5} py={7} className={classes.teamContainer}>
                    <Container maxWidth="md">
                        <Typography variant="h2" className={classes.slogan}>
                            <FormattedMessage id="about_us_page.slogan" />
                        </Typography>
                        <Typography variant="h2" className={`${classes.slogan} ${classes.spacingTop}`}>
                            <FormattedMessage id="about_us_page.studentbe_team" />
                        </Typography>
                        <Grid container justify="center" spacing={2}>
                            {this.teamBox()}
                        </Grid>
                    </Container>
                </Box>

                <Box>
                    <Container maxWidth="lg">
                        <Typography variant="h2" className={classes.subTitle}><FormattedMessage id="about_us_page.join_us" /></Typography>
                        <Box my={5}>
                            <CarouselCards
                                cards={this.props.lastOffers ? this.props.lastOffers : []}
                                type='student_job'
                                userType={this.props.userType}
                                id='lastOffers'
                            />
                        </Box>
                    </Container>
                </Box>
            </>
        )
    }

    render () {
        return (
            <PageWrapper user={this.props.user}>
                <AboutUsHelmet />
                {this.createPageContent()}
            </PageWrapper>
        )
    }
}
export default withStyles(useStyles)(AboutUs)
