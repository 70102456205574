import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { KOT_OWNER_API, KOT_API, USER_API } from './env'

function kotOwnerProfile (method, data) {
    return axios({
        url: KOT_OWNER_API,
        method: method === 'edit' ? 'patch' : 'post',
        data: data
    })
}
function KotOwnerKot (method, data) {
    return axios({
        url: KOT_API,
        method: method === 'edit' ? 'patch' : 'post',
        data: data
    })
}
function KotOwnerKotDelete (data) {
    return axios({
        url: KOT_API,
        method: 'delete',
        data: data
    })
}
function KotOwnerKotDuplicate (id) {
    return axios({
        url: USER_API + 'duplicate_kots?id=' + id,
        method: 'post'
    })
}

export default {
    kotOwnerProfile, KotOwnerKot, KotOwnerKotDelete, KotOwnerKotDuplicate
}
