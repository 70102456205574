/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useStyles } from './CompanyPassport_style'
import { FormattedMessage } from 'react-intl'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Sanitizer from 'assets/Sanitizer'

const CompanyPassport = (props) => {
    const { job } = props
    const classes = useStyles()
    const MAXIMUM_DESCRIPTION_LENGTH_TO_SHOW = 900

    const initExpandBoolean = job.company_description?.length < MAXIMUM_DESCRIPTION_LENGTH_TO_SHOW

    return (
        <Box className={classes.passportContainer}>
            <Accordion defaultExpanded={initExpandBoolean} className={classes.accordion} elevation={0}>
                <AccordionSummary className={classes.accordionSummary} expandIcon={<IconFactory icon='more' />}>
                    <Box className={classes.wrapBox}>
                        <Typography variant='h6' component='h2' className={classes.accordionSummaryTypography}>
                            <FormattedMessage id='job_resume.company_description' />
                        </Typography>
                        <Typography variant='h6' component='h2' className={classes.accordionSummaryTypography}>
                            {job?.company_name &&
                                <RouteMapLink
                                    showItemType='company'
                                    showItemUrl={job.company_url}
                                    className={classes.companyName}
                                    color='var(--red)'
                                >
                                    {job.company_name.toUpperCase()}
                                </RouteMapLink>
                            }
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className={classes.contentContainer}>
                    {job.company_description &&
                        <Box
                            className={classes.companyDescription}
                            dangerouslySetInnerHTML={{ __html: Sanitizer.SanitizeHtmlString(job.company_description) }}
                        />
                    }
                    <RouteMapLink
                        showItemType='company'
                        showItemUrl={job.company_url}
                        className={classes.cta}
                        color='var(--red)'
                    >
                        <FormattedMessage id='job_show.company_passport.link' />
                        {' '}{job.company_name?.toUpperCase()}
                    </RouteMapLink>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}

export default CompanyPassport
