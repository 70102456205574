export const useStyles = theme => ({
    aboutPageBanner: {
        minHeight: '66px',
        background: '#FF6762',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '2.5rem'
    },
    titlePage: {
        color: '#FFFFFF',
        padding: '1rem 0',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center'
        }
    },
    subtitle: {
        marginTop: '10px'
    },
    articleSubmitButton: {
        marginBottom: '90px',
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end'
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    newArticleContainer: {
        [theme.breakpoints.between('sm', 'md')]: {
            width: '87%',
            margin: 'auto'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    articleEditorText: {
        '& h1': {
            ...theme.typography.h1,
            marginBottom: '20px !important'
        },
        '& h2': {
            ...theme.typography.h2,
            marginBottom: '20px !important',
        }
    },
    coverImageUploadContainer: {
        marginTop: '20px',
        height: '160px',
        width: '238px',
        marginBottom: '20px'
    },
    bannerImageUploadContainer: {
        marginTop: '20px',
        height: '360px',
        width: '640px'
    },
    countColor: {
        color: 'var(--red)'
    }
})
