export const useStyles = (theme) => ({
    cardContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            marginBottom: 10
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            flexDirection: 'column'
        }
    },
    card: {
        overflow: 'unset',
        width: '100%'
    },
    maxWidth: {
        width: '100%',
    },
    cardContent: {
        [theme.breakpoints.down('md')]: {
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 100,
            display: 'flex'
        },
        '&:last-child': {
            paddingBottom: 16
        }
    },
    userContactDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    userContactDetailsItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '& > p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        }
    },
    overrideTooltip: {
        marginTop: 4
    },
    copyIcon: {
        height: 15,
        width: 'auto',
        paddingLeft: 4,
        cursor: 'pointer',
    },
    cardTypography: {
        fontSize: 14
    },
    cardHeader: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    cardName: {
        fontSize: 14,
    },
    cardInfo: {
        color: 'grey'
    },
    cardFieldStudy: {
        fontSize: 14
    },
    cardStudyLevel: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    cardPhoneNumber: {
        fontSize: 12
    },
    iconsActionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }
    },
    iconSize: {
        height: 15,
        width: 'auto',
        marginRight: 5
    }
})
