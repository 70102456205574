import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './NewsSearchBar_style'
import { Box, Typography, TextField, Chip, Avatar, MenuItem, Divider } from '@material-ui/core'

import ArticleAPI from 'api/ArticleAPI'
import CollectionHandler from 'assets/CollectionHandler'
import IconFactory from 'shared/icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import { useClickOutside } from 'hooks/useClickOutside'

import { SEARCH_BAR_MAXIMUM_ARTICLES } from '../../settings'

const NewsSearchBar = (props) => {
    const classes = useStyles()

    const [activeSearchBar, setActiveSearchBar] = useState(false)
    const [input, setInput] = useState(null)
    const [articlesSearch, setArticlesSearch] = useState([])
    const [categoriesSearch, setCategoriesSearch] = useState([])

    useEffect(() => {
        if (input === '') setActiveSearchBar(false)
    }, [input])

    const closeModal = () => {
        setActiveSearchBar(false)
    }
    const newsSearchBarRef = useClickOutside(closeModal)

    const handleInput = (event) => {
        const { value } = event.target
        setInput(value)
        if (value !== '') debouncedChangeHandlerBis(value)
    }

    const debouncedChangeHandlerBis = useCallback(
        debounce((value) => {
            searchThroughArticles(value)
        }, 500), []
    )

    const searchThroughArticles = (value) => {
        ArticleAPI.searchArticlesWithTags({
            keywords: value,
            number_of_articles: SEARCH_BAR_MAXIMUM_ARTICLES
        }).then((response) => {
            setActiveSearchBar(true)
            setArticlesSearch(response.data.articles)
            setCategoriesSearch(response.data.categories)
        })
    }

    const renderCategoryChips = () => {
        return categoriesSearch.map((category, index) => {
            return (
                <RouteMapLink articleCategory={category.name} key={`search-bar-category-chip-${index}`}>
                    <Chip
                        label={CollectionHandler.Translate('articleCategoryCollection', category.name)}
                        classes={{ root: classes.chipRoot, label: classes.chipLabel }}
                        className={classes.chip}
                    />
                </RouteMapLink>
            )
        })
    }

    const renderArticlesList = () => {
        return articlesSearch.map((article, index) => {
            return (
                <RouteMapLink showItemType='article' showItemUrl={article.url} key={`search-bar-article-item-${index}`} >
                    <MenuItem className={classes.menuItem}>
                        <Typography className={classes.articleTitle}>{article.title}</Typography>
                    </MenuItem>
                </RouteMapLink>
            )
        })
    }

    return (<Box className={classes.root}>
        <Box className={classes.searchBarContainer}>
            <Box className={`${classes.searchBar} ${activeSearchBar && classes.active}`}>
                <Box className={classes.searchBarInput}>
                    <IconFactory icon='search-regular' color='var(--dark-beige)' fontSize='18px'/>
                    <FormattedMessage id='news_searchbar.input_placeholder'>
                        {placeholder =>
                            <TextField
                                InputProps={{ classes: { input: classes.inputProps }, disableUnderline: true }}
                                placeholder={placeholder}
                                name='title'
                                onChange={handleInput}
                                fullWidth
                            />
                        }
                    </FormattedMessage>
                </Box>
            </Box>
        </Box>
        {activeSearchBar && <Box className={classes.searchBarSupplement} ref={newsSearchBarRef}>
            <Box className={classes.sectionContainer}>
                <Box className={classes.sectionTitleContainer}>
                    <Typography className={classes.sectionTitle} >Tags</Typography>
                    <Avatar className={classes.sectionCounter}>{categoriesSearch.length}</Avatar>
                </Box>
                <Box className={classes.categoryChipsContainer}>
                    {renderCategoryChips()}
                </Box>
            </Box>
            <Divider color='var(--black)' orientation="horizontal" />
            <Box className={classes.sectionContainer}>
                <Box className={classes.sectionTitleContainer}>
                    <Typography className={classes.sectionTitle}>News</Typography>
                    <Avatar className={classes.sectionCounter}>{articlesSearch.length}</Avatar>
                </Box>
                <Box className={classes.articlesContainer}>
                    {renderArticlesList()}
                </Box>
            </Box>
        </Box>}
    </Box>)
}

export default NewsSearchBar
