/* eslint-disable react/prop-types */

import React from 'react'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import AboutFirstJobsHelmet from 'helmets/AboutFirstJobsHelmet'

import FilledButton from 'buttons/FilledButton/FilledButton'
import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import CardSlider from 'components/CardSlider/CardSlider'
import LocationsListContainer from 'components/LocationsListContainer/LocationsListContainer'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import CarouselCards from 'components/CarouselCards/CarouselCards'
import { FormattedMessage } from 'react-intl'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './aboutabout_style'
import Container from '@material-ui/core/Container'

class AboutFirstJobs extends React.Component {
    constructor (props) {
        super(props)
        this.createPageContent = this.createPageContent.bind(this)
    }

    createPageContent () {
        const { classes } = this.props
        return (
            <>
                <Box className={classes.aboutPageBanner}>
                    <Container maxWidth="md">
                        <Typography variant="h1" className={classes.titlePage}>
                            <FormattedMessage id="landing_about_pages.how_to_found" />
                            <FormattedMessage id="landing_about_pages.first_job" /> ?
                        </Typography>
                    </Container>
                </Box>

                <Container maxWidth="md" className={classes.aboutPageBox}>
                    <Box className={classes.breaklines}>
                        <Typography>
                            <FormattedMessage id="landing_about_first_jobs.description" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_first_jobs.link.job_ads" /></RouteMapLink>
                            <FormattedMessage id="landing_about_first_jobs.and_1" />
                            <RouteMapLink page='first-jobs/articles' className={classes.studentFont}><FormattedMessage id="landing_about_first_jobs.link.quality_content" /></RouteMapLink>
                            <FormattedMessage id="landing_about_first_jobs.and_2" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_first_jobs.link.candidacy_advice" /></RouteMapLink>
                        </Typography>
                        <Typography><FormattedMessage id="landing_about_first_jobs.scan_integrate_1" /></Typography>
                        <Typography><FormattedMessage id="landing_about_first_jobs.scan_integrate_2" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_first_jobs.scan_integrate_3" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_first_jobs.link.scan_kazi" /></RouteMapLink>
                        </Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_first_jobs.stay_up_the_date_1" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_first_jobs.link.job_alert" /></RouteMapLink>
                            <FormattedMessage id="landing_about_first_jobs.stay_up_the_date_2" />
                        </Typography>
                    </Box>

                    <Box>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_first_jobs.search_in_city" /></Typography>
                        <Typography><FormattedMessage id="landing_about_first_jobs.select_city_where_to_work" /></Typography>
                    </Box>

                    <Box my={5}>
                        <LocationsListContainer type="first-jobs" />
                    </Box>

                    <Box>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.found_city" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.here" /></RouteMapLink>
                        </Typography>
                        <Typography variant="h2" className={classes.subtitle}><FormattedMessage id="landing_about_pages.search_job_student" /></Typography>
                        <Typography>
                            <FormattedMessage id="landing_about_pages.studentbe" />
                            <FormattedMessage id="landing_about_pages.help_you_to_found" />
                            <RouteMapLink page='student-rooms' className={classes.studentFont}><FormattedMessage id="landing_about_pages.kot" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.a" />
                            <RouteMapLink page='student-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.student_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_pages.and_even_a" />
                            <RouteMapLink page='first-jobs' className={classes.studentFont}><FormattedMessage id="landing_about_pages.first_job" /></RouteMapLink>
                            <FormattedMessage id="landing_about_internship.on_website" />
                            <RouteMapLink page='/' className={classes.studentFont}><FormattedMessage id="landing_about_pages.studentbe" /></RouteMapLink>.
                        </Typography>
                    </Box>

                    <Box className={classes.buttonOffers}>
                        <RouteMapLink page='first-jobs'>
                            <FilledButton
                                name={<FormattedMessage id="landing_about_pages.consult_offers" />}
                                color='secondary'
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
            </>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <PageWrapper user={this.props.user}>
                <AboutFirstJobsHelmet />
                {this.createPageContent()}
                <Container maxWidth="lg">
                    <Typography variant="h2" className={classes.responsiveText}><FormattedMessage id="landing_about_pages.discover_last_job_offers" /></Typography>
                    <Box my={5}>
                        <CarouselCards
                            cards={this.props.lastOffers}
                            type='first_job'
                            userType={this.props.userType}
                            id='lastOffers'
                        />
                    </Box>
                    <Box className={classes.cardTitle}>
                        <Typography variant='h2'>
                            <FormattedMessage id="landing_about_pages.advice_to_find" />
                            <FormattedMessage id="landing_about_pages.first_job" />
                        </Typography>
                        <RouteMapLink page='first-jobs/articles'>
                            <ArrowButton
                                name=<FormattedMessage id="landing_about_pages.see_all_articles" />
                            />
                        </RouteMapLink>
                    </Box>
                </Container>
                <CardSlider
                    side={'left'}
                    cards={this.props.recentArticles}
                    maxItem="3"
                    showLine
                />
            </PageWrapper>
        )
    }
}

export default withStyles(useStyles)(AboutFirstJobs)
