import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    containerOverride: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: 'inherit',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    findOutMoreContainer: {
        background: 'var(--red)',
        height: 350,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            height: 'unset'
        }
    },
    title: {
        fontSize: 40,
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            textAlign: 'center',
            lineHeight: 1.2,
            maxWidth: 280
        }
    },
    subtitle: {
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            textAlign: 'center'
        }
    },
    findOutMoreText: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '55%',
        flexShrink: 0,
        gap: 20,
        padding: 80,
        paddingLeft: 40,
        '& h2': {
            maxWidth: 400,
            lineHeight: 1.4
        },
        '& p': {
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            padding: 40,
            display: 'block',
            flexBasis: 'unset',
        }
    },
    findOutMoreImage: {
        flexBasis: '45%',
        position: 'relative',
        flexShrink: 0,
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            height: 250,
            flexBasis: 'unset',
        }
    },
    image: {
        width: '100%',
        height: 350,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: 250,
        }
    },
    findOutMoreCTA: {
    },
    button: {
        position: 'absolute',
        bottom: '10%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 220,
        minHeight: 60,
        fontSize: 14,
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        fontWeight: 500,
        textTransform: 'uppercase !important'
    },
}))
