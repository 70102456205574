/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './PromotionShow_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import ArrowButton from 'buttons/ArrowButton/ArrowButton'
import PromotionShowHelmet from 'helmets/PromotionShowHelmet'
import PromotionShowSamsung from './PromotionShowSamsung'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import URLParamsHandler from 'assets/URLParamsHandler'
import CTABanner from 'shared/components/CTABanner/CTABanner'
import { PROMOTION } from 'assets/AuthentificationHelper'

const PromotionShow = ({ promotion, user }) => {
    const classes = useStyles()

    const [openPromotionAfterLogin] = useState(URLParamsHandler.loadProcessFromURL() === 'cta_access')

    useEffect(() => {
        if (openPromotionAfterLogin) {
            window.open(promotion.link, '_blank') || window.location(promotion.link)
        }

        // CSM team adds image(s) directly in the content of Promotions via our Admin.
        // As images are included in the promotion content, we cannot style these via React.
        // We know the parent div in React, so we can inject styling into the images
        // making sure they are positioned properly.
        const element = document.querySelector('#idForCustomStyling img')
        if (element) {
            element.parentElement.classList.add('images-container')
        }
    }, [])

    return (
        <Container maxWidth='lg'>
            <PromotionShowHelmet promotion={promotion} />
            <Box my={2}>
                <RouteMapLink page='only-for-students'>
                    <ArrowButton
                        size='small'
                        direction='left'
                        name={<FormattedMessage id="promotions_for_student.back" />}
                    />
                </RouteMapLink>
            </Box>
            <Grid container spacing={0}>
                {promotion.is_samsung
                    ? <PromotionShowSamsung promotion={promotion} />
                    : <><Grid item xs={12} md={2} />
                        <Grid item xs={12} md={7} className={classes.promotionContainer}>
                            <Box className={classes.promotionTitleContainer}>
                                <Typography variant='h1' className={classes.promotionTitle}>
                                    {`${promotion.title}`}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    id='idForCustomStyling'
                                    className={classes.promotionContent}
                                    dangerouslySetInnerHTML={{ __html: promotion.content }}
                                />
                            </Box>
                            <CTABanner
                                user={user}
                                origin={PROMOTION}
                                title={<FormattedMessage id='promotions_for_student.cta.title' />}
                                buttonText={<FormattedMessage id='promotions_for_student.cta.button' />}
                                buttonIcon='badge-dollar'
                                link={promotion.link}
                                skipLogin={promotion.skip_login}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} />
                    </>}
            </Grid>
        </Container>

    )
}

export default PromotionShow
