import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyImpactContainer: {
        padding: '80px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            padding: '40px 16px',
        }
    },
    companyImpactTitle: {
        textAlign: 'center',
        fontSize: 45,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22
        }
    },
    companyImpactCardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 16
    },
    alignCenter: {
        margin: '0 auto'
    }
}))
