import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    companyFilterContainer: {
        background: 'var(--beige)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '80px 0px',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            padding: '40px 20px'
        }
    },
    companyFilterText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 325,
            textAlign: 'center'
        }
    },
    companyFilterTitle: {
        fontSize: 45,
        [theme.breakpoints.down('sm')]: {
            fontSize: 22,
            lineHeight: 1
        }
    },
    companyFilterDescription: {
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
            lineHeight: 1
        }
    },
    companyFilterCarousel: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        gap: 50,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 20
        }
    }
}))
