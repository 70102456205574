import URLHandler from './URLHandler.js'

import collections from './collections/collections.json'

/* HOW TO USE COLLECTIONHANDLER

    First import the CollectionHandler:

        import CollectionHandler from '../../../../assets/CollectionHandler.js'

    To get a certain collection, use the Get function:

        CollectionHandler.Get('nameOfCollection')

    You can find the full list of available collections in the migrate_collections.rake
    file. Feel free to add to this file.

    If you add a collection to this file, don't forget to run this command in the
    terminal, so that all collections are transferred to the collections.json file.

        rake migrate_collections

    IF you need to translate an element of a collection use the Translate function:

        CollectionHandler.Translate('nameOfCollection', 'stringOfWantedValue')

*/

const locale = URLHandler.GetLocaleFromUrl()

const Get = (collectionString) => {
    return collections[locale][collectionString]
}

const Translate = (collectionString, value) => {
    const result = Get(collectionString).find((element) => {
        return element.value === value
    })
    if (result) {
        return result.name
    }

    return '/'
}

// Transform categories from "Student Jobs" format to "student-jobs" format
const categoryToValueTransformation = (category) => {
    return category.toLowerCase()
        .replace(/[^\w\u00C0-\u017F]+/g, '-')
        .replace(/-+/g, '-') 
        .replace(/é/g, 'e') 
        .replace(/(^-|-$$)/g, ''); 
}

export default { Get, Translate, categoryToValueTransformation }
