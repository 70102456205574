/* eslint-disable react/prop-types */
import React from 'react'
import PageWrapper from '../../../../shared/wrappers/PageWrapper/PageWrapper'

import AppComponentNewKotMain from './components/AppComponentNewKotMain'

export default class NewKotMain extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor (props) {
        super(props)
    }

    render () {
        return (
            <div className="desktop-profile profile-container">
                <PageWrapper user={this.props.user}>
                    <AppComponentNewKotMain props={this.props} />
                </PageWrapper>
            </div>
        )
    }
}
