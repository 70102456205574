import React, { useState } from 'react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-viewer'

import { FormattedMessage } from 'react-intl'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'
import IconFactory from 'icons/IconFactory/IconFactory'
import { useGalleryImageFormatting } from 'hooks/useGalleryImageFormatting'
import { useWindowSize } from 'hooks/useWindowSize'

import { withStyles } from '@material-ui/core/styles'
import { useStyles } from 'components/PreviewCard/PreviewCard_style'

const CompanyPageImages = (props) => {
    const { images, classes, noTitle, onLoad } = props
    const [editProfilesLink] = useState('users/company_profiles/edit')
    const [currentImage, setCurrentImage] = useState(0)
    const [lightboxOpen, setSetLightboxOpen] = useState(false)
    const formattedImages = useGalleryImageFormatting(images, onLoad)
    const { isLargeScreen } = useWindowSize()

    const openLightbox = (e, obj) => {
        if (!isLargeScreen) return

        setSetLightboxOpen(true)
        setCurrentImage(obj.index)
    }

    const closeLightbox = () => {
        setSetLightboxOpen(false)
        setCurrentImage(0)
    }

    return (
        <Box>
            {!noTitle &&
                <Grid container className={`${classes.gridContainer} ${classes.noFlexWrap}`}>
                    <Grid item xs={12} md={4} className={`${classes.gridItem} ${classes.previewTitle}`}>
                        <Typography variant='h2' className={classes.subTitle}>
                            <FormattedMessage id="company_profile.company_page_images.title" />
                        </Typography>
                    </Grid>
                </Grid>
            }
            {images.length > 0
                ? <Box>
                    <Gallery photos={formattedImages} onClick={openLightbox} />
                    {isLargeScreen && <Lightbox
                        visible={lightboxOpen}
                        images={formattedImages}
                        onMaskClick={closeLightbox}
                        onClose={closeLightbox}
                        activeIndex={currentImage}
                        disableMouseZoom={true}
                        drag={false}
                        zoomable={false}
                        rotatable={false}
                        scalable={false}
                        noImgDetails
                    />}
                </Box>
                : <div className="empty-box-container">
                    <RouteMapLink className="box-shadow-card-empty" page={editProfilesLink}>
                        <IconFactory className="icon" icon='edit' />
                    </RouteMapLink>
                </div>
            }
        </Box>
    )
}
export default withStyles(useStyles)(CompanyPageImages)
