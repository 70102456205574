import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './AlertAd_style'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'

const AlertAd = (props) => {
    const classes = useStyles()
    const { openAlertJobAd, type } = props

    return (
        <Box className={classes.alertAdContainer}>
            <Box className={classes.alertAdText}>
                <Typography
                    variant='h3'
                    component='span'
                    className={classes.alertAdTitle}
                    id='uniqArticleTitle'
                >
                    <FormattedMessage id='job_alert.alert_ad_title' />
                </Typography>
                <Box className={classes.alertAdDescription}>
                    <FormattedMessage id='job_alert.alert_ad_description' />
                </Box>
            </Box>

            <FilledButton 
                newStyle
                color="initial"
                className={classes.alertAdButton}
                onClick={openAlertJobAd}
                name={<FormattedMessage id={`item_offers.${type}.save_my_search`} />}
                startIcon={<IconFactory icon='mail' />}
            />
        </Box>
    )
}

export default AlertAd
