import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { useStyles } from './CreateAlertPopup_style'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { Hidden } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import FilledButton from 'buttons/FilledButton/FilledButton'

const CreateAlertPopupView = ({
    filters,
    jobType,
    actionPopup,
    getPopupContent
}) => {
    const classes = useStyles()

    useEffect(() => {
        getPopupContent({
            popupTitle: renderTitle(),
            noAction: true
        })
    }, [])

    const renderTitle = () => {
        return (
            <Box className={classes.boxTitle}>
                <FormattedMessage id={'create_alert_popup.title'} />
            </Box>
        )
    }

    const renderFilterTags = () => {
        let numberOfFilters
        let filterTags
        switch (jobType) {
        case 'first_job':
            numberOfFilters =
                filters.studyDomain.length +
                filters.location.length +
                filters.companySize.length +
                filters.sector.length +
                (filters.quickFilter === "" ? 0 : 1)


            filterTags = (<>
                { renderQuickFilterTag(filters.quickFilter) }
                { renderTag(filters.studyDomain) }
                { renderTag(filters.location) }
                { renderTag(filters.companySize) }
                { renderTag(filters.sector) }
            </>)
            break
        case 'internship':
            numberOfFilters =
                filters.studyDomain.length +
                filters.location.length +
                filters.companySize.length +
                (filters.quickFilter === "" ? 0 : 1)

            filterTags = (<>
                { renderQuickFilterTag(filters.quickFilter) }
                { renderTag(filters.studyDomain) }
                { renderTag(filters.location) }
                { renderTag(filters.companySize) }
            </>)
            break
        case 'student_job':
            numberOfFilters =
                filters.studyDomain.length +
                filters.location.length +
                (filters.quickFilter === "" ? 0 : 1)

            filterTags = (<>
                { renderQuickFilterTag(filters.quickFilter) }
                { renderTag(filters.studyDomain) }
                { renderTag(filters.jobSchedule) }
                { renderTag(filters.location) }
            </>)
            break
        }
        if (numberOfFilters === 0) {
            filterTags = <Chip
                key={jobType}
                label={<FormattedMessage id={`filter_tags.default.${jobType}`} />}
                className={classes.tagFilter}
            />
        }

        return (
            <Box className={`${classes.boxTags} ${numberOfFilters > 3 && classes.flewColumn}`}>
                {filterTags}
            </Box>
        )
    }

    const renderTag = (tagsList) => {
        return <Box>
            { tagsList.map((tag) => {
                return <Chip
                    key={tag.value}
                    label={tag.name}
                    className={classes.tagFilter}
                />
            })}
        </Box>
    }

    const renderQuickFilterTag = (quickFilter) => {
        if (quickFilter === "") return null

        return <Chip
            key={quickFilter}
            label={<FormattedMessage id={`quick_filters.${quickFilter}`} />}
            className={classes.tagFilter}
        />
    }

    return (
        <Box>
            <Box className={classes.boxCenter}>
                <Typography>
                    <FormattedMessage id={`create_alert_popup.${jobType}.body`} />
                </Typography>
            </Box>
            <Box>
                { renderFilterTags() }
            </Box>
            <Box className={classes.boxCenter}>
                <FilledButton
                    name={<FormattedMessage id={'item_offers.create_my_job_alert'} />}
                    startIcon={ <IconFactory icon='mail'/> }
                    color='secondary'
                    onClick={actionPopup}
                />
            </Box>
            <Hidden smDown>
                <Box className={classes.boxCenter}>
                    <Typography
                        variant='body2'
                        className={classes.typographyWarning}
                    >
                        <FormattedMessage id={'create_alert_popup.warning'} />
                    </Typography>
                </Box>
            </Hidden>
        </Box>
    )
}

export default CreateAlertPopupView
