import { grey } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

export const THEME = createTheme({
    typography: {
        fontFamily: "'GintoNormal-Regular', sans-serif !important",
        color: grey['900'],
        a: {
            textDecoration: 'none'
        },
        h1: { // SHOWS TITLE
            fontFamily: "'GintoNormal-Bold', sans-serif !important",
            color: grey['900'],
            [breakpoints.down('md')]: {
                fontSize: '24px'
            },
            [breakpoints.up('md')]: {
                fontSize: '22px'
            }
        },
        h2: { // SECTIONS
            fontFamily: "'GintoNormal-Medium', sans-serif !important",
            color: grey['900'],
            [breakpoints.down('md')]: {
                fontSize: '20px !important'
            },
            [breakpoints.up('md')]: {
                fontSize: '18px !important'
            }
        },
        h3: { // CARDS TITLE
            fontFamily: "'GintoNormal-Medium', sans-serif  !important",
            color: grey['900'],
            lineHeight: 1.4,
            [breakpoints.down('md')]: {
                fontSize: '16px !important'
            },
            [breakpoints.up('md')]: {
                fontSize: '16px !important'
            }
        },
        h4: { // SMALL TITLE / Field TITLE
            fontFamily: "'GintoNormal-Medium', sans-serif !important",
            color: grey['900'],
            [breakpoints.down('md')]: {
                fontSize: '14px !important'
            },
            [breakpoints.up('md')]: {
                fontSize: '14px !important'
            }
        },
        h5: { // SMALLEST TITLE
            fontFamily: "'GintoNormal-Medium', sans-serif !important",
            [breakpoints.down('md')]: {
                fontSize: '12px'
            },
            [breakpoints.up('md')]: {
                fontSize: '12px'
            }
        },
        h6: { // SLOGAN
            fontFamily: "'GintoNord-Bold', sans-serif !important",
            [breakpoints.down('md')]: {
                fontSize: '16px'
            },
            [breakpoints.up('md')]: {
                fontSize: '16px'
            }
        },
        body1: { // REGULAR TEXT
            fontFamily: "'GintoNormal-Regular', sans-serif !important",
            fontSize: '14px',
            color: 'var(--black)'
            // margin: 'unset !important'
        },
        subtitle1: { // ADDITIONAL INFOS TEXT
            fontFamily: "'GintoNormal-Light', sans-serif !important",
            fontSize: '0.875rem !important',
            color: 'var(--dark-grey)',
            lineHeight: 1.5
        },
        body2: { // SMALL REGULAR TEXT
            fontFamily: 'GintoNormal-Regular, sans-serif !important',
            fontSize: '0.875rem'
            // margin: 'unset !important'
        },
        subtitle2: { // NOT USED YET
            fontFamily: "'GintoNormal-Light', sans-serif !important",
            fontSize: '0.875rem'
        }
        //  'caption'
        // | 'button'
        // | 'overline'
        // | 'srOnly'
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'testingOne' },
                    styles: {
                        fontFamily: "'GintoNormal-Bold', sans-serif !important",
                        fontSize: '30px !important'
                    }
                }
            ]
        }
    },
    // URL DOC
    // https://next.material-ui.com/customization/components/#adding-new-component-variants
    palette: {
        background: {
            default: 'white'
        },
        primary: {
            light: grey['700'] + ' !important',
            main: grey['900'] + ' !important',
            dark: grey['900'] + ' !important',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#FF6762 !important',
            contrastText: '#FFFFFF'
        },
        success: {
            main: '#76DA77 !important'
        },
        error: {
            main: '#f44336 !important',
            dark: '#d32f2f !important'
        },
        info: {
            main: '#343434 !important'
        }
    },

    // MUI THEME
    // DOC : https://v3.material-ui.com/api/mui-theme-provider/
    overrides: {
        MuiSelect: {
            select: {
                '&:focus': {
                    background: 'inherit !important'
                }
            }
        },
        MuiContainer: {
            root: {
                [breakpoints.up('lg')]: {
                    paddingLeft: 4,
                    paddingRight: 4
                },
            }
        }
    }
})
