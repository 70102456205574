/* eslint-disable no-multi-spaces */
/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import EmployerJobIndexView from './EmployerJobIndexView'
import { FormattedMessage } from 'react-intl'
import URLParamsHandler from 'assets/URLParamsHandler'
import EmployerJobIndexHelper from './EmployerJobIndexHelper'
import AnalyticsHelper from 'assets/AnalyticsHelper'
import CompanyJobAPI from 'api/CompanyJobAPI'

const EmployerJobIndex = (props) => {
    const { user, company, lastTransaction } = props
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [itemCount, setItemCount] = useState(null)
    const [pageCount, setPageCount] = useState(1)
    const [initializePage, setInitializePage] = useState(true)
    const [filters, setFilters] = useState({ title: '', type: '', page: 1, orderDirection: 0, orderKey: 'applied_date' })
    const [checkedItems, setCheckedItems] = useState([])
    const [checkAll, setCheckAll] = useState(false)
    const [showDeleteCompanyJobsModal, setShowDeleteCompanyJobsModal] = useState(false)
    const [showActivateCompanyJobModal, setShowActivateCompanyJobModal] = useState(false)
    const [showBoostCompanyJobModal, setShowBoostCompanyJobModal] = useState(false)
    const [processShown, setProcessShown] = useState(false)
    const [jobToActivate, setJobToActivate] = useState({})
    const [jobToBoost, setJobToBoost] = useState({})
    const [creditCount, setCreditCount] = useState(user.number_of_credits)
    const [feedback, setFeedback] = useState({ open: false, severity: 'success', message: null })
    const [jobPostCongratulation] = useState(!!URLParamsHandler.parseURLParams().session_id)

    useEffect(() => {
        if (jobPostCongratulation) {
            AnalyticsHelper.sendGTMEvent(
                'purchase',
                AnalyticsHelper.constructPurchaseAnalyticsAttributes(user, company, lastTransaction)
            )
            getCompanyJobs()
        } else {
            const urlFilters = URLParamsHandler.loadUrlFilters('company_job')
            if (Object.keys(urlFilters).length === 0) {
                getCompanyJobs()
            } else {
                setFilters(urlFilters)
            }
            setInitializePage(false)
        }
    }, [])

    useEffect(() => {
        if (!initializePage) {
            setLoading(true)
            const delayFilter = setTimeout(() => {
                getCompanyJobs()
            }, 1200)

            return () => clearTimeout(delayFilter)
        }
    }, [filters.title])

    useEffect(() => {
        if (!initializePage) {
            setLoading(true)
            getCompanyJobs()
        }
    }, [filters])

    const getCompanyJobs = () => {
        const data = {
            page: filters.page,
            order_key: filters.orderKey,
            order_direction: filters.orderDirection,
            title: filters.title,
            type: filters.type
        }
        CompanyJobAPI.GetCompanyJobs(data).then((response) => {
            setItemCount(response.data.company_job_count)
            setItems(response.data.company_jobs)
            setPageCount(response.data.page_count)
            setLoading(false)
            if (!processShown && URLParamsHandler.loadProcessFromURL() === 'buy_credits_and_boost_job') {
                const newJobToBoost = getCompanyJobFromUrl(response.data.company_jobs)
                if (EmployerJobIndexHelper.isJobBoostable(newJobToBoost)) {
                    setJobToBoost(newJobToBoost)
                    setShowBoostCompanyJobModal(true)
                }
                setProcessShown(true)
            } else if (!processShown && URLParamsHandler.loadProcessFromURL() === 'buy_credits_and_activate_job') {
                const newJobToActivate = getCompanyJobFromUrl(response.data.company_jobs)
                if (!newJobToActivate.active) {
                    setJobToActivate(newJobToActivate)
                    setShowActivateCompanyJobModal(true)
                }
                setProcessShown(true)
            } else {
                URLParamsHandler.createURLFilters('company_job', filters)
            }
        })
    }

    const getCompanyJobFromUrl = (items) => {
        const jobId = parseInt(URLParamsHandler.parseURLParams(filters).job_id)
        const jobType = URLParamsHandler.parseURLParams(filters).job_type
        const job = items.find((item) => {
            return (item.id === jobId && item.type === jobType)
        })

        return job
    }

    const handlePage = (newPage) => setFilters({ ...filters, page: newPage })
    const handleOrderDirection = (newOrderDirection) => setFilters({ ...filters, orderDirection: newOrderDirection })
    const handleOrder = (newOrderKey, newOrderDirection) => {
        setFilters({ ...filters, page: 1, orderDirection: newOrderDirection, orderKey: newOrderKey })
    }

    const checkAllJobs = () => {
        if (!checkAll) {
            setCheckedItems(items.filter(item => item))
        } else {
            setCheckedItems([])
        }
        setCheckAll(!checkAll)
    }

    const handleJobCheck = (job) => {
        if (checkedItems.includes(job)) {
            setCheckedItems(checkedItems.filter(item => item.id !== job.id))
        } else {
            setCheckedItems([...checkedItems, job])
        }
    }

    const removeJobsFromItems = (jobsToDelete) => {
        let newItems = items
        jobsToDelete.forEach((jobToDelete) => {
            newItems = newItems.filter(item => !(item.id === jobToDelete.id))
        })
        setItems(newItems)
    }

    const removeCompanyJob = (job) => {
        handleJobCheck(job)
        setShowDeleteCompanyJobsModal(true)
    }

    const toggleActive = (job) => {
        setJobToActivate(job)
        setShowActivateCompanyJobModal(true)
    }

    const toggleBoost = (job) => {
        setJobToBoost(job)
        setShowBoostCompanyJobModal(true)
    }

    const toggleActiveJobStatus = (type, id, status, newCreditCount, activeUntil) => {
        const newItems = items.map((item) => {
            if (item.type === type && item.id === id) {
                item.active = status
                item.active_until = activeUntil
                item.latest_boost_or_activation_or_sort_date = new Date()
            }
            return item
        })
        setItems(newItems)
        setCreditCount(newCreditCount)
        setFeedback({
            open: true,
            severity: 'success',
            message: <FormattedMessage id="active_popup.success" />
        })
    }

    const setSortBy = (newKey) => {
        if (filters.orderKey !== newKey) {
            handleOrder(newKey, 0)
        } else {
            handleOrderDirection(filters.orderDirection === 1 ? 0 : 1)
        }
    }

    return (
        <EmployerJobIndexView
            user={user}
            company={company}
            loading={loading}
            getCompanyJobs={getCompanyJobs}
            items={items}
            filters={filters}
            setFilters={setFilters}
            page={filters.page}
            setPage={handlePage}
            pageCount={pageCount}
            setSortBy={setSortBy}
            removeCompanyJob={removeCompanyJob}
            toggleActive={toggleActive}
            toggleBoost={toggleBoost}
            checkAll={checkAll}
            checkAllJobs={checkAllJobs}
            handleJobCheck={handleJobCheck}
            checkedItems={checkedItems}
            removeJobsFromItems={removeJobsFromItems}
            showDeleteCompanyJobsModal={showDeleteCompanyJobsModal}
            setShowDeleteCompanyJobsModal={setShowDeleteCompanyJobsModal}
            showActivateCompanyJobModal={showActivateCompanyJobModal}
            setShowActivateCompanyJobModal={setShowActivateCompanyJobModal}
            showBoostCompanyJobModal={showBoostCompanyJobModal}
            setShowBoostCompanyJobModal={setShowBoostCompanyJobModal}
            jobToActivate={jobToActivate}
            jobToBoost={jobToBoost}
            toggleActiveJobStatus={toggleActiveJobStatus}
            creditCount={creditCount}
            setCreditCount={setCreditCount}
            orderKey={filters.orderKey}
            orderDirection={filters.orderDirection}
            setFeedback={setFeedback}
            feedback={feedback}
            itemCount={itemCount}
        />
    )
}

export default EmployerJobIndex
