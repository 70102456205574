import React from 'react'
import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import { FormattedMessage } from 'react-intl'

import css from './companyarticles.scss'
import Container from '@material-ui/core/Container'

import CardContainer from 'containers/CardContainer/CardContainer'
import FilledButton from 'buttons/FilledButton/FilledButton'
import PageWrapper from 'wrappers/PageWrapper/PageWrapper'
import PopupFactory from 'modals/Popup/PopupFactory'
import SharedTabs from 'components/SharedTabs/SharedTabs'

export default class CompanyArticles extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            showPopupDelete: false,
            articleToDelete: null
        }

        this.deleteArticle = this.deleteArticle.bind(this)
        this.deletePopup = this.deletePopup.bind(this)
    }

    deletePopup (article) {
        console.log(this)
        this.setState({ showPopupDelete: true, articleToDelete: article })
    }

    deleteArticle () {
        axios({
            url: '/api/v1/articles/' + this.state.articleToDelete.id,
            method: 'delete'
        })
            .then(function (response) {
                window.location.reload()
            })
            .catch(function (error) {
                alert(error.response.data.error)
                Bugsnag.notify(error)
            })
    }

    render () {
        return (
            <div className="company-articles-container">
                <PageWrapper user={this.props.user}>
                    {this.state.showPopupDelete &&
                        <PopupFactory
                            type="delete-offer"
                            itemType={this.props.type}
                            itemsLen={1}
                            popupExit={() => this.setState({ showPopupDelete: false })}
                            actionPopup={this.deleteArticle}
                            popupTitle={<FormattedMessage values={{ count: 1 }} id="delete_offer_popup.article.title.one" />}
                            openShare={this.state.showPopupDelete}
                        />
                    }
                    <SharedTabs user={this.props.user} currentTab='users/articles' />
                    <Container maxWidth='lg'>
                        <div className="banner-container">
                            { this.props.user.create_articles &&
                                <div className="button-container">
                                    <FilledButton
                                        name={<FormattedMessage id="company_profile.publish_new_article" />}
                                        color="secondary"
                                        onClick={() => { window.location.href = this.props.newArticleUrl }}
                                    />
                                </div>
                            }
                        </div>
                        <CardContainer
                            key={'cardcontainer_' + 0}
                            type={this.props.type}
                            cards={this.props.items}
                            articleOwner={this.props.user.create_articles}
                            remove={this.deletePopup}
                            noCardsText={<FormattedMessage id="company_profile.no_cards" />}
                            maxLength={3}
                        />
                    </Container>
                </PageWrapper>
            </div>
        )
    }
}
