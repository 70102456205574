import React from 'react'

import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Typography
} from '@material-ui/core'

import ExperienceProfile from './ExperienceProfile/ExperienceProfile'
import InfoProfile from './InfosProfile/InfoProfile'
import LanguageProfile from './LanguageProfile/LanguageProfile'
import UploadAvatarProfile from './UploadAvatarProfile/UploadAvatarProfile'

import FeedbackSnackbar from 'components/FeedbackSnackbar/FeedbackSnackbar'
import FilledButton from 'buttons/FilledButton/FilledButton'
import IconFactory from 'icons/IconFactory/IconFactory'
import StudentJobSearchStatus from 'shared/components/StudentJobSearchStatus/StudentJobSearchStatus'
import ProgressCircle from 'components/ProgressCircle/ProgressCircle'
import UploadDocument from 'containers/UploadDocument/UploadDocument'

import { useStyles } from './StudentProfile_style'
import { FormattedMessage } from 'react-intl'

const StudentProfilePageView = (props) => {
    const {
        blankLanguage,
        closeFeedback,
        disabledSave,
        feedback,
        handleDateBlur,
        handleDateChange,
        handleSubmit,
        imageArray,
        imageAvatar,
        loading,
        pictureId,
        user,
        profile,
        selectedDate,
        setExperienceIsUpdated,
        setImageArray,
        setImageAvatar,
        setStudent,
        setStudentFormation,
        setStudentLanguage,
        student,
        studentFormation,
        studentLanguage,
        userLanguages
    } = props

    const classes = useStyles()

    return (
        <Container maxWidth='lg'>
            <FeedbackSnackbar
                open={feedback.open}
                message={feedback.message}
                closeFeedback={closeFeedback}
            />
            {loading
                ? <ProgressCircle/>
                : <Box mb={5}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className="my-items-selection-container">
                                    <Box mt={3} mb={3} display='flex' justifyContent='flex-end'>
                                        <Box component={Grid} container item xs={12} sm={6} alignItems='center' justify='flex-end' >
                                            <FilledButton
                                                type='submit'
                                                endIcon={<IconFactory icon='save' />}
                                                color='secondary'
                                                name={<FormattedMessage id="landing_student_profile_page.save_change" />}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2} >
                                    <Grid item xs={12} sm={12} md={4}>
                                        <Grid container spacing={2}>
                                            {/* START AVATAR + JOB SEARCH STATUS */}
                                            <Grid item xs={12}>
                                                <Card className={classes.CardBoxShadow}>
                                                    <CardMedia>
                                                        <Box className={classes.profileMainInfoContainer}>
                                                            <Box className={classes.profileMainBackground}>
                                                                <Box className={classes.avatarContainer}>
                                                                    <UploadAvatarProfile
                                                                        user={user}
                                                                        pictureId={pictureId}
                                                                        profileId={profile.id}
                                                                        imageAvatar={imageAvatar}
                                                                        setImageAvatar={setImageAvatar}
                                                                    />
                                                                </Box>
                                                                <Grid container className={classes.profileNameContainer}>
                                                                    <Grid item xs={12} className={classes.nameMargin}>
                                                                        <Typography variant='h6' component='div' className={`${classes.profileText} ${classes.firstName}`}>
                                                                            {student.first_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant='h6' component='div' className={`${classes.profileText} ${classes.lastName}`}>
                                                                            {student.last_name}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </CardMedia>

                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant='h2' component='div'>
                                                                    <FormattedMessage id="landing_student_profile_page.my_status"/>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <StudentJobSearchStatus user={user} context='ProfileEdit' />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            {/* START PROFILE INFO */}
                                            <Grid item xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <InfoProfile
                                                                    setStudent={setStudent}
                                                                    student={student}
                                                                    selectedDate={selectedDate}
                                                                    handleDateChange={handleDateChange}
                                                                    handleDateBlur={handleDateBlur}
                                                                    disabledSave={disabledSave}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            {/* START DOCUMENTS */}
                                            <Grid item xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant='h2' component='div'>
                                                                    <FormattedMessage id="landing_student_profile_page.my_documents" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant={'caption'} component='div'>
                                                                    <FormattedMessage id="landing_student_profile_page.download_documents" />
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <UploadDocument
                                                                    page='studentProfile'
                                                                    profileId={profile.id}
                                                                    imageArray={imageArray}
                                                                    setImageArray={setImageArray}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                            {/* END DOCUMENTS */ }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <ExperienceProfile
                                                    disabledSave={disabledSave}
                                                    setStudentFormation={setStudentFormation}
                                                    studentFormation={studentFormation}
                                                    setExperienceIsUpdated={setExperienceIsUpdated}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LanguageProfile
                                                    blankLanguage={blankLanguage}
                                                    disabledSave={disabledSave}
                                                    setStudent={setStudent}
                                                    setStudentLanguage={setStudentLanguage}
                                                    student={student}
                                                    studentLanguage={studentLanguage}
                                                    userLanguages={userLanguages}
                                                />
                                            </Grid>
                                            <Box component={Grid} container item xs={12} alignItems='center' justify='flex-end' >
                                                <FilledButton
                                                    type='submit'
                                                    endIcon={<IconFactory icon='save' />}
                                                    color='secondary'
                                                    name={<FormattedMessage id="landing_student_profile_page.save_change" />}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            }
        </Container>
    )
}

export default StudentProfilePageView
