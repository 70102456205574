import React from 'react'

import { useStyles } from './MyMessagesIcon_style'
import { useStyles as useDarkModeStyles } from './MyMessageIconDarkMode_style'
import { Badge, Button } from '@material-ui/core'

import IconFactory from 'icons/IconFactory/IconFactory'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MyMessagesIcon = (props) => {
    const { user, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    return (
        <RouteMapLink page='messages' key='button-connected-user-navbar-link-to-messages'>
            <Button className={classes.myMessagesButton}>
                <Badge
                    badgeContent={user.unseen_messages > 20 ? '20+' : user.unseen_messages}
                    color="error"
                    overlap="circle"
                >
                    <IconFactory icon='message' className={classes.myMessagesIcon}/>
                </Badge>
            </Button>
        </RouteMapLink>
    )
}

export default MyMessagesIcon
