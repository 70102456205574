/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { useStyles } from './ApplyButton_style'
import Box from '@material-ui/core/Box'
import FilledButton from 'buttons/FilledButton/FilledButton'
import { ApplyProcessContext } from 'shared/contexts/ApplyProcessContext'

const ApplyButton = (props) => {
    const { job, user, newStyleWhite, className, flexColumn } = props
    const classes = useStyles()
    const applyProcessContext = useContext(ApplyProcessContext)

    return (
        <>
            <Box className={`${classes.applyButtonBox} ${flexColumn ? classes.flexColumn : ''}`}>
                {applyProcessContext.studentHasApplied()
                    ? <FilledButton
                        newStyle
                        newStyleWhite={newStyleWhite}
                        className={classes.alreadyApplied}
                        color={'haha'}
                        name={<FormattedMessage id='apply_button.already_applied' />}
                        forceWrap
                    />
                    : <FilledButton
                        newStyle
                        newStyleWhite={newStyleWhite}
                        className={className}
                        color={applyProcessContext.applyDisabled() ? 'default' : 'secondary'}
                        onClick={applyProcessContext.onClick()}
                        disabled={applyProcessContext.applyDisabled()}
                        name={<FormattedMessage id={job.application_url ? 'transl_internship.postulate_on_company_website' : 'transl_internship.postulate_button'}/>}
                        forceWrap
                    />
                }
                {(user?.admin || job.current_user_is_job_owner) &&
                    <FilledButton
                        newStyle
                        newStyleWhite={newStyleWhite}
                        className={className}
                        color='primary'
                        onClick={applyProcessContext.redirectToEditJob}
                        name={<FormattedMessage id='landing_card_page.job_show.tooltip.edit_offer'/>}
                        forceWrap
                    />
                }
            </Box>
        </>
    )
}

export default ApplyButton
