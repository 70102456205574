import React from 'react'
import { InputAdornment } from '@material-ui/core'
import { useStyles } from '../../ExperienceProfile_style'
import IconFactory from 'icons/IconFactory/IconFactory'

const EndAdornment = (props) => {
    const classes = useStyles()

    return (
        <InputAdornment position='end' className={classes.adornmentContainer}>
            <IconFactory icon='search' fontSize='1rem' />
        </InputAdornment>
    )
}

export default EndAdornment
