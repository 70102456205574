import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    title: {
        width: 'calc(300px - 32px)',
        fontFamily: "'GintoNormal-Medium', sans-serif  !important",
        fontSize: '12px !important'
    },
    text: {
        width: 'calc(300px - 32px)',
        fontSize: '12px !important'
    },
    buttonBox: {
        marginTop: 16
    }
}))
