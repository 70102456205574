import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    link: {
        '&:hover': {
            textDecoration: 'none'
        }
    },
    container: {
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        height: 440,
        minWidth: 315,
        maxWidth: 360,
        backgroundColor: 'white',
        boxShadow: 'var(--shadow-paper)',
        borderRadius: 19,
        [theme.breakpoints.down('sm')]: {
            height: 400,
            maxWidth: 300
        }
    },
    cardImageContainer: {
        height: '40%'
    },
    cardMedia: {
        height: '100%',
        width: '100%',
        borderRadius: '19px 19px 0 0',
        objectFit: 'cover'
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        padding: '16px 32px',
        gap: 16,
        [theme.breakpoints.down('sm')]: {
            padding: 16
        }
    },
    cardText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16
    },
    cardTitleContainer: {
        display: 'flex'
    },
    cardDescriptionContainer: {
        display: 'flex',
        textAlign: 'center'
    },
    cardTitle: {
        fontSize: 26, // 40
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: 55,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20 // 30
        }
    },
    cardDescription: {
        fontSize: 14, // 16
        [theme.breakpoints.down('sm')]: {
            fontSize: 12 // 14
        }
    },
    bottomCardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16
    },
    divider: {
        width: '100%',
        backgroundColor: 'var(--grey)',
        height: 2
    },
    jobAvailableContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: 8
    },
    jobAvailable: {
        display: 'flex',
        flexDirection: 'column'
    },
    jobAvailableText: {
        fontSize: 10,
        textTransform: 'lowercase',
        textAlign: 'center'
    }
}))
