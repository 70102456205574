import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme, props) => ({
    bannerImage: {
        zIndex: -1,
        position: 'absolute',
        width: '100%',
        top: 0,
        backgroundColor: 'black',
        [theme.breakpoints.up('md')]: {
            height: 450
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 350
        }
    },
    headContainer: {
        backgroundColor: 'transparent',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            height: 385
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 235
        }
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            gap: 36,
            justifyContent: 'center'
        },
        [theme.breakpoints.down('sm')]: {
            gap: 24,
            paddingTop: 40
        }
    },
    breadcrumbsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: 8
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px'
        }
    },
    breadcrumbs: {
        fontFamily: "'GintoNormal-Medium', sans-serif !important",
        fontSize: '14px !important',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            padding: '0 24px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2
        }
    },
    breadcrumbRoot: {
        color: '#999999'
    },
    titleText: {
        fontFamily: "'GintoNord-Bold', sans-serif !important",
        color: 'white',
        [theme.breakpoints.up('md')]: {
            fontSize: '36px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px !important'
        }
    },
    categoryList: {
        width: 850,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: 12,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            rowGap: 7,
            columnGap: 8
        }
    },

    chipRoot: {
        height: 'fit-content',
        width: 'fit-content'
    },
    chipLabel: {
        padding: 0,
        lineHeight: 1.4,
        [theme.breakpoints.up('md')]: {
            fontSize: 16
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    chip: {
        backgroundColor: '#343332',
        color: 'white',
        borderRadius: 6,
        fontSize: 12,
        padding: '8px 12px',
        fontFamily: 'GintoNormal-Medium, sans-serif !important',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#2C2A28'
        },
        [theme.breakpoints.up('md')]: {
            height: 37
        },
        [theme.breakpoints.down('sm')]: {
            height: 34
        }
    },

    searchContainer: {
        [theme.breakpoints.up('md')]: {
            position: 'relative',
            top: -45
        }
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            gap: 64,
            marginBottom: 88
        },
        [theme.breakpoints.down('md')]: {
            gap: 40,
            marginTop: 40
        }
    },
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        [theme.breakpoints.up('lg')]: {
            marginTop: 14
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 12px'
        }
    },
    articlesContainerTitle: {
        fontFamily: 'GintoNord-Bold, sans-serif !important',
        '& .articlesSpan': {
            color: 'var(--red)'
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '24px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px !important'
        }
    },
    categoryArticlesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        columnGap: 20,
        [theme.breakpoints.up('md')]: {
            rowGap: 64
        },
        [theme.breakpoints.down('sm')]: {
            rowGap: 32
        }
    },
    CTAContainer: {
        [theme.breakpoints.up('md')]: {
            padding: '0 20px'
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px',
            marginBottom: 20
        }
    }
}))
