/* eslint-disable react/prop-types */
import React from 'react'
import Box from '@material-ui/core/Box'
import IconFactory from 'icons/IconFactory/IconFactory'
import TextField from '@material-ui/core/TextField'
import { useStyles } from 'components/Searchbar/Searchbar_v2_style'
import { FormattedMessage } from 'react-intl'

const TextFilterView = (props) => {
    const { handleInputChange, keyPress, filters, filterTextId, minWidth, modal } = props
    const classes = useStyles()

    return (
        <Box className={`${classes.valueContainer} ${classes.baseInput} ${classes.textFilterContainer} ${modal ? classes.inputBackground : ''}`}>
            <Box className={classes.iconContainer}>
                <IconFactory icon='search-regular' color='var(--dark-beige)' fontSize='15px'/>
            </Box>
            <FormattedMessage id={filterTextId}>
                {placeholder =>
                    <TextField
                        InputProps={{ classes: { input: `${classes.inputProps} ${classes.textFilterInput}` }, disableUnderline: true }}
                        placeholder={placeholder}
                        value={filters.title}
                        name='title'
                        onChange={handleInputChange}
                        fullWidth
                        onKeyPress={keyPress}
                    />
                }
            </FormattedMessage>
        </Box>
    )
}

export default TextFilterView
