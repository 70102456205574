import React from 'react'
import Box from '@material-ui/core/Box'
import { useStyles } from '../FiltersModals_style'
import MoreFilterSectionWrapper from '../MoreFilterSectionWrapper/MoreFilterSectionWrapper'
import TextFilter from 'components/Searchbar/shared/TextFilter/TextFilter'
import BooleanFilter from 'components/Searchbar/shared/BooleanFilter/BooleanFilter'
import CityFilter from 'components/Searchbar/shared/CityFilter/CityFilter'
import CheckboxList from 'components/Searchbar/shared/CheckboxList/CheckboxList'
import TagMultiSelect from 'shared/components/TagMultiSelect/TagMultiSelect'
import CollectionHandler from 'assets/CollectionHandler.js'
import FiltersModalWrapper from 'modals/FilterModals/FiltersModalWrapper'
import MultipleSelectFilter from 'components/Searchbar/shared/MultipleSelectFilter/MultipleSelectFilter'

const FirstJobFiltersModalView = (props) => {
    const classes = useStyles()
    const { reset, showModal, closeModal, offerCount, debouncedFilterChange, handleFilterChange, filters, inputField, setInputField, setTimeoutInput, handleInputSearchCity, searchCities, citiesInput, setSearchCities, setCitiesInput, setFilters, setFiltersPerType } = props
    const companySizeCollection = CollectionHandler.Get('companySizeCollection')
    const studyDomainCollection = CollectionHandler.Get('studyDomainCollection')
    const sectorCollection = CollectionHandler.Get('sectorCollection')

    return (
        <FiltersModalWrapper
            type='first_job'
            titleId='job_filters.more_filters'
            buttonId='job_results.popup.save_button'
            showModal={showModal}
            closeModal={closeModal}
            offerCount={offerCount}
            reset={reset}
        >
            <MoreFilterSectionWrapper
                titleId='job_filters.text_filter.title'
                underTitleId='job_filters.text_filter.description'
            >
                <Box className={classes.filterBox}>
                    <TextFilter
                        handleFilterChange={handleFilterChange}
                        debouncedFilterChange={debouncedFilterChange}
                        filters={filters}
                        setFilters={setFilters}
                        filterTextId='search_bar.search.job'
                        modal
                    />
                </Box>
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.study_fields.title'
                underTitleId='job_filters.study_fields.description'
            >
                <CheckboxList
                    type='studyDomain'
                    collection={studyDomainCollection}
                    selectedItems={filters.studyDomain}
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'studyDomain') }}
                    setFilters={setFilters}
                    width={400}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.location.title'
                underTitleId='job_filters.location.description'
            >
                <Box className={classes.filterBox}>
                    <CityFilter
                        handleInputSearchCity={handleInputSearchCity}
                        searchCities={searchCities}
                        citiesInput={citiesInput}
                        setSearchCities={setSearchCities}
                        setCitiesInput={setCitiesInput}
                        setFilters={setFilters}
                        filters={filters}
                        width={500}
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.sector.title'
                underTitleId='job_filters.sector.description'
            >
                <Box className={classes.filterBox}>
                    <MultipleSelectFilter
                        type='sector'
                        collection={sectorCollection}
                        filters={filters}
                        setFilters={setFilters}
                        width={500}
                        filterTextId='search_bar.sector'
                        showTags
                        dropdownPosition='left'
                        modal
                    />
                </Box>
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.company_type.title'
                underTitleId='job_filters.company_type.description'
            >
                <TagMultiSelect
                    setSelectedItems={(selectedItems) => { setFiltersPerType(selectedItems, 'companySize') }}
                    selectedItems={filters.companySize}
                    collection={companySizeCollection}
                    width={600}
                />
            </MoreFilterSectionWrapper>
            <MoreFilterSectionWrapper
                titleId='job_filters.traineeships.title'
                underTitleId='job_filters.traineeships.description'
                disableDivider
            >
                <BooleanFilter
                    type='traineeship'
                    filters={filters}
                    setFilters={setFilters}
                    filterTextId='filters.traineeship'
                />
            </MoreFilterSectionWrapper>
        </FiltersModalWrapper>
    )
}

export default FirstJobFiltersModalView
