import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    sliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 32
    },
    topContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 8
    },
    titleContainer: {
        display: 'flex',
        gap: 20,
        width: 1280,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '0 12px',
            flexDirection: 'column',
            alignItems: 'flex-start'
        }
    },
    arrowContainer: {
        display: 'flex',
        width: '30%',
        justifyContent: 'flex-end',
        gap: 20
    },
    cardsContainer: {
        width: '100%',
        display: 'flex',
        columnGap: 20,
        overflowX: 'scroll',
        paddingBottom: 32,
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none', /* Firefox */
        '-ms-overflow-style': 'none', /* IE 10+ */
        '&::-webkit-scrollbar': { /* Chrome/Safari/Webkit */
            height: 0,
            background: 'transparent'
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 12px'
        },
        scrollSnapType: 'x mandatory'
    }
}))
