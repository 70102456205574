import React, {} from 'react'
import { Image, Transformation } from 'cloudinary-react'
import { FormattedMessage } from 'react-intl'
import Bugsnag from '@bugsnag/js'

import Box from '@material-ui/core/Box'

import IconFactory from 'shared/icons/IconFactory/IconFactory'
import CloudinaryHelper from 'assets/CloudinaryHelper'
import DocumentAPI from 'api/DocumentAPI'
import { useStyles } from './UploadImageV2_style'
import { MEDIUM_SIZE, SMALL_SIZE } from 'assets/CloudinaryURLHelper'

// TODO CHRIS: replace existing UploadImage components with V2 version
const UploadImageV2 = (props) => {
    const { image, setImage, isLarge } = props
    const classes = useStyles({ isLarge })

    const openModal = () => {
        const cloudy = CloudinaryHelper.createImageUploadWidget({
            cropping: false,
            multiple: false
        },
        (error, result) => {
            if (!error && result?.event === 'success') {
                try {
                    const data = {
                        cloudinary_key: result.info.public_id,
                        name: result.info.original_filename,
                        format: result.info.format,
                        is_used: true
                    }
                    DocumentAPI.postImage(data).then(
                        (response) => {
                            const newImage = {
                                id: response.data.document_object.id,
                                cloudinary_key: result.info.public_id,
                                format: result.info.format,
                                name: result.info.original_filename,
                                is_used: true
                            }
                            setImage(newImage)
                        }
                    )
                } catch (e) {
                    Bugsnag.notify(e)
                }
            }
        })
        cloudy.open()
    }
    const handleDelete = (image) => {
        DocumentAPI.deleteDocumentImage(image.id)
        setImage(null)
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.uploadBox} onClick={openModal}>
                <Box className={classes.uploadIcon}>
                    <IconFactory icon='folder-plus' color='var(--red)' fontSize='20px' />
                </Box>
                <Box className={classes.uploadText}>
                    <FormattedMessage id="upload_image.call_to_action" />
                </Box>
            </Box>
            {image &&
                <Box className={classes.imageContainer}>
                    <Box className={classes.image}>
                        <Image
                            cloudName="studentbe"
                            className={classes.cardMedia}
                            publicId={image.cloudinary_key}
                        >
                            <Transformation flags="progressive" fetchFormat="auto" height={isLarge ? MEDIUM_SIZE : SMALL_SIZE} quality="auto:good" crop="fill" />
                        </Image>
                        {!isLarge &&
                            <Box className={classes.imageName}>
                                {`${image.name}.${image.format}`}
                            </Box>
                        }
                    </Box>
                    <Box className={classes.deleteImage} onClick={() => { handleDelete(image) }}>
                        <IconFactory icon='cross' color='white' style={{ width: '12px', height: '12px' }} />
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default UploadImageV2
