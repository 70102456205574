/* eslint-disable no-irregular-whitespace */
import React from 'react'

import { useStyles } from './MenuNavigation_style'
import { useStyles as useDarkModeStyles } from './MenuNavigationDarkMode_style'
import { AppBar, Box, Container, Hidden } from '@material-ui/core'

import { FormattedMessage } from 'react-intl'
import CloudinaryURLHelper from 'assets/CloudinaryURLHelper'
import LogoHelper from 'assets/LogoHelper'
import MobileAppBar from './MobileNavigation/MobileAppBar/MobileAppBar'
import NavigationButton from './NavigationButton/NavigationButton'
import NavigationUserActions from './NavigationUserActions/NavigationUserActions'
import RouteMapLink from 'wrappers/RouteMapLink/RouteMapLink'

const MenuNavigation = (props) => {
    const { user, page, altLangUrls, paramsForUrlTranslation, selectedArticleTagObject, darkMode } = props
    const classes = darkMode ? useDarkModeStyles() : useStyles()

    // Used to get the active Page.
    // Needed as we have the "Education" button in the navbar that should not be
    // active when the current page is "student-life" or other categories.
    const getStudentLifePage = () => {
        if (selectedArticleTagObject && selectedArticleTagObject.value === 'education') {
            return `student-life/categories/${selectedArticleTagObject.value}`
        } else {
            return page
        }
    }

    return (<>
        <Hidden mdDown>
            <AppBar position='static' color='white' elevation={0} className={classes.appBar}>
                <Container maxWidth='lg' className={classes.container}>
                    {/* Logo + Navigation Buttons | LEFT CONTAINER */}
                    <Box className={classes.navigationLeftContainer}>
                        <RouteMapLink page='/'>
                            <img
                                src={CloudinaryURLHelper.getImageUrl('DEFAULT_LOGO_STUDENT', `student-be-logos/${LogoHelper.getStudentLogo(darkMode)}`)}
                                alt='Student.be Logo'
                                className={classes.logoImage}
                            />
                        </RouteMapLink>

                        {/* Navigation Buttons */}
                        <Box className={classes.navigationButtonsContainer}>
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.student_job_button' />}
                                page={page}
                                type='student-jobs'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.internship_button' />}
                                page={page}
                                type='internships'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.first_job_button' />}
                                page={page}
                                type='first-jobs'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.companies_burger' />}
                                page={page}
                                type='companies'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.education_new' />}
                                page={selectedArticleTagObject ? `student-life/categories/${selectedArticleTagObject.value}` : page}
                                type='student-life/categories/education'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.kot_button' />}
                                page={page}
                                type='student-rooms'
                                darkMode={darkMode}
                            />
                            <NavigationButton
                                name={<FormattedMessage id='page_footer_navbar_data.news' />}
                                page={getStudentLifePage()}
                                type='student-life'
                                darkMode={darkMode}
                            />
                        </Box>
                    </Box>

                    {/* Login Button / Connected Features | RIGHT CONTAINER */}
                    <Box>
                        <NavigationUserActions
                            altLangUrls={altLangUrls}
                            user={user}
                            darkMode={darkMode}
                            paramsForUrlTranslation={paramsForUrlTranslation}
                        />
                    </Box>
                </Container>
            </AppBar>
        </Hidden>
        <MobileAppBar
            altLangUrls={altLangUrls}
            user={user}
            darkMode={darkMode}
            paramsForUrlTranslation={paramsForUrlTranslation}
        />
    </>)
}

export default MenuNavigation
