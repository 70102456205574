/* eslint-disable react/prop-types */

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'cloudinary-react'
import { useLocation } from 'react-router-dom'

import { useStyles } from './Footer_style'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'

import RouteMap from 'assets/RouteMap.js'
import LangSelect from 'inputs/LangSelect/LangSelect'
import IconFactory from 'icons/IconFactory/IconFactory'
import FooterList from './components/FooterList'

const Footer = (props) => {
    const { classes, paramsForUrlTranslation, disableFooterLists } = props

    const locale = RouteMap.GetLocaleFromUrl()
    const location = useLocation()
    const isHomePage = location.pathname === '/'

    const targetPageOrLogin = (targetUrl) => {
        if (props.user) {
            return targetUrl
        } else {
            return `${RouteMap.Page('login')}?userType=employer&redirect=${targetUrl}`
        }
    }

    const getInstagramAccountUrl = () => {
        switch (locale) {
        case 'fr':
            return 'https://www.instagram.com/student.be_fr/'
        case 'en':
        case 'nl':
            return 'https://www.instagram.com/student.be_nl/'
        }
    }

    const getTiktokAccountUrl = () => {
        switch (locale) {
        case 'fr':
            return 'https://www.tiktok.com/@student.be_fr'
        case 'en':
        case 'nl':
            return 'https://www.tiktok.com/@student.be_nl'
        }
    }

    return (
        <>
            {/* FOOTER 1 */}
            {(!isHomePage && !disableFooterLists) &&
                <Box className={classes.footerContainer1}>
                    <Container maxWidth='lg'>
                        <Grid container spacing={4}>
                            <Grid item className={classes.linksContainer} xs={6} sm={3}>
                                <Typography
                                    variant='h3'
                                    component='p'
                                    color='primary'
                                    className={classes.titleColumn1}
                                >
                                    <FormattedMessage id="page_footer_navbar_data.student_job_button" />
                                </Typography>
                                <FooterList type='student-jobs' classes={classes}/>
                            </Grid>
                            <Grid item className={classes.linksContainer} xs={6} sm={3}>
                                <Typography
                                    variant='h3'
                                    component='p'
                                    color='primary'
                                    className={classes.titleColumn1}
                                >
                                    <FormattedMessage id="page_footer_navbar_data.internship_button" />
                                </Typography>
                                <FooterList type='internships' classes={classes}/>
                            </Grid>
                            <Grid item className={classes.linksContainer} xs={6} sm={3}>
                                <Typography
                                    variant='h3'
                                    component='p'
                                    color='primary'
                                    className={classes.titleColumn1}
                                >

                                    <FormattedMessage id="page_footer_navbar_data.first_job_button" />
                                </Typography>
                                <FooterList type='first-jobs' classes={classes}/>
                            </Grid>
                            <Grid item className={classes.linksContainer} xs={6} sm={3}>
                                <Typography
                                    variant='h3'
                                    component='p'
                                    color='primary'
                                    className={classes.titleColumn1}
                                >
                                    <FormattedMessage id="page_footer_navbar_data.news_button" />
                                </Typography>
                                <FooterList type='news' classes={classes}/>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            }
            {/* FOOTER 2 */}
            <Box className={classes.footerContainer}>
                <Container maxWidth='lg'>
                    <Grid container spacing={4}>
                        { /* <Grid item className={`${classes.linksContainer} ${classes.logoContainer}`} sm={12} md={3}> */}
                        <Grid item className={classes.linksContainer} xs={12} sm={12} md={3}>
                            <Image
                                className={classes.logo}
                                cloudName="studentbe"
                                publicId='student-be-logos/Student.be_favicon_logo_white_no_background'
                                alt='Student.be logo'
                            />
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.footerTitle}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.footer_title_up" />
                            </Typography>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.footerTitle}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.footer_title_back" />
                            </Typography>
                        </Grid>
                        <Grid item className={classes.linksContainer} xs={6} sm={6} md={3}>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.titleColumn}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.employer" />
                            </Typography>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('employers')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.our_approach" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('contact-employer')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.contact_us" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('publish-a-student-job')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.publish_student_job" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={targetPageOrLogin(RouteMap.Page('users/internships/new'))}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.publish_internship" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={targetPageOrLogin(RouteMap.Page('users/first_jobs/new'))}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.publish_job" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('payment')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.package_and_price" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.ArticleCategory('employer')}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.article_for_employers" />
                            </Link>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.titleColumn}
                                style={{ marginTop: 20 }}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.employer_kot" />
                            </Typography>
                            {props.user?.type !== 'employer' &&
                                <Link
                                    variant='body2'
                                    component='a'
                                    color='initial'
                                    href={props.user ? RouteMap.Page('users/kots/new') : `${RouteMap.Page('login')}?redirect=${RouteMap.Page('users/kots/new')}` }
                                    className={classes.link}
                                >
                                    <FormattedMessage id="page_footer_navbar_data.footer.publish_kot" />
                                </Link>
                            }
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('kot-owners')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.kot_terms" />
                            </Link>
                        </Grid>
                        <Grid item className={classes.linksContainer} xs={6} sm={6} md={3}>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.titleColumn}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.students_and_young_graduate_part1" />
                            </Typography>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.titleColumn}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.students_and_young_graduate_part2" />
                            </Typography>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('student-job')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_student_jobs_temp" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('summer-job')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_summer_jobs_temp" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('internship')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_internship_temp" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('starter-job')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_first_job_temp" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('companies')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_discover_company" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('student-life/categories/education')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.education" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('student-room')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_kot_temp" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('only-for-students')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.promotions" />
                            </Link>
                        </Grid>
                        <Grid item className={classes.linksContainer} xs={6} sm={6} md={3}>
                            <Typography
                                variant='h6'
                                component='p'
                                className={classes.titleColumn}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.student_be" />
                            </Typography>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('mybusinessschoolevent')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                Business school fair
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('about')}
                                hreflang={`${locale}-BE`}
                                className={`${classes.link}`}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_us" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('podcast')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.podcast" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('press')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_press" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('faq')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.about_faq" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('terms')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.terms" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('contact')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.contact_us" />
                            </Link>
                            <Link
                                variant='body2'
                                component='a'
                                color='initial'
                                href={RouteMap.Page('login')}
                                hreflang={`${locale}-BE`}
                                className={classes.link}
                            >
                                <FormattedMessage id="page_footer_navbar_data.footer.register" />
                            </Link>
                        </Grid>
                    </Grid>
                    <Divider orientation='horizontal' className={classes.divider} />
                    <Grid container spacing={2} className={classes.footerBottomLinks}>
                        <Grid item xs={12} sm={8} className={classes.footerLeftBox}>
                            <Box className={classes.iconsContainer}>
                                <IconButton
                                    href='https://www.facebook.com/student.be/'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.iconButton}
                                >
                                    <IconFactory icon='facebook' className={classes.icon} />
                                </IconButton>
                                <IconButton
                                    href={getInstagramAccountUrl()}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.iconButton}
                                >
                                    <IconFactory icon='instagram' className={classes.icon} />
                                </IconButton>
                                <IconButton
                                    href='https://www.linkedin.com/company/student-be'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.iconButton}
                                >
                                    <IconFactory icon='linkedin' className={classes.icon} />
                                </IconButton>
                                <IconButton
                                    href={getTiktokAccountUrl()}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className={classes.iconButton}
                                >
                                    <IconFactory icon='tiktok' className={classes.icon} />
                                </IconButton>
                            </Box>
                            <Divider orientation='vertical' className={classes.dividerLangIcons} />
                            <LangSelect
                                color="white"
                                lang='FR'
                                name='footer'
                                altLangUrls={props.altLangUrls}
                                allLangsFlat
                                paramsForUrlTranslation={paramsForUrlTranslation}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.footerRightBox}>
                            <Typography
                                variant='body1'
                                component='div'
                                className={classes.copyrightTypo}
                            >
                                <IconFactory icon='copyright' className={classes.iconCopyright}/>
                                <FormattedMessage
                                    id="page_footer_navbar_data.footer.right_reserved"
                                    values={{ year: new Date().getFullYear() }}
                                />
                                <span onClick={() => { window.openAxeptioCookies() }} style={{ marginLeft: '0.3rem', cursor: 'pointer' }}> - Cookies</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default withStyles(useStyles)(Footer)
